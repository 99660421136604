import { Badge, Button, Table } from 'react-bootstrap';
import { Job, JobPhoto, JobVehiclePart, NewJobVehiclePartToAdd } from '../../../jobs/types/jobs';
import React, { Fragment, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { JobVehiclePartEditFormData, JobVehiclePartFormData } from '../../types/vehicleParts';
import { transformVehiclePartsFromForm } from '../../services/vehicleParts';
import { FormProvider, useForm } from 'react-hook-form';
import FormInput from '../../../../libs/components/forms/FormInput';
import { formatDate } from '../../../../libs/services/date';
import EditVehiclePartModal from './EditVehiclePartModal';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import { useAuth } from '../../../../libs/context/AuthContext';
import VehiclePartStatus from './VehiclePartStatus';
import { toPrice } from '../../../../libs/services/price';

interface VehiclePartsToOrderProps {
  job: Job;
  onVehiclePartsSave?: (parts: NewJobVehiclePartToAdd[]) => void;
  onVehiclePartUpdate?: (partId: string, part: JobVehiclePartEditFormData) => void;
  showPhotosOfPart: (partId: string) => void;
}

function getNumberOfLinkedPhotos(partId: string, photos: JobPhoto[]): number {
  let result = 0;
  photos.forEach(photo => {
    if (photo.vehiclePartId === partId) {
      result++;
    }
  });
  return result;
}

export default function VehiclePartsToOrder({
  job,
  onVehiclePartsSave,
  onVehiclePartUpdate,
  showPhotosOfPart,
}: VehiclePartsToOrderProps) {
  const { user, isAdminOrOffice } = useAuth();
  const [parts, setParts] = useState<JobVehiclePartFormData[]>([]);
  const [editedPart, setEditedPart] = useState<JobVehiclePart | undefined>();
  const [addSuccess, setAddSuccess] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const methods = useForm<Record<string, any>>({
    mode: 'all',
    defaultValues: {},
  });
  const [articleNumber1Required, setArticleNumber1Required] = useState(true);

  useEffect(() => {
    setParts([]);
    setEditModal(false);
  }, [job]);

  function addPartItem() {
    setAddSuccess(false);
    setParts([
      ...parts,
      {
        _new: true,
        type: 'Artikel',
        id: v4(),
        name: '',
        partNumber1: '',
        partNumber2: '',
        price: '',
        quantity: '',
      },
    ]);
  }

  function editExistingPartItem(part: JobVehiclePart) {
    setEditedPart(part);
    setEditModal(true);
  }

  async function onSave() {
    if (!onVehiclePartsSave) {
      return;
    }
    setAddSuccess(false);
    await methods.trigger();
    await methods.handleSubmit(data => {
      const transformedData = transformVehiclePartsFromForm(parts, data, user?.isAdmin);
      onVehiclePartsSave(transformedData);
      setAddSuccess(true);
    })();
  }

  function onPartDelete(part: JobVehiclePartFormData) {
    return async function () {
      setParts(parts.filter(item => item.id !== part.id));
      methods.unregister(`part_${part.id}_name`);
      methods.unregister(`part_${part.id}_partNumber1`);
      methods.unregister(`part_${part.id}_partNumber2`);
      methods.unregister(`part_${part.id}_quantity`);
      methods.unregister(`part_${part.id}_price`);
      await methods.trigger();
    };
  }

  function getPriceOfPart(part: JobVehiclePartFormData | JobVehiclePart, fromForm: boolean) {
    if (!fromForm) {
      return (Number(part.quantity) * Number(part.price)).toFixed(2).replace('.', ',') + ' €';
    }

    const quantityState = methods.getFieldState(`part_${part.id}_quantity`);
    const priceState = methods.getFieldState(`part_${part.id}_price`);
    if (quantityState.error || priceState.error) {
      return '---';
    }

    const values = methods.getValues();
    const quantity: string = values[`part_${part.id}_quantity`];
    const price: string = values[`part_${part.id}_price`];

    if (!quantity || quantity.trim() === '' || !price || price.trim() === '') {
      return '---';
    }

    return (Number(quantity) * Number(price.replace(',', '.'))).toFixed(2).replace('.', ',') + ' €';
  }

  function partAddedByCurrentUserOrAdminOffice(part: JobVehiclePart): boolean {
    if (!user) {
      return false;
    }
    return isAdminOrOffice() || user.id === part.createdBy.id;
  }

  function calculateProfit() {
    let profit = 0;
    let ektotal = 0;
    let vktotal = 0;

    job.vehicleParts.forEach(part => {
      if (!part.priceEKNet || !part.priceVKNet) {
        return;
      }

      ektotal += part.priceEKNet;
      vktotal += part.priceVKNet;

      profit += (part.priceVKNet - part.priceEKNet) * part.quantity;
    });

    return {
      ekTotalNetto: toPrice(ektotal),
      ekTotalBrutto: toPrice(ektotal * 1.19),
      vkTotalNetto: toPrice(vktotal),
      vkTotalBrutto: toPrice(vktotal * 1.19),
      profitNetto: toPrice(profit),
      profitBrutto: toPrice(profit * 1.19),
    };
  }

  const profit = calculateProfit();

  const watchType = methods.watch('type');

  useEffect(() => {
    if (watchType === 'Eigene Position') {
      setArticleNumber1Required(false);
    } else {
      setArticleNumber1Required(true);
    }
  }, [watchType]);

  return (
    <>
      {editedPart && (
        <EditVehiclePartModal
          show={editModal}
          editedPart={editedPart}
          onSaved={onVehiclePartUpdate!}
          onCancel={() => setEditModal(false)}
        />
      )}

      <FormProvider {...methods}>
        <SectionTitle title="Autoteile zu bestellen" />
        {onVehiclePartsSave !== undefined && (
          <div className="mb-3">
            <Button size="sm" variant="light" onClick={addPartItem} disabled={parts.length > 0}>
              <i className="bi bi-plus-circle-fill pe-2"></i>
              Teil hinzufügen
            </Button>
          </div>
        )}
        <Table striped bordered className="shadow-sm">
          <thead>
            <tr>
              <th style={{ width: 175 }}>Ang. am / Status</th>
              <th>Beschreibung</th>
              <th style={{ width: 200 }}>Teilnummer</th>
              <th style={{ width: 125 }}>Anzahl</th>
              <th style={{ width: 125 }}>Preis</th>
              <th style={{ width: 35 }}>Bilder</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {job.vehicleParts.map(part => (
              <tr key={`existing-${part.id}`}>
                <td>
                  <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                    Angelegt am/von
                  </div>
                  <div>{formatDate(part.createdAt, 'date')}</div>
                  <div className="mb-2">{part.createdBy.name}</div>
                  <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                    Status
                  </div>
                  <VehiclePartStatus part={part} />
                </td>
                <td>
                  <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                    Artikelname
                  </div>
                  <div className="mb-2">{part.name}</div>
                  {isAdminOrOffice() && (
                    <>
                      <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                        Hersteller
                      </div>
                      <div className="mb-2">{part.manufacturer}</div>
                      <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                        Rechnungsnummer
                      </div>
                      <div className="mb-2">{part.invoiceNo}</div>
                      <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                        Bestellt in
                      </div>
                      <div className="mb-2">{part.orderedIn}</div>
                      <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                        Bestellt am
                      </div>
                      <div className="mb-2">{formatDate(part.orderedAt as string, 'date')}</div>
                      <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                        Lieferzeit
                      </div>
                      <div className="mb-2">{formatDate(part.orderTime as string, 'date')}</div>
                    </>
                  )}
                </td>
                <td>
                  <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                    Nummer 1
                  </div>
                  <div className="mb-2">{part.partNumber1}</div>
                  <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                    Nummer 2
                  </div>
                  <div className="mb-2">{part.partNumber2}</div>
                  {isAdminOrOffice() && (
                    <>
                      <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                        Nummer
                      </div>
                      <div>{part.partNumber1}</div>
                    </>
                  )}
                </td>
                <td>{part.quantity}</td>
                <td>
                  <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                    Einzelpreis
                  </div>
                  <div>{part.price.toFixed(2).replace('.', ',')} &euro;</div>
                  <div>
                    <div className="text-muted mt-2" style={{ fontSize: '0.8rem' }}>
                      Preis insgesamt
                    </div>
                    <div className="mb-2">{getPriceOfPart(part, false)}</div>
                  </div>
                  {isAdminOrOffice() && (
                    <>
                      <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                        EK Preis Netto
                      </div>
                      <div className="mb-2">{toPrice(part.priceEKNet)}</div>
                      <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                        VK Preis Netto
                      </div>
                      <div className="mb-2">{toPrice(part.priceVKNet)}</div>
                      <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                        Kaution
                      </div>
                      <div className="mb-2">{toPrice(part.deposit)}</div>
                      <div className="text-success" style={{ fontSize: '0.8rem' }}>
                        Gewinn
                      </div>
                      {part.priceVKNet && part.priceEKNet && (
                        <div className="mb-2 text-success">
                          <strong>
                            {toPrice((part.priceVKNet - part.priceEKNet) * part.quantity)}
                          </strong>
                        </div>
                      )}
                    </>
                  )}
                </td>
                <td className="text-center">
                  {getNumberOfLinkedPhotos(part.id, job.photos) > 0 ? (
                    <Badge style={{ cursor: 'pointer' }} onClick={() => showPhotosOfPart(part.id)}>
                      {getNumberOfLinkedPhotos(part.id, job.photos)}
                    </Badge>
                  ) : (
                    '---'
                  )}
                </td>
                <td>
                  {partAddedByCurrentUserOrAdminOffice(part) && (
                    <Button
                      size="sm"
                      variant="link"
                      className="text-info"
                      onClick={() => editExistingPartItem(part)}>
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  )}
                </td>
              </tr>
            ))}
            {parts.map((part: JobVehiclePartFormData) => (
              <Fragment key={part.id}>
                <tr>
                  <td></td>
                  <td>
                    <div>
                      <FormInput
                        name="type"
                        type="select"
                        required={true}
                        label="Art"
                        selectItems={[
                          { value: 'Artikel', label: 'Artikel' },
                          { value: 'Eigene Position', label: 'Eigene Position' },
                        ]}
                        options={{
                          required: { value: true, message: 'Art darf nicht leer sein' },
                        }}
                      />
                    </div>
                    <FormInput
                      name={`part_${part.id}_name`}
                      label="Artikel"
                      type="text"
                      required={true}
                      options={{
                        required: { value: true, message: 'Artikel darf nicht leer sein' },
                      }}
                    />
                  </td>
                  <td>
                    <FormInput
                      name={`part_${part.id}_partNumber1`}
                      type="text"
                      label="Teilnummer 1"
                      required={articleNumber1Required}
                      options={{
                        required: {
                          value: articleNumber1Required,
                          message: 'Teilnummer 1 darf nicht leer sein',
                        },
                      }}
                    />
                    <FormInput
                      name={`part_${part.id}_partNumber2`}
                      type="text"
                      label="Teilnummer 2"
                      required={false}
                    />
                  </td>
                  <td>
                    <FormInput
                      name={`part_${part.id}_quantity`}
                      type="number"
                      required={true}
                      label="Anzahl"
                      options={{
                        required: { value: true, message: 'Anzahl darf nicht leer sein' },
                        pattern: { value: /^(0|[1-9]\d*)$/, message: 'Falsches Format' },
                      }}
                    />
                  </td>
                  <td>
                    <FormInput
                      name={`part_${part.id}_price`}
                      type="text"
                      required={true}
                      label="Einzelpreis"
                      options={{
                        required: {
                          value: true,
                          message: 'Preis darf nicht leer sein',
                        },
                        pattern: { value: /^(0|[1-9]\d*)(,\d+)?$/, message: 'Falsches Format' },
                      }}
                    />
                    <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                      Preis insgesamt
                    </div>
                    <div>{getPriceOfPart(part, true)}</div>
                  </td>
                  <td rowSpan={isAdminOrOffice() ? 2 : 1}></td>
                  <td className="align-middle" rowSpan={isAdminOrOffice() ? 2 : 1}>
                    {part._new && (
                      <>
                        {onVehiclePartsSave !== undefined && (
                          <div className="mb-4">
                            <Button size="sm" onClick={onSave}>
                              speichern
                            </Button>
                          </div>
                        )}
                        <Button
                          size="sm"
                          variant="link"
                          className="text-danger"
                          onClick={onPartDelete(part)}>
                          <i className="bi bi-trash-fill"></i>
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
                {isAdminOrOffice() && (
                  <tr>
                    <td>
                      <div>
                        <FormInput
                          name={`part_${part.id}_status`}
                          type="select"
                          selectItems={[
                            { value: 'toOrder', label: 'Zu bestellen' },
                            { value: 'ordered', label: 'Bestellt' },
                            { value: 'delivered', label: 'Geliefert' },
                          ]}
                          label="Status"
                          required={true}
                          options={{
                            required: { value: true, message: 'Status darf nicht leer sein' },
                          }}
                        />
                      </div>
                    </td>
                    <td colSpan={1}>
                      <div>
                        <FormInput
                          name={`part_${part.id}_partNumber`}
                          type="text"
                          label="Teilnummer"
                          required={false}
                        />
                      </div>
                      <div>
                        <FormInput
                          name={`part_${part.id}_manufacturer`}
                          type="text"
                          label="Herstellername"
                          required={false}
                        />
                      </div>
                      <div>
                        <FormInput
                          name={`part_${part.id}_invoiceNo`}
                          type="text"
                          label="Rechnungsnummer"
                          required={false}
                        />
                      </div>
                      <div>
                        <FormInput
                          name={`part_${part.id}_notes`}
                          type="textarea"
                          label="Anmerkungen"
                          required={false}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <FormInput
                          name={`part_${part.id}_orderedIn`}
                          type="text"
                          label="Bestellt in"
                          required={false}
                        />
                      </div>
                      <div>
                        <FormInput
                          name={`part_${part.id}_orderedAt`}
                          type="date"
                          label="Bestellt am"
                          required={false}
                        />
                      </div>
                      <div>
                        <FormInput
                          name={`part_${part.id}_orderTime`}
                          type="date"
                          label="Lieferzeit"
                          required={false}
                        />
                      </div>
                    </td>
                    <td colSpan={2}>
                      <div>
                        <FormInput
                          name={`part_${part.id}_priceVKNet`}
                          type="text"
                          label="VK Netto"
                          required={false}
                        />
                      </div>
                      <div>
                        <FormInput
                          name={`part_${part.id}_priceEKNet`}
                          type="text"
                          label="EK Netto"
                          required={false}
                        />
                      </div>
                      <div>
                        <FormInput
                          name={`part_${part.id}_deposit`}
                          type="text"
                          label="Kaution"
                          required={false}
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </Table>
        {isAdminOrOffice() && (
          <div className="text-end fs-5">
            <div>
              <strong>
                EK insgesamt (netto/brutto): {profit.ekTotalNetto} / {profit.ekTotalBrutto}
              </strong>
            </div>
            <div>
              <strong>
                VK insgesamt (netto/brutto): {profit.vkTotalNetto} / {profit.vkTotalBrutto}
              </strong>
            </div>
            <div className="text-success">
              <strong>
                Gewinn insgesamt (netto/brutto): {profit.profitNetto} / {profit.profitBrutto}
              </strong>
            </div>
          </div>
        )}
        {onVehiclePartsSave !== undefined && (
          <div className="mb-3">
            <Button size="sm" variant="light" onClick={addPartItem} disabled={parts.length > 0}>
              <i className="bi bi-plus-circle-fill pe-2"></i>
              Teil hinzufügen
            </Button>
          </div>
        )}
        {addSuccess && (
          <div className="text-success fs-5">
            <strong>Autoteil wurde gespeichert</strong>
          </div>
        )}
      </FormProvider>
    </>
  );
}
