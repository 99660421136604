import { Card, Col, ListGroup, Row } from 'react-bootstrap';

import { User } from '../../../users/types/users';
import FormInput from '../../../../libs/components/forms/FormInput';
import { mapUsersToForm } from '../../services/formUsers';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';

interface AssignedUsersFormProps {
  users: User[];
}

export default function AssignedUsersForm({ users }: AssignedUsersFormProps) {
  const nonAdminAndNonOfficeUsers = users.filter(item => !item.isAdmin && !item.isOffice);

  return (
    <div>
      <SectionTitle title="Mitarbeiter" />
      <Row>
        <Col lg={4}>
          <FormInput
            name="mainAssignedUser"
            label="Aufgabenmanager"
            required={true}
            type="select"
            selectItems={mapUsersToForm(nonAdminAndNonOfficeUsers)}
          />
        </Col>
      </Row>
      {nonAdminAndNonOfficeUsers.map(item => (
        <ListGroup.Item key={item.id} className="mt-2">
          <FormInput
            name={`assigned_user_${item.id}`}
            label={<span className="pe-2">{item.name}</span>}
            required={false}
            type="checkbox"
            className=""
          />
        </ListGroup.Item>
      ))}
    </div>
  );
}
