import React, { useEffect, useState } from 'react';

import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import StorageTable from './StorageTable';
import { StorageArticle } from '../../types/storage';
import { deleteArticle, getArticles } from '../../services/api';
import { Col, Form, Row } from 'react-bootstrap';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';
import { getStorageCategories } from '../../../storage_categories/services/api';
import { StorageCategory } from '../../../storage_categories/types/storageCategories';

export default function StorageList() {
  const [articles, setArticles] = useState<StorageArticle[]>([]);
  const [categories, setCategories] = useState<StorageCategory[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [actionItem, setActionItem] = useState<StorageArticle | undefined>();
  const [error, setError] = useState<string>('');
  const [categoryFilter, setCategoryFilter] = useState<string>('');

  useEffect(() => {
    loadArticles();
    getStorageCategories().then(data => setCategories(data));
  }, []);

  useEffect(() => {
    loadArticles();
  }, [categoryFilter]);

  useEffect(() => {
    loadArticles();
  }, [filter]);

  function loadArticles() {
    getArticles(filter, categoryFilter).then(data => setArticles(data));
  }

  function onConfirmDelete(item: StorageArticle) {
    setActionItem(item);
    setError('');
    setDeleteModal(true);
  }

  async function onDelete() {
    setError('');
    if (actionItem) {
      try {
        await deleteArticle(actionItem.id);
        setActionItem(undefined);
        setDeleteModal(false);
        loadArticles();
      } catch (err: any) {
        setError(err.response?.data?.message || err.message);
      }
    }
  }

  function onCategoryFilter(event: React.ChangeEvent<HTMLSelectElement>) {
    setCategoryFilter(event.target.value);
  }

  return (
    <>
      <ConfirmModal
        show={deleteModal}
        title="Artikel löschen"
        onConfirm={onDelete}
        onCancel={() => setDeleteModal(false)}>
        <>
          {' '}
          <div>Möchten Sie wirklich den Artikel löschen?</div>
          {error !== '' && <div className="text-danger mt-2">{error}</div>}
        </>
      </ConfirmModal>
      <PageToolbar pageTitle="Lager" titleColWidth={2} contentColWidth={10}>
        <Row>
          <Col lg={2}></Col>
          <Col lg={3}>
            <Form.Select onChange={onCategoryFilter} value={categoryFilter}>
              <option value="">--- Alle Kategorien ---</option>
              {categories.map(cat => (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col lg={{ span: 1 }} className="pt-2">
            Filtern
          </Col>
          <Col lg={{ span: 3 }}>
            <Form.Control onChange={e => setFilter(e.target.value || '')} />
          </Col>
          <Col>
            <ButtonLink link="/storage/add" buttonColor="success" icon="plus-circle-fill">
              Artikel hinzufügen
            </ButtonLink>
          </Col>
        </Row>
      </PageToolbar>
      <StorageTable articles={articles} onDelete={onConfirmDelete} />
    </>
  );
}
