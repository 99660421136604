import { useState } from 'react';

import { User } from '../../types/users';
import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';
import UsersTable from './UsersTable';
import { useUsers } from '../../hooks/useUsers';

export default function UsersList() {
  const { users, deleteUser } = useUsers();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [actionItem, setActionItem] = useState<User | undefined>();
  const [error, setError] = useState<string>('');

  function onConfirmDelete(item: User) {
    setActionItem(item);
    setError('');
    setDeleteModal(true);
  }

  async function onDelete() {
    setError('');
    if (actionItem) {
      try {
        await deleteUser(actionItem.id);
        setActionItem(undefined);
        setDeleteModal(false);
      } catch (err: any) {
        setError(err.response?.data?.message || err.message);
      }
    }
  }

  return (
    <>
      <ConfirmModal
        show={deleteModal}
        title="Benutzer löschen"
        onConfirm={onDelete}
        onCancel={() => setDeleteModal(false)}>
        <>
          <div>
            Möchten Sie wirklich den Benutzer <strong>{actionItem?.name}</strong> löschen?
          </div>
          {error !== '' && <div className="text-danger mt-2">{error}</div>}
        </>
      </ConfirmModal>
      <PageToolbar pageTitle="Benutzer">
        <ButtonLink link="/users/add" buttonColor="success" icon="plus-circle-fill">
          Benutzer anlegen
        </ButtonLink>
      </PageToolbar>
      <UsersTable users={users} onDelete={onConfirmDelete} />
    </>
  );
}
