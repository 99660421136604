import { Button, Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import FormInput, { SelectOption } from '../../../../libs/components/forms/FormInput';
import { mapBrandsToForm, mapBrandTypesToForm } from '../../services/formBrands';
import { Brand, BrandType } from '../../../brands/types/brands';
import AddBrandModal from './AddBrandModal';
import { getBrands } from '../../../brands/services/api';
import AddBrandTypeModal from './AddBrandTypeModal';

export default function BrandForm() {
  const methods = useFormContext();
  const [brands, setBrands] = useState<Brand[]>([]);
  const [createdBrand, setCreatedBrand] = useState<Brand | undefined>();
  const [createdBrandType, setCreatedBrandType] = useState<BrandType | undefined>();
  const [brandItems, setBrandItems] = useState<SelectOption[]>([]);
  const [brandTypeItems, setBrandTypeItems] = useState<SelectOption[]>([]);
  const [addBrandModal, setAddBrandModal] = useState<boolean>(false);
  const [addBrandTypeModal, setAddBrandTypeModal] = useState<boolean>(false);

  useEffect(() => {
    loadBrands();
  }, []);

  function loadBrands() {
    getBrands().then(data => {
      setBrands(data);
      setBrandItems(mapBrandsToForm(data));
    });
  }

  const watchBrand = methods.watch('brand');
  const watchBrandType = methods.watch('brandType');

  useEffect(() => {});

  // load brand types for selected brand
  useEffect(() => {
    if (watchBrand && brands) {
      setBrandTypeItems(mapBrandTypesToForm(watchBrand, brands));
      methods.setFocus('brand');
    } else {
      setBrandTypeItems([]);
    }
  }, [watchBrand]);

  // trigger validation when brand type changed
  useEffect(() => {
    if (watchBrandType) {
      methods.setFocus('brandType');
    }
  }, [watchBrandType]);

  // automatically select created brand
  useEffect(() => {
    if (createdBrand) {
      methods.setValue('brand', createdBrand.id);
      setCreatedBrand(undefined);
      setBrandItems(mapBrandsToForm(brands));
    }
  }, [brands]);

  // automatically set brand types on brand items change
  useEffect(() => {
    setBrandTypeItems(mapBrandTypesToForm(methods.getValues('brand'), brands));
  }, [brandItems]);

  // automatically select created brand type
  useEffect(() => {
    if (createdBrandType) {
      methods.setValue('brandType', createdBrandType.id);
      setCreatedBrandType(undefined);
    }
  }, [brandTypeItems]);

  async function onBrandCreate(newBrand: Brand) {
    setAddBrandModal(false);
    await loadBrands();
    setCreatedBrand(newBrand);
  }

  async function onBrandTypeCreate(newBrandType: BrandType) {
    setAddBrandTypeModal(false);
    setCreatedBrandType(newBrandType);
    await loadBrands();
  }

  function getSelectedBrand(): Brand | undefined {
    if (!brands) {
      return;
    }
    return brands.find(item => item.id === methods.getValues('brand'));
  }

  return (
    <>
      <Row>
        <AddBrandModal
          existingBrands={brands}
          show={addBrandModal}
          onSaved={onBrandCreate}
          onCancel={() => setAddBrandModal(false)}
        />
        <AddBrandTypeModal
          selectedBrand={getSelectedBrand()}
          show={addBrandTypeModal}
          onSaved={onBrandTypeCreate}
          onCancel={() => setAddBrandTypeModal(false)}
        />
        <Col>
          <Row>
            <Col lg={7}>
              <FormInput
                name="brand"
                label="Marke"
                type="select"
                selectItems={brandItems}
                required={true}
                options={{ required: true }}
              />
            </Col>
            <Col className="text-end mt-4 pt-1">
              <Button variant="light" onClick={() => setAddBrandModal(true)}>
                <i className="bi bi-plus-circle-fill pe-2"></i>
                Marke
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col lg={7}>
              <FormInput
                name="brandType"
                label="Modell"
                type="select"
                selectItems={brandTypeItems}
                required={true}
                options={{ required: true }}
              />
            </Col>
            <Col className="text-end mt-4 pt-1">
              <Button
                variant="light"
                disabled={!watchBrand}
                onClick={() => setAddBrandTypeModal(true)}>
                <i className="bi bi-plus-circle-fill pe-2"></i>
                Modell
              </Button>
            </Col>
          </Row>
        </Col>
        <Col></Col>
      </Row>
    </>
  );
}
