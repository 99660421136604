import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { VehicleCommission, VehicleCommissionPhoto } from '../../types/vehicle_commissions';
import { getVehicleCommission } from '../../services/api';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { getVehicleCommissionPreviewPath } from '../../../photos/services/photos';
import { getFormattedDateOrEmpty } from '../../../../libs/services/date';

export default function VehicleCommissionDetails() {
  const { id } = useParams();
  const [details, setDetails] = useState<VehicleCommission | null>(null);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number | undefined>(undefined);
  const [showModalWithGallery, setShowModalWithGallery] = useState<boolean>(false);

  useEffect(() => {
    loadDetails();
  }, [id]);

  function loadDetails() {
    if (!id) {
      return;
    }
    getVehicleCommission(id).then(data => setDetails(data));
  }

  if (details === null) {
    return null;
  }

  function onClick(photo: VehicleCommissionPhoto) {
    setShowModalWithGallery(true);
    setSelectedPhotoIndex(documentPhotos.findIndex(item => item.id === photo.id));
  }

  const documentPhotos = details.photos.filter(photo => photo.type === 'document');
  const otherPhotos = details.photos.filter(photo => photo.type === 'other');

  return (
    <div>
      <Row>
        <Col lg={7}>
          <SectionTitle title="Fahrzeug" />
          <Table striped bordered className="shadow-sm">
            <tbody>
              <tr>
                <td>
                  <strong>Annahmedatum</strong>
                </td>
                <td>
                  {getFormattedDateOrEmpty(
                    details.commissionDate + 'T' + details.commissionTime,
                    'datetime',
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ width: 210 }}>
                  <strong>Marke</strong>
                </td>
                <td>
                  {details.brand.name}{' '}
                  {details.brand.types.find(type => type.id === details.brandType)?.name}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Kennzeichen</strong>
                </td>
                <td>{details.plate}</td>
              </tr>
              <tr>
                <td>
                  <strong>KM-Stand</strong>
                </td>
                <td>{details.mileage}</td>
              </tr>
            </tbody>
          </Table>

          <SectionTitle title="Ausgehändigte Fahrzeugdokumente" />
          <Table striped bordered className="shadow-sm">
            <tbody>
              {details.documentMissing && (
                <tr>
                  <td>
                    <strong>Keine Ausgehändigte Fahrzeugdokumente</strong>
                  </td>
                </tr>
              )}
              {!details.documentMissing && (
                <>
                  <tr>
                    <td style={{ width: 210 }}>
                      <strong>Dokument</strong>
                    </td>
                    <td>{details.documentType}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Original/Kopie</strong>
                    </td>
                    <td>{details.documentFormat}</td>
                  </tr>
                </>
              )}
              {documentPhotos.length > 0 && (
                <tr>
                  <td colSpan={2}>
                    {documentPhotos.map(photo => (
                      <Card className="bg-light">
                        <Card.Body className="p-2">
                          <div style={{ overflow: 'hidden', height: 125 }}>
                            <img
                              style={{ cursor: 'pointer', maxHeight: 125 }}
                              src={getVehicleCommissionPreviewPath(photo)}
                              alt={photo.fileName}
                              onClick={() => onClick(photo)}
                              className="img-fluid"
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <SectionTitle title="Warnleuchten" />
          <Table striped bordered className="shadow-sm">
            <tbody>
              <tr>
                <td style={{ width: 210 }}>
                  <strong>Aktive Warnleuchten</strong>
                </td>
                <td>
                  {!details.warningLightsActive && (
                    <div>
                      <strong>Warnleuchten nicht aktiv</strong>
                    </div>
                  )}
                  {details.warningLightsActive && (
                    <ul>
                      {details.warningLightsNotReadable && (
                        <li>Warnleuchten können nicht abgelesen werden</li>
                      )}
                      {details.mileageNotReadable && <li>KM-Stand kann nicht abgelesen werden</li>}
                      {details.warningLightCheckEngine && <li>Motorkontrollleuchte</li>}
                      {details.warningLightAirbagSrs && <li>Airbag / SRS</li>}
                      {details.warningLightAbsEspAsr && <li>ABS / ESP / ASR</li>}
                      {details.warningLightService && <li>Service</li>}
                      {details.warningLightImmobilizer && <li>Wegfahrsperre</li>}
                      {details.warningLightOilPressure && <li>Öldruck</li>}
                      {details.warningLightParkingBrake && <li>Parkbremse</li>}
                      {details.warningLightWashwaterLevel && <li>Waschwasserstand</li>}
                      {details.warningLightBrakepadWear && <li>Bremsbelagverschleiß</li>}
                      {details.warningLightTractionControl && <li>Traktionskontrolle</li>}
                      {details.warningLightBattery && <li>Batterie</li>}
                      {details.warningLightPreglow && <li>Vorglühen</li>}
                      {details.warningLightParticulateFilter && <li>Partikelfilter</li>}
                    </ul>
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ width: 210 }}>
                  <strong>Andere</strong>
                </td>
                <td>{details.warningLightsOther}</td>
              </tr>
            </tbody>
          </Table>

          <SectionTitle title="Erschwerte Übernahmebedingungen durch" />
          <Table striped bordered className="shadow-sm">
            <tbody>
              <tr>
                <td>
                  <ul>
                    {details.conditionPollution && <li>Verschmutzung</li>}
                    {details.conditionRainwet && <li>Regen/Nässe</li>}
                    {details.conditionDarkness && <li>Dunkelheit</li>}
                    {details.conditionParkingGarage && <li>Parkhaus</li>}
                    {details.conditionSnowice && <li>Schnee/Eis</li>}

                    {details.conditionVehiclescratchedallaround && (
                      <li>Fahrzeug ist rundum verkratzt</li>
                    )}

                    {details.conditionVehiclerustedallover && (
                      <li>Fahrzeug ist rundum verrostet</li>
                    )}

                    {details.conditionVehicledirtyallover && (
                      <li>Fahrzeug ist rundum verschmutzt</li>
                    )}
                    {details.conditionGeneralsignsofwear && <li>Allgemeine Gebrauchspuren</li>}
                  </ul>
                </td>
              </tr>
            </tbody>
          </Table>

          <SectionTitle title="Zustandsprüfung außen" />
          <div>
            <img src="/vehicle_commission_new.jpg" alt="" style={{ width: 1000 }} />
          </div>
          <div className="mb-3">
            <div>
              <strong>Legende</strong>
            </div>
            <div>
              <strong>B</strong> - Beschädigung
              <strong className="ms-3">D</strong> - Delle
              <strong className="ms-3">K</strong> - Kratzer
              <strong className="ms-3">R</strong> - Rost
              <strong className="ms-3">S</strong> - Steinschlag/Rissbildung
            </div>
          </div>
          <Row>
            <Col>
              <Table striped bordered className="shadow-sm">
                <tbody>
                  <tr>
                    <td style={{ width: 50 }}>
                      <strong>A-1</strong>
                    </td>
                    <td>{details.a1}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>A-2</strong>
                    </td>
                    <td>{details.a2}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>A-3</strong>
                    </td>
                    <td>{details.a3}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>A-4</strong>
                    </td>
                    <td>{details.a4}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>A-5</strong>
                    </td>
                    <td>{details.a5}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>A-6</strong>
                    </td>
                    <td>{details.a6}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>A-7</strong>
                    </td>
                    <td>{details.a7}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>A-8</strong>
                    </td>
                    <td>{details.a8}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>A-9</strong>
                    </td>
                    <td>{details.a9}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>A-10</strong>
                    </td>
                    <td>{details.a10}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>A-11</strong>
                    </td>
                    <td>{details.a11}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>A-12</strong>
                    </td>
                    <td>{details.a12}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>A-13</strong>
                    </td>
                    <td>{details.a13}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table striped bordered className="shadow-sm">
                <tbody>
                  <tr>
                    <td style={{ width: 50 }}>
                      <strong>B-1</strong>
                    </td>
                    <td>{details.b1}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>B-2</strong>
                    </td>
                    <td>{details.b2}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>B-3</strong>
                    </td>
                    <td>{details.b3}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>B-4</strong>
                    </td>
                    <td>{details.b4}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>B-5</strong>
                    </td>
                    <td>{details.b5}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>B-6</strong>
                    </td>
                    <td>{details.b6}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>B-7</strong>
                    </td>
                    <td>{details.b7}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>B-8</strong>
                    </td>
                    <td>{details.b8}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table striped bordered className="shadow-sm">
                <tbody>
                  <tr>
                    <td style={{ width: 50 }}>
                      <strong>C-1</strong>
                    </td>
                    <td>{details.c1}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>C-2</strong>
                    </td>
                    <td>{details.c2}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table striped bordered className="shadow-sm">
                <tbody>
                  <tr>
                    <td style={{ width: 50 }}>
                      <strong>D-1</strong>
                    </td>
                    <td>{details.d1}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>D-2</strong>
                    </td>
                    <td>{details.d2}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>D-3</strong>
                    </td>
                    <td>{details.d3}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>D-4</strong>
                    </td>
                    <td>{details.d4}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>D-5</strong>
                    </td>
                    <td>{details.d5}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>D-6</strong>
                    </td>
                    <td>{details.d6}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>D-7</strong>
                    </td>
                    <td>{details.d7}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
