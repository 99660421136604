import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import {
  archiveVehicleAndLinkedJobs,
  getVehiclePlatesOfOpenJobs,
  getVehicles,
} from '../../services/api';
import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import VehiclesTable from './VehiclesTable';
import { Vehicle } from '../../types/vehicles';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';
import { User } from '../../../users/types/users';
import { getUsers } from '../../../users/services/api';

export default function VehiclesList() {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [excludePlates, setExcludePlates] = useState<string[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [archiveModal, setArchiveModal] = useState<boolean>(false);
  const [actionItem, setActionItem] = useState<Vehicle | undefined>();
  const [archivedFilter, setArchivedFilter] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [userFilter, setUserFilter] = useState<string>('');

  useEffect(() => {
    getUsers('workshop').then(data => setUsers(data));
  }, []);

  useEffect(() => {
    loadVehicles();
  }, [filter, archivedFilter, userFilter]);

  function loadVehicles() {
    getVehicles(filter, archivedFilter, userFilter).then(data => setVehicles(data));
    getVehiclePlatesOfOpenJobs().then(data => setExcludePlates(data));
  }

  function onConfirmArchive(vehicle: Vehicle) {
    setActionItem(vehicle);
    setArchiveModal(true);
  }

  async function onArchive() {
    if (!actionItem || actionItem?.isArchived) {
      return;
    }
    await archiveVehicleAndLinkedJobs(actionItem.id);
    setArchiveModal(false);
    setActionItem(undefined);
    loadVehicles();
  }

  function onUserFilter(event: React.ChangeEvent<HTMLSelectElement>) {
    setUserFilter(event.target.value);
  }

  return (
    <>
      <PageToolbar
        pageTitle={`Fahrzeuge: ${vehicles.length}`}
        titleColWidth={2}
        contentColWidth={10}>
        <Row>
          <Col lg={{ offset: 2, span: 3 }} className="pt-2">
            <Form.Check
              type="checkbox"
              label="Auch archivierte anzeigen"
              className="text-start"
              onChange={e => setArchivedFilter(e.target.checked)}
            />
          </Col>
          <Col lg={3}>
            <Form.Select onChange={onUserFilter} value={userFilter}>
              <option value="">--- Alle Mitarbeiter ---</option>
              {users.map(user => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col lg={{ span: 1 }} className="pt-2">
            Filtern
          </Col>
          <Col lg={{ span: 3 }}>
            <Form.Control onChange={e => setFilter(e.target.value || '')} />
          </Col>
        </Row>
      </PageToolbar>
      {actionItem && (
        <ConfirmModal
          show={archiveModal}
          title="Fahrzeug und Arbeitskarten archivieren"
          onConfirm={onArchive}
          onCancel={() => setArchiveModal(false)}>
          <>
            <div className="mb-2">
              Möchten Sie wirklich das ausgewählte Fahrzeug und verknüpfte Arbeitskarten
              archivieren?
            </div>
            <div>
              Fahrzeug:{' '}
              <strong>
                {actionItem.plate}, {actionItem.brandName} {actionItem.brandTypeName}
              </strong>
            </div>
          </>
        </ConfirmModal>
      )}
      <VehiclesTable
        vehicles={vehicles}
        excludePlatesFromArchive={excludePlates}
        onVehicleArchive={onConfirmArchive}
      />
    </>
  );
}
