import { useEffect, useState } from 'react';

import { deleteJobCategory, getJobCategories } from '../../services/api';
import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';
import CategoriesTable from './CategoriesTable';
import { JobCategory } from '../../types/jobCategories';

export default function CategoriesList() {
  const [categories, setCategories] = useState<JobCategory[]>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [actionItem, setActionItem] = useState<JobCategory | undefined>();

  useEffect(() => {
    loadCategories();
  }, []);

  function loadCategories() {
    getJobCategories().then(data => setCategories(data));
  }

  function onConfirmDelete(item: JobCategory) {
    setActionItem(item);
    setDeleteModal(true);
  }

  async function onDelete() {
    if (actionItem) {
      await deleteJobCategory(actionItem.id);
      loadCategories();
    }
    setActionItem(undefined);
    setDeleteModal(false);
  }

  return (
    <>
      <ConfirmModal
        show={deleteModal}
        title="Kategorie löschen"
        onConfirm={onDelete}
        onCancel={() => setDeleteModal(false)}>
        <span>
          Möchten Sie wirklich die Kategorie <strong>{actionItem?.name}</strong> löschen?
        </span>
      </ConfirmModal>
      <PageToolbar pageTitle="Aufgabe Kategorien">
        <ButtonLink link="/job-categories/add" buttonColor="success" icon="plus-circle-fill">
          Kategorie hinzufügen
        </ButtonLink>
      </PageToolbar>
      <CategoriesTable
        categories={categories}
        onDelete={onConfirmDelete}
        reloadCategories={() => loadCategories()}
      />
    </>
  );
}
