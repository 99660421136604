import { Col, Row } from 'react-bootstrap';

import FormInput from '../../../../libs/components/forms/FormInput';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';

export default function NotesForm() {
  return (
    <Row>
      <SectionTitle title="Anmerkungen" />
      <Col lg={6}>
        <div>
          <FormInput
            name="notes"
            label="Anmerkungen für alle Mitarbeiter"
            type="textarea"
            required={false}
          />
        </div>
        <div>
          <FormInput
            name="adminNotes"
            label="Anmerkungen nur für Administratoren"
            type="textarea"
            required={false}
          />
        </div>
      </Col>
    </Row>
  );
}
