import React, { useEffect, useState } from 'react';

import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import PhoneCallsTable from './PhoneCallsTable';
import { PhoneCall } from '../../types/phone_calls';
import { getAllResubmissionPhoneCalls } from '../../services/api';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';

export default function PhoneCallResubmissionList() {
  const [phoneCalls, setPhoneCalls] = useState<PhoneCall[]>([]);

  useEffect(() => {
    loadPhoneCalls();
  }, []);

  function loadPhoneCalls() {
    getAllResubmissionPhoneCalls().then(data => setPhoneCalls(data));
  }

  return (
    <>
      <PageToolbar pageTitle="Wiedervorlagen (Gespräche in Bearbeitung)" titleColWidth={8}>
        <ButtonLink link="/phone-calls" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      <PhoneCallsTable phoneCalls={phoneCalls} mode={'resubmission'} />
    </>
  );
}
