import { useEffect, useState } from 'react';

import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import { Brand } from '../../types/brands';
import { getBrands } from '../../services/api';
import BrandsTable from './BrandsTable';

export default function BrandsList() {
  const [brands, setBrands] = useState<Brand[]>([]);

  useEffect(() => {
    loadBrands();
  }, []);

  function loadBrands() {
    getBrands().then(data => setBrands(data));
  }

  return (
    <>
      <PageToolbar pageTitle="Automarken">
        <ButtonLink link="/brands/add" buttonColor="success" icon="plus-circle-fill">
          Automarke hinzufügen
        </ButtonLink>
      </PageToolbar>
      <BrandsTable brands={brands} reloadBrands={() => loadBrands()} />
    </>
  );
}
