import { useEffect, useState } from 'react';

import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import MyJobsTable from './MyJobsTable';
import { Job } from '../../../jobs/types/jobs';
import { getMyJobs } from '../../services/api';
import { Badge, Col, Form, Row } from 'react-bootstrap';
import {
  calculateActualWorkedTimeOfWholeDailySchedule,
  calculateEstimatedTimeOfWholeDailySchedule,
} from '../../services/time';
import { getTodaysDateString, transformSecondsToDateTime } from '../../../../libs/services/time';
import { useAuth } from '../../../../libs/context/AuthContext';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import UserOverview from '../../../work_overview/components/UserOverview';
import { getTodaysWorkOverviewForUser } from '../../../stats/services/api';
import { WorkOverview } from '../../../stats/types/workTime';
import { DailySchedule } from '../../../daily_schedule/types/dailySchedule';
import { getDailyScheduleForDayAndCurrentUser } from '../../../daily_schedule/services/api';

export default function MyJobsList() {
  const { user } = useAuth();
  const [jobs, setJobs] = useState<Job[]>([]);
  const [workOverview, setWorkOverview] = useState<WorkOverview>();
  const [dailySchedule, setDailySchedule] = useState<DailySchedule[]>([]);
  const [filter, setFilter] = useState<string>('');
  const today = getTodaysDateString();

  useEffect(() => {
    loadTodaysWorkOverview();
    loadDailyScheduleForToday();
  }, [filter]);

  function loadMyJobs(ds: DailySchedule[]) {
    getMyJobs(filter).then(data => {
      const sortedData: Job[] = [];
      ds.forEach(item => {
        const job = data.find(job => job.id === item.job.id);
        if (job) {
          sortedData.push(job);
        }
      });
      setJobs(sortedData);
    });
  }

  function loadTodaysWorkOverview() {
    if (!user) {
      return;
    }
    getTodaysWorkOverviewForUser(user.id).then(data => setWorkOverview(data));
  }

  function loadDailyScheduleForToday() {
    getDailyScheduleForDayAndCurrentUser(today).then(data => {
      setDailySchedule(data);
      loadMyJobs(data);
    });
  }

  if (!user || jobs.length === 0 || dailySchedule.length === 0) {
    return null;
  }

  const estimatedMinutesSum = calculateEstimatedTimeOfWholeDailySchedule(dailySchedule);
  const workedMinutesSum = calculateActualWorkedTimeOfWholeDailySchedule(
    dailySchedule,
    jobs,
    user,
    today,
  );

  const title = (
    <div>
      <span className="pe-4">Meine Aufgaben</span>
      <Badge bg="info">
        <span className="pe-2">Geschätzte Zeit</span>|
        <span className="ps-2">{transformSecondsToDateTime(estimatedMinutesSum * 60)}</span>
      </Badge>
      <span className="px-2"></span>
      <Badge bg={workedMinutesSum > estimatedMinutesSum ? 'danger' : 'success'}>
        <span className="pe-2">Meine Zeit</span>|
        <span className="ps-2">{transformSecondsToDateTime(workedMinutesSum * 60)}</span>
      </Badge>
    </div>
  );

  return (
    <>
      <PageToolbar pageTitle={title} titleColWidth={6} contentColWidth={6}>
        <Row>
          <Col lg={{ offset: 4, span: 2 }} className="pt-2">
            Filtern
          </Col>
          <Col lg={{ span: 6 }}>
            <Form.Control onChange={e => setFilter(e.target.value || '')} />
          </Col>
        </Row>
      </PageToolbar>
      <Row>
        <Col lg={8}>
          <SectionTitle title="Meine Aufgaben" />
          <MyJobsTable jobs={jobs} userId={user.id} />
        </Col>
        <Col lg={4}>
          <SectionTitle title="Meine heutige Arbeit" />
          {workOverview !== undefined && workOverview.items && (
            <UserOverview username="" workTime={workOverview} />
          )}
        </Col>
      </Row>
    </>
  );
}
