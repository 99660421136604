import { SelectedFile } from '../../jobs/components/JobDetails/JobFiles/JobFileAddForm';
import { API } from '../../../libs/services/api';
import { JobFile } from '../../jobs/types/jobs';

const BASE = 'files';

export async function getFiles(): Promise<JobFile[]> {
  return (await API.get(`${BASE}`)).data;
}

export async function uploadFiles(jobId: string, files: SelectedFile[]) {
  await API.post(`${BASE}/${jobId}`, { files });
}

export async function updateFile(jobId: string, fileId: string, data: Record<string, any>) {
  await API.patch(`${BASE}/${jobId}/${fileId}`, data);
}

export async function deleteFile(jobId: string, fileId: string) {
  await API.delete(`${BASE}/${jobId}/${fileId}`);
}

export async function updateFilesTags(filesToUpdate: JobFile[]) {
  const dto = filesToUpdate.map(file => ({
    jobId: file.jobId,
    fileId: file.id,
    tags: file.tags,
  }));
  await API.patch(`${BASE}/tags`, { files: dto });
}
