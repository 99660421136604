import { useParams } from 'react-router-dom';

import PageToolbar from '../../../libs/components/actions/PageToolbar';
import { getJobCategory, updateJobSubCategory } from '../services/api';
import { useEffect, useReducer, useState } from 'react';
import ButtonLink from '../../../libs/components/actions/ButtonLink';
import Message from '../../../libs/components/data/Message';
import ApiErrorMessage from '../../../libs/components/data/ApiErrorMessage';
import {
  FormActionState,
  getFormActionReducer,
} from '../../../libs/services/state/formActionReducer';
import { JobCategory, JobSubCategory, JobSubCategoryFormData } from '../types/jobCategories';
import { SubCategoryForm } from './SubCategoryForm';

const initialState: FormActionState = {
  actionInProgress: false,
  actionSuccess: false,
  actionError: false,
  showForm: true,
  actionErrorResponse: undefined,
};

export default function SubCategoryEdit() {
  const { id, subCategoryId } = useParams();
  const [category, setCategory] = useState<JobCategory>();
  const [subCategory, setSubCategory] = useState<JobSubCategory>();
  const [formData, setFormData] = useState<JobSubCategoryFormData>();
  const [state, dispatch] = useReducer(getFormActionReducer(initialState), initialState);

  useEffect(() => {
    if (!id) {
      return;
    }
    getJobCategory(id).then(data => {
      setCategory(data);
      const subCategory = data.subCategories.find(sub => sub.id === subCategoryId);
      setSubCategory(subCategory);
      setFormData({
        name: subCategory!.name,
        defaultDescription: subCategory!.defaultDescription,
        sortOrder: subCategory!.sortOrder,
      });
    });
  }, [id]);

  async function onSubmit(data: JobSubCategoryFormData) {
    if (!category || !subCategory) {
      return;
    }

    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      await updateJobSubCategory(category.id, subCategory.id, data);
      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    }
  }

  if (!category || !subCategory || !formData) {
    return null;
  }

  return (
    <>
      <PageToolbar pageTitle={`Unterkategorie bearbeiten: ${subCategory.name}`}>
        <ButtonLink link="/job-categories" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      {state.showForm && (
        <div className="mb-4">
          <SubCategoryForm
            submitButtonLabel="Unterkategorie aktualisieren"
            submitButtonColor="primary"
            onSubmit={onSubmit}
            initialValues={formData}
          />
        </div>
      )}
      {state.actionError && (
        <ApiErrorMessage
          label="Unterkategorie konnte nicht aktualisiert werden"
          errorResponse={state.actionErrorResponse}
        />
      )}
      {state.actionSuccess && <Message type="ok">Unterkategorie wurde aktualisiert</Message>}
    </>
  );
}
