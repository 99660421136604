import * as Yup from 'yup';
import { WorkTimeInProgress } from '../types/workTime';

export function getValidationSchema(workTime: WorkTimeInProgress) {
  const fromDate = Yup.date()
    .typeError('"Arbeitszeit von" must ein valides Datum sein')
    .required('"Arbeitszeit von" darf nicht leer sein');

  const fromTime = Yup.string()
    .typeError('"Arbeitszeit von" must eine validee Zeit sein')
    .required('"Arbeitszeit von" darf nicht leer sein');

  const validations: Record<string, any> = {
    fromDate,
    fromTime,
  };

  if (workTime.endDate !== null) {
    validations['toDate'] = Yup.date()
      .typeError('"Arbeitszeit bis" must ein valides Datum sein')
      .required('"Arbeitszeit bis" darf nicht leer sein')
      .min(Yup.ref('fromDate'));

    validations['toTime'] = Yup.string()
      .typeError('"Arbeitszeit bis" must eine validee Zeit sein')
      .required('"Arbeitszeit bis" darf nicht leer sein');
  }

  return Yup.object().shape(validations);
}
