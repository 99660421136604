import { Table } from 'react-bootstrap';

import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import TableHeader from '../../../../libs/components/data/table/TableHeader';
import { IconDelete, IconDetails, IconSeparator } from '../../../../libs/components/data/Icon';
import { JobFile } from '../../../jobs/types/jobs';
import { User } from '../../../users/types/users';
import { Link } from 'react-router-dom';

interface FilesTableProps {
  files: JobFile[];
  authUser: User;
  onDelete: (file: JobFile) => void;
}

const TABLE_COLUMNS = [
  { key: 'createdAt', label: 'Angelegt am', width: 150 },
  { key: 'createdBy', label: 'Angelegt von', width: 125 },
  { key: 'name', label: 'Dateiname' },
  { key: 'tags', label: 'Tags', width: 200 },
];

export default function FilesTable({ files, authUser, onDelete }: FilesTableProps) {
  function filteredFiles(): JobFile[] {
    return files.filter(file => {
      if (file.onlyAdmin && !authUser.isAdmin) {
        return false;
      }
      return true;
    });
  }

  function openFile(event: any, item: JobFile) {
    event.preventDefault();
    window.open(`/files/jobs/${item.jobId}/${item.fileName}`);
  }

  return (
    <Table striped bordered className="shadow-sm">
      <TableHeader columns={TABLE_COLUMNS} actionColumn={authUser.isAdmin} />
      <tbody>
        {filteredFiles().map(item => (
          <tr key={item.id}>
            <td>{getFormattedDateOrEmpty(item.createdAt, 'datetime')}</td>
            <td>{item.createdBy.name}</td>
            <td>
              <a href="#" onClick={e => openFile(e, item)}>
                {item.fileName}
              </a>
            </td>
            <td>{item.tags.join(', ')}</td>
            {authUser.isAdmin && (
              <td>
                <Link to={`/jobs/${item.jobId}`} className="text-info">
                  <IconDetails />
                </Link>
                <IconSeparator />
                <IconDelete onClick={() => onDelete(item)} />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
