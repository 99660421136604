import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { v4 } from 'uuid';

import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import {
  Job,
  JobFile,
  JobPhoto,
  JobPhotoForm,
  JobState,
  JobTask as JobTaskModel,
  NewJobVehiclePartToAdd,
} from '../../types/jobs';
import {
  activateArchivedJob,
  addNewAdminJobNote,
  addNewJobNote,
  archiveJob,
  changeJobIsDone,
  deleteJobTask,
  getJob,
  reopenJob,
  saveNewJobTasks,
  setJobState,
  startUserWorkTime,
  stopUserWorkTime,
  updateAssignedUsers,
  updateMainAssignedUser,
  updatePhotosTags,
} from '../../services/api';
import JobTasks from './JobTasks';
import NewJobsTasks from '../forms/JobTasks';
import { goToJobPrintPage } from '../../services/printJob';
import JobPhotos from './JobPhotos';
import { saveVehicleParts, updateVehiclePart, uploadPhotos } from '../../../my-jobs/services/api';
import JobNotes from './JobNotes/JobNotes';
import JobVehicle from './JobVehicle';
import RelatedJobsList from './RelatedJobsList';
import JobInfo from './JobInfo';
import JobWorkTimeSummary from './JobWorkTimeSummary';
import VehiclePartsToOrder from '../../../my-jobs/components/MyJobDetails/VehiclePartsToOrder';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';
import { JobTask } from '../forms/JobForm';
import { getJobCategories } from '../../../job_categories/services/api';
import { JobCategory } from '../../../job_categories/types/jobCategories';
import { extractJobTasks } from '../../services/jobs';
import VehicleInspectionDetails from '../../../vehicles/components/VehicleDetails/VehicleInspectionDetails';
import { JobVehiclePartEditFormData } from '../../../my-jobs/types/vehicleParts';
import { archiveVehicleAndLinkedJobs } from '../../../vehicles/services/api';
import { useAuth } from '../../../../libs/context/AuthContext';
import { deletePhoto, updatePhoto } from '../../../photos/services/api';
import { IconArchive, IconStart } from '../../../../libs/components/data/Icon';
import PhotoGallery from '../../../photos/components/PhotoGallery/PhotoGallery';
import JobFiles from './JobFiles/JobFiles';
import { SelectedFile } from './JobFiles/JobFileAddForm';
import { deleteFile, updateFile, updateFilesTags, uploadFiles } from '../../../files/services/api';

export default function JobsDetails() {
  const { id } = useParams();
  const { user } = useAuth();
  const [job, setJob] = useState<Job>();
  const [jobCategories, setJobCategories] = useState<JobCategory[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [confirmAssignJobStateModal, setConfirmAssignJobStateModal] = useState<boolean>(false);
  const [confirmArchiveModal, setConfirmArchiveModal] = useState<boolean>(false);
  const [confirmActivationModal, setConfirmActivationModal] = useState<boolean>(false);
  const [changeIsDoneModal, setChangeIsDoneModal] = useState<boolean>(false);
  const [deleteTaskModal, setDeleteTaskModal] = useState<boolean>(false);
  const [deleteTaskItem, setDeleteTaskItem] = useState<JobTaskModel | undefined>();
  const [tasks, setTasks] = useState<JobTask[]>([]);
  const [editedJobPhotos, setEditedJobPhotos] = useState<JobPhoto[]>([]);
  const [partPhotosModal, setPartPhotosModal] = useState<boolean>(false);
  const [partPhotos, setPartPhotos] = useState<JobPhoto[]>([]);
  const [deletePhotoModal, setDeletePhotoModal] = useState<boolean>(false);
  const [deletePhotoItem, setDeletePhotoItem] = useState<JobPhoto | undefined>();

  useEffect(() => {
    loadJob();
    getJobCategories().then(data => setJobCategories(data));
  }, [id]);

  function loadJob() {
    if (!id) {
      return;
    }
    getJob(id).then(data => {
      setJob(data);
      setEditedJobPhotos(data.photos);
    });
  }

  async function saveNewNote(content: string) {
    if (!job) {
      return;
    }
    await addNewJobNote(job.id, content);
    loadJob();
  }

  async function saveAdminNote(content: string) {
    if (!job) {
      return;
    }
    await addNewAdminJobNote(job.id, content);
    loadJob();
  }

  async function uploadJobPhotos(photos: JobPhotoForm[]) {
    setUploading(true);
    await uploadPhotos(job!.id, photos);
    loadJob();
    setUploading(false);
  }

  async function onAssignedUsersUpdate(userIds: string[]) {
    await updateAssignedUsers(job!.id, userIds);
    loadJob();
  }

  async function onUserWorkTimeStart(userId: string) {
    await startUserWorkTime(job!.id, userId);
    loadJob();
  }

  async function onUserWorkTimeStop(userId: string) {
    await stopUserWorkTime(job!.id, userId);
    loadJob();
  }

  async function onMainAssignedUserUpdate(userId: string) {
    await updateMainAssignedUser(job!.id, userId);
    loadJob();
  }

  async function doReopenJob() {
    await reopenJob(id!);
    loadJob();
  }

  async function doAssignJobState() {
    if (!job) {
      return;
    }
    await setJobState(
      job.id,
      job.jobState === JobState.OFFICE ? JobState.WORKSHOP : JobState.OFFICE,
    );
    setConfirmAssignJobStateModal(false);
    loadJob();
  }

  async function doChangeIsDone() {
    if (!job) {
      return;
    }
    await changeJobIsDone(job.id);
    setChangeIsDoneModal(false);
    loadJob();
  }

  function addTask() {
    setTasks([...tasks, { id: v4(), categoryId: '', items: [] }]);
  }

  function deleteTask(id: string) {
    setTasks(tasks.filter(task => task.id !== id));
  }

  function editTask(
    id: string,
    subCategoryId: string,
    field: 'enabled' | 'description',
    value: string | boolean,
  ) {
    const updatedTasks = tasks.map(task => {
      if (task.id !== id) {
        return task;
      }
      return {
        ...task,
        items: task.items.map(item => {
          if (item.subCategoryId !== subCategoryId) {
            return item;
          }
          return {
            ...item,
            [field]: value,
          };
        }),
      };
    });
    setTasks(updatedTasks);
  }

  function selectTaskCategory(id: string, categoryId: string) {
    const category = jobCategories?.find(item => item.id === categoryId);
    if (!category) {
      return;
    }
    const updatedTasks = tasks.map(task => {
      if (task.id !== id) {
        return task;
      }
      return {
        ...task,
        categoryId,
        items: category.subCategories.map(sub => ({
          id: v4(),
          subCategoryId: sub.id,
          subCategoryName: sub.name,
          enabled: false,
          description: sub.defaultDescription,
        })),
      };
    });
    setTasks(updatedTasks);
  }

  async function saveNewTasks() {
    if (!job || tasks.length === 0) {
      return;
    }
    await saveNewJobTasks(job.id, extractJobTasks(tasks));
    loadJob();
    setTasks([]);
  }

  function onPhotoTagEdit(photo: JobPhoto, content: string) {
    setEditedJobPhotos(
      editedJobPhotos.map(item => {
        if (item.id !== photo.id) {
          return item;
        }
        return {
          ...item,
          tags: content.split(','),
        };
      }),
    );
  }

  async function savePhotosTags() {
    await updatePhotosTags(job!.id, editedJobPhotos);
    loadJob();
  }

  function reloadCategories() {
    getJobCategories().then(data => setJobCategories(data));
  }

  function confirmTaskDelete(task: JobTaskModel) {
    setDeleteTaskItem(task);
    setDeleteTaskModal(true);
  }

  async function doDeleteTask() {
    if (!deleteTaskItem || !job) {
      return;
    }
    await deleteJobTask(job.id, deleteTaskItem.id);
    setDeleteTaskModal(false);
    setDeleteTaskItem(undefined);
    loadJob();
  }

  async function onVehiclePartsSave(parts: NewJobVehiclePartToAdd[]) {
    await saveVehicleParts(job!.id, parts);
    loadJob();
  }

  async function onVehiclePartUpdate(partId: string, data: JobVehiclePartEditFormData) {
    await updateVehiclePart(job!.id, partId, data, true);
    loadJob();
  }

  async function activateJob() {
    if (!job || !job.isDone || !job.isArchived || !user?.isAdmin) {
      return;
    }
    await activateArchivedJob(job.id);
    setConfirmActivationModal(false);
    loadJob();
  }

  async function archiveVehicleAndJobs() {
    if (!job || !job.isDone || job.isArchived || !user?.isAdmin) {
      return;
    }

    if (!job.vehicle) {
      await archiveJob(job.id);
    } else {
      await archiveVehicleAndLinkedJobs(job.vehicle.id);
    }

    setConfirmArchiveModal(false);
    loadJob();
  }

  async function onPhotoUpdate(photo: JobPhoto, field: string, value: any) {
    await updatePhoto(photo.jobId, photo.id, field, value);
    loadJob();
  }

  async function onPhotoDelete() {
    if (!deletePhotoItem) {
      return;
    }
    await deletePhoto(deletePhotoItem.jobId, deletePhotoItem.id);
    setDeletePhotoModal(false);
    loadJob();
  }

  function onDeletePhotoConfirm(item: JobPhoto) {
    setDeletePhotoItem(item);
    setDeletePhotoModal(true);
  }

  async function onFilesUpload(files: SelectedFile[]) {
    if (!job) {
      return;
    }
    await uploadFiles(job.id, files);
    loadJob();
  }

  async function onFileDelete(file: JobFile) {
    if (!job) {
      return;
    }
    await deleteFile(file.jobId, file.id);
    loadJob();
  }

  async function onFileUpdate(file: JobFile, field: string, value: any) {
    if (!job) {
      return;
    }
    await updateFile(file.jobId, file.id, { [field]: value });
    loadJob();
  }

  async function onAllFileTagsUpdate(files: JobFile[]) {
    await updateFilesTags(files);
    loadJob();
  }

  function onShowPhotosOfPart(partId: string) {
    if (!job) {
      return;
    }
    setPartPhotos(job.photos.filter(photo => photo.vehiclePartId === partId));
    setPartPhotosModal(true);
  }

  function getTitleOfPartGallery() {
    if (partPhotos.length === 0 || !job) {
      return undefined;
    }
    const partId = partPhotos[0].vehiclePartId;
    const part = job.vehicleParts.find(part => part.id === partId);
    if (!part) {
      return undefined;
    }
    return (
      <span>
        {part.name} / {part.partNumber1}
      </span>
    );
  }

  if (!job || !user) {
    return null;
  }

  return (
    <>
      {deletePhotoItem && (
        <ConfirmModal
          show={deletePhotoModal}
          title="Bild löschen"
          onConfirm={onPhotoDelete}
          onCancel={() => setDeletePhotoModal(false)}>
          Möchten Sie wirklich das ausgewählte Bild löschen?
        </ConfirmModal>
      )}
      <ConfirmModal
        show={deleteTaskModal}
        title="Aufgabe löschen"
        onConfirm={doDeleteTask}
        onCancel={() => setDeleteTaskModal(false)}>
        <>
          <div>Möchten Sie wirklich die Aufgabe löschen?</div>
          <div>Kategorie: {deleteTaskItem?.category.name}</div>
          <div>Unterkategorie: {deleteTaskItem?.subCategory.name}</div>
          <div>Beschreibung: {deleteTaskItem?.description}</div>
        </>
      </ConfirmModal>
      <ConfirmModal
        show={confirmAssignJobStateModal}
        title="Arbeitskarte zuweisen"
        onConfirm={doAssignJobState}
        onCancel={() => setConfirmAssignJobStateModal(false)}>
        <>
          Möchten Sie wirklich die Arbeitskarte{' '}
          <strong>{job.jobState === JobState.OFFICE ? 'der Werkstatt' : 'dem Büro'}</strong>{' '}
          zuweisen?
        </>
      </ConfirmModal>
      <ConfirmModal
        show={changeIsDoneModal}
        title="Arbeitskarte Status ändern"
        onConfirm={doChangeIsDone}
        onCancel={() => setChangeIsDoneModal(false)}>
        <>
          <>{job.isDone && <span>Möchten Sie wirklich die Arbeitskarte wieder eröffnen?</span>}</>
          <>
            {!job.isDone && (
              <span>Möchten Sie wirklich die Arbeitskarte als erledigt markieren?</span>
            )}
          </>
        </>
      </ConfirmModal>
      {user.isAdmin && (
        <>
          <ConfirmModal
            show={confirmArchiveModal}
            title="Fahrzeug archivieren"
            onConfirm={archiveVehicleAndJobs}
            onCancel={() => setConfirmArchiveModal(false)}>
            <>
              Möchten Sie wirklich <strong>das Fahrzeug</strong> und{' '}
              <strong>ALLE verknüpfte Arbeitskarten</strong> archivieren?
            </>
          </ConfirmModal>
          <ConfirmModal
            show={confirmActivationModal}
            title="archivierte Arbeitskarte aktivieren"
            onConfirm={activateJob}
            onCancel={() => setConfirmActivationModal(false)}>
            <>
              Möchten Sie wirklich <strong>diese Arbeitskarte</strong> wieder aktivieren?
            </>
          </ConfirmModal>
        </>
      )}
      <PhotoGallery
        title={getTitleOfPartGallery()}
        jobLinkPrefix="jobs"
        photos={partPhotos}
        selectedPhotoIndex={0}
        show={partPhotosModal}
        onClose={() => {
          setPartPhotosModal(false);
          setPartPhotos([]);
        }}
      />
      <PageToolbar
        pageTitle={`Arbeitskarte ID=${job.jobKey || ''}`}
        titleColWidth={3}
        contentColWidth={9}>
        {job.isDone && !job.isArchived && (
          <Button className="me-3" variant="warning" onClick={() => setConfirmArchiveModal(true)}>
            <IconArchive color="white" /> Fahrzeug archivieren
          </Button>
        )}
        {job.isDone && job.isArchived && (
          <Button
            className="me-3"
            variant="success"
            onClick={() => setConfirmActivationModal(true)}>
            <IconStart color="white" /> archivierte Arbeitskarte aktivieren
          </Button>
        )}
        {!job.isArchived && job.isDone && (
          <Button className="me-3" variant="success" onClick={doReopenJob}>
            <i className="bi bi-play-fill"></i> Arbeitskarte wieder öffnen
          </Button>
        )}
        <Button onClick={() => goToJobPrintPage(job!.id)}>
          <i className="bi bi-printer-fill"></i> Ausdrucken
        </Button>
        <span className="px-2"></span>
        <ButtonLink link="/jobs" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      <Row>
        <Col lg={6}>
          <JobInfo
            job={job}
            onMainAssignedUserUpdate={onMainAssignedUserUpdate}
            onAssignedUsersUpdate={onAssignedUsersUpdate}
            onAssignJobState={() => setConfirmAssignJobStateModal(true)}
            onIsDoneChange={() => setChangeIsDoneModal(true)}
            onUserWorkTimeStart={onUserWorkTimeStart}
            onUserWorkTimeStop={onUserWorkTimeStop}
          />
        </Col>
        <Col lg={6}>
          <JobWorkTimeSummary job={job} />
          {job.vehicle !== null && (
            <>
              <Row>
                <Col>
                  <JobVehicle job={job} />
                  <VehicleInspectionDetails vehicle={job.vehicle} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <RelatedJobsList currentJob={job} />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <JobNotes job={job} onNewNote={saveNewNote} onNewAdminNote={saveAdminNote} />
      <JobTasks job={job} onTaskDelete={confirmTaskDelete} />
      <NewJobsTasks
        title="Neue Aufgaben hinzufügen"
        jobCategories={jobCategories}
        tasks={tasks}
        onAddTask={addTask}
        onDeleteTask={deleteTask}
        onTaskCategorySelect={selectTaskCategory}
        onTaskItemEdit={editTask}
        reloadCategories={reloadCategories}
      />
      <Button onClick={saveNewTasks} disabled={tasks.length === 0}>
        <i className="bi bi-plus-circle-fill"></i> Neue Aufgaben speichern
      </Button>
      <VehiclePartsToOrder
        job={job}
        onVehiclePartsSave={onVehiclePartsSave}
        onVehiclePartUpdate={onVehiclePartUpdate}
        showPhotosOfPart={onShowPhotosOfPart}
      />
      {!uploading && (
        <JobPhotos
          allowedTagsEdit={true}
          vehicleParts={job.vehicleParts}
          photos={editedJobPhotos}
          allowedPhotoAdd={true}
          uploadPhotos={uploadJobPhotos}
          onPhotoTagEdit={onPhotoTagEdit}
          savePhotosTags={savePhotosTags}
          onPhotoUpdate={onPhotoUpdate}
          onPhotoDelete={onDeletePhotoConfirm}
        />
      )}
      <JobFiles
        files={job.files}
        onFilesUpload={onFilesUpload}
        onFileDelete={onFileDelete}
        onFileUpdate={onFileUpdate}
        onAllTagsUpdate={onAllFileTagsUpdate}
      />
    </>
  );
}
