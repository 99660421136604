import { DailySchedule, DailyScheduleForm } from '../types/dailySchedule';
import { transformSecondsToDateTime } from '../../../libs/services/time';
import { Job, JobWorkTime } from '../../jobs/types/jobs';
import { getSumOfWorkTime } from '../../my-jobs/services/time';

export function transformToForm(dailySchedule: DailySchedule[]): DailyScheduleForm[] {
  return dailySchedule.map(ds => ({
    _new: false,
    id: ds.id,
    day: ds.day,
    userId: ds.user.id,
    limitHours: ds.limitHours,
    limitMinutes: ds.limitMinutes,
    order: ds.order,
    job: ds.job,
    isMainAssignedUser: ds.isMainAssignedUser,
  }));
}

export function getDailyScheduleFormOfUser(
  dailyScheduleForm: DailyScheduleForm[],
  userId: string,
): DailyScheduleForm[] {
  return dailyScheduleForm.filter(ds => ds.userId === userId);
}

type JobWorkTimeWithCar = JobWorkTime & { car: string };

export function getWorkingTimeOfUser(
  dailyScheduleForm: DailyScheduleForm[],
  userId: string,
  dayFilter: string,
): JobWorkTimeWithCar[] {
  return dailyScheduleForm
    .filter(ds => ds.userId === userId)
    .reduce((total: JobWorkTimeWithCar[], current) => {
      const currentItems: JobWorkTimeWithCar[] = current.job.workTime
        .filter(wt => wt.startDate.substring(0, 10) === dayFilter)
        .map(t => ({
          ...t,
          car: `${current.job.vehicle?.brandName} ${current.job.vehicle?.brandTypeName}`,
        }));

      return [...total, ...currentItems];
    }, []);
}

export function getWorkingTimeSumOfUser(
  dailyScheduleForm: DailyScheduleForm[],
  userId: string,
  dayFilter: string,
) {
  let result = 0;

  dailyScheduleForm
    .filter(ds => ds.userId === userId)
    .forEach(ds => {
      result += Number(
        getSumOfWorkTime(
          ds.job.workTime.filter(wt => wt.startDate.substring(0, 10) === dayFilter),
          false,
        ),
      );
    });

  return transformSecondsToDateTime(result);
}

export function getItemsCountForUser(
  dailyScheduleForm: DailyScheduleForm[],
  userId: string,
): number {
  return dailyScheduleForm.filter(ds => ds.userId === userId).length;
}

export function getTimeSumForUser(dailyScheduleForm: DailyScheduleForm[], userId: string): string {
  const totalMinutes = dailyScheduleForm
    .filter(ds => ds.userId === userId)
    .reduce((totalMinutes, current) => {
      return totalMinutes + current.limitMinutes - 60 + current.limitHours * 60;
    }, 0);

  return transformSecondsToDateTime(totalMinutes * 60);
}

export function createSearchOptions(jobs: Job[]): string[] {
  const result: string[] = [];

  jobs.forEach(job => {
    result.push(
      `${
        job.vehicle
          ? `${job.vehicle.brandName} ${job.vehicle.brandTypeName}, ${job.vehicle.plate}, ${job.jobKey}`
          : job.jobKey
      }`,
    );
  });

  return result;
}
