import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';
import { MouseEventHandler } from 'react';

interface ButtonLinkProps {
  link?: string;
  click?: MouseEventHandler;
  buttonColor: string;
  icon?: string;
  children: string | JSX.Element;
  size?: string;
}

export default function ButtonLink({
  link,
  click,
  buttonColor,
  icon,
  size,
  children,
}: ButtonLinkProps) {
  if (!click && !link) {
    return null;
  }

  if (click) {
    return (
      <Button size={size as any} variant={buttonColor} onClick={click!}>
        {icon !== undefined && <i className={`bi bi-${icon} me-2`}></i>} {children}
      </Button>
    );
  }

  return (
    <LinkContainer to={link!}>
      <Button size={size as any} variant={buttonColor}>
        {icon !== undefined && <i className={`bi bi-${icon} me-2`}></i>} {children}
      </Button>
    </LinkContainer>
  );
}
