import PageToolbar from '../../../libs/components/actions/PageToolbar';
import { CategoryForm } from './CategoryForm';
import { useReducer, useState } from 'react';
import ButtonLink from '../../../libs/components/actions/ButtonLink';
import Message from '../../../libs/components/data/Message';
import ApiErrorMessage from '../../../libs/components/data/ApiErrorMessage';
import {
  FormActionState,
  getFormActionReducer,
} from '../../../libs/services/state/formActionReducer';
import { JobCategory, JobCategoryFormData } from '../types/jobCategories';
import { addJobCategory } from '../services/api';

const initialState: FormActionState = {
  actionInProgress: false,
  actionSuccess: false,
  actionError: false,
  showForm: true,
  actionErrorResponse: undefined,
};

export default function CategoryAdd() {
  const [createdCategory, setCreatedCategory] = useState<JobCategory>();
  const [state, dispatch] = useReducer(getFormActionReducer(initialState), initialState);

  async function onSubmit(data: JobCategoryFormData) {
    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      const category = await addJobCategory(data);
      setCreatedCategory(category);
      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
      dispatch({ type: 'TOGGLE_FORM' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    }
  }

  return (
    <>
      <PageToolbar pageTitle="Kategorie hinzufügen">
        <ButtonLink link="/job-categories" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      {state.showForm && (
        <div className="mb-4">
          <CategoryForm
            submitButtonLabel="Kategorie hinzufügen"
            onSubmit={onSubmit}
            initialValues={{}}
          />
        </div>
      )}
      {state.actionError && (
        <ApiErrorMessage
          label="Kategorie konnte nicht hinzugefügt werden"
          errorResponse={state.actionErrorResponse}
        />
      )}
      {state.actionSuccess && (
        <Message type="ok">
          <span>
            <strong>Kategorie wurde hinzugefügt</strong>
          </span>
          <div className="mt-4">
            <ButtonLink
              buttonColor="light"
              icon="pencil-square"
              link={`/job-categories/edit/${createdCategory?.id}`}>
              Kategorie bearbeiten
            </ButtonLink>
          </div>
        </Message>
      )}
    </>
  );
}
