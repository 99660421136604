import { useParams } from 'react-router-dom';

import { JobEditForm } from './JobEditForm';
import React, { useEffect, useReducer, useState } from 'react';
import {
  FormActionState,
  getFormActionReducer,
} from '../../../../libs/services/state/formActionReducer';
import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import ApiErrorMessage from '../../../../libs/components/data/ApiErrorMessage';
import Message from '../../../../libs/components/data/Message';
import { Job, JobEditFormData } from '../../types/jobs';
import { getJob, updateJob } from '../../services/api';

const initialState: FormActionState = {
  actionInProgress: false,
  actionSuccess: false,
  actionError: false,
  showForm: true,
  actionErrorResponse: undefined,
};

export default function JobEdit() {
  const { id } = useParams();
  const [job, setJob] = useState<Job>();
  const [formData, setFormData] = useState<JobEditFormData>();
  const [state, dispatch] = useReducer(getFormActionReducer(initialState), initialState);

  useEffect(() => {
    if (!id) {
      return;
    }
    getJob(id).then(data => {
      setJob(data);
      setFormData(data);
    });
  }, [id]);

  async function onSubmit(data: JobEditFormData) {
    if (!job) {
      return;
    }

    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      await updateJob(job.id, data, job.vehicle !== null);
      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    }
  }

  if (!job || !formData) {
    return null;
  }

  return (
    <>
      <PageToolbar pageTitle={`Arbeitskarte bearbeiten: ${job.jobKey}`}>
        <ButtonLink link="/jobs" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      {state.showForm && (
        <JobEditForm
          job={job}
          submitButtonLabel="Arbeitskarte aktualisieren"
          submitButtonColor="primary"
          onSubmit={onSubmit}
          initialValues={formData}
        />
      )}
      {state.actionError && (
        <ApiErrorMessage
          label="Arbeitskarte konnte nicht aktualisiert werden"
          errorResponse={state.actionErrorResponse}
        />
      )}
      {state.actionSuccess && <Message type="ok">Arbeitskarte wurde aktualisiert</Message>}
    </>
  );
}
