import { Table } from 'react-bootstrap';
import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import { Vehicle } from '../../types/vehicles';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';

interface VehicleInspectionDetailsProps {
  vehicle: Vehicle;
}

export default function VehicleInspectionDetails({ vehicle }: VehicleInspectionDetailsProps) {
  return (
    <>
      <SectionTitle title="Untersuchung" />
      <Table striped bordered className="shadow-sm">
        <tbody>
          <tr>
            <td style={{ width: 200 }}>
              <strong>HU</strong>
            </td>
            <td>{getFormattedDateOrEmpty(vehicle.inspectionHU)}</td>
          </tr>
          <tr>
            <td>
              <strong>HU Erinnerung</strong>
            </td>
            <td>{getFormattedDateOrEmpty(vehicle.inspectionHURemind)}</td>
          </tr>
          <tr>
            <td>
              <strong>AU</strong>
            </td>
            <td>{getFormattedDateOrEmpty(vehicle.inspectionAU)}</td>
          </tr>
          <tr>
            <td>
              <strong>Letzte Inspektion</strong>
            </td>
            <td>{getFormattedDateOrEmpty(vehicle.lastInspection)}</td>
          </tr>
          <tr>
            <td>
              <strong>Nächste Insp. Erinnerung</strong>
            </td>
            <td>{getFormattedDateOrEmpty(vehicle.nextInspectionRemind)}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
