import React, { useEffect, useState } from 'react';

import { archiveJob, deleteJob, getJobs, moveToWaitingRoom } from '../../services/api';
import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import JobsTable from './JobsTable';
import { Job } from '../../types/jobs';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';
import { Col, Form, Row } from 'react-bootstrap';
import { User } from '../../../users/types/users';
import { getUsers } from '../../../users/services/api';

export default function JobsList() {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [archiveModal, setArchiveModal] = useState<boolean>(false);
  const [moveToWaitingRoomModal, setMoveToWaitingRoomModal] = useState<boolean>(false);
  const [actionItem, setActionItem] = useState<Job | undefined>();
  const [filter, setFilter] = useState<string>('');
  const [archivedFilter, setArchivedFilter] = useState<boolean>(false);
  const [withoutCarFilter, setWithoutCarFilter] = useState<boolean>(false);
  const [userFilter, setUserFilter] = useState<string>('');

  useEffect(() => {
    getUsers('workshop').then(data => setUsers(data));
  }, []);

  useEffect(() => {
    loadJobs();
  }, [filter, archivedFilter, userFilter, withoutCarFilter]);

  function loadJobs() {
    getJobs(filter, archivedFilter, userFilter, withoutCarFilter).then(data => setJobs(data));
  }

  function onConfirmDelete(item: Job) {
    setActionItem(item);
    setDeleteModal(true);
  }

  async function onDelete() {
    if (actionItem) {
      await deleteJob(actionItem.id);
      loadJobs();
    }
    setActionItem(undefined);
    setDeleteModal(false);
  }

  function onConfirmArchive(job: Job) {
    setActionItem(job);
    setArchiveModal(true);
  }

  async function onArchive() {
    if (!actionItem || actionItem?.isArchived) {
      return;
    }
    await archiveJob(actionItem.id);
    setArchiveModal(false);
    setActionItem(undefined);
    loadJobs();
  }

  function onConfirmMoveToWaitingRoom(job: Job) {
    setActionItem(job);
    setMoveToWaitingRoomModal(true);
  }

  async function onMoveToWaitingRoom() {
    if (!actionItem || actionItem?.isArchived) {
      return;
    }
    await moveToWaitingRoom(actionItem.id);
    setMoveToWaitingRoomModal(false);
    setActionItem(undefined);
    loadJobs();
  }

  function onUserFilter(event: React.ChangeEvent<HTMLSelectElement>) {
    setUserFilter(event.target.value);
  }

  return (
    <>
      <ConfirmModal
        show={deleteModal}
        title="Arbeitskarte löschen"
        onConfirm={onDelete}
        onCancel={() => setDeleteModal(false)}>
        <span>
          Möchten Sie wirklich die Arbeitskarte <strong>{actionItem?.jobKey}</strong> löschen?
        </span>
      </ConfirmModal>
      {actionItem && (
        <ConfirmModal
          show={archiveModal}
          title="Arbeitskarte ohne Fahrzeug archivieren"
          onConfirm={onArchive}
          onCancel={() => setArchiveModal(false)}>
          <>
            <div className="mb-2">
              Möchten Sie wirklich die Arbeitskarte <strong>{actionItem.jobKey}</strong>{' '}
              archivieren?
            </div>
          </>
        </ConfirmModal>
      )}
      {actionItem && (
        <ConfirmModal
          show={moveToWaitingRoomModal}
          title="Warteraum"
          onConfirm={onMoveToWaitingRoom}
          onCancel={() => setMoveToWaitingRoomModal(false)}>
          <>
            Möchten Sie wirklich die Arbeitskarte in den <strong>Warteraum</strong> verschieben und
            alle Arbeitszeiten
            <strong>stoppen</strong>?
          </>
        </ConfirmModal>
      )}
      <PageToolbar pageTitle="Arbeitskarten" titleColWidth={2} contentColWidth={10}>
        <Row>
          <Col className="pt-2">
            <Form.Check
              type="checkbox"
              label="Auch archivierte anzeigen"
              onChange={e => setArchivedFilter(e.target.checked)}
              style={{ textAlign: 'left' }}
            />
          </Col>
          <Col className="pt-2">
            <Form.Check
              type="checkbox"
              label="Nur OHNE auto"
              onChange={e => setWithoutCarFilter(e.target.checked)}
              style={{ textAlign: 'left' }}
            />
          </Col>
          <Col lg={2}>
            <Form.Select onChange={onUserFilter} value={userFilter}>
              <option value="">--- Alle Mitarbeiter ---</option>
              {users.map(user => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col lg={1} className="pt-2">
            Filtern
          </Col>
          <Col lg={2}>
            <Form.Control onChange={e => setFilter(e.target.value || '')} />
          </Col>
          <Col lg={3}>
            <ButtonLink link="/jobs/add" buttonColor="success" icon="plus-circle-fill">
              Arbeitskarte anlegen
            </ButtonLink>
          </Col>
        </Row>
      </PageToolbar>
      <JobsTable
        jobs={jobs}
        onDelete={onConfirmDelete}
        onArchive={onConfirmArchive}
        onMoveToWaitingFrom={onConfirmMoveToWaitingRoom}
      />
    </>
  );
}
