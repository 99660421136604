import { createContext, useContext, useEffect, useState } from 'react';
import { User } from '../../modules/users/types/users';
import { getUser, hasPermission, removeLoginData, saveLoginData } from '../services/auth';
import { ModulePermission } from '../../types/module';

interface AuthContextState {
  user: User | undefined;
  login: (token: string, user: User) => void;
  logout: () => void;
  isUserAuthenticated: () => boolean;
  isAdminOrOffice: () => boolean;
  hasUserPermission: (permissions: ModulePermission[]) => boolean;
}

const AuthContext = createContext<AuthContextState>({
  user: getUser(),
} as any);

export function useAuth() {
  return useContext(AuthContext);
}

interface AuthContextProviderProps {
  children: JSX.Element | JSX.Element[];
}

export function AuthContextProvider({ children }: AuthContextProviderProps) {
  const [authenticatedUser, setAuthenticatedUser] = useState<User | undefined>(getUser());

  function logout() {
    removeLoginData();
    setAuthenticatedUser(undefined);
  }

  function login(token: string, user: User) {
    saveLoginData(token, user);
    setAuthenticatedUser(user);
  }

  function isUserAuthenticated() {
    return authenticatedUser !== undefined;
  }

  function hasUserPermission(permissions: ModulePermission[]) {
    if (authenticatedUser === undefined) {
      return false;
    }
    return hasPermission(authenticatedUser, permissions);
  }

  function isAdminOrOffice() {
    if (authenticatedUser === undefined) {
      return false;
    }
    return authenticatedUser.isAdmin || authenticatedUser.isOffice;
  }

  return (
    <AuthContext.Provider
      value={{
        user: authenticatedUser,
        login,
        logout,
        isUserAuthenticated,
        hasUserPermission,
        isAdminOrOffice,
      }}>
      {children}
    </AuthContext.Provider>
  );
}
