import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './styles/index.scss';
import { ROUTES } from './config/routes';
import { AuthContextProvider } from './libs/context/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <RouterProvider router={createBrowserRouter(ROUTES)} />
    </AuthContextProvider>
  </React.StrictMode>,
);
