import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import TableHeader from '../../../../libs/components/data/table/TableHeader';
import { IconDelete, IconDetails, IconSeparator } from '../../../../libs/components/data/Icon';
import { VehicleCommission } from '../../types/vehicle_commissions';

interface VehicleCommissionsTableProps {
  vehicleCommissions: VehicleCommission[];
  onDelete: (item: VehicleCommission) => void;
}

const TABLE_COLUMNS = [
  { key: 'createdAt', label: 'Annahmedatum', width: 150 },
  { key: 'plate', label: 'Kennzeichen', width: 125 },
  { key: 'brand', label: 'Marke', width: 125 },
  { key: 'mileage', label: 'KM-Stand', width: 125 },
];

export default function VehicleCommissionsTable({
  vehicleCommissions,
  onDelete,
}: VehicleCommissionsTableProps) {
  return (
    <Table striped bordered className="shadow-sm">
      <TableHeader columns={TABLE_COLUMNS} actionColumn={true} />
      <tbody>
        {vehicleCommissions.map(item => (
          <tr key={item.id}>
            <td>
              {getFormattedDateOrEmpty(item.commissionDate + 'T' + item.commissionTime, 'datetime')}
            </td>
            <td>{item.plate}</td>
            <td>
              {item.brand.name} {item.brand.types.find(type => type.id === item.brandType)?.name}
            </td>
            <td>{item.mileage}</td>
            <td>
              <Link to={`/vehicle_commissions/${item.id}`} className="text-info">
                <IconDetails />
              </Link>
              <IconSeparator />
              <IconDelete onClick={() => onDelete(item)} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
