import { API } from '../../../libs/services/api';
import { StorageCategory, StorageSubCategoryFormData } from '../types/storageCategories';

const BASE = 'storageCategories';

export async function getStorageCategories(): Promise<StorageCategory[]> {
  return (await API.get(BASE)).data;
}

export async function getStorageCategory(id: string): Promise<StorageCategory> {
  return (await API.get(`${BASE}/${id}`)).data;
}

export async function addStorageCategory(data: any) {
  const payload = {
    name: data.name,
  };
  return (await API.post(BASE, payload)).data;
}

export async function updateStorageCategory(id: string, data: any) {
  const payload = {
    name: data.name,
  };
  return (await API.patch(`${BASE}/${id}`, payload)).data;
}

export async function deleteStorageCategory(id: string) {
  return (await API.delete(`${BASE}/${id}`)).data;
}

export async function addStorageSubCategory(
  parentCategoryId: string,
  data: StorageSubCategoryFormData,
) {
  const payload = {
    name: data.name,
  };
  return (await API.post(`${BASE}/${parentCategoryId}/subCategories`, payload)).data;
}

export async function updateStorageSubCategoryLevel1(
  categoryId: string,
  subCategoryId: string,
  data: any,
) {
  const payload = {
    name: data.name,
    categories: data._categories,
  };
  return (await API.patch(`${BASE}/${categoryId}/subCategories/${subCategoryId}`, payload)).data;
}
