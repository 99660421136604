import React from 'react';

interface IconProps {
  icon: string;
  color?: string;
  styles?: string;
  onClick?: () => void;
}

interface IconSeparatorProps {
  padding?: number;
}

export function Icon({ icon, color = 'info', styles = '', onClick }: IconProps) {
  return (
    <i
      className={`text-${color} bi bi-${icon} ${styles}`}
      style={{ cursor: 'pointer' }}
      onClick={onClick}></i>
  );
}

export function IconDetails(props: Partial<IconProps>) {
  return <Icon icon="zoom-in" color="info" {...props} />;
}

export function IconVehicleDetails(props: Partial<IconProps>) {
  return <Icon icon="car-front-fill" color="info" {...props} />;
}

export function IconAdd(props: Partial<IconProps>) {
  return <Icon icon="plus-circle-fill" color="info" {...props} />;
}

export function IconEdit(props: Partial<IconProps>) {
  return <Icon icon="pencil-square" color="info" {...props} />;
}

export function IconDelete(props: Partial<IconProps>) {
  return <Icon icon="trash-fill" color="danger" {...props} />;
}

export function IconArchive(props: Partial<IconProps>) {
  return <Icon icon="archive-fill" color="warning" {...props} />;
}

export function IconStart(props: Partial<IconProps>) {
  return <Icon icon="play-fill" color="success" {...props} />;
}

export function IconStop(props: Partial<IconProps>) {
  return <Icon icon="stop-fill" color="warning" {...props} />;
}

export function IconWaitingRoom(props: Partial<IconProps>) {
  return <Icon icon="stop-circle-fill" color="warning" {...props} />;
}

export function IconWEMA(props: Partial<IconProps>) {
  return <Icon icon="gear-fill" color="white" {...props} />;
}

export function IconSeparator({ padding = 2 }: IconSeparatorProps) {
  return <span className={`px-${padding}`}></span>;
}
