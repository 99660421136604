import React, { ChangeEvent, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';

import { Job } from '../../../types/jobs';
import { SectionTitle } from '../../../../../libs/components/data/SectionTitle';
import JobNotesList from './JobNotesList';

interface JobNotesProps {
  job: Job;
  onNewNote: (content: string) => void;
  onNewAdminNote?: (content: string) => void;
}

export default function JobNotes({ job, onNewNote, onNewAdminNote }: JobNotesProps) {
  const [newNote, setNewNote] = useState<string>('');
  const [newAdminNote, setNewAdminNote] = useState<string>('');
  function onNotesChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setNewNote(e.target.value);
  }

  function onAdminNotesChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setNewAdminNote(e.target.value);
  }

  function saveNote() {
    onNewNote(newNote);
    setNewNote('');
  }

  function saveAdminNote() {
    if (!onNewAdminNote) {
      return;
    }
    onNewAdminNote(newAdminNote);
    setNewAdminNote('');
  }

  return (
    <>
      {onNewAdminNote !== undefined && (
        <>
          <SectionTitle title="Anmerkungen nur für Administratoren" />
          {job.adminNotes.length > 0 && (
            <Card className="bg-light mb-4">
              <Card.Body className="p-3">
                <JobNotesList notes={job.adminNotes} />
              </Card.Body>
            </Card>
          )}
          <div>
            <Form.Control
              as="textarea"
              rows={3}
              value={newAdminNote}
              onChange={onAdminNotesChange}
            />
          </div>
          <div className="mt-3">
            <Button size="sm" disabled={newAdminNote.trim() === ''} onClick={saveAdminNote}>
              Neue Anmerkung hinzufügen
            </Button>
          </div>
        </>
      )}
      <SectionTitle title="Anmerkungen von allen Mitarbeitern" />
      {job.notes.length > 0 && (
        <Card className="bg-light mb-4">
          <Card.Body className="p-3">
            <JobNotesList notes={job.notes} />
          </Card.Body>
        </Card>
      )}
      <div>
        <Form.Control as="textarea" rows={3} value={newNote} onChange={onNotesChange} />
      </div>
      <div className="mt-3">
        <Button size="sm" disabled={newNote.trim() === ''} onClick={saveNote}>
          Neue Anmerkung hinzufügen
        </Button>
      </div>
    </>
  );
}
