import { Job, JobWorkTime } from '../../jobs/types/jobs';
import { getSecondsDiff, transformSecondsToDateTime } from '../../../libs/services/time';
import { DailySchedule } from '../../daily_schedule/types/dailySchedule';
import { User } from '../../users/types/users';
import { WorkOverviewItem } from '../../stats/types/workTime';

export function getSumOfWorkTime(
  workTime: JobWorkTime[] | WorkOverviewItem[],
  transformToDateTime: boolean = true,
) {
  let secSum = 0;
  workTime.forEach(item => {
    if ((item as WorkOverviewItem).startDateOriginal !== undefined) {
      secSum += getSecondsDiff(
        (item as WorkOverviewItem).startDateOriginal,
        (item as WorkOverviewItem).endDateOriginal,
      );
    } else {
      secSum += getSecondsDiff(item.startDate, item.endDate);
    }
  });

  if (transformToDateTime) {
    return transformSecondsToDateTime(secSum);
  }
  return secSum;
}

export function getWorkTimeInterval(startDate: string, endDate: string | null) {
  return transformSecondsToDateTime(getSecondsDiff(startDate, endDate));
}

export function workTimeDiff(
  estimatedTimeHours: number,
  estimatedTimeMinutes: number,
  doneSeconds: number,
): number {
  const estimated = estimatedTimeHours * 60 + estimatedTimeMinutes;
  const done = Math.round(doneSeconds / 60);
  return estimated - done;
}

export function getSumOfWorkTimeForJobAndUser(job: Job, userId: string, day: string) {
  const workTimeOfUser = job.workTime.filter(
    item => item.userId === userId && item.startDate.substring(0, 10) === day,
  );
  return getSumOfWorkTime(workTimeOfUser);
}

export function calculateEstimatedTimeOfWholeDailySchedule(ds: DailySchedule[]): number {
  let estimatedMinutesSum = 0;
  ds.forEach(schedule => {
    estimatedMinutesSum += schedule.limitMinutes + schedule.limitHours * 60;
  });
  return estimatedMinutesSum;
}

export function calculateActualWorkedTimeOfWholeDailySchedule(
  ds: DailySchedule[],
  jobs: Job[],
  user: User,
  today: string,
) {
  let workedMinutesSum = 0;
  jobs.forEach(job => {
    const workTimeOfUser = job.workTime.filter(
      item => item.userId === user.id && item.startDate.substring(0, 10) === today,
    );
    workedMinutesSum += Math.round((getSumOfWorkTime(workTimeOfUser, false) as number) / 60);

    const scheduleForThisJob = ds.find(item => item.job.id === job.id);
    if (scheduleForThisJob) {
      job.estimatedWorkTimeHours = scheduleForThisJob.limitHours;
      job.estimatedWorkTimeMinutes = scheduleForThisJob.limitMinutes;
    }
  });
  return workedMinutesSum;
}
