import { useEffect, useState } from 'react';
import {
  deleteVehicleCommission as doDeleteVehicleCommission,
  getVehicleCommissions,
} from '../services/api';
import { VehicleCommission } from '../types/vehicle_commissions';

export function useVehicleCommissions() {
  const [vehicleCommissions, setVehicleCommissions] = useState<VehicleCommission[]>([]);

  useEffect(() => {
    loadVehicleCommissions();
  }, []);

  function loadVehicleCommissions() {
    getVehicleCommissions().then(data => setVehicleCommissions(data));
  }

  async function deleteVehicleCommission(id: string) {
    await doDeleteVehicleCommission(id);
    loadVehicleCommissions();
  }

  return { vehicleCommissions, deleteVehicleCommission };
}
