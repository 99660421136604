import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { StorageArticleFormData, StorageArticlePhotoForm } from '../../types/storage';
import { getValidationSchema } from '../../config/validation';
import FormInput from '../../../../libs/components/forms/FormInput';
import CategoryForm from './CategoryForm';
import React, { useEffect, useState } from 'react';
import StoragePhotoForm from './StoragePhotoForm';
import { StorageCategory } from '../../../storage_categories/types/storageCategories';
import { getStorageCategories } from '../../../storage_categories/services/api';

interface ArticleFormProps {
  submitButtonLabel: string;
  submitButtonColor?: string;
  initialValues: Partial<StorageArticleFormData>;
  onSubmit: (data: StorageArticleFormData) => void;
}

export function ArticleForm({
  submitButtonLabel,
  submitButtonColor = 'success',
  initialValues,
  onSubmit,
}: ArticleFormProps) {
  const [categories, setCategories] = useState<StorageCategory[]>([]);
  const [capturedPhotos, setCapturedPhotos] = useState<StorageArticlePhotoForm[]>([]);

  const methods = useForm<StorageArticleFormData>({
    defaultValues: initialValues,
    mode: 'all',
    resolver: yupResolver(getValidationSchema()),
  });

  useEffect(() => {
    getStorageCategories().then(data => setCategories(data));
  }, []);

  function addPhoto(photo: StorageArticlePhotoForm) {
    setCapturedPhotos([...capturedPhotos, photo]);
  }

  function deletePhoto(id: string) {
    setCapturedPhotos(capturedPhotos.filter(item => item.id !== id));
  }

  function editPhotoTags(id: string, tags: string) {
    setCapturedPhotos(
      capturedPhotos.map(photo => {
        if (photo.id !== id) {
          return photo;
        }
        return {
          ...photo,
          tags: tags,
        };
      }),
    );
  }

  function submit(data: StorageArticleFormData) {
    onSubmit({ ...data, photos: capturedPhotos });
  }

  return (
    <>
      {categories.length > 0 && (
        <FormProvider {...methods}>
          <Card className="bg-light">
            <Card.Body>
              <Form onSubmit={methods.handleSubmit(submit)}>
                <Row>
                  <Col>
                    <CategoryForm categories={categories} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormInput name="manufacturer" label="Hersteller" type="text" required={true} />
                  </Col>
                  <Col>
                    <FormInput name="supplier" label="Lieferant" type="text" required={true} />
                  </Col>
                  <Col>
                    <FormInput name="orderDate" label="Bestelldatum" type="date" required={true} />
                  </Col>
                  <Col>
                    <FormInput
                      name="billNumber"
                      label="Rechnungsnummer"
                      type="text"
                      required={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <FormInput name="quantity" label="Menge" type="number" required={true} />
                  </Col>
                  <Col lg={3}>
                    <FormInput name="unit" label="Einheit" type="text" required={true} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormInput
                      name="purchasingNetPrice"
                      label="EK Preis Netto"
                      type="text"
                      required={true}
                    />
                  </Col>
                  <Col>
                    <FormInput
                      name="perUnitPurchasingNetPrice"
                      label="EK Preis Netto pro Einheit"
                      type="text"
                      required={true}
                    />
                  </Col>
                  <Col>
                    <FormInput
                      name="sellingNetPrice"
                      label="VK Preis Netto"
                      type="text"
                      required={true}
                    />
                  </Col>
                  <Col>
                    <FormInput
                      name="perUnitSellingNetPrice"
                      label="VK Preis Netto pro Einheit"
                      type="text"
                      required={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <FormInput name="storagePlace" label="Lageort" type="text" required={true} />
                  </Col>
                  <Col lg={3}>
                    <FormInput name="shelf" label="Regalnummer" type="text" required={true} />
                  </Col>
                  <Col lg={3}>
                    <FormInput name="board" label="Gestellnummer" type="text" required={true} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormInput rows={2} name="tags" label="Tags" type="textarea" required={false} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <StoragePhotoForm
                      photos={capturedPhotos}
                      onCapture={addPhoto}
                      onDelete={deletePhoto}
                      onTagsEdit={editPhotoTags}
                    />
                  </Col>
                </Row>
                <Button variant={submitButtonColor} type="submit" className="mt-3">
                  {submitButtonLabel}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </FormProvider>
      )}
    </>
  );
}
