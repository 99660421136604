import { Table } from 'react-bootstrap';

import TableHeader from '../../../../libs/components/data/table/TableHeader';
import { PhoneCall } from '../../types/phone_calls';
import { formatDate } from '../../../../libs/services/date';
import {
  Icon,
  IconDelete,
  IconDetails,
  IconEdit,
  IconSeparator,
} from '../../../../libs/components/data/Icon';
import React from 'react';
import Status from '../../../../libs/components/data/Status';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../libs/context/AuthContext';

interface PhoneCallsTableProps {
  phoneCalls: PhoneCall[];
  mode?: 'resubmission';
  onDelete?: (item: PhoneCall) => void;
}

const TABLE_COLUMNS = [
  { key: 'callType', label: 'Art', width: 55 },
  { key: 'createdAt', label: 'Angelegt am/von', width: 150 },
  { key: 'category', label: 'Kategorie', width: 100 },
  { key: 'status', label: 'Status', width: 125 },
  { key: 'phoneNumber', label: 'Telefonnummer', width: 150 },
  { key: 'caller', label: 'Anrufer' },
  { key: 'plateNumber', label: 'Kennzeichen' },
];

const TABLE_COLUMNS_RESUBMISSION = [
  { key: 'callType', label: 'Art', width: 55 },
  { key: 'createdAt', label: 'Angelegt am/von', width: 150 },
  { key: 'category', label: 'Kategorie', width: 100 },
  { key: 'resubmission', label: 'Wiedervorlage', width: 150 },
  { key: 'phoneNumber', label: 'Telefonnummer', width: 150 },
  { key: 'caller', label: 'Anrufer' },
  { key: 'plateNumber', label: 'Kennzeichen' },
];

export default function PhoneCallsTable({ phoneCalls, mode, onDelete }: PhoneCallsTableProps) {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  function onConfirmDelete(item: PhoneCall) {
    if (!onDelete) {
      return;
    }
    return function () {
      onDelete(item);
    };
  }

  return (
    <Table striped bordered hover className="shadow-sm">
      <TableHeader
        columns={mode === 'resubmission' ? TABLE_COLUMNS_RESUBMISSION : TABLE_COLUMNS}
        actionColumn={true}
      />
      <tbody>
        {phoneCalls.map(item => (
          <tr key={item.id} className="text-center">
            <td>
              <Icon
                color={item.callType === 'Eingehender Anruf' ? 'primary' : 'warning'}
                icon={
                  item.callType === 'Eingehender Anruf'
                    ? 'telephone-inbound-fill'
                    : 'telephone-outbound-fill'
                }
              />
            </td>
            <td>
              {formatDate(item.callDate, 'date')} {item.callTime}, {item.createdBy.name}
            </td>
            <td>{item.category}</td>
            {mode === 'resubmission' && (
              <td style={{ width: 100 }}>
                {formatDate(item.resubmission, 'date')}
                <br />
                {item.resubmissionUser?.name}
              </td>
            )}
            {mode !== 'resubmission' && (
              <td style={{ width: 150 }}>
                <Status
                  status={item.status === 'erledigt'}
                  labelTrue="Erledigt"
                  labelFalse="In Bearbeitung"
                  colorTrue="success"
                  colorFalse="warning"
                />
              </td>
            )}
            <td>{item.phoneNumber}</td>
            <td>
              {item.callerSalutation} {item.caller}
            </td>
            <td>{item.plateNumber}</td>
            <td>
              <Link to={`/phone-calls/${item.id}`} className="text-info">
                <IconDetails />
              </Link>
              <IconSeparator />
              <Link to={`/phone-calls/edit/${item.id}`} className="text-info">
                <IconEdit />
              </Link>
              {user.isAdmin && onDelete && (
                <>
                  <IconSeparator />
                  <IconDelete onClick={onConfirmDelete(item)} />
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
