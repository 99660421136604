import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import { JobVehiclePart } from '../../../jobs/types/jobs';
import { getLatestJobVehicleParts } from '../../../vehicle_parts/services/api';
import Status from '../../../../libs/components/data/Status';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';

export default function LatestVehiclePartsList() {
  const [items, setItems] = useState<JobVehiclePart[]>([]);

  useEffect(() => {
    load();
  }, []);

  function load() {
    getLatestJobVehicleParts(10).then(data => setItems(data));
  }

  return (
    <>
      <SectionTitle title="Neueste Autoteile" />
      <Table striped bordered className="shadow-sm">
        <thead>
          <tr>
            <th>Status</th>
            <th>Name</th>
            <th>Nummer 1</th>
            <th>Nummer 2</th>
            <th>Anzahl</th>
            <th>Preis</th>
            <th>Preis insgesamt</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.id}>
              <td style={{ width: 125 }}>
                <Status
                  status={!!item.orderStatus}
                  labelTrue="Bestellt"
                  labelFalse="Nicht bestellt"
                  colorFalse="warning"
                />
              </td>
              <td>{item.name}</td>
              <td>{item.partNumber1}</td>
              <td>{item.partNumber2}</td>
              <td>{item.quantity}</td>
              <td>{item.price.toFixed(2).replace('.', ',')} &euro;</td>
              <td>
                {Number(item.quantity * item.price)
                  .toFixed(2)
                  .replace('.', ',')}{' '}
                &euro;
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
