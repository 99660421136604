import { useState } from 'react';

import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';
import VehicleCommissionsTable from './VehicleCommissionsTable';
import { useVehicleCommissions } from '../../hooks/useVehicleCommissions';
import { VehicleCommission } from '../../types/vehicle_commissions';

export default function VehicleCommissionsList() {
  const { vehicleCommissions, deleteVehicleCommission } = useVehicleCommissions();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [actionItem, setActionItem] = useState<VehicleCommission | undefined>();
  const [error, setError] = useState<string>('');

  function onConfirmDelete(item: VehicleCommission) {
    setActionItem(item);
    setError('');
    setDeleteModal(true);
  }

  async function onDelete() {
    setError('');
    if (actionItem) {
      try {
        await deleteVehicleCommission(actionItem.id);
        setActionItem(undefined);
        setDeleteModal(false);
      } catch (err: any) {
        setError(err.response?.data?.message || err.message);
      }
    }
  }

  return (
    <>
      <ConfirmModal
        show={deleteModal}
        title="Fahrzeugannahme löschen"
        onConfirm={onDelete}
        onCancel={() => setDeleteModal(false)}>
        <>
          <div>Möchten Sie wirklich die Fahrzeugannahme löschen?</div>
          {error !== '' && <div className="text-danger mt-2">{error}</div>}
        </>
      </ConfirmModal>
      <PageToolbar pageTitle="Fahrzeugannahmen">
        <ButtonLink link="/vehicle_commissions/add" buttonColor="success" icon="plus-circle-fill">
          Neue Fahrzeugannahme
        </ButtonLink>
      </PageToolbar>
      <VehicleCommissionsTable vehicleCommissions={vehicleCommissions} onDelete={onConfirmDelete} />
    </>
  );
}
