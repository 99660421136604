import { Job } from '../../types/jobs';
import { useEffect, useState } from 'react';
import { User } from '../../../users/types/users';
import { getUsers } from '../../../users/services/api';
import { Button, Form } from 'react-bootstrap';
import { IconStart, IconStop } from '../../../../libs/components/data/Icon';

interface JobAssignedUsersProps {
  job: Job;
  onAssignedUsersUpdate: (userIds: string[]) => void;
  onUserWorkTimeStart: (userId: string) => void;
  onUserWorkTimeStop: (userId: string) => void;
}

function getWorkshopUsersOnly(users: User[]): User[] {
  return users.filter(user => !user.isAdmin && !user.isOffice);
}

export default function JobAssignedUsers({
  job,
  onAssignedUsersUpdate,
  onUserWorkTimeStart,
  onUserWorkTimeStop,
}: JobAssignedUsersProps) {
  const [users, setUsers] = useState<User[]>([]);
  const [assignedUsers, setAssignedUsers] = useState<string[]>([]);

  function toggleAssignedUser(user: User) {
    if (!assignedUsers.includes(user.id)) {
      setAssignedUsers([...assignedUsers, user.id]);
    } else {
      setAssignedUsers(assignedUsers.filter(item => item !== user.id));
    }
  }

  useEffect(() => {
    getUsers().then(data => setUsers(data));
  }, []);

  useEffect(() => {
    setAssignedUsers(job.assignedUsers.map(user => user.id));
  }, [job]);

  function userWorkInProgress(user: User): boolean {
    return job.workTime.some(workTime => workTime.userId === user.id && workTime.endDate === null);
  }

  if (users.length === 0) {
    return null;
  }

  return (
    <>
      <ul className="list-group">
        {getWorkshopUsersOnly(users).map(item => (
          <li
            key={item.id}
            style={{ padding: '5px 15px' }}
            className="list-group-item d-flex justify-content-between align-items-center">
            <div>
              <Form.Check
                type="checkbox"
                disabled={item.id === job.mainAssignedUser.id}
                label={item.name}
                checked={assignedUsers.includes(item.id)}
                onChange={() => toggleAssignedUser(item)}
              />
            </div>
            {(job.assignedUsers.map(({ id }) => id).includes(item.id) ||
              job.mainAssignedUser.id === item.id) && (
              <div className="text-end">
                {!userWorkInProgress(item) && (
                  <Button variant="success" size="sm" onClick={() => onUserWorkTimeStart(item.id)}>
                    <IconStart styles="text-white" />
                  </Button>
                )}
                {userWorkInProgress(item) && (
                  <Button variant="warning" size="sm" onClick={() => onUserWorkTimeStop(item.id)}>
                    <IconStop styles="text-white" />
                  </Button>
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
      <div className="mt-3 mb-1">
        <Button variant="info" size="sm" onClick={() => onAssignedUsersUpdate(assignedUsers)}>
          Zugewiesene Mitarbeiter aktualisieren
        </Button>
      </div>
    </>
  );
}
