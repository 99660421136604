import { Link } from 'react-router-dom';
import {
  IconDelete,
  IconDetails,
  IconEdit,
  IconSeparator,
} from '../../../libs/components/data/Icon';
import { WorkOverview, WorkOverviewItem } from '../../stats/types/workTime';
import { getSumOfWorkTime, workTimeDiff } from '../../my-jobs/services/time';
import { DailySchedule } from '../../daily_schedule/types/dailySchedule';

interface UserOverviewProps {
  username: string;
  userId?: string;
  workTime: WorkOverview;
  date?: string;
  dailySchedule?: DailySchedule[];
  onWorkTimeEdit?: (item: WorkOverviewItem) => void;
  onWorkTimeDelete?: (item: WorkOverviewItem) => void;
}

export default function UserOverview({
  username,
  userId,
  workTime,
  date,
  dailySchedule,
  onWorkTimeEdit,
  onWorkTimeDelete,
}: UserOverviewProps) {
  const scheduleForThisItem = dailySchedule?.find(item => item.user.id === userId);
  const sumOfWorkTime = getSumOfWorkTime(workTime.items, false) as number;

  return (
    <>
      {username !== '' && (
        <div className="text-primary mb-2">
          <strong>
            {username}{' '}
            <span className="fs-6 badge bg-secondary rounded-pill">{workTime.durationSum}</span>
          </strong>
        </div>
      )}
      <ul className="list-group">
        {workTime.items.map(item => (
          <li
            key={item.id}
            className="list-group-item d-flex justify-content-between align-items-center">
            <div>
              <div className="me-2 badge bg-info rounded-pill">{item.jobKey}</div>
              {date !== undefined &&
                dailySchedule !== undefined &&
                scheduleForThisItem !== undefined && (
                  <div>
                    <div
                      className={`badge me-2 rounded-pill text-white bg-${
                        workTimeDiff(
                          scheduleForThisItem.limitHours,
                          scheduleForThisItem.limitMinutes,
                          sumOfWorkTime,
                        ) <= 0
                          ? 'danger'
                          : 'success'
                      }`}>
                      {scheduleForThisItem.limitHours} St. {scheduleForThisItem.limitMinutes} Min.
                    </div>
                  </div>
                )}
            </div>
            <div className="ms-2" style={{ width: 100 }}>
              {item.startTime} - {item.endTime}
            </div>
            <div className="ms-2 me-auto">
              <div className="fw-bold">{item.vehicle}</div>
              <div>{item.plate}</div>
            </div>
            <div>
              <span
                className={`badge bg-${
                  item.endTime !== null ? 'success' : 'warning'
                } rounded-pill`}>
                {item.duration}
              </span>
            </div>
            <div className="ms-4">
              <Link to={`/jobs/${item.jobId}`} className="text-info">
                <IconDetails />
              </Link>
              {onWorkTimeEdit !== undefined && item.endDate !== null && (
                <>
                  <IconSeparator />
                  <IconEdit onClick={() => onWorkTimeEdit(item)}></IconEdit>
                </>
              )}
              {onWorkTimeDelete !== undefined && (
                <>
                  <IconSeparator />
                  <IconDelete onClick={() => onWorkTimeDelete(item)}></IconDelete>
                </>
              )}
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
