import { Table } from 'react-bootstrap';
import { Job } from '../../types/jobs';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import React from 'react';

interface JobVehicleProps {
  job: Job;
}

export default function JobVehicle({ job }: JobVehicleProps) {
  if (job.vehicle === null) {
    return null;
  }

  return (
    <>
      <SectionTitle title="Fahrzeugdaten" />
      <Table striped bordered className="shadow-sm">
        <tbody>
          <tr>
            <td style={{ width: 200 }}>
              <strong>Kennzeichen</strong>
            </td>
            <td>{job.vehicle.plate}</td>
          </tr>
          <tr>
            <td>
              <strong>Marke</strong>
            </td>
            <td>
              {job.vehicle.brandName} {job.vehicle.brandTypeName}
            </td>
          </tr>
          <tr>
            <td>
              <strong>KM-Stand</strong>
            </td>
            <td>{job.mileage}</td>
          </tr>
          <tr>
            <td>
              <strong>Erstzulassung</strong>
            </td>
            <td>{job.vehicle.registrationDate}</td>
          </tr>
          <tr>
            <td>
              <strong>FIN</strong>
            </td>
            <td>{job.vehicle.fin}</td>
          </tr>
          <tr>
            <td>
              <strong>HSN</strong>
            </td>
            <td>{job.vehicle.hsn}</td>
          </tr>
          <tr>
            <td>
              <strong>TSN</strong>
            </td>
            <td>{job.vehicle.tsn}</td>
          </tr>
          <tr>
            <td>
              <strong>kW / PS</strong>
            </td>
            <td>
              {job.vehicle.kw} / {job.vehicle.ps}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Hubraum (ccm)</strong>
            </td>
            <td>{job.vehicle.displacement}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
