import { API } from '../../../libs/services/api';
import { Vehicle, VehicleEditFormData } from '../types/vehicles';

const BASE = 'vehicles';

export async function getVehicles(
  filter: string,
  archivedFilter: boolean,
  userFilter: string,
): Promise<Vehicle[]> {
  return (
    await API.get(
      `${BASE}?filter=${filter}&archivedFilter=${
        archivedFilter ? '1' : ''
      }&userFilter=${userFilter}`,
    )
  ).data;
}

export async function getVehicle(id: string): Promise<Vehicle> {
  return (await API.get(`${BASE}/${id}`)).data;
}

export async function addNewVehicleNote(vehicleId: string, content: string) {
  await API.post(`${BASE}/${vehicleId}/notes`, { content });
}

export async function updateVehicle(vehicleId: string, dto: VehicleEditFormData) {
  await API.patch(`${BASE}/${vehicleId}`, { ...dto });
}

export async function archiveVehicleAndLinkedJobs(vehicleId: string) {
  await API.patch(`${BASE}/${vehicleId}/archive`);
}

export async function getVehiclePlatesOfOpenJobs(): Promise<string[]> {
  return (await API.get('/jobs/vehiclePlatesOfOpenJobs')).data;
}
