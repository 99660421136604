import Pagination from 'react-bootstrap/Pagination';

interface ListPaginationProps {
  currentPage: number;
  pageCount: number;
  onPageSelect: (pageNumber: number) => void;
}

export default function ListPagination({
  currentPage = 1,
  pageCount = 1,
  onPageSelect,
}: ListPaginationProps) {
  function renderItems() {
    const arr = [];
    for (let i = 1; i <= pageCount; i++) {
      arr.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageSelect(i)}>
          {i}
        </Pagination.Item>,
      );
    }
    return arr;
  }

  return (
    <Pagination size="sm" className="mt-4 d-flex justify-content-center">
      <Pagination.First onClick={() => onPageSelect(1)} />
      {currentPage > 1 && <Pagination.Prev onClick={() => onPageSelect(currentPage - 1)} />}
      {renderItems()}
      {pageCount > currentPage && <Pagination.Next onClick={() => onPageSelect(currentPage + 1)} />}
      <Pagination.Last onClick={() => onPageSelect(pageCount)} />
    </Pagination>
  );
}
