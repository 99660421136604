import { Col, Row } from 'react-bootstrap';
import FormInput from '../../../../libs/components/forms/FormInput';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import React from 'react';

export default function JobWithoutVehicleData() {
  return (
    <>
      <SectionTitle title="Arbeitskartedaten" />
      <Row>
        <Col lg={5}>
          <FormInput name="jobFor" label="Arbeitskarte für" type="textarea" required={true} />
        </Col>
        <Col>
          <FormInput name="clientName" label="Firmen- / Kundennummer" type="text" required={true} />
        </Col>
        <Col>
          <FormInput name="itemNumber" label="Teilnummer" type="text" required={true} />
        </Col>
        <Col lg={2}></Col>
      </Row>
    </>
  );
}
