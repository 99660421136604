import { Job } from '../../types/jobs';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { getRelatedJobs } from '../../services/api';
import Status from '../../../../libs/components/data/Status';
import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import { Link } from 'react-router-dom';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import { IconDetails } from '../../../../libs/components/data/Icon';

interface RelatedJobsListProps {
  currentJob: Job;
  linkPrefix?: string;
}

export default function RelatedJobsList({ currentJob, linkPrefix = 'jobs' }: RelatedJobsListProps) {
  const [jobs, setJobs] = useState<Job[]>([]);

  useEffect(() => {
    getRelatedJobs(currentJob.vehicle!.id).then(data =>
      setJobs(data.filter(job => job.id !== currentJob.id)),
    );
  }, [currentJob]);

  if (jobs.length === 0) {
    return null;
  }

  return (
    <>
      <SectionTitle title={`Weitere Arbeitskarten für ${currentJob.vehicle!.plate}`} />
      <Table striped bordered className="shadow-sm">
        <thead>
          <tr>
            <th style={{ width: 75 }}>ID</th>
            <th style={{ width: 80 }}>Erledigt</th>
            <th style={{ width: 125 }}>Anlegegt am</th>
            <th>KM-Stand</th>
            <th>Manager</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map(item => (
            <tr key={item.id}>
              <td>{item.jobKey}</td>
              <td>
                <Status status={item.isDone} labelTrue="Ja" labelFalse="Nein" />
              </td>
              <td>{getFormattedDateOrEmpty(item.createdAt)}</td>
              <td>{item.mileage}</td>
              <td>{item.mainAssignedUser.name}</td>
              <td>
                <Link
                  to={`/${linkPrefix}/${item.id}?fromRelatedList=1`}
                  className="text-info"
                  target="_blank">
                  <IconDetails />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
