import { API } from '../../../libs/services/api';
import { JobPhoto } from '../../jobs/types/jobs';
import { createPaginationQuery } from '../../../libs/services/pagination';
import { PagedResponseDto } from '../../../types/pagination';

const BASE = 'jobPhotos';

export async function getJobPhotos(
  filter: string,
  page: number = 1,
  limit: number,
): Promise<PagedResponseDto<JobPhoto>> {
  return (await API.get(`${BASE}?filter=${filter}${createPaginationQuery(page, limit, '&')}`)).data;
}

export async function deletePhoto(jobId: string, photoId: string) {
  return (await API.delete(`${BASE}/${jobId}/${photoId}`)).data;
}

export async function updatePhoto(jobId: string, photoId: string, field: string, value: any) {
  return (await API.patch(`${BASE}/${jobId}/${photoId}`, { [field]: value })).data;
}
