import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import React, { useEffect, useState } from 'react';
import { StorageArticle, StorageArticlePhoto, StorageArticlePhotoForm } from '../../types/storage';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  deletePhoto,
  getArticle,
  updatePhoto,
  updatePhotosTags,
  uploadPhotos,
} from '../../services/api';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import { formatDate } from '../../../../libs/services/date';
import { toPrice } from '../../../../libs/services/price';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import ArticlePhotos from './ArticlePhotos';
import { QRCodeSVG } from 'qrcode.react';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';

export default function StorageArticleDetails() {
  const { id } = useParams();
  const [article, setArticle] = useState<StorageArticle>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [editedArticlePhotos, setEditedArticlePhotos] = useState<StorageArticlePhoto[]>([]);
  const [deletePhotoItem, setDeletePhotoItem] = useState<StorageArticlePhoto | undefined>();
  const [deletePhotoModal, setDeletePhotoModal] = useState<boolean>(false);

  useEffect(() => {
    loadArticle(id!);
  }, [id]);

  async function uploadStoragePhotos(photos: StorageArticlePhotoForm[]) {
    setUploading(true);
    await uploadPhotos(article!.id, photos);
    loadArticle(article!.id);
    setUploading(false);
  }

  function loadArticle(id: string) {
    getArticle(id).then(data => setArticle(data));
  }

  function onPhotoTagEdit(photo: StorageArticlePhoto, content: string) {
    setEditedArticlePhotos(
      editedArticlePhotos.map(item => {
        if (item.id !== photo.id) {
          return item;
        }
        return {
          ...item,
          tags: content.split(','),
        };
      }),
    );
  }

  async function savePhotosTags() {
    await updatePhotosTags(article!.id, editedArticlePhotos);
    loadArticle(article!.id);
  }

  async function onPhotoUpdate(photo: StorageArticlePhoto, field: string, value: any) {
    await updatePhoto(photo.storageArticleId, photo.id, field, value);
    loadArticle(article!.id);
  }

  function onDeletePhotoConfirm(item: StorageArticlePhoto) {
    setDeletePhotoItem(item);
    setDeletePhotoModal(true);
  }

  async function onPhotoDelete() {
    if (!deletePhotoItem) {
      return;
    }
    await deletePhoto(deletePhotoItem.storageArticleId, deletePhotoItem.id);
    setDeletePhotoModal(false);
    loadArticle(article!.id);
  }

  function goToStorageArticlePrintPage(id: string) {
    window.open(`/article-qrcode-print/${id}`, '_blank');
  }

  if (!article) {
    return null;
  }

  return (
    <>
      {deletePhotoItem && (
        <ConfirmModal
          show={deletePhotoModal}
          title="Bild löschen"
          onConfirm={onPhotoDelete}
          onCancel={() => setDeletePhotoModal(false)}>
          Möchten Sie wirklich das ausgewählte Bild löschen?
        </ConfirmModal>
      )}
      <PageToolbar pageTitle="Lager Artikel">
        <Button onClick={() => goToStorageArticlePrintPage(article!.id)}>
          <i className="bi bi-printer-fill"></i> QR Code ausdrucken
        </Button>
        <span className="px-2"></span>
        <ButtonLink link="/storage" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      <Row>
        <Col lg={7}>
          <SectionTitle title="Details" />
          <Table striped bordered className="shadow-sm">
            <tbody>
              <tr>
                <td style={{ width: 210 }}>
                  <strong>ID</strong>
                </td>
                <td>{article.id}</td>
              </tr>
              <tr>
                <td>
                  <strong>Kategorie</strong>
                </td>
                <td>
                  {article.category}
                  {article.subCategoryLevel1 !== null && (
                    <span> / {article.subCategoryLevel1}</span>
                  )}
                  {article.subCategoryLevel2 !== null && (
                    <span> / {article.subCategoryLevel2}</span>
                  )}
                  {article.subCategoryLevel3 !== null && (
                    <span> / {article.subCategoryLevel3}</span>
                  )}
                  {article.subCategoryLevel4 !== null && (
                    <span> / {article.subCategoryLevel4}</span>
                  )}{' '}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Lager</strong>
                </td>
                <td>
                  {article.storagePlace} / {article.shelf} / {article.board}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Hersteller</strong>
                </td>
                <td>{article.manufacturer}</td>
              </tr>
              <tr>
                <td>
                  <strong>Lieferant</strong>
                </td>
                <td>{article.supplier}</td>
              </tr>
              <tr>
                <td>
                  <strong>Bestelldatum</strong>
                </td>
                <td>{formatDate(article.orderDate, 'date')}</td>
              </tr>
              <tr>
                <td>
                  <strong>Rechnungsnummer</strong>
                </td>
                <td>{article.billNumber}</td>
              </tr>
              <tr>
                <td>
                  <strong>Menge / Einheit</strong>
                </td>
                <td>
                  {article.quantity} {article.unit}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>EK Preis Netto</strong>
                </td>
                <td>{toPrice(article.purchasingNetPrice)}</td>
              </tr>
              <tr>
                <td>
                  <strong>EK pro Einheit Netto</strong>
                </td>
                <td>{toPrice(article.perUnitPurchasingNetPrice)}</td>
              </tr>
              <tr>
                <td>
                  <strong>VK Preis Netto</strong>
                </td>
                <td>{toPrice(article.sellingNetPrice)}</td>
              </tr>
              <tr>
                <td>
                  <strong>VK pro Einheit Netto</strong>
                </td>
                <td>{toPrice(article.perUnitSellingNetPrice)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Tags</strong>
                </td>
                <td>{article.tags}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col lg={5}>
          <SectionTitle title="QR Code" />
          <QRCodeSVG size={350} value={window.location.href} />
        </Col>
      </Row>
      {!uploading && (
        <ArticlePhotos
          photos={article.photos}
          allowedTagsEdit={true}
          allowedPhotoAdd={true}
          uploadPhotos={uploadStoragePhotos}
          onPhotoTagEdit={onPhotoTagEdit}
          savePhotosTags={savePhotosTags}
          onPhotoUpdate={onPhotoUpdate}
          onPhotoDelete={onDeletePhotoConfirm}
        />
      )}
    </>
  );
}
