import { Button, Table } from 'react-bootstrap';

import { JobVehiclePart } from '../../../jobs/types/jobs';
import Status from '../../../../libs/components/data/Status';
import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import { toPrice } from '../../../../libs/services/price';
import { Icon, IconEdit, IconSeparator } from '../../../../libs/components/data/Icon';
import TableHeader from '../../../../libs/components/data/table/TableHeader';
import VehiclePartStatus from '../../../my-jobs/components/MyJobDetails/VehiclePartStatus';

interface VehiclePartsTableProps {
  parts: JobVehiclePart[];
  toggleOrderStatus: (partId: string) => void;
  onNoteEdit: (partId: string) => void;
}

const TABLE_COLUMNS = [
  { key: 'status', label: 'Status' },
  { key: 'name', label: 'Name' },
  { key: 'partNumber1', label: 'Nummer 1' },
  { key: 'partNumber2', label: 'Nummer 2' },
  { key: 'quantity', label: 'Anzahl' },
  { key: 'price', label: 'Preis' },
  { key: 'priceTotal', label: 'Preis insgesamt' },
  { key: 'notes', label: 'Anmerkungen' },
];

export default function VehiclePartsTable({
  parts,
  toggleOrderStatus,
  onNoteEdit,
}: VehiclePartsTableProps) {
  return (
    <Table striped bordered hover className="shadow-sm">
      <TableHeader columns={TABLE_COLUMNS} actionColumn={true} />
      <tbody>
        {parts.map(item => (
          <tr key={item.id}>
            <td style={{ width: 150 }}>
              <VehiclePartStatus part={item} />
              {item.orderStatusLastChangedAt !== null && (
                <div style={{ fontSize: '0.8rem' }} className="mt-1">
                  Letzte Status Änderung:{' '}
                  {getFormattedDateOrEmpty(item.orderStatusLastChangedAt, 'datetime')} von{' '}
                  {item.orderStatusLastChangedBy?.name}
                </div>
              )}
            </td>
            <td>{item.name}</td>
            <td>{item.partNumber1}</td>
            <td>{item.partNumber2}</td>
            <td>{item.quantity}</td>
            <td>{toPrice(item.price)}</td>
            <td>{toPrice(item.quantity * item.price)}</td>
            <td>
              <div style={{ whiteSpace: 'pre-wrap' }}>{item.notes}</div>
            </td>
            <td>
              <Button size="sm" variant="info" onClick={() => toggleOrderStatus(item.id)}>
                <Icon icon="check-square" styles="text-white" /> Bestellstatus
              </Button>
              <IconSeparator />
              <Button size="sm" variant="light" onClick={() => onNoteEdit(item.id)}>
                <IconEdit /> Anmerkungen
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
