import { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';

import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import { getBrand, updateBrandType } from '../../services/api';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import Message from '../../../../libs/components/data/Message';
import ApiErrorMessage from '../../../../libs/components/data/ApiErrorMessage';
import {
  FormActionState,
  getFormActionReducer,
} from '../../../../libs/services/state/formActionReducer';
import { Brand, BrandType, BrandTypeFormData } from '../../types/brands';
import { BrandTypeForm } from './BrandTypeForm';

const initialState: FormActionState = {
  actionInProgress: false,
  actionSuccess: false,
  actionError: false,
  showForm: true,
  actionErrorResponse: undefined,
};

export default function BrandTypeEdit() {
  const { id, typeId } = useParams();
  const [brand, setBrand] = useState<Brand>();
  const [brandType, setBrandType] = useState<BrandType>();
  const [formData, setFormData] = useState<BrandTypeFormData>();
  const [state, dispatch] = useReducer(getFormActionReducer(initialState), initialState);

  useEffect(() => {
    if (!id) {
      return;
    }
    getBrand(id).then(data => {
      setBrand(data);
      const brandType = data.types.find(type => type.id === typeId);
      setBrandType(brandType);
      setFormData({
        name: brandType!.name,
      });
    });
  }, [id]);

  async function onSubmit(data: BrandTypeFormData) {
    if (!brand || !brandType) {
      return;
    }

    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      await updateBrandType(brand.id, brandType.id, data);
      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    }
  }

  if (!brand || !brandType || !formData) {
    return null;
  }

  return (
    <>
      <PageToolbar pageTitle={`Modell bearbeiten: ${brandType.name}`}>
        <ButtonLink link="/brands" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      {state.showForm && (
        <div className="mb-4">
          <BrandTypeForm
            submitButtonLabel="Modell bearbeiten"
            onSubmit={onSubmit}
            initialValues={formData}
          />
        </div>
      )}
      {state.actionError && (
        <ApiErrorMessage
          label="Modell konnte nicht aktualisiert werden"
          errorResponse={state.actionErrorResponse}
        />
      )}
      {state.actionSuccess && (
        <Message type="ok">
          <span>
            <strong>Modell wurde aktualisiert</strong>
          </span>
        </Message>
      )}
    </>
  );
}
