import { Job, JobState } from '../../types/jobs';
import { Col, Row, Table } from 'react-bootstrap';
import Status from '../../../../libs/components/data/Status';
import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import JobMainAssignedUser from './JobMainAssignedUser';
import JobAssignedUsers from './JobAssignedUsers';
import React from 'react';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';

interface JobInfoProps {
  job: Job;
  onMainAssignedUserUpdate: (userId: string) => void;
  onAssignedUsersUpdate: (userIds: string[]) => void;
  onAssignJobState: () => void;
  onIsDoneChange: () => void;
  onUserWorkTimeStart: (userId: string) => void;
  onUserWorkTimeStop: (userId: string) => void;
}

export default function JobInfo({
  job,
  onMainAssignedUserUpdate,
  onAssignedUsersUpdate,
  onAssignJobState,
  onIsDoneChange,
  onUserWorkTimeStart,
  onUserWorkTimeStop,
}: JobInfoProps) {
  return (
    <>
      <SectionTitle title="Arbeitskarte" />
      <Table striped bordered className="shadow-sm">
        <tbody>
          <tr>
            <td style={{ width: 210 }}>
              <strong>ID</strong>
            </td>
            <td>{job.jobKey}</td>
          </tr>
          {job.vehicle && (
            <tr>
              <td>
                <strong>Pos. Schlüsselnummer</strong>
              </td>
              <td>
                {job.keyPosition1 ?? ''}/{job.keyPosition2 ?? ''}
              </td>
            </tr>
          )}
          {job.vehicle === null && (
            <>
              <tr>
                <td>
                  <strong>Arbeitskarte für</strong>
                </td>
                <td>{job.jobFor}</td>
              </tr>
              <tr>
                <td>
                  <strong>Kunden- / Firmenname</strong>
                </td>
                <td>{job.clientName}</td>
              </tr>
              <tr>
                <td>
                  <strong>Teilnummer</strong>
                </td>
                <td>{job.itemNumber}</td>
              </tr>
            </>
          )}
          <tr>
            <td>
              <strong>Erledigt</strong>
            </td>
            <td>
              <Row>
                <Col lg={3}>
                  <Status status={job.isDone} labelTrue="Ja" labelFalse="Nein" />
                </Col>
                <Col lg={9} className="text-end">
                  <ButtonLink
                    click={onIsDoneChange}
                    size="sm"
                    buttonColor="info"
                    icon="person-fill-up">
                    <>{job.isDone ? 'wieder eröffnen' : 'als erledigt markieren'}</>
                  </ButtonLink>
                </Col>
              </Row>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Archiviert</strong>
            </td>
            <td>
              <Status status={job.isArchived} labelTrue="Ja" labelFalse="Nein" />
            </td>
          </tr>
          <tr>
            <td>
              <strong>Bearbeiter</strong>
            </td>
            <td>
              <Row>
                <Col lg={3}>
                  <Status
                    status={job.jobState === JobState.WORKSHOP}
                    labelTrue="Wekstatt"
                    labelFalse="Büro"
                    colorTrue="primary"
                    colorFalse="warning"
                  />
                </Col>
                <Col lg={9} className="text-end">
                  <ButtonLink
                    click={onAssignJobState}
                    size="sm"
                    buttonColor="info"
                    icon="person-fill-up">
                    <>{job.jobState === JobState.OFFICE ? 'der Werkstatt' : 'dem Büro'} zuweisen</>
                  </ButtonLink>
                </Col>
              </Row>
            </td>
          </tr>
          {job.isDone && (
            <tr>
              <td>
                <strong>Erledigt am</strong>
              </td>
              <td>{getFormattedDateOrEmpty(job.doneAt, 'datetime')}</td>
            </tr>
          )}
          <tr>
            <td>
              <strong>Angelegt am</strong>
            </td>
            <td>{getFormattedDateOrEmpty(job.createdAt, 'datetime')}</td>
          </tr>
          <tr>
            <td>
              <strong>Angelegt von</strong>
            </td>
            <td>{job.createdBy.name}</td>
          </tr>
          <tr>
            <td>
              <strong>Fertig bis</strong>
            </td>
            <td>{getFormattedDateOrEmpty(job.expectedDoneAt)}</td>
          </tr>
          <tr>
            <td>
              <strong>Geschätzte Arbeitszeit</strong>
            </td>
            <td>
              {job.estimatedWorkTimeHours} St. {job.estimatedWorkTimeMinutes} Min.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Aufgabenmanager</strong>
            </td>
            <td>
              <JobMainAssignedUser job={job} onMainAssignedUserUpdate={onMainAssignedUserUpdate} />
            </td>
          </tr>
          <tr>
            <td>
              <strong>Mitarbeiter</strong>
            </td>
            <td>
              <JobAssignedUsers
                job={job}
                onAssignedUsersUpdate={onAssignedUsersUpdate}
                onUserWorkTimeStart={onUserWorkTimeStart}
                onUserWorkTimeStop={onUserWorkTimeStop}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
