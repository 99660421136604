import { Button, Modal } from 'react-bootstrap';

interface ConfirmModalProps {
  show: boolean;
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
  children: string | JSX.Element;
}

export default function ConfirmModal(props: ConfirmModalProps) {
  return (
    <Modal show={props.show} onHide={props.onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onCancel}>
          Abbrechen
        </Button>
        <Button variant="primary" onClick={props.onConfirm}>
          Bestätigen
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
