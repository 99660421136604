import axios from 'axios';

import { getToken, removeLoginData } from './auth';

export const API = axios.create({
  baseURL: '/api/',
});

API.interceptors.request.use(config => {
  config.headers!.Authorization = getToken();
  return config;
});

API.interceptors.response.use(
  res => res,
  err => {
    if (err.response.status === 401 && err.response.data?.message.startsWith('ACCESS DENIED')) {
      removeLoginData();
      window.location.href = '/login';
      return;
    }
    return Promise.reject(err);
  },
);
