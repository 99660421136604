import { Button, Modal, Table } from 'react-bootstrap';
import * as React from 'react';
import { createRef, useState } from 'react';

import { User } from '../../users/types/users';
import { Job } from '../../jobs/types/jobs';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { Typeahead } from 'react-bootstrap-typeahead';
import { formatDate } from '../../../libs/services/date';

interface AddFormItemModalProps {
  jobs: Job[];
  searchOptions: string[];
  selectedUser: User;
  show: boolean;
  onSelect: (job: Job) => void;
  onCancel: () => void;
}

export default function AddFormItemModal(props: AddFormItemModalProps) {
  const [foundJob, setFoundJob] = useState<Job | undefined>();
  const ref = createRef<any>();

  async function onSearch(option: string) {
    let jobKey = '';
    if (option.startsWith('xid')) {
      jobKey = option;
    } else {
      const result = option.split(', ');
      jobKey = result[result.length - 1];
    }

    setFoundJob(props.jobs.find(job => job.jobKey === jobKey));
  }

  function onOptionSelect(selectedItem: Option[]) {
    if (selectedItem.length === 1 && selectedItem[0]) {
      onSearch(selectedItem[0] as string);
    }
  }

  function selectFoundJob() {
    if (!foundJob) {
      return;
    }
    props.onSelect(foundJob);
    setFoundJob(undefined);
  }

  function onCancel() {
    ref.current.clear();
    setFoundJob(undefined);
    props.onCancel();
  }

  return (
    <Modal show={props.show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Arbeitskarte für {props.selectedUser.name} hinzufügen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">
          <strong>Marke ODER Kennzeichen ODER xid</strong>
        </div>
        <Typeahead
          ref={ref}
          multiple={false}
          id="existingVehicleSearch"
          onChange={onOptionSelect}
          options={props.searchOptions}
        />
        {foundJob && (
          <div className="mt-3">
            <Table striped bordered className="shadow-sm">
              <tbody>
                <tr>
                  <td style={{ width: 150 }}>
                    <strong>XID</strong>
                  </td>
                  <td>{foundJob.jobKey}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Angelegt am</strong>
                  </td>
                  <td>{formatDate(foundJob.createdAt, 'date')}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Fahrzeug</strong>
                  </td>
                  <td>
                    {!foundJob.vehicle && <span>ohne Fahrzeug</span>}
                    {foundJob.vehicle?.brandName} {foundJob.vehicle?.brandTypeName}
                  </td>
                </tr>
                {foundJob.vehicle && (
                  <tr>
                    <td>
                      <strong>KM-Stand</strong>
                    </td>
                    <td>{foundJob.mileage}</td>
                  </tr>
                )}
                {!foundJob.vehicle && (
                  <tr>
                    <td>
                      <strong>Arbeitskarte für</strong>
                    </td>
                    <td>{foundJob.jobFor}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Abbrechen
        </Button>
        <Button variant="primary" onClick={selectFoundJob} disabled={!foundJob}>
          Hinzufügen
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
