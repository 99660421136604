import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

interface PageToolbarProps {
  pageTitle: string | JSX.Element;
  children?: React.ReactNode;
  titleColWidth?: number;
  contentColWidth?: number;
}

export default function PageToolbar({
  pageTitle,
  children,
  titleColWidth,
  contentColWidth,
}: PageToolbarProps) {
  return (
    <Card className="bg-light mb-4">
      <Card.Body className="p-3">
        <Row>
          <Col lg={titleColWidth}>
            <div className="fs-4 m-0">{pageTitle}</div>
          </Col>
          <Col className="text-end" lg={contentColWidth}>
            {children}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
