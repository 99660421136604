import { Sort } from '../../types/sort';
import { TableHeaderColumn } from '../../types/table';

export function getSortDir(column: TableHeaderColumn, currentSort?: Sort): 'asc' | 'desc' {
  if (currentSort?.field !== column.key) {
    return 'asc';
  }
  return currentSort?.dir === 'asc' ? 'desc' : 'asc';
}

export function createSortQuery(prefix: string, sort: Sort): string {
  return `${prefix}sortField=${sort.field}&sortDir=${sort.dir}`;
}
