import { useEffect, useState } from 'react';

import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import SessionsTable from './SessionsTable';
import { UserSession } from '../../types/usersessions';
import { deleteSession, getSessions } from '../../services/api';

export default function SessionsList() {
  const [sessions, setSessions] = useState<UserSession[]>([]);

  useEffect(() => {
    loadSessions();
  }, []);

  function loadSessions() {
    getSessions().then(data => setSessions(data));
  }

  async function onDeleteSession(session: UserSession) {
    await deleteSession(session.id);
    loadSessions();
  }

  return (
    <>
      <PageToolbar pageTitle="Benutzersitzungen"></PageToolbar>
      <SessionsTable sessions={sessions} onSessionDelete={onDeleteSession} />
    </>
  );
}
