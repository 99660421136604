import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import Status from '../../../../libs/components/data/Status';
import { User } from '../../types/users';
import TableHeader from '../../../../libs/components/data/table/TableHeader';
import { IconDelete, IconEdit, IconSeparator } from '../../../../libs/components/data/Icon';
import { UserTypeLabel } from './UserTypeLabel';

interface UsersTableProps {
  users: User[];
  onDelete: (user: User) => void;
}

const TABLE_COLUMNS = [
  { key: 'createdAt', label: 'Angelegt am', width: 125 },
  { key: 'lastLoginAt', label: 'Letzter Login', width: 135 },
  { key: 'name', label: 'Name' },
  { key: 'login', label: 'Benutzername' },
  { key: 'accountType', label: 'Kontotyp' },
  { key: 'status', label: 'Status' },
];

export default function UsersTable({ users, onDelete }: UsersTableProps) {
  return (
    <Table striped bordered className="shadow-sm">
      <TableHeader columns={TABLE_COLUMNS} actionColumn={true} />
      <tbody>
        {users.map(item => (
          <tr key={item.id}>
            <td>{getFormattedDateOrEmpty(item.createdAt)}</td>
            <td>{getFormattedDateOrEmpty(item.lastLoginAt)}</td>
            <td>{item.name}</td>
            <td>{item.login}</td>
            <td>
              <UserTypeLabel user={item} />
            </td>
            <td>
              <Status status={item.enabled} labelTrue="AKTIV" labelFalse="INAKTIV" />
            </td>
            <td>
              <Link to={`/users/edit/${item.id}`} className="text-info">
                <IconEdit />
              </Link>
              <IconSeparator />
              <IconDelete onClick={() => onDelete(item)} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
