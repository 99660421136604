import { Button, Form, Modal } from 'react-bootstrap';

import { Brand } from '../../../brands/types/brands';
import * as React from 'react';
import { useState } from 'react';
import { addBrand } from '../../../brands/services/api';
import ErrorList from '../../../../libs/components/data/ErrorList';

interface AddBrandModalProps {
  existingBrands: Brand[];
  show: boolean;
  onSaved: (newBrand: Brand) => void;
  onCancel: () => void;
}

export default function AddBrandModal(props: AddBrandModalProps) {
  const [value, setValue] = useState<string>('');
  const [errorResponse, setErrorResponse] = useState<unknown>();

  async function onSubmit() {
    if (value.trim() === '') {
      return;
    }

    const existingBrand = props.existingBrands.find(item => item.name === value);
    if (existingBrand) {
      setErrorResponse({ response: { data: { message: 'Diese Automarke bereits existiert' } } });
      return;
    }

    try {
      const createdBrand = await addBrand({ name: value });
      setValue('');
      props.onSaved(createdBrand);
    } catch (err) {
      setErrorResponse(err);
    }
  }

  function onCancel() {
    setValue('');
    props.onCancel();
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  return (
    <Modal show={props.show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Automarke hinzufügen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Form.Group>
            <Form.Label>
              Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control type="text" onChange={onChange} value={value} />
          </Form.Group>
          {errorResponse && <ErrorList errorResponse={errorResponse} />}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Abbrechen
        </Button>
        <Button variant="primary" onClick={onSubmit} disabled={value.trim() === ''}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
