import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import { Col, Row, Table } from 'react-bootstrap';
import { Vehicle } from '../../types/vehicles';
import React from 'react';
import VehicleInspectionDetails from './VehicleInspectionDetails';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';

interface VehicleInfoProps {
  vehicle: Vehicle;
}

export default function VehicleInfo({ vehicle }: VehicleInfoProps) {
  return (
    <Row>
      <Col>
        <SectionTitle title="Fahrzeugdaten" />
        <Table striped bordered className="shadow-sm">
          <tbody>
            <tr>
              <td style={{ width: 250 }}>
                <strong>Kennzeichen</strong>
              </td>
              <td>{vehicle.plate}</td>
            </tr>
            <tr>
              <td>
                <strong>Marke</strong>
              </td>
              <td>
                {vehicle.brandName} {vehicle.brandTypeName}
              </td>
            </tr>
            <tr>
              <td>
                <strong>KM-Stand</strong>
              </td>
              <td>
                {vehicle.mileage} <span className="text-muted">(erster eingetragener Stand)</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Erstzulassung</strong>
              </td>
              <td>{vehicle.registrationDate}</td>
            </tr>
            <tr>
              <td>
                <strong>FIN</strong>
              </td>
              <td>{vehicle.fin}</td>
            </tr>
            <tr>
              <td>
                <strong>HSN</strong>
              </td>
              <td>{vehicle.hsn}</td>
            </tr>
            <tr>
              <td>
                <strong>TSN</strong>
              </td>
              <td>{vehicle.tsn}</td>
            </tr>
            <tr>
              <td>
                <strong>kW / PS</strong>
              </td>
              <td>
                {vehicle.kw} / {vehicle.ps}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Hubraum (ccm)</strong>
              </td>
              <td>{vehicle.displacement}</td>
            </tr>
            <tr>
              <td>
                <strong>Angelegt am</strong>
              </td>
              <td>{getFormattedDateOrEmpty(vehicle.createdAt)}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <Col>
        <VehicleInspectionDetails vehicle={vehicle} />
      </Col>
    </Row>
  );
}
