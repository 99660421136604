import { Form } from 'react-bootstrap';
import { RegisterOptions, useFormContext } from 'react-hook-form';

export interface SelectOption {
  value: string;
  label: string;
}

interface FormElementProps {
  name: string;
  label?: string | JSX.Element;
  required: boolean;
  type: string;
  options?: RegisterOptions;
  selectItems?: SelectOption[];
  selectEmptyValueLabel?: string;
  className?: string;
  disabled?: boolean;
  rows?: number;
  inlineCheckbox?: boolean;
  disableValidationStyle?: boolean;
}

export default function FormInput(props: FormElementProps) {
  const {
    register,
    formState: { errors, touchedFields },
  } = useFormContext();

  let validClass = '';
  if (!props.disableValidationStyle && touchedFields[props.name]) {
    validClass = errors[props.name] ? 'is-invalid' : 'is-valid';
  }

  if (props.type === 'checkbox') {
    if (props.inlineCheckbox) {
      return (
        <Form.Check
          type="checkbox"
          label={props.label}
          disabled={props.disabled}
          inline
          className={props.className ?? ''}
          {...register(props.name)}
        />
      );
    }

    return (
      <Form.Group className={props.className ?? 'mb-3'}>
        <Form.Check
          type="checkbox"
          label={props.label}
          disabled={props.disabled}
          {...register(props.name)}
        />
      </Form.Group>
    );
  }

  let element: JSX.Element;
  if (props.type === 'select' && props.selectItems) {
    element = (
      <Form.Select
        disabled={props.disabled}
        className={validClass}
        {...register(props.name, props.options)}>
        <option value="">{props.selectEmptyValueLabel ?? '--- Auswählen ---'}</option>
        {props.selectItems.map(item => (
          <option key={`option-${item.value}`} value={item.value}>
            {item.label}
          </option>
        ))}
      </Form.Select>
    );
  } else {
    element = (
      <Form.Control
        className={validClass}
        disabled={props.disabled}
        type={props.type}
        rows={props.type === 'textarea' ? props.rows ?? 5 : undefined}
        as={props.type === 'textarea' ? 'textarea' : undefined}
        {...register(props.name, props.options)}
      />
    );
  }

  return (
    <Form.Group className={props.className ?? 'mb-3'}>
      {props.label && (
        <Form.Label>
          {props.label} {props.required && <span className="text-danger">*</span>}
        </Form.Label>
      )}
      {element}
      {errors[props.name] !== undefined && (
        <p className="text-danger">{errors[props.name]?.message as string}</p>
      )}
    </Form.Group>
  );
}
