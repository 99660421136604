import { Button, Col, Modal, Row } from 'react-bootstrap';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { JobVehiclePart } from '../../../jobs/types/jobs';
import FormInput from '../../../../libs/components/forms/FormInput';
import { FormProvider, useForm } from 'react-hook-form';
import { JobVehiclePartEditFormData } from '../../types/vehicleParts';
import ErrorList from '../../../../libs/components/data/ErrorList';
import { useAuth } from '../../../../libs/context/AuthContext';

interface EditVehiclePartModalProps {
  show: boolean;
  editedPart: JobVehiclePart;
  onSaved: (partId: string, data: JobVehiclePartEditFormData) => void;
  onCancel: () => void;
}

export default function EditVehiclePartModal(props: EditVehiclePartModalProps) {
  const [errorResponse, setErrorResponse] = useState<unknown>();
  const [articleNumber1Required, setArticleNumber1Required] = useState(
    props.editedPart.type === 'Artikel',
  );
  const { isAdminOrOffice } = useAuth();
  const methods = useForm<JobVehiclePartEditFormData>({
    mode: 'all',
    defaultValues: {
      name: props.editedPart.name,
      type: props.editedPart.type ?? 'Artikel',
      partNumber1: props.editedPart.partNumber1,
      partNumber2: props.editedPart.partNumber2,
      quantity: String(props.editedPart.quantity),
      price: String(String(props.editedPart.price).replace('.', ',')),
      partNumber: props.editedPart.partNumber ?? '',
      status: props.editedPart.orderStatus,
      manufacturer: props.editedPart.manufacturer ?? '',
      invoiceNo: props.editedPart.invoiceNo ?? '',
      notes: props.editedPart.notes ?? '',
      orderedIn: props.editedPart.orderedIn ?? '',
      orderedAt: props.editedPart.orderedAt ?? '',
      orderTime: props.editedPart.orderTime ?? '',
      priceVKNet: String(String(props.editedPart.priceVKNet).replace('.', ',')),
      priceEKNet: String(String(props.editedPart.priceEKNet).replace('.', ',')),
      deposit: String(String(props.editedPart.deposit).replace('.', ',')),
    },
  });

  async function onSubmit(data: JobVehiclePartEditFormData) {
    try {
      props.onSaved(props.editedPart.id, data);
    } catch (err) {
      setErrorResponse(err);
    }
  }

  function onCancel() {
    props.onCancel();
  }

  function getPriceOfPart() {
    const part = methods.getValues();
    return (
      (Number(part.quantity) * Number(part.price.replace(',', '.'))).toFixed(2).replace('.', ',') +
      ' €'
    );
  }

  const watchType = methods.watch('type');

  useEffect(() => {
    if (watchType === 'Eigene Position') {
      setArticleNumber1Required(false);
    } else {
      setArticleNumber1Required(true);
    }
  }, [watchType]);

  return (
    <Modal show={props.show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Autoteil bearbeiten</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <FormProvider {...methods}>
            <Row>
              <Col>
                <div>
                  <FormInput
                    name="type"
                    type="select"
                    required={true}
                    label="Art"
                    selectItems={[
                      { value: 'Artikel', label: 'Artikel' },
                      { value: 'Eigene Position', label: 'Eigene Position' },
                    ]}
                    options={{
                      required: { value: true, message: 'Art darf nicht leer sein' },
                    }}
                  />
                </div>
                <div>
                  <FormInput
                    name="name"
                    type="text"
                    required={true}
                    label="Artikel"
                    options={{ required: { value: true, message: 'Name darf nicht leer sein' } }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <FormInput
                  name="partNumber1"
                  type="text"
                  required={articleNumber1Required}
                  label="Artikelnummer 1"
                  options={{
                    required: {
                      value: articleNumber1Required,
                      message: 'Artikelnummer 1 darf nicht leer sein',
                    },
                  }}
                />
              </Col>
              <Col lg={6}>
                <FormInput
                  name="partNumber2"
                  type="text"
                  label="Artikelnummer 2"
                  required={false}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <FormInput
                  name="quantity"
                  type="number"
                  required={true}
                  label="Anzahl"
                  options={{
                    required: { value: true, message: 'Anzahl darf nicht leer sein' },
                    pattern: { value: /^(0|[1-9]\d*)$/, message: 'Falsches Format' },
                  }}
                />
              </Col>
              <Col lg={6}>
                <FormInput
                  name="price"
                  type="text"
                  required={true}
                  label="Preis"
                  options={{
                    required: {
                      value: true,
                      message: 'Preis darf nicht leer sein',
                    },
                    pattern: { value: /^(0|[1-9]\d*)(,\d+)?$/, message: 'Falsches Format' },
                  }}
                />
              </Col>
            </Row>
            <div>
              <strong>Preis insegsamt: {getPriceOfPart()}</strong>
            </div>
            {isAdminOrOffice() && (
              <>
                <Row className="mt-4">
                  <Col lg={6}>
                    <FormInput
                      name="status"
                      type="select"
                      selectItems={[
                        { value: 'toOrder', label: 'Zu bestellen' },
                        { value: 'ordered', label: 'Bestellt' },
                        { value: 'delivered', label: 'Geliefert' },
                      ]}
                      label="Status"
                      required={true}
                      options={{
                        required: { value: true, message: 'Status darf nicht leer sein' },
                      }}
                    />
                  </Col>
                  <Col lg={6}>
                    <FormInput name="partNumber" type="text" label="Teilnummer" required={false} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormInput
                      name="manufacturer"
                      type="text"
                      label="Herstellername"
                      required={false}
                    />
                  </Col>
                  <Col lg={6}>
                    <FormInput
                      name="invoiceNo"
                      type="text"
                      label="Rechnungsnummer"
                      required={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormInput name="orderedIn" type="text" label="Bestellt in" required={false} />
                  </Col>
                  <Col lg={6}>
                    <FormInput name="orderedAt" type="date" label="Bestellt am" required={false} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormInput name="orderTime" type="date" label="Lieferzeit" required={false} />
                  </Col>
                  <Col lg={6}>
                    <FormInput name="deposit" type="text" label="Kaution" required={false} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormInput name="priceEKNet" type="text" label="EK Netto" required={false} />
                  </Col>
                  <Col lg={6}>
                    <FormInput name="priceVKNet" type="text" label="VK Netto" required={false} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormInput name="notes" type="textarea" label="Anmerkungen" required={false} />
                  </Col>
                </Row>
              </>
            )}
          </FormProvider>
          {errorResponse && <ErrorList errorResponse={errorResponse} />}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Abbrechen
        </Button>
        <Button variant="primary" onClick={methods.handleSubmit(onSubmit)}>
          Autoteil aktualisieren
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
