import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import {
  VehicleCommissionFormData,
  VehicleCommissionPhotoForm,
} from '../types/vehicle_commissions';
import { SectionTitle } from '../../../libs/components/data/SectionTitle';
import React, { useEffect, useState } from 'react';
import FormInput from '../../../libs/components/forms/FormInput';
import BrandForm from '../../jobs/components/forms/BrandForm';
import PhotoForm from './PhotoForm';

interface UserFormProps {
  submitButtonLabel: string;
  submitButtonColor?: string;
  initialValues: Partial<VehicleCommissionFormData>;
  onSubmit: (
    data: VehicleCommissionFormData,
    documentPhotos: VehicleCommissionPhotoForm[],
    otherPhotos: VehicleCommissionPhotoForm[],
  ) => void;
}

export function VehicleCommissionForm({
  submitButtonLabel,
  submitButtonColor = 'success',
  initialValues,
  onSubmit,
}: UserFormProps) {
  const [newDocumentPhotos, setNewDocumentPhotos] = useState<VehicleCommissionPhotoForm[]>([]);
  const [newOtherPhotos, setNewOtherPhotos] = useState<VehicleCommissionPhotoForm[]>([]);

  function onNewPhotoAdd(type: 'document' | 'other', photo: VehicleCommissionPhotoForm) {
    if (type === 'document') {
      setNewDocumentPhotos([...newDocumentPhotos, photo]);
    } else {
      setNewDocumentPhotos([...newOtherPhotos, photo]);
    }
  }

  function onNewPhotoDelete(type: 'document' | 'other', id: string) {
    if (type === 'document') {
      setNewDocumentPhotos(newDocumentPhotos.filter(item => item.id !== id));
    } else {
      setNewOtherPhotos(newOtherPhotos.filter(item => item.id !== id));
    }
  }

  function editPhotoTags(type: 'document' | 'other', id: string, tags: string) {
    setNewDocumentPhotos(
      newDocumentPhotos.map(photo => {
        if (photo.id !== id) {
          return photo;
        }
        return {
          ...photo,
          tags: tags,
        };
      }),
    );
  }

  const methods = useForm<VehicleCommissionFormData>({
    defaultValues: initialValues,
    mode: 'all',
  });

  const stateOptions = [
    {
      value: 'B',
      label: 'B',
    },
    {
      value: 'D',
      label: 'D',
    },
    {
      value: 'K',
      label: 'K',
    },
    {
      value: 'R',
      label: 'R',
    },
    {
      value: 'S',
      label: 'S',
    },
  ];

  const documentMissing = methods.watch('documentMissing');
  const warningLightsActive = methods.watch('warningLightsActive');

  const watchPlate = methods.watch('plate');

  useEffect(() => {
    if (watchPlate !== undefined && watchPlate !== watchPlate.toUpperCase()) {
      methods.setValue('plate', watchPlate.toUpperCase());
    }
  }, [watchPlate]);

  function conditionField(name: string, label: string) {
    return (
      <FormInput
        name={name}
        required={false}
        selectItems={stateOptions}
        type="select"
        selectEmptyValueLabel={`[${label}]`}
        className="d-inline-flex"
        disableValidationStyle={true}
      />
    );
  }

  function handleSubmit(data: VehicleCommissionFormData) {
    onSubmit(data, newDocumentPhotos, newOtherPhotos);
  }

  return (
    <FormProvider {...methods}>
      <Card className="bg-light">
        <Card.Body>
          <Form onSubmit={methods.handleSubmit(handleSubmit)}>
            <SectionTitle title="Ausgehändigte Fahrzeugdokumente" />
            <div className="mb-2">
              <div className="mb-3">
                <FormInput
                  name="documentMissing"
                  label="Keine Dokumente"
                  required={false}
                  type="checkbox"
                  inlineCheckbox={true}
                />
              </div>
              <FormInput
                name="documentFormat"
                label="Original/Kopie"
                required={!documentMissing}
                disabled={documentMissing}
                selectItems={[
                  { value: 'Original', label: 'Original' },
                  { value: 'Kopie', label: 'Kopie' },
                ]}
                options={{ required: !documentMissing }}
                type="select"
                className="w-25"
              />
            </div>
            <div>
              <FormInput
                name="documentType"
                label="Dokumenttyp"
                required={!documentMissing}
                disabled={documentMissing}
                options={{ required: !documentMissing }}
                selectItems={[
                  { value: 'Fahrzeugschein', label: 'Fahrzeugschein' },
                  { value: 'Fahrzeugbrief', label: 'Fahrzeugbrief' },
                ]}
                type="select"
                className="w-25"
              />
            </div>

            <div>
              <PhotoForm
                photos={newDocumentPhotos}
                onCapture={photo => onNewPhotoAdd('document', photo)}
                onDelete={id => onNewPhotoDelete('document', id)}
                onTagsEdit={(id, tags) => editPhotoTags('document', id, tags)}
                type="document"
              />
            </div>

            <SectionTitle title="Warnleuchten" />
            <FormInput
              name="warningLightsActive"
              label="Warnleuchten aktiv"
              required={false}
              type="checkbox"
              className="fw-bold"
              inlineCheckbox={true}
            />
            <br />
            <br />
            <FormInput
              name="warningLightsNotReadable"
              label="Warnleuchten können nicht abgelesen werden"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <FormInput
              name="mileageNotReadable"
              label="KM-Stand kann nicht abgelesen werden"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <FormInput
              name="warningLightCheckEngine"
              label="Motorkontrollleuchte"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <FormInput
              name="warningLightAirbagSrs"
              label="Airbag / SRS"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <FormInput
              name="warningLightAbsEspAsr"
              label="ABS / ESP / ASR"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <FormInput
              name="warningLightService"
              label="Service"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <FormInput
              name="warningLightImmobilizer"
              label="Wegfahrsperre"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <FormInput
              name="warningLightOilPressure"
              label="Öldruck"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <FormInput
              name="warningLightParkingBrake"
              label="Parkbremse"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <FormInput
              name="warningLightWashwaterLevel"
              label="Waschwasserstand"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <FormInput
              name="warningLightBrakepadWear"
              label="Bremsbelagverschleiß"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <FormInput
              name="warningLightTractionControl"
              label="Traktionskontrolle"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <FormInput
              name="warningLightBattery"
              label="Batterie"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <FormInput
              name="warningLightPreglow"
              label="Vorglühen"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <FormInput
              name="warningLightParticulateFilter"
              label="Partikelfilter"
              required={false}
              type="checkbox"
              inlineCheckbox={true}
              disabled={!warningLightsActive}
            />
            <div className="mt-2">
              <FormInput
                name="warningLightsOther"
                label="Andere"
                type="textarea"
                required={false}
                rows={2}
              />
            </div>

            <SectionTitle title="Zustandsprüfung außen" />
            <div className="mb-3">
              <div>
                <strong>Legende</strong>
              </div>
              <div>
                <strong>B</strong> - Beschädigung
                <strong className="ms-3">D</strong> - Delle
                <strong className="ms-3">K</strong> - Kratzer
                <strong className="ms-3">R</strong> - Rost
                <strong className="ms-3">S</strong> - Steinschlag/Rissbildung
              </div>
            </div>
            <div>
              <div className="mb-2">
                <strong>Erschwerte Übernahmebedingungen durch:</strong>
              </div>
              <FormInput
                name="conditionPollution"
                label="Verschmutzung"
                required={false}
                type="checkbox"
                inlineCheckbox={true}
              />
              <FormInput
                name="conditionRainwet"
                label="Regen/Nässe"
                required={false}
                type="checkbox"
                inlineCheckbox={true}
              />
              <FormInput
                name="conditionDarkness"
                label="Dunkelheit"
                required={false}
                type="checkbox"
                inlineCheckbox={true}
              />
              <FormInput
                name="conditionParkingGarage"
                label="Parkhaus"
                required={false}
                type="checkbox"
                inlineCheckbox={true}
              />
              <FormInput
                name="conditionSnowice"
                label="Schnee/Eis"
                required={false}
                type="checkbox"
                inlineCheckbox={true}
              />
            </div>
            <div className="mt-2">
              <FormInput
                name="conditionVehiclescratchedallaround"
                label="Fahrzeug ist rundum verkratzt"
                required={false}
                type="checkbox"
                inlineCheckbox={true}
              />
              <FormInput
                name="conditionVehiclerustedallover"
                label="Fahrzeug ist rundum verrostet"
                required={false}
                type="checkbox"
                inlineCheckbox={true}
              />
              <FormInput
                name="conditionVehicledirtyallover"
                label="Fahrzeug ist rundum verschmutzt"
                required={false}
                type="checkbox"
                inlineCheckbox={true}
              />
              <FormInput
                name="conditionGeneralsignsofwear"
                label="Allgemeine Gebrauchspuren"
                required={false}
                type="checkbox"
                inlineCheckbox={true}
              />
            </div>

            <div className="my-4">
              <div className="mb-2">
                {conditionField('a1', 'A-1')}
                {conditionField('a2', 'A-2')}
                {conditionField('a3', 'A-3')}
                {conditionField('a4', 'A-4')}
                {conditionField('a5', 'A-5')}
                {conditionField('a6', 'A-6')}
                {conditionField('a7', 'A-7')}
                {conditionField('a8', 'A-8')}
                {conditionField('a9', 'A-9')}
                {conditionField('a10', 'A-10')}
                {conditionField('a11', 'A-11')}
                {conditionField('a12', 'A-12')}
                {conditionField('a13', 'A-13')}
              </div>
              <div className="mb-2">
                {conditionField('b1', 'B-1')}
                {conditionField('b2', 'B-2')}
                {conditionField('b3', 'B-3')}
                {conditionField('b4', 'B-4')}
                {conditionField('b5', 'B-5')}
                {conditionField('b6', 'B-6')}
                {conditionField('b7', 'B-7')}
                {conditionField('b8', 'B-8')}
              </div>
              <img src="/vehicle_commission_new.jpg" alt="" style={{ width: 1000 }} />
              <div className="mt-2">
                {conditionField('c1', 'C-1')}
                {conditionField('c2', 'C-2')}
              </div>
              <div className="mt-2">
                {conditionField('d1', 'D-1')}
                {conditionField('d2', 'D-2')}
                {conditionField('d3', 'D-3')}
                {conditionField('d4', 'D-4')}
                {conditionField('d5', 'D-5')}
                {conditionField('d6', 'D-6')}
                {conditionField('d7', 'D-7')}
              </div>
            </div>

            <div className="small">
              <Row>
                <Col lg={3}>
                  A – 1 Felge vorne links
                  <br />
                  A – 2 Reifen vorne links
                  <br />
                  A – 3 Kotflügel vorne links
                  <br />
                  A – 4 Außenspiegel vorne links
                  <br />
                  A – 5 Tür vorne links
                  <br />
                  A – 6 Fenster vorne links <br />
                  A – 7 Seitenschweller links
                  <br />
                  A – 8 Tür hinten links
                  <br />
                  A – 9 Fenster hinten links
                  <br />
                  A – 10 Kotflügel hinten links
                  <br />
                  A – 11 Felge hinten links
                  <br />
                  A – 12 Reifen hinten links
                  <br />A – 13 Stoßstange hinten links
                </Col>
                <Col lg={3}>
                  B – 1 Scheinwerfer vorne rechts
                  <br />
                  B – 2 Nebelscheinwerfer vorne rechts <br />
                  B – 3 Motorhaube
                  <br />
                  B – 4 Frontscheibe
                  <br />
                  B – 5 Kühlergrill
                  <br />
                  B – 6 Frontstoßstange
                  <br />
                  B – 7 Nebelscheinwerfer vorne links
                  <br />B – 8 Scheinwerfer vorne links
                </Col>
                <Col lg={2}>
                  C – 1 Dach
                  <br />C – 2 Antenne
                </Col>
                <Col lg={3}>
                  D – 1 Rückleuchte hinten links
                  <br />
                  D – 2 Kofferraumklappe
                  <br />
                  D – 3 Heckscheibe <br />
                  D – 4 Kennzeichen
                  <br />
                  D – 5 Heckstoßstange
                  <br />
                  D – 6 Rückleuchte hinten rechts
                  <br />D – 7 Auspuff
                </Col>
              </Row>
            </div>

            <div>
              <PhotoForm
                photos={newOtherPhotos}
                onCapture={photo => onNewPhotoAdd('other', photo)}
                onDelete={id => onNewPhotoDelete('other', id)}
                onTagsEdit={(id, tags) => editPhotoTags('other', id, tags)}
                type="other"
              />
            </div>

            <SectionTitle title="Fahrzeug" />
            <BrandForm />
            <Row>
              <Col lg={2}>
                <FormInput
                  name="plate"
                  label="Kennzeichen"
                  type="text"
                  required={true}
                  options={{ required: true }}
                />
              </Col>
              <Col lg={2}>
                <FormInput
                  name="mileage"
                  label="KM-Stand"
                  type="text"
                  required={true}
                  options={{ required: true }}
                />
              </Col>
            </Row>

            <SectionTitle title="Aufnahmedatum" />
            <Row>
              <Col lg={2}>
                <FormInput
                  name="commissionDate"
                  label="Datum"
                  type="date"
                  required={true}
                  options={{ required: true }}
                />
              </Col>
              <Col lg={2}>
                <FormInput
                  name="commissionTime"
                  label="Uhrzeit"
                  type="time"
                  required={true}
                  options={{ required: true }}
                />
              </Col>
            </Row>

            <Button variant={submitButtonColor} type="submit" className="mt-3">
              {submitButtonLabel}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </FormProvider>
  );
}
