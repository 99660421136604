import { ModuleGroup } from '../types/module';

export const MODULES: ModuleGroup[] = [
  {
    name: 'home',
    label: 'Home',
    permissions: ['admin', 'office', 'user'],
    items: [
      {
        name: 'dashboard',
        label: 'Dashboard',
        icon: 'speedometer',
        link: '/',
        permissions: ['admin', 'office'],
      },
      {
        name: 'new_job',
        label: 'Neue Arbeitskarte',
        icon: 'plus-square-fill',
        link: '/jobs/add',
        permissions: ['admin', 'office'],
      },
      {
        name: 'new_vehicle_commission',
        label: 'Fahrzeugannahme',
        icon: 'tools',
        link: '/vehicle_commissions/add',
        permissions: ['admin', 'office'],
      },
      {
        name: 'work_overview',
        label: 'Arbeitsübersicht',
        icon: 'person-gear',
        link: '/work_overview',
        permissions: ['admin'],
      },
      {
        name: 'daily_schedule',
        label: 'Tagesplanung',
        icon: 'calendar-week',
        link: '/daily_schedule',
        permissions: ['admin', 'office'],
      },
      {
        name: 'my_jobs',
        label: 'Meine Aufgaben',
        icon: 'clipboard-check-fill',
        link: '/my-jobs',
        permissions: ['user'],
      },
    ],
  },
  {
    name: 'management',
    label: 'Verwaltung',
    permissions: ['admin', 'office'],
    items: [
      {
        name: 'jobs',
        label: 'Arbeitskarten',
        icon: 'clipboard-check-fill',
        link: '/jobs',
        permissions: ['admin', 'office'],
      },
      {
        name: 'vehicle_commissions',
        label: 'Fahrzeugannahmen',
        icon: 'tools',
        link: '/vehicle_commissions',
        permissions: ['admin', 'office'],
      },
      {
        name: 'vehicles',
        label: 'Fahrzeuge',
        icon: 'car-front-fill',
        link: '/vehicles',
        permissions: ['admin', 'office'],
      },
      {
        name: 'phone_calls',
        label: 'Telefongespräche',
        icon: 'telephone-fill',
        link: '/phone-calls',
        permissions: ['admin', 'office'],
      },
      {
        name: 'storage',
        label: 'Lager',
        icon: 'archive-fill',
        link: '/storage',
        permissions: ['admin', 'office'],
      },
      {
        name: 'workTime',
        label: 'Arbeitszeiten',
        icon: 'clock-fill',
        link: '/worktime',
        permissions: ['admin', 'office'],
      },
      {
        name: 'vehicleParts',
        label: 'Autoteile',
        icon: 'clipboard-check-fill',
        link: '/vehicle-parts',
        permissions: ['admin', 'office'],
      },
      {
        name: 'photos',
        label: 'Bilder',
        icon: 'image-fill',
        link: '/photos',
        permissions: ['admin', 'office'],
      },
      {
        name: 'files',
        label: 'Dateien',
        icon: 'file-earmark-pdf-fill',
        link: '/files',
        permissions: ['admin', 'office'],
      },
    ],
  },
  {
    name: 'settings',
    label: 'Einstellungen',
    permissions: ['admin'],
    items: [
      {
        name: 'job_categories',
        label: 'Aufgabe Kategorien',
        icon: 'list-task',
        link: '/job-categories',
        permissions: ['admin'],
      },
      {
        name: 'storage_categories',
        label: 'Lager Kategorien',
        icon: 'list-task',
        link: '/storage-categories',
        permissions: ['admin'],
      },
      {
        name: 'brands',
        label: 'Automarken',
        icon: 'gear-wide-connected',
        link: '/brands',
        permissions: ['admin'],
      },
      {
        name: 'users',
        label: 'Benutzer',
        icon: 'people-fill',
        link: '/users',
        permissions: ['admin'],
      },
    ],
  },
  {
    name: 'system',
    label: 'System',
    permissions: ['admin'],
    items: [
      { name: 'logs', label: 'Logs', icon: 'eye-fill', link: '/logs', permissions: ['admin'] },
      {
        name: 'sessions',
        label: 'Benutzersitzungen',
        icon: 'person-workspace',
        link: '/sessions',
        permissions: ['admin'],
      },
      {
        name: 'system_status',
        label: 'Status',
        icon: 'activity',
        link: '/status',
        permissions: ['admin'],
      },
    ],
  },
];
