import { useParams } from 'react-router-dom';

import PageToolbar from '../../../libs/components/actions/PageToolbar';
import { UserForm } from './UserForm';
import { EditUserFormData, User, UserEditPasswordFormData } from '../types/users';
import { getUser, updateUser, updateUserPassword } from '../services/api';
import { useEffect, useReducer, useState } from 'react';
import ButtonLink from '../../../libs/components/actions/ButtonLink';
import Message from '../../../libs/components/data/Message';
import ApiErrorMessage from '../../../libs/components/data/ApiErrorMessage';
import {
  FormActionState,
  getFormActionReducer,
} from '../../../libs/services/state/formActionReducer';
import { Col, Row } from 'react-bootstrap';
import EditPasswordForm from './EditPasswordForm';

const initialState: FormActionState = {
  actionInProgress: false,
  actionSuccess: false,
  actionError: false,
  showForm: true,
  actionErrorResponse: undefined,
};

export default function UserEdit() {
  const { id } = useParams();
  const [user, setUser] = useState<User>();
  const [formData, setFormData] = useState<EditUserFormData>();
  const [state, dispatch] = useReducer(getFormActionReducer(initialState), initialState);

  useEffect(() => {
    if (!id) {
      return;
    }
    getUser(id).then(data => {
      setUser(data);
      setFormData({
        enabled: data.enabled,
        isAdmin: data.isAdmin,
        isOffice: data.isOffice,
        login: data.login,
        name: data.name,
      });
    });
  }, [id]);

  async function onSubmit(data: EditUserFormData) {
    if (!user) {
      return;
    }

    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      await updateUser(user.id, data);
      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    }
  }

  async function onNewPasswordSubmit(data: UserEditPasswordFormData) {
    if (!user) {
      return;
    }

    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      await updateUserPassword(user.id, data);
      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    }
  }

  if (!user || !formData) {
    return null;
  }

  return (
    <>
      <PageToolbar pageTitle={`Benutzerdaten bearbeiten: ${user.name}`}>
        <ButtonLink link="/users" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      {state.showForm && (
        <Row className="mb-4">
          <Col lg={4}>
            <UserForm
              submitButtonLabel="Benutzer aktualisieren"
              submitButtonColor="primary"
              onSubmit={onSubmit}
              initialValues={formData}
              skipPassword={true}
            />
          </Col>
          <Col lg={4}>
            <EditPasswordForm onSubmit={onNewPasswordSubmit} />
          </Col>
        </Row>
      )}
      {state.actionError && (
        <ApiErrorMessage
          label="Benutzerdaten konnten nicht aktualisiert werden"
          errorResponse={state.actionErrorResponse}
        />
      )}
      {state.actionSuccess && <Message type="ok">Benutzerdaten wurden aktualisiert</Message>}
    </>
  );
}
