import { Job } from '../../types/jobs';
import { Button, Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { User } from '../../../users/types/users';
import { getUsers } from '../../../users/services/api';

interface JobMainAssignedUserProps {
  job: Job;
  onMainAssignedUserUpdate: (userId: string) => void;
}

export default function JobMainAssignedUser({
  job,
  onMainAssignedUserUpdate,
}: JobMainAssignedUserProps) {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>(job.mainAssignedUser.id);

  useEffect(() => {
    getUsers().then(data => setUsers(data));
  }, []);

  function onUserSelect(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedUserId(event.target.value);
  }

  if (users.length === 0) {
    return null;
  }

  return (
    <>
      <div className="mb-2">Aktuell gespeichert: {job.mainAssignedUser.name}</div>
      <Form.Select onChange={onUserSelect} value={selectedUserId}>
        <option value="">--- Auswählen ---</option>
        {users.map(item => (
          <option key={`option-${item.id}`} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Select>
      <div className="mt-2">
        <Button
          variant="info"
          size="sm"
          disabled={selectedUserId === ''}
          onClick={() => onMainAssignedUserUpdate(selectedUserId)}>
          Aufgabenmanager aktualisieren
        </Button>
      </div>
    </>
  );
}
