import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Form, Table } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';

import { getValidationSchema } from '../../config/validation';
import {
  Job,
  JobFormData,
  JobPhotoForm,
  JobUsedKey,
  TransformedJobFormData,
} from '../../types/jobs';
import BrandForm from './BrandForm';
import BasicCarElementsForm from './BasicCarElementsForm';
import FormActions, { ActionType } from './FormActions';
import { User } from '../../../users/types/users';
import AssignedUsersForm from './AssignedUsersForm';
import { getUsers } from '../../../users/services/api';
import { JobCategory } from '../../../job_categories/types/jobCategories';
import { getJobCategories } from '../../../job_categories/services/api';
import JobTasks from './JobTasks';
import VehicleSearchForm from './VehicleSearchForm';
import PhotoForm from './PhotoForm';
import NotesForm from './NotesForm';
import { transformJobFormData } from '../../services/jobs';
import {
  archiveJob,
  getLatestJobForVehicle,
  getUsedKeys,
  getVehicleByPlate,
} from '../../services/api';
import { Vehicle } from '../../../vehicles/types/vehicles';
import { v4 } from 'uuid';
import MileageForm from './MileageForm';
import JobTimesForm from './JobTimesForm';
import KeyPositionForm from './KeyPositionForm';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import JobWithoutVehicleData from './JobWithoutVehicleData';
import { useAuth } from '../../../../libs/context/AuthContext';
import { IconArchive, IconDetails } from '../../../../libs/components/data/Icon';
import { formatDate } from '../../../../libs/services/date';

interface JobFormProps {
  existingVehiclePlates: string[];
  actionInProgress: boolean;
  onCreateJob: (actionType: ActionType, data: Partial<TransformedJobFormData>) => void;
}

export interface JobTask {
  id: string;
  categoryId: string;
  items: JobTaskItem[];
}

export interface JobTaskItem {
  id: string;
  subCategoryId: string;
  subCategoryName: string;
  description: string;
  enabled: boolean;
}

export default function JobForm({
  existingVehiclePlates,
  actionInProgress,
  onCreateJob,
}: JobFormProps) {
  const { user } = useAuth();
  const [users, setUsers] = useState<User[]>();
  const [usedKeys, setUsedKeys] = useState<JobUsedKey[] | null>(null);
  const [foundKey, setFoundKey] = useState<JobUsedKey | null>(null);
  const [jobCategories, setJobCategories] = useState<JobCategory[]>();
  const [selectedExistingVehicle, setSelectedExistingVehicle] = useState<Vehicle | undefined>();
  const [latestVehicleJob, setLatestVehicleJob] = useState<Job | undefined>();
  const [capturedPhotos, setCapturedPhotos] = useState<JobPhotoForm[]>([]);
  const [tasks, setTasks] = useState<JobTask[]>([]);
  const [jobWithoutVehicle, setJobWithoutVehicle] = useState<boolean>(false);
  const methods = useForm<JobFormData>({
    mode: 'all',
    resolver: yupResolver(
      getValidationSchema(
        latestVehicleJob?.mileage ? latestVehicleJob.mileage + 1 : 0,
        existingVehiclePlates,
        selectedExistingVehicle,
        jobWithoutVehicle,
      ),
    ),
  });

  useEffect(() => {
    getUsers().then(data => setUsers(data));
    getJobCategories().then(data => setJobCategories(data));
    getUsedKeys().then(data => setUsedKeys(data));
  }, []);

  const [keyPosition1, keyPosition2] = methods.watch(['keyPosition1', 'keyPosition2']);

  useEffect(() => {
    if (keyPosition1 !== undefined && keyPosition2 !== undefined) {
      const toCheck = `${keyPosition1}-${keyPosition2}`;

      const foundKey = usedKeys?.find(key => key.key === toCheck);
      if (foundKey) {
        setFoundKey(foundKey);
      } else {
        setFoundKey(null);
      }
    }
  }, [keyPosition1, keyPosition2]);

  function addPhoto(photo: JobPhotoForm) {
    setCapturedPhotos([...capturedPhotos, photo]);
  }

  function deletePhoto(id: string) {
    setCapturedPhotos(capturedPhotos.filter(item => item.id !== id));
  }

  function editPhotoTags(id: string, tags: string) {
    setCapturedPhotos(
      capturedPhotos.map(photo => {
        if (photo.id !== id) {
          return photo;
        }
        return {
          ...photo,
          tags: tags,
        };
      }),
    );
  }

  function addTask() {
    setTasks([...tasks, { id: v4(), categoryId: '', items: [] }]);
  }

  function deleteTask(id: string) {
    setTasks(tasks.filter(task => task.id !== id));
  }

  function editTask(
    id: string,
    subCategoryId: string,
    field: 'enabled' | 'description',
    value: string | boolean,
  ) {
    const updatedTasks = tasks.map(task => {
      if (task.id !== id) {
        return task;
      }
      return {
        ...task,
        items: task.items.map(item => {
          if (item.subCategoryId !== subCategoryId) {
            return item;
          }
          return {
            ...item,
            [field]: value,
          };
        }),
      };
    });
    setTasks(updatedTasks);
  }

  function selectTaskCategory(id: string, categoryId: string) {
    const category = jobCategories?.find(item => item.id === categoryId);
    if (!category) {
      return;
    }
    const updatedTasks = tasks.map(task => {
      if (task.id !== id) {
        return task;
      }
      return {
        ...task,
        categoryId,
        items: category.subCategories.map(sub => ({
          id: v4(),
          subCategoryId: sub.id,
          subCategoryName: sub.name,
          enabled: false,
          description: sub.defaultDescription,
        })),
      };
    });
    setTasks(updatedTasks);
  }

  async function onSubmit(actionType: ActionType) {
    await methods.trigger();
    await methods.handleSubmit(
      data => {
        onCreateJob(
          actionType,
          transformJobFormData(
            data,
            tasks,
            capturedPhotos,
            jobWithoutVehicle,
            selectedExistingVehicle,
          ),
        );
      },
      errors => {
        console.log(methods.getValues());
        console.log(errors);
      },
    )();
  }

  async function onVehicleSearchSelect(plate: string) {
    const foundVehicle = await getVehicleByPlate(plate);
    if (!foundVehicle) {
      setSelectedExistingVehicle(undefined);
      return;
    }
    const latestJob = await getLatestJobForVehicle(foundVehicle.id);
    setSelectedExistingVehicle(foundVehicle);
    setLatestVehicleJob(latestJob);
    setFormValuesFromExistingVehicle(foundVehicle);
  }

  function setFormValuesFromExistingVehicle(vehicle: Vehicle) {
    methods.setValue('brand', vehicle.brandId);
    methods.setValue('brandType', vehicle.brandTypeId);
    methods.setValue('plate', vehicle.plate);
    methods.setValue('fin', vehicle.fin);
    methods.setValue('hsn', vehicle.hsn);
    methods.setValue('tsn', vehicle.tsn);
    methods.setValue('kw', vehicle.kw);
    methods.setValue('displacement', vehicle.displacement);
    methods.setValue('registrationDate', vehicle.registrationDate);
    methods.setValue('mileage', '0');
    methods.setValue('inspectionHU', vehicle.inspectionHU);
    methods.setValue('inspectionHURemind', vehicle.inspectionHURemind);
    methods.setValue('inspectionAU', vehicle.inspectionAU);
    methods.setValue('lastInspection', vehicle.lastInspection);
    methods.setValue('nextInspectionRemind', vehicle.nextInspectionRemind);
  }

  function onSelectedVehicleReset() {
    setSelectedExistingVehicle(undefined);
    setLatestVehicleJob(undefined);
    methods.reset();
  }

  function reloadCategories() {
    getJobCategories().then(data => setJobCategories(data));
  }

  async function archiveLatestJob() {
    await archiveJob(latestVehicleJob!.id);
    await onVehicleSearchSelect(latestVehicleJob!.vehicle!.plate);
  }

  if (!users || !jobCategories || !user || usedKeys === null) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Card className="bg-light">
        <Card.Body>
          <Form>
            {!jobWithoutVehicle && <KeyPositionForm foundKey={foundKey} />}
            <SectionTitle title="Fahrzeugdaten" />
            <div className="mb-4">
              <Form.Check
                type="checkbox"
                label="ohne Fahrzeug"
                onChange={e => setJobWithoutVehicle(e.target.checked)}
              />
            </div>
            {jobWithoutVehicle && <JobWithoutVehicleData />}
            {!jobWithoutVehicle && (
              <>
                <VehicleSearchForm
                  existingVehiclePlates={existingVehiclePlates}
                  onVehicleSelect={onVehicleSearchSelect}
                  onReset={onSelectedVehicleReset}
                />
                {latestVehicleJob && !latestVehicleJob.isArchived && (
                  <div>
                    <h5 className="text-danger fw-bold mb-2">
                      Es existiert eine aktive Arbeitskarte mit dem ausgewälhten Kennzeichen!
                    </h5>
                    <div className="mb-2">
                      Archivieren Sie diese Arbeitskarte, bevor Sie eine neue mit dem{' '}
                      <strong>{latestVehicleJob.vehicle?.plate}</strong> anlegen!
                    </div>
                    <Table striped bordered className="shadow-sm">
                      <tbody>
                        <tr>
                          <td style={{ width: 150 }}>
                            <strong>Angelegt am</strong>
                          </td>
                          <td>{formatDate(latestVehicleJob.createdAt, 'date')}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>XID</strong>
                          </td>
                          <td>{latestVehicleJob.jobKey}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Marke</strong>
                          </td>
                          <td>
                            {latestVehicleJob.vehicle?.brandName}{' '}
                            {latestVehicleJob.vehicle?.brandTypeName}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>KM-Stand</strong>
                          </td>
                          <td>{latestVehicleJob.mileage}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className="mb-5">
                      <Button className="me-3" variant="warning" onClick={archiveLatestJob}>
                        <IconArchive color="white" /> Arbeitskarte {latestVehicleJob.jobKey}{' '}
                        archivieren
                      </Button>
                      <span className="mx-2"></span>
                      <Button
                        variant="light"
                        onClick={() => window.open(`/jobs/${latestVehicleJob!.id}`)}>
                        <IconDetails color="black" />
                        <span className="ms-2">Arbeitskarte öffnen</span>
                      </Button>
                    </div>
                  </div>
                )}
                {!selectedExistingVehicle && (
                  <>
                    <BrandForm />
                    <BasicCarElementsForm />
                  </>
                )}
                {selectedExistingVehicle && latestVehicleJob && (
                  <MileageForm
                    selectedVehicle={selectedExistingVehicle}
                    latestJob={latestVehicleJob}
                  />
                )}
              </>
            )}
            <JobTimesForm />
            <AssignedUsersForm users={users} />
            <JobTasks
              jobCategories={jobCategories}
              tasks={tasks}
              onAddTask={addTask}
              onDeleteTask={deleteTask}
              onTaskCategorySelect={selectTaskCategory}
              onTaskItemEdit={editTask}
              reloadCategories={reloadCategories}
            />
            <PhotoForm
              photos={capturedPhotos}
              vehicleParts={[]}
              onCapture={addPhoto}
              onDelete={deletePhoto}
              onTagsEdit={editPhotoTags}
            />
            <SectionTitle title="Dateien" />
            <NotesForm />
            {!actionInProgress && <FormActions onClick={onSubmit} />}
          </Form>
        </Card.Body>
      </Card>
    </FormProvider>
  );
}
