import { Job, JobState } from '../../../jobs/types/jobs';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { formatDate } from '../../../../libs/services/date';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import {
  getSumOfWorkTime,
  getSumOfWorkTimeForJobAndUser,
  getWorkTimeInterval,
} from '../../services/time';
import { useAuth } from '../../../../libs/context/AuthContext';
import { DailySchedule } from '../../../daily_schedule/types/dailySchedule';
import { getTodaysDateString, transformSecondsToDateTime } from '../../../../libs/services/time';

interface MyJobTimeProps {
  job: Job;
  dailySchedule: DailySchedule[];
  alreadyStartedJobWarning: Job | undefined;
  onWorkTimeStart: (force: boolean) => void;
  onWorkTimeEnd: () => void;
}

export default function MyJobTime({
  job,
  dailySchedule,
  alreadyStartedJobWarning,
  onWorkTimeStart,
  onWorkTimeEnd,
}: MyJobTimeProps) {
  const { user } = useAuth();
  const workTimeOfUser = job.workTime.filter(item => item.userId === user?.id);
  const workInProgress = workTimeOfUser.some(item => item.endDate === null);
  const today = getTodaysDateString();

  function getDayLimitMinutes() {
    return dailySchedule
      .filter(schedule => schedule.user.id === user?.id && schedule.job.id === job.id)
      .reduce((sum, current) => current.limitMinutes + current.limitHours * 60, 0);
  }

  if (!user) {
    return null;
  }

  return (
    <>
      <SectionTitle title="Meine Arbeitszeit" />
      {!job.isDone && job.jobState === JobState.WORKSHOP && (
        <>
          <div>
            {!workInProgress && (
              <Button
                variant="success"
                disabled={alreadyStartedJobWarning !== undefined}
                onClick={() => onWorkTimeStart(false)}>
                <i className="bi bi-play-fill"></i> Zeit starten
              </Button>
            )}
            {workInProgress && (
              <Button variant="warning" onClick={() => onWorkTimeEnd()}>
                <i className="bi bi-stop-fill"></i> Zeit beenden
              </Button>
            )}
          </div>
          {alreadyStartedJobWarning !== undefined && (
            <Card className="my-3 bg-light">
              <Card.Body>
                <div className="fs-5">
                  <i className="bi bi-exclamation-circle-fill"></i> Sie arbeiten gerade an dieser
                  Arbeitskarte:
                  <div className="ps-2">
                    <div>ID: {alreadyStartedJobWarning.jobKey}</div>
                    {alreadyStartedJobWarning.vehicle !== null && (
                      <div>
                        {alreadyStartedJobWarning.vehicle.brandName}{' '}
                        {alreadyStartedJobWarning.vehicle.brandTypeName},{' '}
                        {alreadyStartedJobWarning.vehicle.plate}
                      </div>
                    )}
                  </div>
                </div>
                <div className="my-2">Wählen Sie die Aktion aus:</div>
                <div>
                  <div>
                    <Button variant="success" onClick={() => onWorkTimeStart(true)}>
                      <i className="bi bi-play-fill"></i> Die alte Arbeitskarte stoppen und Zeit
                      starten
                    </Button>
                  </div>
                  <div className="mt-3">
                    <ButtonLink
                      link={`/my-jobs/${alreadyStartedJobWarning.id}`}
                      buttonColor="info"
                      icon="zoom-in">
                      Zur gestarteten Arbeitskarte wechseln
                    </ButtonLink>
                  </div>
                </div>
              </Card.Body>
            </Card>
          )}
        </>
      )}
      <Row className="mt-4">
        <Col lg={12}>
          <Table striped bordered className="shadow-sm">
            <thead>
              <tr>
                <th>Begonnen am</th>
                <th>Beendet am</th>
                <th>Dauer</th>
              </tr>
            </thead>
            <tbody>
              {workTimeOfUser.map(item => (
                <tr key={item.id}>
                  <td>{formatDate(item.startDate, 'datetime')}</td>
                  <td>{item.endDate !== null ? formatDate(item.endDate, 'datetime') : '-'}</td>
                  <td>{getWorkTimeInterval(item.startDate, item.endDate)}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={3}></td>
              </tr>
              <tr>
                <td>
                  <strong>Meine Arbeitszeit insgesamt</strong>
                </td>
                <td colSpan={2}>{getSumOfWorkTimeForJobAndUser(job, user.id, today)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Alle Arbeitszeiten insgesamt</strong>
                </td>
                <td colSpan={2}>{getSumOfWorkTime(job.workTime)}</td>
              </tr>
              <tr>
                <td className="text-primary">
                  <strong>Geschätzte Arbeitszeit</strong>
                </td>
                <td className="text-primary" colSpan={2}>
                  <strong>{transformSecondsToDateTime(getDayLimitMinutes() * 60)}</strong>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
