import { Button, Card, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Job, JobEditFormData } from '../../types/jobs';
import { getJobEditValidationSchema } from '../../config/validation';
import JobWithoutVehicleData from '../forms/JobWithoutVehicleData';
import KeyPositionForm from '../forms/KeyPositionForm';
import JobTimesForm from '../forms/JobTimesForm';

interface JobEditFormProps {
  job: Job;
  submitButtonLabel: string;
  submitButtonColor?: string;
  initialValues: Partial<JobEditFormData>;
  onSubmit: (data: JobEditFormData) => void;
}

export function JobEditForm({
  job,
  submitButtonLabel,
  submitButtonColor = 'success',
  initialValues,
  onSubmit,
}: JobEditFormProps) {
  const methods = useForm<JobEditFormData>({
    defaultValues: initialValues,
    mode: 'all',
    resolver: yupResolver(getJobEditValidationSchema(job.vehicle)),
  });

  return (
    <FormProvider {...methods}>
      <Card className="bg-light">
        <Card.Body>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            {job.vehicle !== null && <KeyPositionForm foundKey={null} />}
            {job.vehicle === null && <JobWithoutVehicleData />}
            <JobTimesForm />
            <Button variant={submitButtonColor} type="submit" className="mt-3">
              {submitButtonLabel}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </FormProvider>
  );
}
