export function transformSecondsToDateTime(seconds: number): string {
  let minutes = seconds / 60;
  if (minutes < 60) {
    return `${Math.round(minutes)} Min.`;
  }
  const hours = Math.floor(seconds / 3600);
  minutes = Math.round(minutes % 60);
  return `${hours} St. ${minutes} Min.`;
}

export function getSecondsDiff(startDate: Date | string, endDate: Date | null | string): number {
  const start = startDate instanceof Date ? startDate : new Date(startDate);
  let end = new Date(Date.now() + 7200000);
  if (endDate instanceof Date) {
    end = endDate;
  } else if (endDate !== null) {
    end = new Date(endDate);
  }
  return (end.getTime() - start.getTime()) / 1000;
}

export function extractDateFromDateTime(dateTime: Date | string): string {
  if (!(dateTime instanceof Date)) {
    dateTime = new Date(dateTime);
  }
  return dateTime.toISOString().substring(0, 10);
}

export function getTodaysDateString(): string {
  return new Date().toISOString().substring(0, 10);
}

export function getCallTimeNow() {
  const now = new Date().toISOString().substring(11, 16);
  const [hours, min] = now.split(':');
  return `${Number(hours) + 1}:${min}`;
}

export function getZoneAdjustedDate(date: string) {
  const newDate = new Date(date);
  return new Date(
    newDate.getFullYear(),
    newDate.getMonth(),
    newDate.getDay(),
    newDate.getHours() + 1,
    newDate.getMinutes(),
    newDate.getSeconds(),
  );
}
