import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Job } from '../types/jobs';
import { getJob } from '../services/api';
import { Table } from 'react-bootstrap';
import JobNotesList from './JobDetails/JobNotes/JobNotesList';

export default function JobPrint() {
  const { id } = useParams();
  const [job, setJob] = useState<Job>();

  useEffect(() => {
    loadJob();
  }, [id]);

  function loadJob() {
    if (!id) {
      return;
    }
    getJob(id).then(data => {
      setJob(data);
    });
  }

  useEffect(() => {
    const printTimeout = setTimeout(() => {
      window.print();
    }, 1000);

    return () => {
      clearTimeout(printTimeout);
    };
  });

  if (!job) {
    return null;
  }

  return (
    <div className="m-4">
      <p className="fs-4">Arbeitskarte {job.vehicle?.plate || ''}</p>
      <Table striped bordered className="shadow-sm">
        <tbody>
          <tr>
            <td style={{ width: 250 }}>
              <strong>Kennzeichen</strong>
            </td>
            <td>{job.vehicle?.plate}</td>
            <td>
              <strong>Marke</strong>
            </td>
            <td>
              {job.vehicle?.brandName} {job.vehicle?.brandTypeName}
            </td>
            <td>
              <strong>kW / PS</strong>
            </td>
            <td>
              {job.vehicle?.kw} / {job.vehicle?.ps}
            </td>
          </tr>
          <tr>
            <td>
              <strong>KM-Stand</strong>
            </td>
            <td>{job.mileage}</td>
            <td>
              <strong>Erstzulassung</strong>
            </td>
            <td colSpan={3}>{job.vehicle?.registrationDate}</td>
          </tr>
          <tr>
            <td>
              <strong>FIN</strong>
            </td>
            <td>{job.vehicle?.fin}</td>
            <td>
              <strong>HSN</strong>
            </td>
            <td>{job.vehicle?.hsn}</td>
            <td>
              <strong>TSN</strong>
            </td>
            <td>{job.vehicle?.tsn}</td>
          </tr>
          <tr>
            <td>
              <strong>Anmerkungen</strong>
            </td>
            <td colSpan={5}>
              <JobNotesList notes={job.notes} />
            </td>
          </tr>
        </tbody>
      </Table>
      <p className="my-4 fs-5">Aufgaben</p>
      <Table striped bordered className="shadow-sm">
        <thead>
          <tr>
            <th>Kategorie</th>
            <th>Beschreibung</th>
          </tr>
        </thead>
        <tbody>
          {job.tasks.map(task => (
            <tr key={task.id}>
              <td>
                <strong>{task.category.name}</strong> <br /> {task.subCategory.name}
              </td>
              <td>{task.description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
