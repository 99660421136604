import { Sort } from '../../../../types/sort';
import { TableHeaderColumn } from '../../../../types/table';
import { getSortDir } from '../../../services/sort';

interface TableHeaderProps {
  columns: TableHeaderColumn[];
  actionColumn: boolean;
  onSort?: (sort: Sort) => void;
  currentSort?: Sort;
  actionsLabel?: string;
}

export default function TableHeader({
  columns,
  actionColumn,
  onSort,
  currentSort,
  actionsLabel = 'Aktionen',
}: TableHeaderProps) {
  return (
    <thead>
      <tr>
        {columns.map(column => (
          <th key={column.key} style={{ width: column.width }}>
            {onSort !== undefined && column.sortable ? (
              <span
                className="text-primary"
                style={{ cursor: 'pointer' }}
                onClick={() => onSort({ field: column.key, dir: getSortDir(column, currentSort) })}>
                {column.label}
              </span>
            ) : (
              column.label
            )}
            {currentSort?.field === column.key && (
              <span className="ps-2">
                <strong>
                  {currentSort.dir === 'desc' ? (
                    <i className="bi bi-sort-alpha-up"></i>
                  ) : (
                    <i className="bi bi-sort-alpha-down"></i>
                  )}
                </strong>
              </span>
            )}
          </th>
        ))}
        {actionColumn && <th>{actionsLabel}</th>}
      </tr>
    </thead>
  );
}
