import { Badge } from 'react-bootstrap';

interface StatusProps {
  status: boolean;
  labelTrue: string;
  labelFalse: string;
  colorTrue?: string;
  colorFalse?: string;
}

export default function Status({
  status,
  labelTrue,
  labelFalse,
  colorTrue,
  colorFalse,
}: StatusProps) {
  const color = status ? colorTrue || 'success' : colorFalse || 'danger';

  return (
    <Badge pill bg={color}>
      {status ? labelTrue : labelFalse}
    </Badge>
  );
}
