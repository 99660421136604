import { Form } from 'react-bootstrap';
import React from 'react';

import { JobVehiclePart } from '../../types/jobs';

interface PhotoVehiclePartsFormProps {
  vehicleParts: JobVehiclePart[];
  selectedPartId: JobVehiclePart['id'] | null;
  onSelect: (selectedPartId: string | null) => void;
}

export default function PhotoVehiclePartsForm({
  vehicleParts,
  selectedPartId,
  onSelect,
}: PhotoVehiclePartsFormProps) {
  function onChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    onSelect(value !== '' ? value : null);
  }

  return (
    <div className="mt-2 text-muted">
      <div>Autoteil</div>
      <Form.Select onChange={onChange} value={selectedPartId ?? ''}>
        <option value="">-----</option>
        {vehicleParts.map(item => (
          <option key={item.id} value={item.id}>
            {item.name} / {item.partNumber1}
          </option>
        ))}
      </Form.Select>
    </div>
  );
}
