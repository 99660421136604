import { Button } from 'react-bootstrap';

export type ActionType = 'printOnly' | 'saveOnly' | 'saveAndPrint';

interface FormActionsProps {
  onClick: (actionType: ActionType) => void;
}

export default function FormActions({ onClick }: FormActionsProps) {
  return (
    <div className="mt-4">
      <Button variant="success" className="me-3" onClick={() => onClick('saveOnly')}>
        Arbeitskarte nur speichern
      </Button>
      <Button variant="primary" onClick={() => onClick('saveAndPrint')}>
        Arbeitskarte speichern und ausdrucken
      </Button>
    </div>
  );
}
