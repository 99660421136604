import { API } from '../../../libs/services/api';
import {
  EditUserFormData,
  User,
  UserEditPasswordFormData,
  UserFormData,
  UserType,
} from '../types/users';

const BASE = 'users';

export async function getUsers(userType?: UserType): Promise<User[]> {
  const users: User[] = (await API.get(BASE)).data;
  if (!userType) {
    return users;
  }

  return users.filter(user => {
    if (userType === 'admin' && user.isAdmin) {
      return true;
    } else if (userType === 'office' && user.isOffice) {
      return true;
    } else if (userType === 'workshop' && !user.isAdmin && !user.isOffice) {
      return true;
    }
    return false;
  });
}

export async function getUser(id: string): Promise<User> {
  return (await API.get(`${BASE}/${id}`)).data;
}

export async function addUser(data: UserFormData): Promise<User> {
  const payload = {
    name: data.name,
    login: data.login,
    password: data.password,
    isAdmin: data.isAdmin,
    isOffice: data.isOffice,
    enabled: data.enabled,
  };
  return (await API.post(BASE, payload)).data;
}

export async function updateUser(id: string, data: EditUserFormData) {
  await API.patch(`${BASE}/${id}`, data);
}

export async function updateUserPassword(id: string, data: UserEditPasswordFormData) {
  const payload = {
    password: data.password,
  };
  await API.patch(`${BASE}/${id}/password`, payload);
}

export async function deleteUser(id: string) {
  await API.delete(`${BASE}/${id}`);
}
