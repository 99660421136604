import { API } from '../../../libs/services/api';
import { DailySchedule, DailyScheduleForm } from '../types/dailySchedule';

const BASE = 'daily-schedule';

export async function getDailyScheduleForDay(day: string): Promise<DailySchedule[]> {
  return (await API.get(`${BASE}/${day}`)).data;
}

export async function getDailyScheduleForDayAndCurrentUser(day: string): Promise<DailySchedule[]> {
  return (await API.get(`${BASE}/currentUser/${day}`)).data;
}

export async function saveDailyScheduleForDay(day: string, ds: DailyScheduleForm[]) {
  const items = ds.map(item => ({
    _new: item._new,
    id: item.id,
    day: item.day,
    userId: item.userId,
    jobId: item.job.id,
    limitHours: item.limitHours,
    limitMinutes: item.limitMinutes,
    order: item.order,
    isMainAssignedUser: item.isMainAssignedUser,
  }));
  await API.put(`${BASE}/${day}`, { items });
}

export async function deleteDailyScheduleItem(id: string) {
  await API.delete(`${BASE}/${id}`);
}

export async function activateDailySchedule(day: string) {
  await API.post(`${BASE}/activate/${day}`);
}

export async function activateNewlyAddedScheduleItems(day: string) {
  await API.put(`${BASE}/activate/${day}`);
}
