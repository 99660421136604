import Message from './Message';
import ErrorList from './ErrorList';

interface ApiErrorMessageProps {
  label: string;
  errorResponse: unknown;
}

export default function ApiErrorMessage({ label, errorResponse }: ApiErrorMessageProps) {
  return (
    <Message type="error">
      <span>
        <strong>{label}</strong>
      </span>
      <ErrorList errorResponse={errorResponse} />
    </Message>
  );
}
