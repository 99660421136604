import { Button, Card, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { UserFormData } from '../types/users';
import FormInput from '../../../libs/components/forms/FormInput';
import { getValidationSchema } from '../config/validation';

interface UserFormProps {
  submitButtonLabel: string;
  submitButtonColor?: string;
  initialValues: Partial<UserFormData>;
  skipPassword?: boolean;
  onSubmit: (data: UserFormData) => void;
}

export function UserForm({
  submitButtonLabel,
  submitButtonColor = 'success',
  initialValues,
  skipPassword = false,
  onSubmit,
}: UserFormProps) {
  const methods = useForm<UserFormData>({
    defaultValues: initialValues,
    mode: 'all',
    resolver: yupResolver(getValidationSchema(skipPassword)),
  });

  return (
    <FormProvider {...methods}>
      <Card className="bg-light">
        <Card.Body>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormInput name="name" label="Name" type="text" required={true} />
            <FormInput name="login" label="Benutzername" type="text" required={true} />
            {!skipPassword && (
              <>
                <FormInput name="password" label="Passwort" type="password" required={true} />
                <FormInput
                  name="repeat_password"
                  label="Passwort wiederholen"
                  type="password"
                  required={true}
                />
              </>
            )}
            <FormInput name="isAdmin" label="Administrator" required={false} type="checkbox" />
            <FormInput name="isOffice" label="Büro" required={false} type="checkbox" />
            <FormInput name="enabled" label="Konto aktiv" required={false} type="checkbox" />
            <Button variant={submitButtonColor} type="submit" className="mt-3">
              {submitButtonLabel}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </FormProvider>
  );
}
