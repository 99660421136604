import { useParams } from 'react-router-dom';
import React, { useEffect, useReducer, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { VehicleForm } from './VehicleForm';
import { Vehicle, VehicleEditFormData } from '../../types/vehicles';
import { getVehicle, updateVehicle } from '../../services/api';
import {
  FormActionState,
  getFormActionReducer,
} from '../../../../libs/services/state/formActionReducer';
import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import ApiErrorMessage from '../../../../libs/components/data/ApiErrorMessage';
import Message from '../../../../libs/components/data/Message';

const initialState: FormActionState = {
  actionInProgress: false,
  actionSuccess: false,
  actionError: false,
  showForm: true,
  actionErrorResponse: undefined,
};

export default function VehicleEdit() {
  const { id } = useParams();
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [formData, setFormData] = useState<VehicleEditFormData>();
  const [state, dispatch] = useReducer(getFormActionReducer(initialState), initialState);

  useEffect(() => {
    if (!id) {
      return;
    }
    getVehicle(id).then(data => {
      setVehicle(data);
      setFormData(data);
    });
  }, [id]);

  async function onSubmit(data: VehicleEditFormData) {
    if (!vehicle) {
      return;
    }

    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      await updateVehicle(vehicle.id, data);
      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    }
  }

  if (!vehicle || !formData) {
    return null;
  }

  return (
    <>
      <PageToolbar pageTitle={`Fahrzeugdaten bearbeiten: ${vehicle.plate}`}>
        <ButtonLink link="/vehicles" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      {state.showForm && (
        <Row className="mb-4">
          <Col lg={10}>
            <VehicleForm
              submitButtonLabel="Fahrzeugdaten aktualisieren"
              submitButtonColor="primary"
              onSubmit={onSubmit}
              initialValues={formData}
            />
          </Col>
        </Row>
      )}
      {state.actionError && (
        <ApiErrorMessage
          label="Fahrzeugdaten konnten nicht aktualisiert werden"
          errorResponse={state.actionErrorResponse}
        />
      )}
      {state.actionSuccess && <Message type="ok">Fahrzeugdaten wurden aktualisiert</Message>}
    </>
  );
}
