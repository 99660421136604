import { Button, Form, Table } from 'react-bootstrap';

import { JobFile } from '../../../types/jobs';
import TableHeader from '../../../../../libs/components/data/table/TableHeader';
import { getFormattedDateOrEmpty } from '../../../../../libs/services/date';
import { IconDelete } from '../../../../../libs/components/data/Icon';
import { User } from '../../../../users/types/users';
import { useEffect, useState } from 'react';

interface JobFilesTableProps {
  files: JobFile[];
  authUser: User;
  onDelete?: (file: JobFile) => void;
  onUpdate?: (file: JobFile, field: keyof JobFile, value: any) => void;
  onAllTagsUpdate?: (updatedFiles: JobFile[]) => void;
}

const TABLE_COLUMNS = [
  { key: 'createdAt', label: 'Hinzugefügt am/von', width: 150 },
  { key: 'name', label: 'Dateiname' },
  { key: 'tags', label: 'Tags', width: 250 },
  { key: 'access', label: 'Zugriff', width: 100 },
];

export default function JobFilesTable({
  files,
  authUser,
  onUpdate,
  onDelete,
  onAllTagsUpdate,
}: JobFilesTableProps) {
  const [updatedFiles, setUpdatedFiles] = useState<JobFile[]>(files);

  useEffect(() => {
    if (updatedFiles.length !== files.length) {
      setUpdatedFiles(files);
    }
  }, [files, updatedFiles]);

  const tableColumns = TABLE_COLUMNS.filter(column => {
    if (onUpdate === undefined && ['tags', 'access'].includes(column.key)) {
      return false;
    }
    return true;
  });

  function onChangeTags(file: JobFile, newValue: string) {
    setUpdatedFiles(
      updatedFiles.map(f => {
        if (f.id === file.id) {
          f.tags = newValue.split(',');
        }
        return f;
      }),
    );
  }

  function onTagsUpdate() {
    if (!onAllTagsUpdate) {
      return;
    }
    const changedFiles = updatedFiles.filter((file, i) => {
      return file.tags.join(',') !== files[i].tags.join(',');
    });
    onAllTagsUpdate(changedFiles);
  }

  return (
    <>
      <Table striped bordered className="shadow-sm">
        <TableHeader columns={tableColumns} actionColumn={onDelete !== undefined} />
        <tbody>
          {files.map((item, i) => (
            <tr key={item.id}>
              <td>
                <div>{getFormattedDateOrEmpty(item.createdAt)}</div>
                <div>{item.createdBy.name}</div>
              </td>
              <td>
                <a
                  href=""
                  onClick={() => window.open(`/files/jobs/${item.jobId}/${item.fileName}`)}>
                  {item.fileName}
                </a>
              </td>
              {onUpdate !== undefined && (
                <td>
                  <span>
                    <Form.Control
                      type="text"
                      value={updatedFiles[i]?.tags.join(',') ?? ''}
                      onChange={e => onChangeTags(item, e.target.value)}
                    />
                  </span>
                </td>
              )}
              {onUpdate !== undefined && (
                <td>
                  <>
                    {authUser.isAdmin && (
                      <div>
                        <Form.Check
                          label="Admin"
                          checked={item.onlyAdmin}
                          onChange={e => onUpdate(item, 'onlyAdmin', e.target.checked)}
                        />
                      </div>
                    )}
                    <div>
                      <Form.Check
                        label="Büro"
                        checked={item.onlyOffice}
                        onChange={e => onUpdate(item, 'onlyOffice', e.target.checked)}
                      />
                    </div>
                  </>
                </td>
              )}
              {onDelete !== undefined && (
                <td>
                  <IconDelete onClick={() => onDelete(item)} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      {onAllTagsUpdate !== undefined && files.length > 0 && (
        <div className="mt-2 text-end">
          <Button size="sm" onClick={onTagsUpdate}>
            Tags speichern
          </Button>
        </div>
      )}
    </>
  );
}
