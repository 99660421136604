import { Button } from 'react-bootstrap';
import React, { useState } from 'react';

import PageToolbar from '../../../libs/components/actions/PageToolbar';
import ButtonLink from '../../../libs/components/actions/ButtonLink';
import DoneJobsList from './lists/DoneJobsList';
import NewestWorkTimeList from './lists/NewestWorkTimeList';
import NewestJobsList from './lists/NewestJobsList';
import WorkingTimeInProgressList from './lists/WorkingTimeInProgressList';
import ConfirmModal from '../../../libs/components/modals/ConfirmModal';
import { stopAllWorkTimeInProgress } from '../services/api';
import LatestVehiclePartsList from './lists/LatestVehiclePartsList';
import { Icon } from '../../../libs/components/data/Icon';
import NextExpectedDoneList from './lists/NextExpectedDoneList';

export default function Dashboard() {
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function onStopWorkTimeConfirm() {
    await setLoading(true);
    await stopAllWorkTimeInProgress();
    setConfirmModal(false);
    setLoading(false);
  }

  return (
    <>
      <ConfirmModal
        show={confirmModal}
        title="Abmelden"
        onConfirm={onStopWorkTimeConfirm}
        onCancel={() => setConfirmModal(false)}>
        Möchten Sie sich alle laufende Arbeitszeiten stoppen?
      </ConfirmModal>
      <PageToolbar pageTitle="Dashboard">
        <ButtonLink link="/jobs/add" buttonColor="success" icon="plus-circle-fill">
          Arbeitskarte anlegen
        </ButtonLink>
        <span className="px-2"></span>
        <Button variant="warning" onClick={() => setConfirmModal(true)}>
          <Icon icon="stop-fill" styles="text-white" /> Alle Arbeitszeiten stoppen
        </Button>
      </PageToolbar>
      {!loading && (
        <>
          <WorkingTimeInProgressList />
          <NextExpectedDoneList />
          <NewestWorkTimeList />
          <DoneJobsList />
          <NewestJobsList />
          <LatestVehiclePartsList />
        </>
      )}
    </>
  );
}
