import { WorkTimeItemForm, WorkTimeWithSum } from '../types/workTime';
import { API } from '../../../libs/services/api';

const BASE = 'work-time';

export async function getWorkTime(
  filter: string,
  userFilter: string,
  fromFilter: string,
  toFilter: string,
): Promise<WorkTimeWithSum> {
  return (
    await API.get(`${BASE}?filter=${filter}&user=${userFilter}&from=${fromFilter}&to=${toFilter}`)
  ).data;
}

export async function updateWorkTimeItem(jobId: string, itemId: string, dto: WorkTimeItemForm) {
  return (await API.patch(`${BASE}/${jobId}/${itemId}`, dto)).data;
}

export async function deleteWorkTimeItem(jobId: string, itemId: string) {
  return (await API.delete(`${BASE}/${jobId}/${itemId}`)).data;
}
