import { useEffect, useState } from 'react';
import { Job, JobState } from '../../../jobs/types/jobs';
import { getLatestJobs } from '../../../jobs/services/api';
import { Table } from 'react-bootstrap';
import Status from '../../../../libs/components/data/Status';
import { formatDate } from '../../../../libs/services/date';
import { Link } from 'react-router-dom';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import { IconDetails } from '../../../../libs/components/data/Icon';

export default function NewestJobsList() {
  const [jobs, setJobs] = useState<Job[]>([]);

  useEffect(() => {
    loadJobs();
  }, []);

  function loadJobs() {
    getLatestJobs(10).then(data => setJobs(data));
  }

  return (
    <>
      <SectionTitle title="Neueste angelegte Arbeitskarten" />
      <Table striped bordered className="shadow-sm">
        <thead>
          <tr>
            <th style={{ width: 100 }}>Erledigt</th>
            <th style={{ width: 100 }}>Bearbeiter</th>
            <th style={{ width: 200 }}>Anlegegt am</th>
            <th>Anlegegt von</th>
            <th>Kennzeichen</th>
            <th>Marke</th>
            <th>KM-Stand</th>
            <th>Erstzulassung</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map(item => (
            <tr key={item.id}>
              <td>
                <Status status={item.isDone} labelTrue="Ja" labelFalse="Nein" />
              </td>
              <td>
                <Status
                  status={item.jobState === JobState.WORKSHOP}
                  labelTrue="Wekstatt"
                  labelFalse="Büro"
                  colorTrue="primary"
                  colorFalse="warning"
                />
              </td>
              <td>{formatDate(item.createdAt, 'datetime')}</td>
              <td>{item.createdBy.name}</td>
              <td>{item.vehicle?.plate || '---'}</td>
              <td>
                {item.vehicle?.brandName || '---'} {item.vehicle?.brandTypeName || ''}
              </td>
              <td>{item.mileage}</td>
              <td>{item.vehicle?.registrationDate || '---'}</td>
              <td>
                <Link to={`/jobs/${item.id}`} className="text-info">
                  <IconDetails />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
