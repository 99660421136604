import { useEffect, useState } from 'react';
import { Job } from '../../../jobs/types/jobs';
import { getNextExpectedDoneJobs } from '../../../jobs/services/api';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import { Table } from 'react-bootstrap';
import Status from '../../../../libs/components/data/Status';
import { formatDate, getFormattedDateOrEmpty } from '../../../../libs/services/date';
import { Link } from 'react-router-dom';
import { IconDetails } from '../../../../libs/components/data/Icon';

export default function NextExpectedDoneList() {
  const [jobs, setJobs] = useState<Job[]>([]);

  useEffect(() => {
    load();
  }, []);

  function load() {
    getNextExpectedDoneJobs(10).then(data => setJobs(data));
  }

  return (
    <>
      <SectionTitle title="Nächste Arbeitskarten zu erledigen" />
      <Table striped bordered className="shadow-sm">
        <thead>
          <tr>
            <th style={{ width: 100 }}>Erledigt</th>
            <th style={{ width: 200 }}>Anlegegt am</th>
            <th style={{ width: 200 }}>Zu erledigen bis</th>
            <th>Kennzeichen</th>
            <th>Marke</th>
            <th>KM-Stand</th>
            <th>Erstzulassung</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map(item => (
            <tr key={item.id}>
              <td>
                <Status status={item.isDone} labelTrue="Ja" labelFalse="Nein" />
              </td>
              <td>{formatDate(item.createdAt, 'datetime')}</td>
              <td
                className={
                  item.expectedDoneAt && new Date(item.expectedDoneAt) < new Date()
                    ? 'text-danger'
                    : ''
                }>
                <>{getFormattedDateOrEmpty(item.expectedDoneAt, 'date')}</>
              </td>
              <td>{item.vehicle?.plate || '---'}</td>
              <td>
                {item.vehicle?.brandName || '---'} {item.vehicle?.brandTypeName || ''}
              </td>
              <td>{item.mileage}</td>
              <td>{item.vehicle?.registrationDate || '---'}</td>
              <td>
                <Link to={`/jobs/${item.id}`} className="text-info">
                  <IconDetails />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
