import React, { ChangeEvent, useState } from 'react';
import { Button, Card, Form, Stack, Table } from 'react-bootstrap';

import { IconDelete } from '../../../../../libs/components/data/Icon';
import { MAX_FILE_SIZE } from '../../../../files/config/validation';
import { User } from '../../../../users/types/users';

export interface SelectedFile {
  name: string;
  content: string;
  tags: string;
  onlyAdmin: boolean;
  onlyOffice: boolean;
}

interface JobFileAddFormProps {
  authUser: User;
  onFilesUpload: (files: SelectedFile[]) => void;
}

export default function JobFileAddForm({ authUser, onFilesUpload }: JobFileAddFormProps) {
  const [files, setFiles] = useState<SelectedFile[]>([]);

  function onFileSelect(event: ChangeEvent<HTMLInputElement>) {
    if (event.target?.files) {
      const file = event.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        alert(`Datei zu groß! Max. Dateigröße: ${MAX_FILE_SIZE / 1000_000} MB`);
        event.target!.value! = '';
        return;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file.slice());
      reader.onload = function () {
        setFiles([
          ...files,
          {
            name: file.name,
            content: reader.result as string,
            tags: '',
            onlyAdmin: false,
            onlyOffice: false,
          },
        ]);
        event.target!.value! = '';
      };
    }
  }

  function onUpdate(file: SelectedFile, field: string, value: any) {
    setFiles(
      files.map(item => {
        if (item.name === file.name) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      }),
    );
  }

  function deleteFile(file: SelectedFile) {
    setFiles(files.filter(f => f.name !== file.name));
  }

  function uploadFiles() {
    onFilesUpload(files);
    setFiles([]);
  }

  return (
    <Card className="mb-4 bg-light">
      <Card.Body>
        <div className="mb-2">
          <strong>Neue Dateien hochladen</strong> (
          <span className="text-danger">max. Dateigröße: {MAX_FILE_SIZE / 1000_000} MB</span>)
        </div>
        <div className="w-50">
          <Form.Control type="file" name="file" onChange={onFileSelect} />
        </div>
        {files.length > 0 && (
          <>
            <Table className="my-3 table-bordered bg-white">
              <thead>
                <tr>
                  <th></th>
                  <th>Dateiname</th>
                  <th style={{ width: 300 }}>Tags</th>
                  <th>Rechte</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, i) => (
                  <tr key={file.name}>
                    <td style={{ width: 35 }}>{i + 1}</td>
                    <td>{file.name}</td>
                    <td>
                      <Form.Control
                        name={`content_${file.name}`}
                        value={file.tags}
                        onChange={e => onUpdate(file, 'tags', e.target.value)}
                      />
                    </td>
                    <td>
                      <Stack className="mt-2 text-muted" direction="horizontal">
                        {authUser.isAdmin && (
                          <Form.Check
                            label="Admin"
                            checked={file.onlyAdmin}
                            onChange={e => onUpdate(file, 'onlyAdmin', e.target.checked)}
                          />
                        )}
                        <span className="px-2"></span>
                        <Form.Check
                          label="Büro"
                          checked={file.onlyOffice}
                          onChange={e => onUpdate(file, 'onlyOffice', e.target.checked)}
                        />
                      </Stack>
                    </td>
                    <td className="text-center" style={{ width: 50 }}>
                      <IconDelete onClick={() => deleteFile(file)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button variant="primary" className="d-inline-block" onClick={uploadFiles}>
              Dateien hochladen
            </Button>
          </>
        )}
      </Card.Body>
    </Card>
  );
}
