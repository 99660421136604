import { Button, Form, Modal } from 'react-bootstrap';
import * as React from 'react';
import { useState } from 'react';

import { Brand, BrandType } from '../../types/brands';
import { addBrandType } from '../../services/api';
import ErrorList from '../../../../libs/components/data/ErrorList';

interface AddBrandTypeModalProps {
  selectedBrand: Brand | undefined;
  show: boolean;
  onSaved: (newBrandType: BrandType) => void;
  onCancel: () => void;
}

export default function AddBrandTypeModal(props: AddBrandTypeModalProps) {
  const [value, setValue] = useState<string>('');
  const [errorResponse, setErrorResponse] = useState<unknown>();

  async function onSubmit() {
    if (value.trim() === '' || !props.selectedBrand) {
      return;
    }

    const existingBrandType = props.selectedBrand?.types.find(
      (item: BrandType) => item.name === value,
    );
    if (existingBrandType) {
      setErrorResponse({ response: { data: { message: 'Dieses Modell bereits existiert' } } });
      return;
    }

    try {
      const brandWithCreatedType = await addBrandType(props.selectedBrand.id, { name: value });
      const createdType = brandWithCreatedType.types.find((type: BrandType) => type.name === value);
      if (!createdType) {
        return;
      }
      setValue('');
      props.onSaved(createdType);
    } catch (err) {
      setErrorResponse(err);
    }
  }

  function onCancel() {
    setValue('');
    props.onCancel();
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  if (!props.selectedBrand) {
    return null;
  }

  return (
    <Modal show={props.show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          Modell für <strong>{props.selectedBrand.name}</strong> hinzufügen
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Form.Group>
            <Form.Label>
              Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control type="text" onChange={onChange} value={value} />
          </Form.Group>
          {errorResponse && <ErrorList errorResponse={errorResponse} />}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Abbrechen
        </Button>
        <Button variant="primary" onClick={onSubmit} disabled={value.trim() === ''}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
