import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { VehicleEditFormData } from '../../types/vehicles';
import { getValidationSchema } from '../../config/validation';
import React, { useEffect, useState } from 'react';
import FormInput from '../../../../libs/components/forms/FormInput';
import { kwToPs } from '../../../jobs/services/kwPs';

interface VehicleFormProps {
  submitButtonLabel: string;
  submitButtonColor?: string;
  initialValues: Partial<VehicleEditFormData>;
  onSubmit: (data: VehicleEditFormData) => void;
}

export function VehicleForm({
  submitButtonLabel,
  submitButtonColor = 'success',
  initialValues,
  onSubmit,
}: VehicleFormProps) {
  const methods = useForm<VehicleEditFormData>({
    defaultValues: initialValues,
    mode: 'all',
    resolver: yupResolver(getValidationSchema()),
  });

  const [ps, setPs] = useState<number | undefined>();

  const watchPlate = methods.watch('plate');
  const watchKw = methods.watch('kw');

  // automatically transform plate to uppercase format
  useEffect(() => {
    if (watchPlate !== undefined && watchPlate !== watchPlate.toUpperCase()) {
      methods.setValue('plate', watchPlate.toUpperCase());
    }
  }, [watchPlate]);

  // show PS based on kW
  useEffect(() => {
    if (watchKw && !isNaN(watchKw)) {
      setPs(() => kwToPs(Number(watchKw)));
    } else {
      setPs(() => undefined);
    }
  }, [watchKw]);

  return (
    <FormProvider {...methods}>
      <Card className="bg-light">
        <Card.Body>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <FormInput name="plate" label="Kennzeichen" type="text" required={true} />
              </Col>
              <Col>
                <FormInput name="mileage" label="KM-Stand" type="text" required={true} />
              </Col>
              <Col>
                <FormInput
                  name="registrationDate"
                  label="Erstzulassung"
                  type="date"
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput name="fin" label="FIN" type="text" required={true} />
              </Col>
              <Col>
                <FormInput name="hsn" label="HSN" type="text" required={true} />
              </Col>
              <Col>
                <FormInput name="tsn" label="TSN" type="text" required={true} />
              </Col>
            </Row>

            <Row>
              <Col>
                <Row>
                  <Col lg={6}>
                    <FormInput name="kw" label="kW" type="text" required={true} />
                  </Col>
                  <Col lg={6} className="pt-5">
                    {ps !== undefined && <span>{ps} PS</span>}
                  </Col>
                </Row>
              </Col>
              <Col>
                <FormInput
                  name="displacement"
                  label="Hubraum (ccm)"
                  type="number"
                  required={true}
                />
              </Col>
              <Col></Col>
              <Col lg={4}></Col>
            </Row>
            <Row>
              <Col>
                <FormInput name="inspectionHU" label="HU" type="date" required={true} />
              </Col>
              <Col>
                <FormInput
                  name="inspectionHURemind"
                  label="HU Erinnerung"
                  type="date"
                  required={false}
                />
              </Col>
              <Col>
                <FormInput name="inspectionAU" label="AU" type="date" required={true} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput
                  name="lastInspection"
                  label="Letzte Inspektion"
                  type="date"
                  required={false}
                />
              </Col>
              <Col>
                <FormInput
                  name="nextInspectionRemind"
                  label="Nächste Inspektion Erinnerung"
                  type="date"
                  required={false}
                />
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <FormInput name="tags" label="Tags" type="text" required={false} />
              </Col>
              <Col></Col>
            </Row>
            <Button variant={submitButtonColor} type="submit" className="mt-3">
              {submitButtonLabel}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </FormProvider>
  );
}
