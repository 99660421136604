import { API } from '../../../libs/services/api';
import {
  StorageArticle,
  StorageArticleFormData,
  StorageArticlePhoto,
  StorageArticlePhotoForm,
} from '../types/storage';

const BASE = 'storage';

export async function getArticles(
  filter: string,
  categoryFilter: string,
): Promise<StorageArticle[]> {
  return (await API.get(`${BASE}?filter=${filter}&category=${categoryFilter}`)).data;
}

export async function getArticle(id: string): Promise<StorageArticle> {
  return (await API.get(`${BASE}/${id}`)).data;
}

export async function deleteArticle(id: string) {
  await API.delete(`${BASE}/${id}`);
}

export async function addArticle(data: StorageArticleFormData): Promise<StorageArticle> {
  const payload = {
    category: data['category'],
    subCategory1: data['subCategory1'],
    subCategory2: data['subCategory2'],
    manufacturer: data['manufacturer'],
    supplier: data['supplier'],
    orderDate: data['orderDate'],
    billNumber: data['billNumber'],
    quantity: Number(data['quantity']),
    unit: data['unit'],
    purchasingNetPrice: Number(data['purchasingNetPrice']),
    perUnitPurchasingNetPrice: Number(data['perUnitPurchasingNetPrice']),
    sellingNetPrice: Number(data['sellingNetPrice']),
    perUnitSellingNetPrice: Number(data['perUnitSellingNetPrice']),
    storagePlace: data['storagePlace'],
    shelf: data['shelf'],
    board: data['board'],
    photos: data['photos'],
    tags: data['tags'],
  };
  return (await API.post(BASE, payload)).data;
}

export async function uploadPhotos(articleId: string, photos: StorageArticlePhotoForm[]) {
  return (await API.patch(`${BASE}/${articleId}/photos`, { photos })).data;
}

export async function updatePhotosTags(articleId: string, editedPhotos: StorageArticlePhoto[]) {
  return (
    await API.patch(`${BASE}/${articleId}/photoTags`, {
      tags: editedPhotos.map(photo => ({ photoId: photo.id, tags: photo.tags })),
    })
  ).data;
}

export async function updatePhoto(articleId: string, photoId: string, field: string, value: any) {
  return (await API.patch(`${BASE}/${articleId}/${photoId}`, { [field]: value })).data;
}

export async function deletePhoto(articleId: string, photoId: string) {
  return (await API.delete(`${BASE}/${articleId}/${photoId}`)).data;
}
