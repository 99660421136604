import { RouteObject } from 'react-router-dom';

import App from '../layout/App';
import Dashboard from '../modules/dashboard/components/Dashboard';
import LoginForm from '../modules/login/components/LoginForm';
import UsersList from '../modules/users/components/UsersList/UsersList';
import UserAdd from '../modules/users/components/UserAdd';
import UserEdit from '../modules/users/components/UserEdit';
import JobAdd from '../modules/jobs/components/JobAdd';
import CategoriesList from '../modules/job_categories/components/CategoriesList/CategoriesList';
import CategoryAdd from '../modules/job_categories/components/CategoryAdd';
import CategoryEdit from '../modules/job_categories/components/CategoryEdit';
import VehiclesList from '../modules/vehicles/components/VehiclesList/VehiclesList';
import JobsList from '../modules/jobs/components/JobsList/JobsList';
import JobDetails from '../modules/jobs/components/JobDetails/JobsDetails';
import VehicleDetails from '../modules/vehicles/components/VehicleDetails/VehicleDetails';
import JobPrint from '../modules/jobs/components/JobPrint';
import ProtectedPage from '../libs/components/auth/ProtectedPage';
import MyJobsList from '../modules/my-jobs/components/MyJobsList/MyJobsList';
import MyJobDetails from '../modules/my-jobs/components/MyJobDetails/MyJobDetails';
import SubCategoryEdit from '../modules/job_categories/components/SubCategoryEdit';
import VehiclePartsList from '../modules/vehicle_parts/components/VehiclePartsList/VehiclePartsList';
import PhotosList from '../modules/photos/components/PhotosList/PhotosList';
import WorkTimeList from '../modules/work_time/components/WorkTimeList/WorkTimeList';
import StatusDashboard from '../modules/system_status/components/StatusDashboard';
import VehicleEdit from '../modules/vehicles/components/VehicleEdit/VehicleEdit';
import SessionsList from '../modules/sessions/components/SessionsList/SessionsList';
import LogsList from '../modules/logs/components/LogsList/LogsList';
import JobEdit from '../modules/jobs/components/JobEdit/JobEdit';
import LogDetails from '../modules/logs/components/LogDetails/LogDetails';
import BrandTypeEdit from '../modules/brands/BrandTypeEdit/BrandAddEdit/BrandTypeEdit';
import BrandsList from '../modules/brands/BrandTypeEdit/BrandsList/BrandsList';
import BrandAdd from '../modules/brands/BrandTypeEdit/BrandAddEdit/BrandAdd';
import BrandEdit from '../modules/brands/BrandTypeEdit/BrandAddEdit/BrandEdit';
import FilesList from '../modules/files/components/FilesList/FilesList';
import WorkOverview from '../modules/work_overview/components/WorkOverview';
import { DailyScheduleContainer } from '../modules/daily_schedule/components/DailyScheduleContainer';
import StorageList from '../modules/storage/components/StorageList/StorageList';
import ArticleAdd from '../modules/storage/components/ArticleEdit/ArticleAdd';
import ArticleEdit from '../modules/storage/components/ArticleEdit/ArticleEdit';
import StorageArticleDetails from '../modules/storage/components/StorageDetails/StorageArticleDetails';
import StorageCategoriesList from '../modules/storage_categories/components/CategoriesList/CategoriesList';
import StorageCategoryAdd from '../modules/storage_categories/components/CategoryAdd';
import StorageCategoryEdit from '../modules/storage_categories/components/CategoryEdit';
import StorageSubCategoryEdit from '../modules/storage_categories/components/SubCategoryEdit';
import StorageArticlePrint from '../modules/storage/components/StorageArticlePrint';
import PhoneCallsList from '../modules/phone_calls/components/PhoneCallsList/PhoneCallsList';
import PhoneCallAdd from '../modules/phone_calls/components/PhoneCallEdit/PhoneCallAdd';
import PhoneCallEdit from '../modules/phone_calls/components/PhoneCallEdit/PhoneCallEdit';
import PhoneCallDetails from '../modules/phone_calls/components/PhoneCallDetails/PhoneCallDetails';
import PhoneCallResubmissionList from '../modules/phone_calls/components/PhoneCallsList/PhoneCallResubmissionList';
import VehicleCommissionsList from '../modules/vehicle_commission/components/VehicleComissionsList/VehicleCommissionsList';
import VehicleCommissionAdd from '../modules/vehicle_commission/components/VehicleCommissionAdd';
import VehicleCommissionDetails from '../modules/vehicle_commission/components/VehicleCommissionDetails/VehicleCommissionDetails';

function Protected(cmp: JSX.Element, module: string): JSX.Element {
  return <ProtectedPage module={module}>{cmp}</ProtectedPage>;
}

export const ROUTES: RouteObject[] = [
  {
    path: '/login',
    element: <LoginForm />,
  },
  {
    path: '/error',
    element: <div>Fehler</div>,
  },
  {
    path: '/job-print/:id',
    element: Protected(<JobPrint />, 'jobs'),
  },
  {
    path: '/article-qrcode-print/:id',
    element: Protected(<StorageArticlePrint />, 'storage'),
  },
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/my-jobs',
        element: Protected(<MyJobsList />, 'my_jobs'),
      },
      {
        path: '/my-jobs/:id',
        element: Protected(<MyJobDetails />, 'my_jobs'),
      },
      {
        path: '/',
        element: Protected(<Dashboard />, 'dashboard'),
      },
      {
        path: '/work_overview',
        element: Protected(<WorkOverview />, 'work_overview'),
      },
      {
        path: '/daily_schedule',
        element: Protected(<DailyScheduleContainer />, 'daily_schedule'),
      },
      {
        path: '/jobs',
        element: Protected(<JobsList />, 'jobs'),
      },
      {
        path: '/jobs/add',
        element: Protected(<JobAdd />, 'jobs'),
      },
      {
        path: '/jobs/edit/:id',
        element: Protected(<JobEdit />, 'jobs'),
      },
      {
        path: '/jobs/:id',
        element: Protected(<JobDetails />, 'jobs'),
      },
      {
        path: '/vehicles',
        element: Protected(<VehiclesList />, 'vehicles'),
      },
      {
        path: '/vehicles/edit/:id',
        element: Protected(<VehicleEdit />, 'vehicles'),
      },
      {
        path: '/vehicles/:id',
        element: Protected(<VehicleDetails />, 'vehicles'),
      },
      {
        path: '/phone-calls',
        element: Protected(<PhoneCallsList />, 'phone_calls'),
      },
      {
        path: '/phone-calls/resubmission',
        element: Protected(<PhoneCallResubmissionList />, 'phone_calls'),
      },
      {
        path: '/phone-calls/:id',
        element: Protected(<PhoneCallDetails />, 'phone_calls'),
      },
      {
        path: '/phone-calls/add',
        element: Protected(<PhoneCallAdd />, 'phone_calls'),
      },
      {
        path: '/phone-calls/edit/:id',
        element: Protected(<PhoneCallEdit />, 'phone_calls'),
      },
      {
        path: '/storage',
        element: Protected(<StorageList />, 'storage'),
      },
      {
        path: '/storage/:id',
        element: Protected(<StorageArticleDetails />, 'storage'),
      },
      {
        path: '/storage/add',
        element: Protected(<ArticleAdd />, 'storage'),
      },
      {
        path: '/storage/edit/:id',
        element: Protected(<ArticleEdit />, 'storage'),
      },
      {
        path: '/users',
        element: Protected(<UsersList />, 'users'),
      },
      {
        path: '/users/add',
        element: Protected(<UserAdd />, 'users'),
      },
      {
        path: '/users/edit/:id',
        element: Protected(<UserEdit />, 'users'),
      },
      {
        path: '/vehicle_commissions',
        element: Protected(<VehicleCommissionsList />, 'vehicle_commissions'),
      },
      {
        path: '/vehicle_commissions/add',
        element: Protected(<VehicleCommissionAdd />, 'vehicle_commissions'),
      },
      {
        path: '/vehicle_commissions/:id',
        element: Protected(<VehicleCommissionDetails />, 'vehicle_commissions'),
      },
      {
        path: '/brands',
        element: Protected(<BrandsList />, 'brands'),
      },
      {
        path: '/brands/add',
        element: Protected(<BrandAdd />, 'brands'),
      },
      {
        path: '/brands/edit/:id',
        element: Protected(<BrandEdit />, 'brands'),
      },
      {
        path: '/brands/:id/edit-type/:typeId',
        element: Protected(<BrandTypeEdit />, 'brands'),
      },
      {
        path: '/job-categories',
        element: Protected(<CategoriesList />, 'job_categories'),
      },
      {
        path: '/job-categories/add',
        element: Protected(<CategoryAdd />, 'job_categories'),
      },
      {
        path: '/job-categories/edit/:id',
        element: Protected(<CategoryEdit />, 'job_categories'),
      },
      {
        path: '/job-categories/:id/edit-subcategory/:subCategoryId',
        element: Protected(<SubCategoryEdit />, 'job_categories'),
      },
      {
        path: '/storage-categories',
        element: Protected(<StorageCategoriesList />, 'storage_categories'),
      },
      {
        path: '/storage-categories/add',
        element: Protected(<StorageCategoryAdd />, 'storage_categories'),
      },
      {
        path: '/storage-categories/edit/:id',
        element: Protected(<StorageCategoryEdit />, 'storage_categories'),
      },
      {
        path: '/storage-categories/:id/edit-subcategory/:subCategoryId',
        element: Protected(<StorageSubCategoryEdit />, 'storage_categories'),
      },
      {
        path: '/vehicle-parts',
        element: Protected(<VehiclePartsList />, 'vehicleParts'),
      },
      {
        path: '/photos',
        element: Protected(<PhotosList />, 'photos'),
      },
      {
        path: '/files',
        element: Protected(<FilesList />, 'files'),
      },
      {
        path: '/worktime',
        element: Protected(<WorkTimeList />, 'workTime'),
      },
      {
        path: '/sessions',
        element: Protected(<SessionsList />, 'sessions'),
      },
      {
        path: '/logs',
        element: Protected(<LogsList />, 'logs'),
      },
      {
        path: '/logs/:id',
        element: Protected(<LogDetails />, 'logs'),
      },
      {
        path: '/status',
        element: Protected(<StatusDashboard />, 'system_status'),
      },
      {
        path: '*',
        element: <div>404 Not Found</div>,
      },
    ],
  },
];
