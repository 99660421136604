import { Table } from 'react-bootstrap';

import { Job, JobTask } from '../../types/jobs';
import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import Status from '../../../../libs/components/data/Status';
import JobNotesList from './JobNotes/JobNotesList';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import { useAuth } from '../../../../libs/context/AuthContext';
import { IconDelete } from '../../../../libs/components/data/Icon';

interface JobTasksProps {
  job: Job;
  onTaskDelete?: (task: JobTask) => void;
}

export default function JobTasks({ job, onTaskDelete }: JobTasksProps) {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <>
      <SectionTitle title="Aufgaben" />
      <Table striped bordered className="shadow-sm">
        <thead>
          <tr>
            <th>Kategorie / Unterkategorie</th>
            <th>Beschreibung</th>
            <th>Mitarbeiter Anmerkungen</th>
            <th>Erledigt</th>
            <th>Zuletzt aktualisiert am</th>
            {onTaskDelete !== undefined && user.isAdmin && <th>Aktionen</th>}
          </tr>
        </thead>
        <tbody>
          {job.tasks.map(task => (
            <tr key={task.id}>
              <td>
                <div>
                  <strong>{task.category.name}</strong>
                </div>
                <div>{task.subCategory.name}</div>
              </td>
              <td>{task.description}</td>
              <td>
                <JobNotesList notes={task.assignedUserNotes} />
              </td>
              <td>
                <Status status={task.isDone} labelTrue="Ja" labelFalse="Nein" />
              </td>
              <td>{getFormattedDateOrEmpty(task.lastUpdatedAt, 'datetime')}</td>
              {onTaskDelete !== undefined && user.isAdmin && (
                <td>
                  <IconDelete onClick={() => onTaskDelete(task)} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
