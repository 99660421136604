import { Table } from 'react-bootstrap';

import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import { UserSession } from '../../types/usersessions';
import Status from '../../../../libs/components/data/Status';
import { IconDelete } from '../../../../libs/components/data/Icon';
import TableHeader from '../../../../libs/components/data/table/TableHeader';

interface UserSessionsTableProps {
  sessions: UserSession[];
  onSessionDelete: (session: UserSession) => void;
}

const TABLE_COLUMNS = [
  { key: 'createdAt', label: 'Angelegt am', width: 140 },
  { key: 'deactivatedAt', label: 'Beendet am', width: 140 },
  { key: 'lastLoginAt', label: 'Zulezt aktiv am', width: 150 },
  { key: 'active', label: 'Status' },
  { key: 'name', label: 'Benutzer' },
  { key: 'ipDuringLogin', label: 'IP' },
  { key: 'userAgentDuringLogin', label: 'User Agent' },
  { key: 'lastActionApiUrl', label: 'Letzte Aktion' },
];

export default function SessionsTable({ sessions, onSessionDelete }: UserSessionsTableProps) {
  return (
    <Table striped bordered className="shadow-sm">
      <TableHeader columns={TABLE_COLUMNS} actionColumn={true} />
      <tbody>
        {sessions.map(item => (
          <tr key={item.id}>
            <td>{getFormattedDateOrEmpty(item.createdAt, 'datetime')}</td>
            <td>{getFormattedDateOrEmpty(item.deactivatedAt)}</td>
            <td>{getFormattedDateOrEmpty(item.lastActionAt, 'datetime')}</td>
            <td>
              <Status
                status={item.active}
                labelTrue="aktiv"
                labelFalse="beendet"
                colorTrue="success"
                colorFalse="warning"
              />
            </td>
            <td>{item.user.name}</td>
            <td>{item.ipDuringLogin}</td>
            <td>{item.userAgentDuringLogin.substring(0, 50)}...</td>
            <td>{item.lastActionApiUrl}</td>
            <td>
              <IconDelete onClick={() => onSessionDelete(item)} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
