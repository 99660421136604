import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import { PhoneCall } from '../../types/phone_calls';
import {
  addPhoneCallNote,
  getOtherPhoneCallsForNumber,
  getPhoneCall,
  markPhoneCallAsDone,
} from '../../services/api';
import Status from '../../../../libs/components/data/Status';
import { formatDate } from '../../../../libs/services/date';
import { Icon } from '../../../../libs/components/data/Icon';
import { phoneCallTypes } from '../../config/phone_calls';
import TableHeader from '../../../../libs/components/data/table/TableHeader';
import { getZoneAdjustedDate } from '../../../../libs/services/time';

const TABLE_COLUMNS = [
  { key: 'callType', label: 'Art', width: 55 },
  { key: 'callDate', label: 'Datum', width: 150 },
  { key: 'status', label: 'Status', width: 125 },
  { key: 'assignedUser', label: 'Mitarbeiter' },
];

const TABLE_COLUMNS_HISTORY = [
  { key: 'callType', label: 'Art', width: 55 },
  { key: 'callDate', label: 'Datum', width: 150 },
  { key: 'status', label: 'Status', width: 125 },
  { key: 'notes', label: 'Notizen' },
];

export default function PhoneCallDetails() {
  const { id } = useParams();
  const [phoneCall, setPhoneCall] = useState<PhoneCall>();
  const [otherPhoneCalls, setOtherPhoneCalls] = useState<PhoneCall[]>([]);
  const [newNote, setNewNote] = useState<string>('');
  const [newNoteType, setNewNoteType] = useState<string>('Eingehender Anruf');
  const [newNoteResubmission, setNewNoteResubmission] = useState<string | undefined>();

  useEffect(() => {
    loadPhoneCall(id!);
  }, [id]);

  function loadPhoneCall(id: string) {
    getPhoneCall(id).then(data => {
      setPhoneCall(data);
      getOtherPhoneCallsForNumber(data.phoneNumber, data.id).then(data => setOtherPhoneCalls(data));
    });
  }

  function onNotesChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setNewNote(e.target.value);
  }

  function onNotesTypeSelect(e: ChangeEvent<HTMLSelectElement>) {
    setNewNoteType(e.target.value);
  }

  function onNotesResubmissionChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setNewNoteResubmission(e.target.value);
  }

  function saveNote(done?: boolean) {
    addPhoneCallNote(id!, newNote, newNoteType, newNoteResubmission, done).then(() => {
      loadPhoneCall(id!);
    });
    setNewNote('');
  }

  async function markAsDone() {
    await markPhoneCallAsDone(id!);
    loadPhoneCall(id!);
  }

  if (!phoneCall) {
    return null;
  }

  return (
    <>
      <PageToolbar pageTitle="Telefongespräch">
        {phoneCall && phoneCall.status !== 'erledigt' && (
          <Button onClick={markAsDone} variant="success" type="button" className="me-4">
            Gespräch und alle Notizen Als erledigt markieren
          </Button>
        )}
        <ButtonLink link="/phone-calls" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      <Row>
        <Col lg={5}>
          <SectionTitle title="Details" />
          <Table striped bordered className="shadow-sm">
            <tbody>
              <tr>
                <td style={{ width: 200 }}>
                  <strong>Angelegt am/von</strong>
                </td>
                <td>
                  {formatDate(phoneCall.callDate, 'date')} {phoneCall.callTime},{' '}
                  {phoneCall.createdBy.name}
                </td>
              </tr>
              <tr>
                <td style={{ width: 250 }}>
                  <strong>Telefonnummer</strong>
                </td>
                <td>{phoneCall.phoneNumber}</td>
              </tr>
              <tr>
                <td>
                  <strong>Art</strong>
                </td>
                <td>{phoneCall.callType}</td>
              </tr>
              <tr>
                <td>
                  <strong>Anruf Typ</strong>
                </td>
                <td>{phoneCall.callFormat}</td>
              </tr>
              <tr>
                <td>
                  <strong>Kategorie</strong>
                </td>
                <td>
                  {phoneCall.category}
                  {phoneCall.category === 'Sonstige' && <div>{phoneCall.categoryOther}</div>}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Status</strong>
                </td>
                <td>
                  <Status
                    status={phoneCall.status === 'erledigt'}
                    labelTrue="Erledigt"
                    labelFalse="In Bearbeitung"
                    colorTrue="success"
                    colorFalse="warning"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Anrufer</strong>
                </td>
                <td>
                  {phoneCall.callerSalutation} {phoneCall.caller}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Kennzeichen</strong>
                </td>
                <td>{phoneCall.plateNumber}</td>
              </tr>
              <tr>
                <td>
                  <strong>Mitarbeiter</strong>
                </td>
                <td>{phoneCall.assignedUser?.name}</td>
              </tr>
              <tr>
                <td>
                  <strong>Wiedervorlage</strong>
                </td>
                <td>{formatDate(phoneCall.resubmission, 'date')}</td>
              </tr>
              <tr>
                <td>
                  <strong>Mitarbeiter - Wiedervorlage</strong>
                </td>
                <td>{phoneCall.resubmissionUser?.name}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col lg={7}>
          <SectionTitle title="Notizen" />
          <div className="mb-4">
            <div className="mb-4">
              <strong>Neue Notiz</strong>
            </div>
            <div className="mb-2">
              <div>
                <strong>Status</strong>
              </div>
              <Form.Select style={{ width: 200 }} value={newNoteType} onChange={onNotesTypeSelect}>
                {phoneCallTypes.map(item => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="mb-2">
              <div>
                <strong>Wiedervorlage</strong>
              </div>
              <div>
                <Form.Control
                  style={{ width: 200 }}
                  type="date"
                  value={newNoteResubmission}
                  onChange={onNotesResubmissionChange}
                />
              </div>
            </div>
            <div>
              <strong>Inhalt</strong>
            </div>
            <div className="mb-2">
              <Form.Control as="textarea" rows={2} value={newNote} onChange={onNotesChange} />
            </div>
            <div className="text-end">
              <Button variant="secondary" size="sm" onClick={() => saveNote()}>
                Notiz speichern
              </Button>
              <span className="me-4"></span>
              <Button variant="secondary" size="sm" onClick={() => saveNote(true)}>
                Notiz speichern und erledigen
              </Button>
            </div>
          </div>
          <Table striped bordered className="shadow-sm">
            <tbody>
              {phoneCall.notes
                .sort((a, b) => {
                  if (a.createdAt > b.createdAt) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map(note => (
                  <tr key={note.id}>
                    <td>
                      <div className="d-flex justify-content-between">
                        <div className="text-dark small">
                          <Icon
                            color={note.type === 'Eingehender Anruf' ? 'primary' : 'warning'}
                            icon={
                              note.type === 'Eingehender Anruf'
                                ? 'telephone-inbound-fill'
                                : 'telephone-outbound-fill'
                            }
                          />
                          <span className="me-2"></span>
                          {formatDate(
                            getZoneAdjustedDate(note.createdAt).toISOString(),
                            'datetime',
                          )}{' '}
                          <span className="px-1"></span> {note.createdBy?.name}
                        </div>
                        <div>
                          <Status
                            status={note.status === 'erledigt'}
                            labelTrue="Erledigt"
                            labelFalse="In Bearbeitung"
                            colorTrue="success"
                            colorFalse="warning"
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>{note.content}</div>
                        {note.status !== 'erledigt' && (
                          <div className="mt-3">
                            <Button variant="secondary" size="sm" onClick={() => saveNote(true)}>
                              erledigen
                            </Button>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <SectionTitle title={`Frühere Gespräche mit ${phoneCall.phoneNumber}`} />
          <Table striped bordered className="shadow-sm">
            <TableHeader columns={TABLE_COLUMNS_HISTORY} actionColumn={false} />
            <tbody>
              {otherPhoneCalls
                .filter(call => call.id !== phoneCall.id)
                .map(item => (
                  <tr key={item.id}>
                    <td className="text-center align-middle" style={{ width: 50 }}>
                      <Icon
                        color={item.callType === 'Eingehender Anruf' ? 'primary' : 'warning'}
                        icon={
                          item.callType === 'Eingehender Anruf'
                            ? 'telephone-inbound-fill'
                            : 'telephone-outbound-fill'
                        }
                      />
                    </td>
                    <td style={{ width: 125 }}>
                      {formatDate(item.callDate, 'date')} {item.callTime}
                      <br />
                      {item.createdBy.name}
                    </td>
                    <td className="text-center align-middle" style={{ width: 50 }}>
                      <Status
                        status={item.status === 'erledigt'}
                        labelTrue="Erledigt"
                        labelFalse="In Bearbeitung"
                        colorTrue="success"
                        colorFalse="warning"
                      />
                    </td>
                    <td className="align-middle">
                      {item.notes
                        .sort((a, b) => {
                          if (a.createdAt > b.createdAt) {
                            return -1;
                          } else {
                            return 1;
                          }
                        })
                        .map(note => (
                          <div key={note.id} className="mb-4">
                            <div className="text-dark small">
                              {formatDate(
                                getZoneAdjustedDate(phoneCall.createdAt).toISOString(),
                                'datetime',
                              )}{' '}
                              <span className="px-1"></span> {note.createdBy?.name}
                            </div>
                            <div>
                              <Icon
                                color={note.type === 'Eingehender Anruf' ? 'primary' : 'warning'}
                                icon={
                                  note.type === 'Eingehender Anruf'
                                    ? 'telephone-inbound-fill'
                                    : 'telephone-outbound-fill'
                                }
                              />
                              <span className="me-2"></span>
                              <Status
                                status={note.status === 'erledigt'}
                                labelTrue="Erledigt"
                                labelFalse="In Bearbeitung"
                                colorTrue="success"
                                colorFalse="warning"
                              />
                            </div>
                            <div>{note.content}</div>
                          </div>
                        ))}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
