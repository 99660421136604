import { Link } from 'react-router-dom';

import { Brand } from '../../types/brands';
import { IconEdit } from '../../../../libs/components/data/Icon';

interface BrandTypeListProps {
  brand: Brand;
}

export default function BrandTypeList({ brand }: BrandTypeListProps) {
  return (
    <>
      {brand.types.sort().map(model => (
        <div key={model.id}>
          {model.name}{' '}
          <Link to={`/brands/${brand.id}/edit-type/${model.id}`} className="text-info">
            <IconEdit />
          </Link>
        </div>
      ))}
    </>
  );
}
