import { Button, Card, Form, Stack } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { VehicleCommissionPhotoForm } from '../types/vehicle_commissions';
import { v4 } from 'uuid';
import { SectionTitle } from '../../../libs/components/data/SectionTitle';
import { IconDelete } from '../../../libs/components/data/Icon';

interface PhotoFormProps {
  label?: string;
  photos: VehicleCommissionPhotoForm[];
  onCapture: (photo: VehicleCommissionPhotoForm) => void;
  onDelete: (id: string) => void;
  onTagsEdit: (id: string, content: string) => void;
  type: 'document' | 'other';
}

export default function PhotoForm({
  label = 'Bilder',
  photos,
  onCapture,
  onDelete,
  onTagsEdit,
  type,
}: PhotoFormProps) {
  const ref = useRef<Webcam>(null);
  const [enableCam, setEnableCam] = useState<boolean>(false);

  const capture = () => {
    if (!ref || !ref.current) {
      return;
    }
    const imageSrc = ref.current.getScreenshot();
    if (imageSrc === null) {
      return;
    }
    onCapture({
      id: v4(),
      content: imageSrc,
      tags: '',
      type: type,
    });
  };

  return (
    <div className="mb-4 mt-4">
      {label && <SectionTitle title={label} />}
      <Card>
        <Card.Body>
          <div className="mb-2">
            <Button variant="light" onClick={() => setEnableCam(!enableCam)}>
              Camera {!enableCam ? 'einschalten' : 'ausschalten'}
            </Button>
          </div>
          {enableCam && (
            <>
              <div>
                <Webcam
                  ref={ref}
                  screenshotFormat="image/jpeg"
                  screenshotQuality={1}
                  minScreenshotWidth={2048}
                />
              </div>
              <Button variant="info" onClick={capture}>
                <i className="bi bi-camera-fill"></i>
              </Button>
            </>
          )}
          {photos.length > 0 && (
            <div className="mt-4">
              <Stack direction="horizontal" gap={3}>
                {photos.map(photo => (
                  <Card className="me-3 bg-light" key={`photo-${photo.id}`}>
                    <Card.Body className="p-2">
                      <div className="mb-3">
                        <img src={photo.content} width={175} alt={photo.id} />
                      </div>
                      <div className="mb-2">
                        <Form.Control
                          size="sm"
                          className="d-inline"
                          style={{ width: 175 }}
                          value={photo.tags}
                          onChange={e => onTagsEdit(photo.id, e.target.value)}
                        />
                      </div>
                      <div>
                        <IconDelete onClick={() => onDelete(photo.id)} />
                      </div>
                    </Card.Body>
                  </Card>
                ))}
              </Stack>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
