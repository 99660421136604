import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { formatDate } from '../../../../libs/services/date';
import { WorkTimeInProgress } from '../../../work_time/types/workTime';
import { getWorkTimeInProgress } from '../../services/api';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import { IconDetails } from '../../../../libs/components/data/Icon';

export default function WorkingTimeInProgressList() {
  const [items, setItems] = useState<WorkTimeInProgress[]>([]);

  useEffect(() => {
    load();
  }, []);

  function load() {
    getWorkTimeInProgress().then(data => setItems(data));
  }

  return (
    <>
      <SectionTitle title="Laufende Arbeitszeiten" />
      <Table striped bordered className="shadow-sm">
        <thead>
          <tr>
            <th style={{ width: 150 }}>Begonnen am</th>
            <th>Mitarbeiter</th>
            <th>Kennzeichen</th>
            <th>Marke</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={`jobs-in-progress-${item.jobId}`}>
              <td>{formatDate(item.startDate, 'datetime')}</td>
              <td>{item.user.name}</td>
              <td>{item.vehicle?.plate ?? '---'}</td>
              <td>
                {item.vehicle !== null && (
                  <>
                    {item.vehicle.brandName} {item.vehicle.brandTypeName}
                  </>
                )}
              </td>
              <td>
                <Link to={`/jobs/${item.jobId}`} className="text-info">
                  <IconDetails />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
