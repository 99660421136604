import { API } from '../../../libs/services/api';
import { JobCategory, JobCategoryFormData, JobSubCategoryFormData } from '../types/jobCategories';

const BASE = 'jobCategories';

export async function getJobCategories(): Promise<JobCategory[]> {
  return (await API.get(BASE)).data;
}

export async function getJobCategory(id: string): Promise<JobCategory> {
  return (await API.get(`${BASE}/${id}`)).data;
}

export async function addJobCategory(data: JobCategoryFormData) {
  const payload = {
    name: data.name,
    sortOrder: Number(data.sortOrder),
  };
  return (await API.post(BASE, payload)).data;
}

export async function updateJobCategory(id: string, data: JobCategoryFormData) {
  const payload = {
    name: data.name,
    sortOrder: Number(data.sortOrder),
  };
  return (await API.patch(`${BASE}/${id}`, payload)).data;
}

export async function deleteJobCategory(id: string) {
  return (await API.delete(`${BASE}/${id}`)).data;
}

export async function addJobSubCategory(categoryId: string, data: JobSubCategoryFormData) {
  const payload = {
    name: data.name,
    defaultDescription: data.defaultDescription,
    sortOrder: data.sortOrder,
  };
  return (await API.post(`${BASE}/${categoryId}/subCategories`, payload)).data;
}

export async function updateJobSubCategory(
  categoryId: string,
  subCategoryId: string,
  data: JobSubCategoryFormData,
) {
  const payload = {
    name: data.name,
    defaultDescription: data.defaultDescription,
    sortOrder: Number(data.sortOrder),
  };
  return (await API.patch(`${BASE}/${categoryId}/subCategories/${subCategoryId}`, payload)).data;
}
