import { Button, Form, Modal } from 'react-bootstrap';
import * as React from 'react';
import { useState } from 'react';

import ErrorList from '../../../libs/components/data/ErrorList';

interface VehiclePartInfoEditModalProps {
  content: string;
  show: boolean;
  onSaved: (content: string) => void;
  onCancel: () => void;
}

export default function VehiclePartInfoEditModal(props: VehiclePartInfoEditModalProps) {
  const [content, setContent] = useState<string>(props.content);
  const [errorResponse, setErrorResponse] = useState<unknown>();

  async function onSubmit() {
    try {
      props.onSaved(content);
    } catch (err) {
      setErrorResponse(err);
    }
  }

  function onCancel() {
    props.onCancel();
  }

  function onChangeContent(event: React.ChangeEvent<HTMLInputElement>) {
    setContent(event.target.value);
  }

  if (content === undefined) {
    return null;
  }

  return (
    <Modal show={props.show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Anmerkungen bearbeiten</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Form.Group>
            <Form.Control as="textarea" rows={10} onChange={onChangeContent} value={content} />
          </Form.Group>
          {errorResponse && <ErrorList errorResponse={errorResponse} />}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Abbrechen
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
