import React from 'react';

import { JobNote } from '../../../types/jobs';
import { getFormattedDateOrEmpty } from '../../../../../libs/services/date';

interface JobNotesListProps {
  notes: JobNote[];
}

export default function JobNotesList({ notes }: JobNotesListProps) {
  return (
    <>
      {notes.map(note => (
        <div key={note.id} className="mb-2">
          <div className="text-muted small">
            {getFormattedDateOrEmpty(note.createdAt, 'datetime')} <span className="px-2">|</span>{' '}
            {note.createdBy.name}
          </div>
          <div style={{ whiteSpace: 'pre-wrap' }}>{note.content}</div>
        </div>
      ))}
    </>
  );
}
