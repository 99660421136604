import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Carousel, Col, Modal, Row } from 'react-bootstrap';
import { formatDate } from '../../../../libs/services/date';
import { useAuth } from '../../../../libs/context/AuthContext';
import { StorageArticlePhoto } from '../../types/storage';

interface PhotoGalleryProps {
  title?: string | JSX.Element;
  photos: StorageArticlePhoto[];
  selectedPhotoIndex: number;
  show: boolean;
  onClose: () => void;
}

export default function StoragePhotoGallery({
  title,
  photos,
  selectedPhotoIndex,
  show,
  onClose,
}: PhotoGalleryProps) {
  const { isAdminOrOffice } = useAuth();
  const [index, setIndex] = useState<number>(selectedPhotoIndex);
  const [photo, setPhoto] = useState<StorageArticlePhoto | undefined>(
    photos.find((_, i) => i === selectedPhotoIndex),
  );

  useEffect(() => {
    setIndex(selectedPhotoIndex);
    setPhoto(photos.find((_, i) => i === selectedPhotoIndex));
  }, [selectedPhotoIndex, photos]);

  const handleSelect = (i: number) => {
    setIndex(i);
    setPhoto(photos.find((_, idx) => i === idx));
  };

  if (photos.length === 0) {
    return null;
  }

  return (
    <Modal show={show} onHide={onClose} size="lg">
      {title !== undefined && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <Carousel activeIndex={index} onSelect={handleSelect} fade>
          {photos.map(item => (
            <Carousel.Item key={item.id}>
              <img
                className="d-block w-100"
                src={`/files/storage/${item.storageArticleId}/${item.fileName}`}
                alt="First slide"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
      {photo !== undefined && (
        <Modal.Footer className="d-block">
          <Row>
            <Col lg={10} className="text-muted">
              <div>
                Angelegt am {formatDate(photo!.createdAt, 'date')} von {photo!.createdBy.name}
              </div>
              {isAdminOrOffice() && <div>Tags: {photo!.tags.join(', ')}</div>}
            </Col>

            <Col lg={2} className="text-end">
              <Button variant="primary" onClick={onClose}>
                Schließen
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      )}
    </Modal>
  );
}
