import { Button, Col, Container, Navbar, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmModal from '../../libs/components/modals/ConfirmModal';
import ButtonLink from '../../libs/components/actions/ButtonLink';
import { SystemInfo } from '../../modules/system_status/types/info';
import { getSystemInfo } from '../../modules/system_status/services/api';
import Menu from '../Menu/Menu';
import { useAuth } from '../../libs/context/AuthContext';
import { IconWEMA } from '../../libs/components/data/Icon';
import UserInfo from './UserInfo';
import EnvInfo from './EnvInfo';

export default function Header() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [systemInfo, setSystemInfo] = useState<SystemInfo>();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    getSystemInfo().then(data => setSystemInfo(data));
  }, []);

  async function onConfirmLogout() {
    await logout();
    navigate('/login');
  }

  if (!systemInfo || !user) {
    return null;
  }

  return (
    <>
      <ConfirmModal
        show={confirmModal}
        title="Abmelden"
        onConfirm={onConfirmLogout}
        onCancel={() => setConfirmModal(false)}>
        Möchten Sie sich wirklich abmelden?
      </ConfirmModal>
      <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
        <Container fluid>
          <Row className="py-2 text-white d-flex align-items-center w-100">
            <Col lg={2} xs={6}>
              <div className="fs-5 m-0">
                <IconWEMA />
                <span className="ps-2 d-none d-lg-inline">Werkstatt Manager</span>
              </div>
            </Col>
            <Col lg={user.isAdmin ? 7 : 2} className="d-none d-lg-block">
              <UserInfo userName={user.name} />
            </Col>
            {!user.isAdmin && (
              <Col lg={5} className="d-none d-lg-block">
                <ButtonLink link="/my-jobs" buttonColor="secondary">
                  Meine Aufgaben
                </ButtonLink>
              </Col>
            )}
            <Col lg={1} className="text-end d-none d-lg-block">
              <EnvInfo systemInfo={systemInfo} />
            </Col>
            <Col lg={2} xs={6} className="text-end">
              <Button
                className="ms-5 d-none d-lg-inline"
                variant="danger"
                onClick={() => setConfirmModal(true)}>
                <i className="bi bi-box-arrow-rig ht me-2"></i> Abmelden
              </Button>
              <span className="d-inline d-lg-none fs-5">
                <i
                  className="bi bi-box-arrow-right"
                  onClick={() => setConfirmModal(true)}
                  style={{ cursor: 'pointer' }}
                />
              </span>
              <span className="d-inline d-lg-none fs-4 ms-5">
                <i
                  className="bi bi-list"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setMobileMenuOpen(!mobileMenuOpen);
                  }}
                />
              </span>
            </Col>
          </Row>
        </Container>
      </Navbar>
      {mobileMenuOpen && (
        <div style={{ marginTop: 75 }}>
          <Menu onModuleSelect={() => setMobileMenuOpen(!mobileMenuOpen)} />
        </div>
      )}
    </>
  );
}
