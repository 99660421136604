import { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';

import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import { getBrand, updateBrand } from '../../services/api';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import Message from '../../../../libs/components/data/Message';
import ApiErrorMessage from '../../../../libs/components/data/ApiErrorMessage';
import {
  FormActionState,
  getFormActionReducer,
} from '../../../../libs/services/state/formActionReducer';
import { Brand, BrandFormData } from '../../types/brands';
import { BrandForm } from './BrandForm';

const initialState: FormActionState = {
  actionInProgress: false,
  actionSuccess: false,
  actionError: false,
  showForm: true,
  actionErrorResponse: undefined,
};

export default function BrandEdit() {
  const { id } = useParams();
  const [brand, setBrand] = useState<Brand>();
  const [formData, setFormData] = useState<BrandFormData>();
  const [state, dispatch] = useReducer(getFormActionReducer(initialState), initialState);

  useEffect(() => {
    if (!id) {
      return;
    }
    getBrand(id).then(data => {
      setBrand(data);
      setFormData({
        name: data.name,
      });
    });
  }, [id]);

  async function onSubmit(data: BrandFormData) {
    if (!brand) {
      return;
    }

    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      await updateBrand(brand.id, data);
      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    }
  }

  if (!brand || !formData) {
    return null;
  }

  return (
    <>
      <PageToolbar pageTitle={`Automarke bearbeiten: ${brand.name}`}>
        <ButtonLink link="/brands" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      {state.showForm && (
        <div className="mb-4">
          <BrandForm
            submitButtonLabel="Automarke bearbeiten"
            onSubmit={onSubmit}
            initialValues={formData}
          />
        </div>
      )}
      {state.actionError && (
        <ApiErrorMessage
          label="Automarke konnte nicht aktualisiert werden"
          errorResponse={state.actionErrorResponse}
        />
      )}
      {state.actionSuccess && (
        <Message type="ok">
          <span>
            <strong>Automarke wurde aktualisiert</strong>
          </span>
        </Message>
      )}
    </>
  );
}
