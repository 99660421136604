import { Vehicle } from '../../../vehicles/types/vehicles';
import { Job } from '../../types/jobs';
import { Col, Row, Table } from 'react-bootstrap';
import FormInput from '../../../../libs/components/forms/FormInput';
import { formatDate } from '../../../../libs/services/date';
import VehicleInspectionDetails from '../../../vehicles/components/VehicleDetails/VehicleInspectionDetails';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';

interface MileageFormProps {
  selectedVehicle: Vehicle;
  latestJob: Job;
}

export default function MileageForm({ selectedVehicle, latestJob }: MileageFormProps) {
  return (
    <>
      <Row>
        <Col lg={5}>
          <SectionTitle title="Fahrzeugdaten" />
          <Table striped bordered className="shadow-sm">
            <tbody>
              <tr>
                <td style={{ width: 175 }}>
                  <strong>Kennzeichen</strong>
                </td>
                <td>{selectedVehicle.plate}</td>
              </tr>
              <tr>
                <td>
                  <strong>Marke</strong>
                </td>
                <td>
                  {selectedVehicle.brandName} {selectedVehicle.brandTypeName}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Erstzulassung</strong>
                </td>
                <td>{selectedVehicle.registrationDate}</td>
              </tr>
              <tr>
                <td>
                  <strong>FIN</strong>
                </td>
                <td>{selectedVehicle.fin}</td>
              </tr>
              <tr>
                <td>
                  <strong>HSN</strong>
                </td>
                <td>{selectedVehicle.hsn}</td>
              </tr>
              <tr>
                <td>
                  <strong>TSN</strong>
                </td>
                <td>{selectedVehicle.tsn}</td>
              </tr>
              <tr>
                <td>
                  <strong>kW / PS</strong>
                </td>
                <td>
                  {selectedVehicle.kw} / {selectedVehicle.ps}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col lg={5}>
          <SectionTitle title="Letzte Arbeitskarte" />
          <Table striped bordered className="shadow-sm">
            <tbody>
              <tr>
                <td style={{ width: 200 }}>
                  <strong>Angelegt am</strong>
                </td>
                <td>{formatDate(latestJob.createdAt, 'date')}</td>
              </tr>
              <tr>
                <td>
                  <strong>KM-Stand</strong>
                </td>
                <td>{latestJob.mileage}</td>
              </tr>
            </tbody>
          </Table>
          <VehicleInspectionDetails vehicle={selectedVehicle} />
        </Col>
      </Row>
      <Row>
        <Col lg={5}>
          <div className="mb-2">
            <strong>KM-Stand muss höher sein als in der letzen Arbeitskarte</strong>
          </div>
          <FormInput name="mileage" label="KM-Stand" type="text" required={true} />
        </Col>
      </Row>
    </>
  );
}
