import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';

import { JobCategory } from '../../../job_categories/types/jobCategories';
import { JobTask } from './JobForm';
import { ChangeEvent } from 'react';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import { IconDelete } from '../../../../libs/components/data/Icon';

interface JobTasksFormProps {
  title?: string;
  jobCategories: JobCategory[];
  tasks: JobTask[];
  onAddTask: () => void;
  onDeleteTask: (id: string) => void;
  onTaskCategorySelect: (id: string, categoryId: string) => void;
  onTaskItemEdit: (
    taskId: string,
    taskSubcategory: string,
    field: 'enabled' | 'description',
    value: string | boolean,
  ) => void;
  reloadCategories: () => void;
}

export default function JobTasks({
  title = 'Aufgaben',
  jobCategories,
  tasks,
  onAddTask,
  onTaskItemEdit,
  onDeleteTask,
  onTaskCategorySelect,
  reloadCategories,
}: JobTasksFormProps) {
  function editTask(id: string, subCategoryId: string, field: 'description' | 'enabled') {
    return function (e: ChangeEvent<HTMLInputElement>) {
      const value = field === 'enabled' ? e.target.checked : e.target.value;
      onTaskItemEdit(id, subCategoryId, field, value);
    };
  }

  return (
    <div>
      <SectionTitle title={title} />
      <div className="mb-4">
        <Button variant="light" onClick={onAddTask}>
          <i className="bi bi-plus-circle-fill pe-2"></i>
          Aufgabe hinzufügen
        </Button>
      </div>
      {tasks.map(task => (
        <Card key={`task-${task.id}`} className="mb-2">
          <Card.Body>
            <Row className="mb-3">
              <Col lg={6}>
                <Row>
                  <Col lg={10}>
                    <Form.Select
                      onChange={e => onTaskCategorySelect(task.id, e.currentTarget.value)}>
                      <option value="">--- Kategorie auswählen ---</option>
                      {jobCategories.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col lg={2} className="text-end">
                    <Button size="sm" variant="light" onClick={reloadCategories}>
                      <i className="bi bi-arrow-clockwise"></i>
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col className="text-end">
                <IconDelete onClick={() => onDeleteTask(task.id)} />
              </Col>
            </Row>
            {task.categoryId && (
              <Table striped bordered hover className="shadow-sm">
                <thead>
                  <tr>
                    <th>Unterkategorie</th>
                    <th style={{ width: 50 }}>Aktiv</th>
                    <th>Beschreibung</th>
                  </tr>
                </thead>
                <tbody>
                  {task.items.map(taskItem => (
                    <tr key={`task-${task.categoryId}-sub-${taskItem.id}`}>
                      <td>{taskItem.subCategoryName}</td>
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={taskItem.enabled}
                          onChange={editTask(task.id, taskItem.subCategoryId, 'enabled')}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          size="sm"
                          value={taskItem.description}
                          onChange={editTask(task.id, taskItem.subCategoryId, 'description')}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      ))}
      {tasks.length > 0 && (
        <div className="my-4">
          <Button variant="light" onClick={onAddTask}>
            <i className="bi bi-plus-circle-fill pe-2"></i>
            Aufgabe hinzufügen
          </Button>
        </div>
      )}
    </div>
  );
}
