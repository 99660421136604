import { Button, Col, Modal, Row } from 'react-bootstrap';
import * as React from 'react';
import { useState } from 'react';

import { WorkTimeInProgress, WorkTimeItemForm } from '../../types/workTime';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { getValidationSchema } from '../../config/validation';
import FormInput from '../../../../libs/components/forms/FormInput';
import { updateWorkTimeItem } from '../../services/api';
import ErrorList from '../../../../libs/components/data/ErrorList';

interface EditWorkTimeModalProps {
  workTime: WorkTimeInProgress;
  show: boolean;
  onSaved: () => void;
  onCancel: () => void;
}

export default function EditWorkTimeModal(props: EditWorkTimeModalProps) {
  const [errorResponse, setErrorResponse] = useState<unknown>();
  const methods = useForm<WorkTimeItemForm>({
    mode: 'all',
    resolver: yupResolver(getValidationSchema(props.workTime)),
    defaultValues: {
      fromDate: props.workTime.startDate.substring(0, 10),
      fromTime: props.workTime.startDate.substring(11, 16),
      toDate: props.workTime.endDate !== null ? props.workTime.endDate.substring(0, 10) : '',
      toTime: props.workTime.endDate !== null ? props.workTime.endDate.substring(11, 16) : '',
    },
  });

  async function onSubmit(data: WorkTimeItemForm) {
    try {
      const fromDate = (data.fromDate as any as Date)
        .toLocaleString('sv')
        .replace(' ', 'T')
        .substring(0, 10);
      const toDate = (data.toDate as any as Date)
        .toLocaleString('sv')
        .replace(' ', 'T')
        .substring(0, 10);
      await updateWorkTimeItem(props.workTime.jobId, props.workTime.id, {
        fromDate: fromDate,
        fromTime: data.fromTime,
        toDate: toDate,
        toTime: data.toTime,
      });
      props.onSaved();
      resetFields();
    } catch (err) {
      setErrorResponse(err);
    }
  }

  function resetFields() {
    methods.reset();
  }

  function onCancel() {
    resetFields();
    props.onCancel();
  }

  return (
    <FormProvider {...methods}>
      <Modal show={props.show} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Arbeitszeit bearbeiten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row>
              <Col>
                <FormInput name="fromDate" label="Arbeiszeit von" type="date" required={true} />
              </Col>
              <Col>
                <FormInput name="fromTime" label="Arbeiszeit von" type="time" required={true} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInput
                  name="toDate"
                  label="Arbeiszeit bis"
                  type="date"
                  disabled={props.workTime.endDate === null}
                  required={props.workTime.endDate !== null}
                />
              </Col>
              <Col>
                <FormInput
                  name="toTime"
                  label="Arbeiszeit bis"
                  type="time"
                  disabled={props.workTime.endDate === null}
                  required={props.workTime.endDate !== null}
                />
              </Col>
            </Row>
            {errorResponse && <ErrorList errorResponse={errorResponse} />}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={methods.handleSubmit(onSubmit)}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
}
