import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LogEntry } from '../../types/logs';
import { Badge, Table } from 'react-bootstrap';
import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import { getLog } from '../../services/api';
import { getActionColor } from '../../services/logs';

export default function LogDetails() {
  const { id } = useParams();
  const [log, setLog] = useState<LogEntry>();

  useEffect(() => {
    getLog(id!).then(data => setLog(data));
  }, [id]);

  if (!log) {
    return null;
  }

  return (
    <>
      <PageToolbar pageTitle="Log Details">
        <span className="px-2"></span>
        <ButtonLink link="/logs" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      <Table striped bordered className="shadow-sm">
        <tbody>
          <tr>
            <td style={{ width: 150 }}>
              <strong>ID</strong>
            </td>
            <td>{log.id}</td>
          </tr>
          <tr>
            <td>
              <strong>Angelegt am</strong>
            </td>
            <td>{getFormattedDateOrEmpty(log.createdAt, 'datetimesec')}</td>
          </tr>
          <tr>
            <td>
              <strong>Modul</strong>
            </td>
            <td>{log.appModule}</td>
          </tr>
          <tr>
            <td>
              <strong>Aktion</strong>
            </td>
            <td>
              <Badge bg={getActionColor(log.action)}>{log.action}</Badge>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Benutzer</strong>
            </td>
            <td>{log.authUser ? log.authUser.name : '---'}</td>
          </tr>
          <tr>
            <td>
              <strong>Debug</strong>
            </td>
            <td>
              <textarea rows={25} style={{ width: '100%' }}>
                {JSON.stringify(log.content)}
              </textarea>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
