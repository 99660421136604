import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getArticle } from '../services/api';
import { Table } from 'react-bootstrap';
import { StorageArticle } from '../types/storage';
import { QRCodeSVG } from 'qrcode.react';
import { formatDate } from '../../../libs/services/date';

export default function StorageArticlePrint() {
  const { id } = useParams();
  const [article, setArticle] = useState<StorageArticle>();

  useEffect(() => {
    loadArticle();
  }, [id]);

  function loadArticle() {
    if (!id) {
      return;
    }
    getArticle(id).then(data => {
      setArticle(data);
    });
  }

  useEffect(() => {
    const printTimeout = setTimeout(() => {
      window.print();
    }, 1000);

    return () => {
      clearTimeout(printTimeout);
    };
  });

  if (!article) {
    return null;
  }

  return (
    <div className="m-4">
      <Table striped bordered className="shadow-sm" style={{ width: 300 }}>
        <tbody>
          <tr>
            <td>
              <strong>Kategorie</strong>
            </td>
            <td>
              {article.category}
              {article.subCategoryLevel1 !== null && <span> / {article.subCategoryLevel1}</span>}
              {article.subCategoryLevel2 !== null && <span> / {article.subCategoryLevel2}</span>}
              {article.subCategoryLevel3 !== null && <span> / {article.subCategoryLevel3}</span>}
              {article.subCategoryLevel4 !== null && (
                <span> / {article.subCategoryLevel4}</span>
              )}{' '}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Hersteller</strong>
            </td>
            <td>{article.manufacturer}</td>
          </tr>
          <tr>
            <td>
              <strong>Lieferant</strong>
            </td>
            <td>{article.supplier}</td>
          </tr>
          <tr>
            <td>
              <strong>Bestelldatum</strong>
            </td>
            <td>{formatDate(article.orderDate, 'date')}</td>
          </tr>
        </tbody>
      </Table>
      <QRCodeSVG size={300} value={window.location.href} />
    </div>
  );
}
