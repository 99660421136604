type DateFormat = 'date' | 'datetime' | 'datetimesec';

export function formatDate(value: string, format: DateFormat) {
  if (!value) {
    return '';
  }

  switch (format) {
    case 'date':
      return localizeDate(value);
    case 'datetime':
      return localizeDateTime(value);
    case 'datetimesec':
      return localizeDateTimeSec(value);
  }
}

export function getFormattedDateOrEmpty(
  value: string | null,
  format: DateFormat = 'date',
  emptyLabel: string = '---',
): string {
  if (!value || value === '') {
    return emptyLabel;
  }
  return formatDate(value, format);
}

function localizeDate(isoDate: string) {
  return `${isoDate.substring(8, 10)}.${isoDate.substring(5, 7)}.${isoDate.substring(0, 4)}`;
}

function localizeDateTime(isoDate: string) {
  return `${localizeDate(isoDate)} ${isoDate.substring(11, 16)}`;
}

function localizeDateTimeSec(isoDate: string) {
  return `${localizeDateTime(isoDate)}${isoDate.substring(16, 19)}`;
}
