import { Card } from 'react-bootstrap';

interface MessageProps {
  type: 'ok' | 'error';
  children: string | JSX.Element | JSX.Element[];
}
export default function Message({ type, children }: MessageProps) {
  const color = type === 'ok' ? 'success' : 'danger';
  const icon = type === 'ok' ? 'check-circle-fill' : 'exclamation-circle-fill';

  return (
    <div className={`text-${color} fs-5`}>
      <i className={`bi bi-${icon} me-2`}></i> {children}
    </div>
  );
}
