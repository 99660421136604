import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import { useReducer, useState } from 'react';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import Message from '../../../../libs/components/data/Message';
import ApiErrorMessage from '../../../../libs/components/data/ApiErrorMessage';
import {
  FormActionState,
  getFormActionReducer,
} from '../../../../libs/services/state/formActionReducer';
import { PhoneCallForm } from './PhoneCallForm';
import { PhoneCall, PhoneCallFormData } from '../../types/phone_calls';
import { addPhoneCall } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { getCallTimeNow } from '../../../../libs/services/time';

const initialState: FormActionState = {
  actionInProgress: false,
  actionSuccess: false,
  actionError: false,
  showForm: true,
  actionErrorResponse: undefined,
};

export default function PhoneCallAdd() {
  const [createdPhoneCall, setCreatedPhoneCall] = useState<PhoneCall>();
  const [state, dispatch] = useReducer(getFormActionReducer(initialState), initialState);
  const navigate = useNavigate();

  async function onSubmit(action: string, data: PhoneCallFormData) {
    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      const item = await addPhoneCall(data);

      if (action === 'save') {
        navigate('/phone-calls/edit/' + item.id + '?created=1');
      } else {
        navigate('/phone-calls');
      }

      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
      dispatch({ type: 'TOGGLE_FORM' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    }
  }

  const callTime = getCallTimeNow();

  return (
    <>
      <PageToolbar pageTitle="Telefongespräch anlegen">
        <ButtonLink link="/phone-calls" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      {state.showForm && (
        <div className="mb-4">
          <PhoneCallForm
            mode="add"
            submitButtonLabel="Anlegen"
            onSubmit={onSubmit}
            initialValues={{
              callDate: new Date().toISOString().substring(0, 10),
              callTime: callTime,
            }}
          />
        </div>
      )}
      {state.actionError && (
        <ApiErrorMessage
          label="Telefongespräch konnte nicht angelegt werden"
          errorResponse={state.actionErrorResponse}
        />
      )}
      {state.actionSuccess && (
        <Message type="ok">
          <span>
            <strong>Telefongespräch wurde angelegt</strong>
          </span>
          <div className="mt-4">
            <ButtonLink
              buttonColor="light"
              icon="pencil-square"
              link={`/phone-calls/edit/${createdPhoneCall?.id}`}>
              Telefongespräch bearbeiten
            </ButtonLink>
          </div>
        </Message>
      )}
    </>
  );
}
