import { useParams } from 'react-router-dom';

import PageToolbar from '../../../libs/components/actions/PageToolbar';
import { getStorageCategory, updateStorageSubCategoryLevel1 } from '../services/api';
import { useEffect, useReducer, useState } from 'react';
import ButtonLink from '../../../libs/components/actions/ButtonLink';
import Message from '../../../libs/components/data/Message';
import ApiErrorMessage from '../../../libs/components/data/ApiErrorMessage';
import {
  FormActionState,
  getFormActionReducer,
} from '../../../libs/services/state/formActionReducer';
import { SubCategoryForm } from './SubCategoryForm';
import { StorageCategory, StorageSubCategoryLevel1 } from '../types/storageCategories';

const initialState: FormActionState = {
  actionInProgress: false,
  actionSuccess: false,
  actionError: false,
  showForm: true,
  actionErrorResponse: undefined,
};

export default function StorageSubCategoryEdit() {
  const { id, subCategoryId } = useParams();
  const [category, setCategory] = useState<StorageCategory>();
  const [subCategory, setSubCategory] = useState<StorageSubCategoryLevel1>();
  const [formData, setFormData] = useState<any>();
  const [state, dispatch] = useReducer(getFormActionReducer(initialState), initialState);

  useEffect(() => {
    if (!id) {
      return;
    }
    getStorageCategory(id).then(data => {
      setCategory(data);
      const subCategory = data.subCategoriesLevel1.find(sub => sub.id === subCategoryId);
      setSubCategory(subCategory);
      setFormData({
        name: subCategory!.name,
        _categories: subCategory?._categories ?? '',
      });
    });
  }, [id]);

  async function onSubmit(data: any) {
    if (!category || !subCategory) {
      return;
    }

    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      await updateStorageSubCategoryLevel1(category.id, subCategory.id, data);
      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    }
  }

  if (!category || !subCategory || !formData) {
    return null;
  }

  return (
    <>
      <PageToolbar pageTitle={`Unterkategorie bearbeiten: ${subCategory.name}`}>
        <ButtonLink
          link="/storage-categories"
          buttonColor="secondary"
          icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      {state.showForm && (
        <div className="mb-4">
          <SubCategoryForm
            submitButtonLabel="Unterkategorie aktualisieren"
            submitButtonColor="primary"
            onSubmit={onSubmit}
            initialValues={formData}
          />
        </div>
      )}
      {state.actionError && (
        <ApiErrorMessage
          label="Unterkategorie konnte nicht aktualisiert werden"
          errorResponse={state.actionErrorResponse}
        />
      )}
      {state.actionSuccess && <Message type="ok">Unterkategorie wurde aktualisiert</Message>}
    </>
  );
}
