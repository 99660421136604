import { ApiActionState } from '../../../types/state';

export interface FormActionState extends ApiActionState {
  showForm: boolean;
}

interface Action {
  type:
    | 'RESET_STATE'
    | 'TOGGLE_FORM'
    | 'TOGGLE_ACTION_IN_PROGRESS'
    | 'TOGGLE_ACTION_SUCCESS'
    | 'TOGGLE_ACTION_ERROR'
    | 'SET_ACTION_ERROR_RESPONSE';
  payload?: any;
}

export function getFormActionReducer(initialState: FormActionState) {
  return function (state: FormActionState, action: Action): FormActionState {
    switch (action.type) {
      case 'RESET_STATE':
        return { ...initialState };
      case 'TOGGLE_ACTION_IN_PROGRESS':
        return {
          ...state,
          actionInProgress: !state.actionInProgress,
        };
      case 'TOGGLE_ACTION_SUCCESS':
        return { ...state, actionSuccess: !state.actionSuccess };
      case 'TOGGLE_ACTION_ERROR':
        return { ...state, actionError: !state.actionError };
      case 'SET_ACTION_ERROR_RESPONSE':
        return { ...state, actionErrorResponse: action.payload };
      case 'TOGGLE_FORM':
        return { ...state, showForm: !state.showForm };
      default:
        return state;
    }
  };
}
