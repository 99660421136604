import { DailyScheduleForm } from '../types/dailySchedule';
import { User } from '../../users/types/users';
import { Button, Form } from 'react-bootstrap';
import { IconDelete, IconStart, IconStop } from '../../../libs/components/data/Icon';
import React, { ChangeEvent } from 'react';
import { formatDate } from '../../../libs/services/date';
import { getSumOfWorkTime } from '../../my-jobs/services/time';

interface DailyScheduleItemProps {
  user: User;
  form: DailyScheduleForm;
  totalForUser: number;
  onDeleteItem: (item: DailyScheduleForm) => void;
  onEditItem: (item: DailyScheduleForm) => void;
  onUserWorkTimeStart: (item: DailyScheduleForm) => void;
  onUserWorkTimeStop: (item: DailyScheduleForm) => void;
}

export function DailyScheduleItem({
  user,
  form,
  totalForUser,
  onDeleteItem,
  onEditItem,
  onUserWorkTimeStart,
  onUserWorkTimeStop,
}: DailyScheduleItemProps) {
  function onEdit(field: string) {
    return function (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) {
      const value = 'checked' in e.target ? e.target.checked : e.target.value;
      onEditItem({ ...form, [field]: value });
    };
  }

  const userWorkInProgress = form.job.workTime.some(workTime => workTime.endDate === null);
  const workTime = form.job.workTime.filter(
    workTime => workTime.startDate.substring(0, 10) === form.day,
  );
  const sum = getSumOfWorkTime(workTime);

  return (
    <div
      className={`${
        form._new ? 'list-group-item-danger' : ''
      } bg-light list-group-item d-flex justify-content-between align-items-start`}>
      <div className="ms-2 me-auto">
        <div className="fw-bold">{form.job.vehicle ? form.job.vehicle.plate : form.job.jobKey}</div>
        {form.job.vehicle !== null && (
          <div>
            {form.job.vehicle?.brandName} {form.job.vehicle?.brandTypeName}
            <span className="text-muted ps-2">
              (Pos. Schl. {form.job.keyPosition1} / {form.job.keyPosition2})
            </span>
          </div>
        )}
        {form.job.vehicle === null && (
          <div>
            {form.job.jobFor} / {form.job.clientName}
          </div>
        )}
        <div className="mt-2">
          <Form.Select
            onChange={onEdit('limitHours')}
            value={form.limitHours}
            size="sm"
            style={{ display: 'inline', width: 'auto', marginRight: 10 }}>
            <option value="">-- St. --</option>
            <option value="0">0 St.</option>
            <option value="1">1 St.</option>
            <option value="2">2 St.</option>
            <option value="3">3 St.</option>
            <option value="4">4 St.</option>
            <option value="5">5 St.</option>
            <option value="6">6 St.</option>
            <option value="7">7 St.</option>
            <option value="8">8 St.</option>
            <option value="9">9 St.</option>
            <option value="10">10 St.</option>
            <option value="11">11 St.</option>
            <option value="12">12 St.</option>
          </Form.Select>
          <Form.Select
            onChange={onEdit('limitMinutes')}
            value={form.limitMinutes}
            size="sm"
            style={{ display: 'inline', width: 'auto' }}>
            <option value="">-- Min. --</option>
            <option value="0">0 Min.</option>
            <option value="15">15 Min.</option>
            <option value="30">30 Min.</option>
            <option value="45">45 Min.</option>
          </Form.Select>
        </div>
        <div className="mt-3">
          <i className="bi bi-person-fill-check"></i>
          <Form.Check
            checked={form.isMainAssignedUser}
            onChange={onEdit('isMainAssignedUser')}
            className="ms-2"
            style={{ display: 'inline', width: 'auto' }}
          />
          <span className="ms-5">
            <i className="bi bi-sort-numeric-down me-2"></i>
            <Form.Select
              onChange={onEdit('order')}
              value={form.order}
              size="sm"
              style={{ display: 'inline', width: 'auto' }}>
              {Array(totalForUser)
                .fill(1)
                .map((_, i) => (
                  <option key={`${user.id}-${i + 1}`} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
            </Form.Select>
          </span>
        </div>
        <div className="mt-2">
          Gesamt am {formatDate(form.day, 'date')}: <strong>{sum}</strong>
        </div>
      </div>
      <div>
        <div
          className="badge bg-primary rounded-pill"
          style={{ cursor: 'pointer' }}
          onClick={() => window.open(`/jobs/${form.job.id}`)}>
          {form.job.jobKey}
        </div>
        <div className="mt-4 text-end">
          <IconDelete onClick={() => onDeleteItem(form)} />
        </div>
        {user.name !== 'Warteraum' && (
          <div className="mt-4 text-end">
            {!userWorkInProgress && (
              <Button variant="success" size="sm" onClick={() => onUserWorkTimeStart(form)}>
                <IconStart styles="text-white" />
              </Button>
            )}
            {userWorkInProgress && (
              <Button variant="warning" size="sm" onClick={() => onUserWorkTimeStop(form)}>
                <IconStop styles="text-white" />
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
