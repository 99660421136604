import { Col, Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

import Menu from './Menu/Menu';
import Header from './Header/Header';
import { useAuth } from '../libs/context/AuthContext';
import ScrollToTop from '../libs/services/scrollToTop';

export default function App() {
  const { isAdminOrOffice } = useAuth();

  return (
    <>
      <ScrollToTop />
      <Header />
      <Container fluid className="py-4" style={{ marginTop: 75 }}>
        <Row>
          {isAdminOrOffice() && (
            <Col lg={2} className="d-none d-lg-block">
              <Menu />
            </Col>
          )}
          <Col lg={isAdminOrOffice() ? 10 : 12}>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
}
