import { Badge, Button, Col, Form, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import PageToolbar from '../../../libs/components/actions/PageToolbar';
import { User } from '../../users/types/users';
import { getUsers } from '../../users/services/api';
import { SectionTitle } from '../../../libs/components/data/SectionTitle';
import { IconAdd } from '../../../libs/components/data/Icon';
import {
  activateDailySchedule,
  activateNewlyAddedScheduleItems,
  deleteDailyScheduleItem,
  getDailyScheduleForDay,
  saveDailyScheduleForDay,
} from '../services/api';
import { DailySchedule, DailyScheduleForm } from '../types/dailySchedule';
import {
  createSearchOptions,
  getDailyScheduleFormOfUser,
  getItemsCountForUser,
  getTimeSumForUser,
  getWorkingTimeOfUser,
  getWorkingTimeSumOfUser,
  transformToForm,
} from '../services/dailySchedule';
import { DailyScheduleItem } from './DailyScheduleItem';
import AddFormItemModal from './AddFormItemModal';
import { Job } from '../../jobs/types/jobs';
import { getJobs, startUserWorkTime, stopUserWorkTime } from '../../jobs/services/api';
import { v4 } from 'uuid';
import ConfirmModal from '../../../libs/components/modals/ConfirmModal';
import { formatDate } from '../../../libs/services/date';
import Message from '../../../libs/components/data/Message';

export function DailyScheduleContainer() {
  const [dayFilter, setDayFilter] = useState<string>(new Date().toISOString().substring(0, 10));
  const [users, setUsers] = useState<User[]>([]);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [searchOptions, setSearchOptions] = useState<string[]>([]);
  const [dailySchedule, setDailySchedule] = useState<DailySchedule[]>([]);
  const [dailyScheduleForm, setDailyScheduleForm] = useState<DailyScheduleForm[]>([]);
  const [selectedUserToAddItem, setSelectedUserToAddItem] = useState<User | undefined>();
  const [selectedItemToDelete, setSelectedItemToDelete] = useState<DailyScheduleForm | undefined>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [confirmActivate, setConfirmActivate] = useState<boolean>(false);

  useEffect(() => {
    loadUsers();
    loadDS();
    loadSearchOptions();
  }, []);

  useEffect(() => {
    loadDS();
  }, [dayFilter]);

  function loadUsers() {
    getUsers('workshop').then(data => setUsers(data.filter(data => data.enabled)));
  }

  function loadSearchOptions() {
    getJobs('', false, '', false).then(result => {
      setJobs(result.filter(job => !job.isDone));
      setSearchOptions(createSearchOptions(result.filter(job => !job.isDone)));
    });
  }

  function loadDS() {
    getDailyScheduleForDay(dayFilter).then(data => {
      setDailySchedule(data);
      setDailyScheduleForm(transformToForm(data));
    });
  }

  function openAddItemDialog(user: User) {
    setSelectedUserToAddItem(user);
  }

  function addItemToUser(job: Job) {
    if (!selectedUserToAddItem) {
      return;
    }

    setDailyScheduleForm([
      ...dailyScheduleForm,
      {
        _new: true,
        id: v4(),
        day: dayFilter,
        userId: selectedUserToAddItem.id,
        job: {
          id: job.id,
          jobFor: job.jobFor,
          clientName: job.clientName,
          jobKey: job.jobKey,
          vehicle: job.vehicle,
          keyPosition1: job.keyPosition1,
          keyPosition2: job.keyPosition2,
          workTime: [],
        },
        order: 1,
        limitHours: 0,
        limitMinutes: 0,
        isMainAssignedUser: false,
      },
    ]);

    setSelectedUserToAddItem(undefined);
  }

  function onConfirmDeleteFormItem(item: DailyScheduleForm) {
    setSelectedItemToDelete(item);
    setDeleteModal(true);
  }

  async function onDeleteFormItem() {
    setSuccessMessage('');
    if (!selectedItemToDelete) {
      return;
    }

    if (selectedItemToDelete._new) {
      setDailyScheduleForm([...dailyScheduleForm.filter(ds => ds.id !== selectedItemToDelete.id)]);
    } else {
      await deleteDailyScheduleItem(selectedItemToDelete.id);
    }

    setSelectedItemToDelete(undefined);
    setDeleteModal(false);
    loadDS();
    setSuccessMessage('Eintrag wurde gelöscht');
  }

  async function saveItemsForDay() {
    setSuccessMessage('');
    await saveDailyScheduleForDay(dayFilter, dailyScheduleForm);
    setSuccessMessage('Tagesplanung wurde gespeichert');
    loadDS();
  }

  function onEditItem(item: DailyScheduleForm) {
    setDailyScheduleForm(
      dailyScheduleForm.map(ds => {
        if (ds.id !== item.id) {
          return ds;
        }
        return {
          ...ds,
          limitHours: Number(item.limitHours),
          limitMinutes: Number(item.limitMinutes),
          order: Number(item.order),
          isMainAssignedUser: item.isMainAssignedUser,
        };
      }),
    );
  }

  async function onActivateDailySchedule() {
    setSuccessMessage('');
    await activateDailySchedule(dayFilter);
    setSuccessMessage('Tagesplanung wurde aktiviert');
    setConfirmActivate(false);
    loadDS();
  }

  async function activateUpdatedItems() {
    setSuccessMessage('');
    await activateNewlyAddedScheduleItems(dayFilter);
    setSuccessMessage('Änderungen wurden aktiviert');
    loadDS();
  }

  function isSomethingNotActive() {
    if (dailySchedule.length === 0) {
      return false;
    }
    return dailySchedule.some(item => !item.activated);
  }

  async function onUserWorkTimeStart(dailyScheduleForm: DailyScheduleForm) {
    await startUserWorkTime(dailyScheduleForm.job.id, dailyScheduleForm.userId);
    loadUsers();
    loadDS();
    loadSearchOptions();
  }

  async function onUserWorkTimeStop(dailyScheduleForm: DailyScheduleForm) {
    await stopUserWorkTime(dailyScheduleForm.job.id, dailyScheduleForm.userId);
    loadUsers();
    loadDS();
    loadSearchOptions();
  }

  if (users.length === 0 || searchOptions.length === 0) {
    return null;
  }

  return (
    <>
      <ConfirmModal
        show={deleteModal}
        title="Eintrag löschen"
        onConfirm={onDeleteFormItem}
        onCancel={() => {
          setDeleteModal(false);
          setSelectedItemToDelete(undefined);
        }}>
        <span>Möchten Sie wirklich den Eintrag löschen?</span>
      </ConfirmModal>
      <ConfirmModal
        show={confirmActivate}
        title="Tagesplanung aktivieren"
        onConfirm={onActivateDailySchedule}
        onCancel={() => {
          setConfirmActivate(false);
        }}>
        <>
          <div>
            Möchten Sie wirklich die Planung für den Tag{' '}
            <strong>{formatDate(dayFilter, 'date')}</strong> aktivieren?
          </div>
          <div className="mt-3">Die folgenden Aktionen werden durchgeführt:</div>
          <ol>
            <li>
              Alle laufende Arbeitszeiten werden <strong>gestoppt</strong>
            </li>
            <li>
              Alle zugewiesene Arbeitskarten werden in den Warteraum <strong>verschoben</strong>
            </li>
            <li>
              Alle aktive Benutzersitzungen (nur von den Werkstatt Benutzern) werden{' '}
              <strong>deaktiviert</strong>
            </li>
            <li>
              Die in der Tagesplanung ausgewählte Arbeitskarten werden den Benutzern{' '}
              <strong>zugewiesen</strong>
            </li>
          </ol>
        </>
      </ConfirmModal>
      {selectedUserToAddItem && (
        <AddFormItemModal
          jobs={jobs}
          searchOptions={searchOptions}
          selectedUser={selectedUserToAddItem}
          show={true}
          onSelect={addItemToUser}
          onCancel={() => {
            setSelectedUserToAddItem(undefined);
          }}
        />
      )}
      <PageToolbar titleColWidth={2} contentColWidth={10} pageTitle="Tagesplanung">
        <Row>
          <Col lg={1} className="pt-2">
            Tag
          </Col>
          <Col lg={2}>
            <Form.Control
              type="date"
              value={dayFilter}
              onChange={e => setDayFilter(e.target.value)}
            />
          </Col>
          <Col lg={9} className="text-end">
            <Button className="me-3" variant="success" onClick={saveItemsForDay}>
              Plan speichern
            </Button>
            <Button
              disabled={!isSomethingNotActive()}
              className="me-3"
              variant="info"
              onClick={activateUpdatedItems}>
              Änderungen aktivieren
            </Button>
            <Button
              disabled={
                Date.parse(dayFilter) < Date.parse(new Date().toISOString().substring(0, 10))
              }
              className="me-3"
              variant="primary"
              onClick={() => setConfirmActivate(true)}>
              Alles aktivieren
            </Button>
          </Col>
        </Row>
      </PageToolbar>
      {successMessage !== '' && <Message type="ok">{successMessage}</Message>}
      <Row>
        {users.map(user => (
          <Col lg={4} key={user.id}>
            <SectionTitle title={user.name} />
            <Row className="mb-3">
              <Col>
                <Badge bg="info fs-6" className="me-3">
                  Insgesamt: {getTimeSumForUser(dailyScheduleForm, user.id)}
                </Badge>
                <IconAdd color="success" onClick={() => openAddItemDialog(user)} />
              </Col>
            </Row>
            <div className="small">
              {getWorkingTimeOfUser(dailyScheduleForm, user.id, dayFilter).map(item => (
                <Row className="mb-2" key={item.id}>
                  <Col lg={5}>{item.car}</Col>
                  <Col lg={7} className="text-end">
                    {formatDate(item.startDate, 'datetime')} -{' '}
                    {item.endDate ? formatDate(item.endDate, 'datetime') : ''}
                  </Col>
                </Row>
              ))}
            </div>
            <Row className="fw-bold mb-2">
              <Col>Insgesamt</Col>
              <Col className="text-end">
                {getWorkingTimeSumOfUser(dailyScheduleForm, user.id, dayFilter)}
              </Col>
            </Row>
            <div className="list-group">
              {getDailyScheduleFormOfUser(dailyScheduleForm, user.id).map(dsItem => (
                <DailyScheduleItem
                  key={dsItem.id}
                  user={user}
                  form={dsItem}
                  totalForUser={getItemsCountForUser(dailyScheduleForm, user.id)}
                  onDeleteItem={onConfirmDeleteFormItem}
                  onEditItem={onEditItem}
                  onUserWorkTimeStart={onUserWorkTimeStart}
                  onUserWorkTimeStop={onUserWorkTimeStop}
                />
              ))}
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
}
