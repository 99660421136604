import { LogEntry } from '../types/logs';
import { API } from '../../../libs/services/api';
import { PagedResponseDto } from '../../../types/pagination';
import { createPaginationQuery } from '../../../libs/services/pagination';
import { Sort } from '../../../types/sort';
import { createSortQuery } from '../../../libs/services/sort';

const BASE = 'logs';

export async function getLogs(
  page: number = 1,
  limit: number,
  sort: Sort,
): Promise<PagedResponseDto<LogEntry>> {
  return (
    await API.get(`${BASE}${createPaginationQuery(page, limit)}${createSortQuery('&', sort)}`)
  ).data;
}

export async function getLog(id: string): Promise<LogEntry> {
  return (await API.get(`${BASE}/${id}`)).data;
}
