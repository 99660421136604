import { API } from '../../../libs/services/api';
import { JobVehiclePart } from '../../jobs/types/jobs';

const BASE = 'vehicleParts';

export async function getJobVehicleParts(
  filter: string,
  orderStatusFilter: string,
): Promise<JobVehiclePart[]> {
  return (await API.get(`${BASE}?filter=${filter}&orderStatus=${orderStatusFilter}`)).data;
}

export async function getLatestJobVehicleParts(limit: number): Promise<JobVehiclePart[]> {
  return (await API.get(`${BASE}/latest?limit=${limit}`)).data;
}

export async function toggleJobVehiclePartOrderStatus(partId: string) {
  await API.patch(`${BASE}/${partId}/orderStatus`);
}

export async function updateJobVehiclePartNotes(partId: string, notes: string) {
  await API.patch(`${BASE}/${partId}/notes`, { notes });
}
