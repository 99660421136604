import { Col, Row } from 'react-bootstrap';

import FormInput from '../../../../libs/components/forms/FormInput';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { kwToPs } from '../../services/kwPs';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';

export default function BasicCarElementsForm() {
  const methods = useFormContext();
  const [ps, setPs] = useState<number | undefined>();

  const watchPlate = methods.watch('plate');
  const watchKw = methods.watch('kw');

  // automatically transform plate to uppercase format
  useEffect(() => {
    if (watchPlate !== undefined && watchPlate !== watchPlate.toUpperCase()) {
      methods.setValue('plate', watchPlate.toUpperCase());
    }
  }, [watchPlate]);

  // show PS based on kW
  useEffect(() => {
    if (watchKw && !isNaN(watchKw)) {
      setPs(() => kwToPs(Number(watchKw)));
    } else {
      setPs(() => undefined);
    }
  }, [watchKw]);

  return (
    <>
      <Row>
        <Col>
          <FormInput name="plate" label="Kennzeichen" type="text" required={true} />
        </Col>
        <Col>
          <FormInput name="mileage" label="KM-Stand" type="text" required={true} />
        </Col>
        <Col>
          <FormInput name="registrationDate" label="Erstzulassung" type="date" required={true} />
        </Col>
        <Col lg={4}></Col>
      </Row>
      <Row>
        <Col>
          <FormInput name="fin" label="FIN" type="text" required={true} />
        </Col>
        <Col>
          <FormInput name="hsn" label="HSN" type="text" required={true} />
        </Col>
        <Col>
          <FormInput name="tsn" label="TSN" type="text" required={true} />
        </Col>
        <Col lg={4}></Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col lg={6}>
              <FormInput name="kw" label="kW" type="text" required={true} />
            </Col>
            <Col lg={6} className="pt-5">
              {ps !== undefined && <span>{ps} PS</span>}
            </Col>
          </Row>
        </Col>
        <Col>
          <FormInput name="displacement" label="Hubraum (ccm)" type="number" required={true} />
        </Col>
        <Col></Col>
        <Col lg={4}></Col>
      </Row>
      <Row>
        <Col>
          <FormInput name="inspectionHU" label="HU" type="date" required={true} />
        </Col>
        <Col>
          <FormInput name="inspectionHURemind" label="HU Erinnerung" type="date" required={false} />
        </Col>
        <Col>
          <FormInput name="inspectionAU" label="AU" type="date" required={true} />
        </Col>
        <Col lg={4}></Col>
      </Row>
      <Row>
        <Col>
          <FormInput name="lastInspection" label="Letzte Inspektion" type="date" required={false} />
        </Col>
        <Col>
          <FormInput
            name="nextInspectionRemind"
            label="Nächste Inspektion Erinnerung"
            type="date"
            required={false}
          />
        </Col>
        <Col></Col>
        <Col lg={4}></Col>
      </Row>
      <Row>
        <Col lg={6}>
          <SectionTitle title="Anmerkungen zum Fahrzeug" />
          <FormInput name="vehicleNotes" label="Anmerkungen" type="textarea" required={false} />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <FormInput name="vehicleTags" label="Tags zum Fahrzeug" type="text" required={false} />
        </Col>
      </Row>
    </>
  );
}
