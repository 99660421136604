import * as Yup from 'yup';

const phoneNumber = Yup.string()
  .required('Telefonnummer darf nicht leer sein')
  .test({
    test: value => /^[0-9]+$/.test(value ?? ''),
    message: 'Falsche Nummer, nur Zahlen erlaubt',
  });

const callDate = Yup.date()
  .typeError('Datum muss ein valides Datum sein')
  .required('Datum darf nicht leer sein');

const resubmission = Yup.date()
  .typeError('Wiedervolage muss ein valides Datum sein')
  .optional()
  .transform((value, input) => (input === '' ? undefined : value));

const callTime = Yup.string().required('Uhrzeit darf nicht leer sein');

const callType = Yup.string().required('Art des Kontakts darf nicht leer sein');

const category = Yup.string().required('Kategorie darf nicht leer sein');

const caller = Yup.string().required('Anrufer darf nicht leer sein');

const callerSalutation = Yup.string().required('Anrede darf nicht leer sein');

const status = Yup.string().required('Status darf nicht leer sein');

export function getValidationSchema() {
  const schema: Record<string, Yup.AnySchema> = {
    callDate,
    callTime,
    phoneNumber,
    callType,
    category,
    caller,
    callerSalutation,
    status,
    resubmission,
  };

  return Yup.object().shape(schema);
}
