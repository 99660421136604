import { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Brand } from '../../types/brands';
import AddBrandTypeModal from './AddBrandTypeModal';
import { IconAdd, IconEdit, IconSeparator } from '../../../../libs/components/data/Icon';
import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import BrandTypeList from './BrandTypeList';

interface BrandsTableProps {
  brands: Brand[];
  reloadBrands: () => void;
}

export default function BrandsTable({ brands, reloadBrands }: BrandsTableProps) {
  const [addBrandModal, setAddBrandModal] = useState<boolean>(false);
  const [selectedBrand, setSelectedBrand] = useState<Brand | undefined>();

  function onBrandTypeCreate() {
    setAddBrandModal(false);
    reloadBrands();
  }

  function onAddBrandType(brand: Brand) {
    setSelectedBrand(brand);
    setAddBrandModal(true);
  }

  return (
    <>
      <AddBrandTypeModal
        selectedBrand={selectedBrand}
        show={addBrandModal}
        onSaved={onBrandTypeCreate}
        onCancel={() => setAddBrandModal(false)}
      />
      <Table striped bordered hover className="shadow-sm">
        <thead>
          <tr>
            <th style={{ width: 125 }}>Angelegt am</th>
            <th>Name</th>
            <th>Modelle</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {brands.map(item => (
            <tr key={item.id}>
              <td>{getFormattedDateOrEmpty(item.createdAt)}</td>
              <td>{item.name}</td>
              <td>
                <BrandTypeList brand={item} />
              </td>
              <td style={{ minWidth: 200 }}>
                <Link to={`/brands/edit/${item.id}`} className="text-info">
                  <IconEdit />
                </Link>
                <IconSeparator />
                <Button size="sm" variant="info" onClick={() => onAddBrandType(item)}>
                  <IconAdd styles="pe-2" /> Modell
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
