import React from 'react';
import { VehicleNote } from '../../../types/vehicles';
import { getFormattedDateOrEmpty } from '../../../../../libs/services/date';

interface VehicleNotesListProps {
  notes: VehicleNote[];
}

export default function VehicleNotesList({ notes }: VehicleNotesListProps) {
  return (
    <>
      {notes.map(note => (
        <div key={note.id} className="mb-2">
          <div className="text-muted small">
            {getFormattedDateOrEmpty(note.createdAt, 'datetime')} <span className="px-2">|</span>{' '}
            {note.createdBy.name}
          </div>
          <div style={{ whiteSpace: 'pre-wrap' }}>{note.content}</div>
        </div>
      ))}
    </>
  );
}
