import { Job } from '../../types/jobs';
import React, { useEffect, useState } from 'react';
import { WorkTimeSummary } from '../../../stats/types/workTime';
import { getJobWorkTimeSummary } from '../../../stats/services/api';
import { Table } from 'react-bootstrap';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';

interface JobWorkTimeSummary {
  job: Job;
}

export default function JobWorkTimeSummary({ job }: JobWorkTimeSummary) {
  const [workTimeSummary, setWorkTimeSummary] = useState<WorkTimeSummary>();

  useEffect(() => {
    getJobWorkTimeSummary(job.id).then(data => setWorkTimeSummary(data));
  }, []);

  return (
    <>
      <SectionTitle title="Aktuelle Arbeitszeiten" />
      {workTimeSummary !== undefined && (
        <Table striped bordered className="shadow-sm">
          <tbody>
            {Object.keys(workTimeSummary.usersTimeSum).map(name => (
              <tr key={name}>
                <td style={{ width: 250 }}>
                  <strong>{name}</strong>
                </td>
                <td>
                  <div className="mb-1">
                    <strong>{workTimeSummary?.usersTimeSum[name]}</strong>
                  </div>
                  {workTimeSummary?.usersDaysList[name].join(', ')}
                </td>
              </tr>
            ))}
            <tr className="text-primary">
              <td>
                <strong>Erfasste Arbeitszeit insgesamt</strong>
              </td>
              <td>
                <strong>{workTimeSummary.sum}</strong>
              </td>
            </tr>
            <tr className="text-info">
              <td>
                <strong>Geschätze Arbeitszeit</strong>
              </td>
              <td>
                <strong>
                  {job.estimatedWorkTimeHours} St. {job.estimatedWorkTimeMinutes} Min.
                </strong>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
}
