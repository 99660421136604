import { useEffect, useState } from 'react';
import { JobFile } from '../../jobs/types/jobs';
import { deleteFile as doDeleteFile, getFiles } from '../services/api';

export function useFiles() {
  const [files, setFiles] = useState<JobFile[]>([]);

  useEffect(() => {
    loadFiles();
  }, []);

  function loadFiles() {
    getFiles().then(data => setFiles(data));
  }

  async function deleteFile(jobId: string, fileId: string) {
    await doDeleteFile(jobId, fileId);
    loadFiles();
  }

  return { files, deleteFile };
}
