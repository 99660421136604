import { useEffect, useState } from 'react';

import { User } from '../types/users';
import { deleteUser as doDeleteUser, getUsers } from '../services/api';

export function useUsers() {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    loadUsers();
  }, []);

  function loadUsers() {
    getUsers().then(data => setUsers(data));
  }

  async function deleteUser(id: string) {
    await doDeleteUser(id);
    loadUsers();
  }

  return { users, deleteUser };
}
