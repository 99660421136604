import { NewJobVehiclePartToAdd } from '../../jobs/types/jobs';
import { JobVehiclePartFormData } from '../types/vehicleParts';

export function transformVehiclePartsFromForm(
  parts: JobVehiclePartFormData[],
  formData: Record<string, any>,
  isAdmin = false,
): NewJobVehiclePartToAdd[] {
  return parts.map(part => {
    let item: NewJobVehiclePartToAdd = {
      type: formData['type'],
      name: formData[`part_${part.id}_name`],
      partNumber1: formData[`part_${part.id}_partNumber1`],
      partNumber2: formData[`part_${part.id}_partNumber2`],
      price: Number(String(formData[`part_${part.id}_price`]).replace(',', '.')),
      quantity: Number(formData[`part_${part.id}_quantity`]),
    };

    if (isAdmin) {
      item = {
        ...item,
        partNumber: formData[`part_${part.id}_partNumber`],
        status: formData[`part_${part.id}_status`],
        manufacturer: formData[`part_${part.id}_manufacturer`],
        invoiceNo: formData[`part_${part.id}_invoiceNo`],
        notes: formData[`part_${part.id}_notes`],
        orderedIn: formData[`part_${part.id}_orderedIn`],
        orderedAt: formData[`part_${part.id}_orderedAt`],
        orderTime: formData[`part_${part.id}_orderTime`],
        priceVKNet: Number(String(formData[`part_${part.id}_priceVKNet`]).replace(',', '.')),
        priceEKNet: Number(String(formData[`part_${part.id}_priceEKNet`]).replace(',', '.')),
        deposit: Number(String(formData[`part_${part.id}_deposit`]).replace(',', '.')),
      };
    }

    return item;
  });
}
