import { Col, Row } from 'react-bootstrap';
import FormInput from '../../../../libs/components/forms/FormInput';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import { JobUsedKey } from '../../types/jobs';
import Message from '../../../../libs/components/data/Message';

export default function KeyPositionForm({ foundKey }: { foundKey: JobUsedKey | null }) {
  return (
    <>
      <SectionTitle title="Position Schlüsselnummer" />
      <Row>
        <Col>
          <FormInput name="keyPosition1" label="Position 1" type="number" required={true} />
        </Col>
        <Col>
          <FormInput name="keyPosition2" label="Position 2" type="number" required={true} />
        </Col>
        <Col lg={8}></Col>
      </Row>
      {foundKey !== null && (
        <Row>
          <Col>
            <Message type="error">Die eingegebenen Schlüsselnummer sind bereits vergeben!</Message>
            <div>
              Arbeitskarte: <strong>{foundKey.jobKey}</strong>
            </div>
            {foundKey.plate !== null && (
              <div>
                Fahrzeug: <strong>{foundKey.plate}</strong>
              </div>
            )}
            <div>
              <strong>Die {foundKey.jobKey} muss zuerst archiviert werden!</strong>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}
