import { API } from '../../../libs/services/api';
import { UserSession } from '../types/usersessions';

const BASE = 'sessions';

export async function getSessions(): Promise<UserSession[]> {
  return (await API.get(BASE)).data;
}

export async function deleteSession(id: string) {
  await API.delete(`${BASE}/${id}`);
}
