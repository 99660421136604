import { Badge } from 'react-bootstrap';
import React from 'react';
import { SystemInfo } from '../../modules/system_status/types/info';

interface EnvInfoProps {
  systemInfo: SystemInfo;
}

export default function EnvInfo({ systemInfo }: EnvInfoProps) {
  return (
    <>
      <span className="text-muted pt-4">v.{systemInfo.version}</span>
      {systemInfo.environment !== 'prod' && (
        <Badge bg="light" className="ms-2">
          {String(systemInfo.environment || 'dev').toUpperCase()}
        </Badge>
      )}
    </>
  );
}
