import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import { useParams } from 'react-router-dom';
import { useEffect, useReducer, useState } from 'react';
import { StorageArticle, StorageArticleFormData } from '../../types/storage';
import { getArticle } from '../../services/api';
import {
  FormActionState,
  getFormActionReducer,
} from '../../../../libs/services/state/formActionReducer';
import { ArticleForm } from './ArticleForm';
import ApiErrorMessage from '../../../../libs/components/data/ApiErrorMessage';
import Message from '../../../../libs/components/data/Message';

const initialState: FormActionState = {
  actionInProgress: false,
  actionSuccess: false,
  actionError: false,
  showForm: true,
  actionErrorResponse: undefined,
};

export default function ArticleEdit() {
  const { id } = useParams();
  const [article, setArticle] = useState<StorageArticle>();
  const [formData, setFormData] = useState<StorageArticleFormData>();
  const [state, dispatch] = useReducer(getFormActionReducer(initialState), initialState);

  useEffect(() => {
    if (!id) {
      return;
    }
    getArticle(id).then(data => {
      setArticle(data);
      setFormData({
        ...data,
        orderDate: data.orderDate.substring(0, 10),
        category: data._cat._id,
        subCategory1: data.subCategoryLevel1Id,
        subCategory2: getSubcategory2Id(data),
      });
    });
  }, [id]);

  function getSubcategory2Id(data: StorageArticle) {
    if (data.subCategoryLevel4Id !== null) {
      return data.subCategoryLevel4Id;
    } else if (data.subCategoryLevel3Id !== null) {
      return data.subCategoryLevel3Id;
    } else if (data.subCategoryLevel2Id !== null) {
      return data.subCategoryLevel2Id;
    }
  }

  async function onSubmit(data: StorageArticleFormData) {
    if (!article) {
      return;
    }

    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      //await updateJob(job.id, data, job.vehicle !== null);
      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    }
  }

  return (
    <>
      <PageToolbar pageTitle="Artikel bearbeiten">
        <ButtonLink link="/storage" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      {state.showForm && formData && (
        <div className="mb-4">
          <ArticleForm
            submitButtonLabel="Artikel bearbeiten"
            onSubmit={onSubmit}
            initialValues={{ ...formData }}
          />
        </div>
      )}
      {state.actionError && (
        <ApiErrorMessage
          label="Artikel konnte nicht aktualisiert werden"
          errorResponse={state.actionErrorResponse}
        />
      )}
      {state.actionSuccess && (
        <Message type="ok">
          <span>
            <strong>Artikel wurde aktualisiert</strong>
          </span>
        </Message>
      )}
    </>
  );
}
