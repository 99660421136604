import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getValidationSchema } from '../../config/validation';
import FormInput from '../../../../libs/components/forms/FormInput';
import React, { createRef, useEffect, useState } from 'react';
import { PhoneCall, PhoneCallFormData } from '../../types/phone_calls';
import {
  phoneCallCategories,
  phoneCallFormats,
  phoneCallStatuses,
  phoneCallTypes,
} from '../../config/phone_calls';
import { User } from '../../../users/types/users';
import { getUsers } from '../../../users/services/api';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { getAutocomplete, getOtherPhoneCallsForNumber } from '../../services/api';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import TableHeader from '../../../../libs/components/data/table/TableHeader';
import { Icon, IconDetails } from '../../../../libs/components/data/Icon';
import { formatDate } from '../../../../libs/services/date';
import Status from '../../../../libs/components/data/Status';
import { Link } from 'react-router-dom';

interface ArticleFormProps {
  submitButtonLabel: string;
  submitButtonColor?: string;
  initialValues: Partial<PhoneCallFormData>;
  mode: 'add' | 'edit';
  onSubmit: (action: string, data: PhoneCallFormData) => void;
}

const TABLE_COLUMNS = [
  { key: 'callType', label: 'Art', width: 55 },
  { key: 'callDate', label: 'Datum', width: 150 },
  { key: 'status', label: 'Status', width: 125 },
  { key: 'assignedUser', label: 'Mitarbeiter' },
];

export function PhoneCallForm({
  submitButtonLabel,
  submitButtonColor = 'success',
  initialValues,
  mode,
  onSubmit,
}: ArticleFormProps) {
  const [users, setUsers] = useState<User[]>([]);
  const [showCategoryOther, setShowCategoryOther] = useState<boolean>(
    initialValues['category'] === 'Sonstige',
  );
  const [showInProgressFields, setShowInProgressFields] = useState<boolean>(
    initialValues['status'] === 'in Bearbeitung',
  );

  const [autocomplete, setAutocomplete] = useState<Record<string, unknown>[]>([]);
  const [phoneCallsWithNumber, setPhoneCallsWithNumber] = useState<PhoneCall[]>([]);

  const ref = createRef<any>();

  function onClear() {
    ref.current.clear();
    setPhoneCallsWithNumber([]);
  }

  useEffect(() => {
    getUsers().then(data => setUsers(data));
    if (mode === 'add') {
      getAutocomplete().then(data => setAutocomplete(data));
    }
  }, []);

  const methods = useForm<PhoneCallFormData>({
    defaultValues: initialValues,
    mode: 'all',
    resolver: yupResolver(getValidationSchema()),
  });

  const category = methods.watch('category');
  const status = methods.watch('status');

  useEffect(() => {
    setShowCategoryOther(category === 'Sonstige');
    setShowInProgressFields(status === 'in Bearbeitung');
  }, [category, status]);

  function submit(data: PhoneCallFormData) {
    onSubmit('save', { ...data });
  }

  function submitAndClose(data: PhoneCallFormData) {
    onSubmit('saveAndClose', { ...data });
  }

  function onSelect(selectedItem: Option[]) {
    if (selectedItem.length === 1 && selectedItem[0]) {
      const item = selectedItem[0] as Record<string, string>;
      methods.setValue('phoneNumber', item['phoneNumber']);
      methods.setValue('plateNumber', item['plateNumber']);
      getOtherPhoneCallsForNumber(item['phoneNumber'], '0').then(data =>
        setPhoneCallsWithNumber(data),
      );
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        {mode === 'add' && autocomplete.length > 0 && (
          <Card className="mb-3 col-4">
            <Card.Header>
              <strong>Telefonnummer / Kennzeichen durchsuchen</strong>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Typeahead
                    ref={ref}
                    multiple={false}
                    id="existingPhoneNumberSearch"
                    onChange={onSelect}
                    options={autocomplete.map(item => ({
                      ...item,
                      label: item['value'],
                    }))}
                  />
                </Col>
                <Col lg={3} className="text-end">
                  <Button variant="light" onClick={onClear}>
                    <i className="bi bi-x-circle-fill"></i>
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
        {phoneCallsWithNumber.length > 0 && (
          <>
            <SectionTitle
              title={`Frühere Telefongespräche mit: ${phoneCallsWithNumber[0].phoneNumber}`}
            />
            <Table striped bordered className="shadow-sm mb-5">
              <TableHeader columns={TABLE_COLUMNS} actionColumn={true} />
              <tbody>
                {phoneCallsWithNumber.map(item => (
                  <tr key={item.id} className="text-center">
                    <td>
                      <Icon
                        color={item.callType === 'Eingehender Anruf' ? 'primary' : 'warning'}
                        icon={
                          item.callType === 'Eingehender Anruf'
                            ? 'telephone-inbound-fill'
                            : 'telephone-outbound-fill'
                        }
                      />
                    </td>
                    <td>
                      {formatDate(item.callDate, 'date')} {item.callTime}
                    </td>
                    <td>
                      <Status
                        status={item.status === 'erledigt'}
                        labelTrue="Erledigt"
                        labelFalse="In Bearbeitung"
                        colorTrue="success"
                        colorFalse="warning"
                      />
                    </td>
                    <td>{item.assignedUser?.name}</td>
                    <td>
                      <Link target="_blank" to={`/phone-calls/${item.id}`} className="text-info">
                        <IconDetails />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
        <Card className="bg-light">
          <Card.Body>
            <Form>
              <Row>
                <Col lg={4}>
                  <FormInput name="phoneNumber" label="Telefonnummer" type="text" required={true} />
                </Col>
                <Col lg={3}>
                  <FormInput name="plateNumber" label="Kennzeichen" type="text" required={false} />
                </Col>
              </Row>
              <Row>
                <Col lg={2}>
                  <FormInput name="callDate" label="Datum" type="date" required={true} />
                </Col>
                <Col lg={2}>
                  <FormInput name="callTime" label="Uhrzeit" type="time-local" required={true} />
                </Col>
                <Col lg={3}>
                  <FormInput
                    name="callType"
                    label="Art des Kontakts"
                    type="select"
                    selectItems={phoneCallTypes.map(item => ({ value: item, label: item }))}
                    required={true}
                  />
                </Col>
                <Col lg={3}>
                  <FormInput
                    name="callFormat"
                    label="Typ des Kontakts"
                    type="select"
                    selectItems={phoneCallFormats.map(item => ({ value: item, label: item }))}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={2}>
                  <FormInput
                    name="callerSalutation"
                    label="Anrede"
                    type="select"
                    selectItems={[
                      { value: 'herr', label: 'Herr' },
                      { value: 'Frau', label: 'Frau' },
                      { value: 'Firma', label: 'Firma' },
                    ]}
                    required={true}
                  />
                </Col>
                <Col lg={5}>
                  <FormInput name="caller" label="Anrufer" type="text" required={true} />
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <FormInput
                    name="category"
                    label="Kategorie"
                    type="select"
                    selectItems={phoneCallCategories.map(item => ({ value: item, label: item }))}
                    required={true}
                  />
                  {showCategoryOther && (
                    <FormInput
                      name="categoryOther"
                      label="Kategorie - Sonstige"
                      type="text"
                      required={false}
                    />
                  )}
                </Col>
                <Col lg={3}>
                  <FormInput
                    name="status"
                    label="Status"
                    type="select"
                    selectItems={phoneCallStatuses.map(item => ({ value: item, label: item }))}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                {showInProgressFields && (
                  <Col lg={2}>
                    <FormInput
                      name="resubmission"
                      label="Wiedervorlage"
                      type="date"
                      required={false}
                    />
                  </Col>
                )}
                {showInProgressFields && users.length && (
                  <Col lg={3}>
                    <FormInput
                      name="resubmissionUser"
                      label="Wiedervorlage - Mitarbeiter"
                      type="select"
                      selectItems={users.map(user => ({ value: user.id, label: user.name }))}
                      required={false}
                    />
                  </Col>
                )}
              </Row>
              {mode === 'add' && (
                <Row>
                  <Col lg={7}>
                    <FormInput name="note" label="Notizen" type="textarea" required={false} />
                  </Col>
                </Row>
              )}
              <Button
                onClick={async () => {
                  await methods.trigger();
                  await methods.handleSubmit(submit)();
                }}
                variant={submitButtonColor}
                type="button"
                className="mt-3 me-3">
                {submitButtonLabel}
              </Button>
              <Button
                onClick={async () => {
                  await methods.trigger();
                  await methods.handleSubmit(submitAndClose)();
                }}
                variant={submitButtonColor}
                type="button"
                className="mt-3">
                {submitButtonLabel} und zurück
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </FormProvider>
    </>
  );
}
