import * as Yup from 'yup';

export const USER_NAME_MIN_LENGTH = 3;
export const USER_LOGIN_MIN_LENGTH = 3;
export const USER_PASSWORD_MIN_LENGTH = 5;

const name = Yup.string()
  .required('Name darf nicht leer sein')
  .min(USER_NAME_MIN_LENGTH, `Name muss mindestens ${USER_NAME_MIN_LENGTH} Zeichen enthalten`);

const login = Yup.string()
  .required('Benutzername darf nicht leer sein')
  .min(
    USER_LOGIN_MIN_LENGTH,
    `Benutzername muss mindestens ${USER_LOGIN_MIN_LENGTH} Zeichen enhalten`,
  );

const password = Yup.string()
  .required('Passwort darf nicht leer sein')
  .min(
    USER_PASSWORD_MIN_LENGTH,
    `Passwort muss mindestens ${USER_PASSWORD_MIN_LENGTH} Zeichen enthalten`,
  );

const repeat_password = Yup.string()
  .required('Passwort wiederholen darf nicht leer sein')
  .oneOf([Yup.ref('password')], 'Passwörter stimmen nicht überein');

export function getValidationSchema(skipPassword: boolean) {
  const schema: Record<string, Yup.AnySchema> = {
    name,
    login,
  };

  if (!skipPassword) {
    schema['password'] = password;
    schema['repeat_password'] = repeat_password;
  }

  return Yup.object().shape(schema);
}

export function getPasswordValidationSchema() {
  return Yup.object().shape({ password, repeat_password });
}
