import { Button, Form, Table } from 'react-bootstrap';
import { Job, JobTask } from '../../../jobs/types/jobs';
import Status from '../../../../libs/components/data/Status';
import { ChangeEvent, useState } from 'react';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';
import JobNotesList from '../../../jobs/components/JobDetails/JobNotes/JobNotesList';
import { formatDate } from '../../../../libs/services/date';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import { useAuth } from '../../../../libs/context/AuthContext';

interface MyJobTasksProps {
  job: Job;
  onTaskDone: (taskId: string) => void;
  onJobDone: (jobId: string) => void;
  onTaskNoteAdd: (taskId: string, value: string) => void;
}

export default function MyJobTasks({ job, onTaskDone, onJobDone, onTaskNoteAdd }: MyJobTasksProps) {
  const { user } = useAuth();
  const [newNotes, setNewNotes] = useState<Record<string, string>>({});
  const [jobDoneConfirmModal, setJobDoneConfirmModal] = useState<boolean>(false);

  function onNewNoteChange(taskId: string) {
    return function (e: ChangeEvent<HTMLTextAreaElement>) {
      const value = e.target.value;
      setNewNotes({ ...newNotes, [taskId]: value });
    };
  }

  function saveTaskNote(taskId: string) {
    return function () {
      if (newNotes[taskId]) {
        onTaskNoteAdd(taskId, newNotes[taskId]);
        setNewNotes({ ...newNotes, [taskId]: '' });
      }
    };
  }

  function setJobDone() {
    setJobDoneConfirmModal(false);
    onJobDone(job!.id);
  }

  function allTasksDone() {
    return job.tasks.every(task => task.isDone);
  }

  if (!user) {
    return null;
  }

  return (
    <>
      <ConfirmModal
        show={jobDoneConfirmModal}
        title="Aufgabe als ERLEDIGT markieren"
        onConfirm={() => setJobDone()}
        onCancel={() => setJobDoneConfirmModal(false)}>
        <>
          Möchten Sie wirklich die ganze Aufgabe als ERLEDIGT markieren?
          <div className="fs-5 mt-2 text-danger">
            <strong>
              Uwaga! Ten przycisk może być tylko naciśnięty jak auto jest kompletnie naprawione i
              została przeprowadzona jazdna próbna i przez Majstra auto zostało zaakceptowane do
              oddania!
            </strong>
          </div>
        </>
      </ConfirmModal>
      <SectionTitle title="Aufgaben" />
      <Table striped bordered className="shadow-sm">
        <thead>
          <tr>
            <th style={{ width: 100 }}>Erledigt</th>
            <th style={{ width: 100 }}>Erstelldatum</th>
            <th>Kategorie / Unterkategorie</th>
            <th>Beschreibung</th>
            <th>Anmerkungen</th>
            <th style={{ width: 150 }}>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {job.tasks.map((task: JobTask) => (
            <tr key={task.id}>
              <td>
                <Status status={task.isDone} labelTrue="Ja" labelFalse="Nein" />
              </td>
              <td>{formatDate(task.createdAt, 'date')}</td>
              <td>
                <div>
                  <strong>{task.category.name}</strong>
                </div>
                <div>{task.subCategory.name}</div>
              </td>
              <td>{task.description}</td>
              <td>
                <JobNotesList notes={task.assignedUserNotes} />
                {!task.isDone && job.mainAssignedUser.id === user.id && (
                  <>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="mb-3"
                      value={newNotes[task.id] || ''}
                      onChange={onNewNoteChange(task.id)}
                    />
                    <Button size="sm" variant="light" onClick={saveTaskNote(task.id)}>
                      Neue Anmerkung hinzufügen
                    </Button>
                  </>
                )}
              </td>
              <td className="align-middle text-center">
                {!task.isDone && (
                  <Button size="sm" onClick={() => onTaskDone(task.id)}>
                    Als <br />
                    erledigt markieren
                  </Button>
                )}
                {task.isDone && <span className="text-muted">Aufgabe erledigt</span>}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {!job.isDone && job.mainAssignedUser.id === user.id && (
        <div>
          <Button disabled={!allTasksDone()} onClick={() => setJobDoneConfirmModal(true)}>
            Ganze Aufgabe als erledigt markieren
          </Button>
        </div>
      )}
    </>
  );
}
