import { API } from '../../../libs/services/api';
import {
  VehicleCommission,
  VehicleCommissionFormData,
  VehicleCommissionPhotoForm,
} from '../types/vehicle_commissions';

const BASE = 'vehicle_commissions';

export async function getVehicleCommissions(): Promise<VehicleCommission[]> {
  return (await API.get(BASE)).data;
}

export async function getVehicleCommission(id: string): Promise<VehicleCommission> {
  return (await API.get(`${BASE}/${id}`)).data;
}

export async function addVehicleCommission(
  data: VehicleCommissionFormData,
  documentPhotos: VehicleCommissionPhotoForm[],
  otherPhotos: VehicleCommissionPhotoForm[],
): Promise<string> {
  const payload = {
    ...data,
    mileage: Number(data.mileage),
    warningLightsNotReadable: data.warningLightsNotReadable ?? false,
    mileageNotReadable: data.mileageNotReadable ?? false,
    warningLightCheckEngine: data.warningLightCheckEngine ?? false,
    warningLightAirbagSrs: data.warningLightAirbagSrs ?? false,
    warningLightAbsEspAsr: data.warningLightAbsEspAsr ?? false,
    warningLightService: data.warningLightService ?? false,
    warningLightImmobilizer: data.warningLightImmobilizer ?? false,
    warningLightOilPressure: data.warningLightOilPressure ?? false,
    warningLightParkingBrake: data.warningLightParkingBrake ?? false,
    warningLightWashwaterLevel: data.warningLightWashwaterLevel ?? false,
    warningLightBrakepadWear: data.warningLightBrakepadWear ?? false,
    warningLightTractionControl: data.warningLightTractionControl ?? false,
    warningLightBattery: data.warningLightBattery ?? false,
    warningLightPreglow: data.warningLightPreglow ?? false,
    warningLightParticulateFilter: data.warningLightParticulateFilter ?? false,
    photos: [...documentPhotos, ...otherPhotos],
  };

  return (await API.post(BASE, payload)).data;
}

export async function updateVehicleCommission(id: string, data: VehicleCommissionFormData) {
  await API.patch(`${BASE}/${id}`, data);
}

export async function deleteVehicleCommission(id: string) {
  await API.delete(`${BASE}/${id}`);
}
