import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, Card, Form } from 'react-bootstrap';

import { UserEditPasswordFormData } from '../types/users';
import { getPasswordValidationSchema } from '../config/validation';
import FormInput from '../../../libs/components/forms/FormInput';

interface EditPasswordFormProps {
  onSubmit: (data: UserEditPasswordFormData) => void;
}

export default function EditPasswordForm({ onSubmit }: EditPasswordFormProps) {
  const methods = useForm<UserEditPasswordFormData>({
    mode: 'all',
    resolver: yupResolver(getPasswordValidationSchema()),
  });

  return (
    <FormProvider {...methods}>
      <Card className="bg-light">
        <Card.Body>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormInput name="password" label="Passwort" type="password" required={true} />
            <FormInput
              name="repeat_password"
              label="Passwort wiederholen"
              type="password"
              required={true}
            />
            <Button variant="primary" type="submit" className="mt-3">
              Passwort ändern
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </FormProvider>
  );
}
