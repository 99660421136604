import { Button, Card, Col, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { createRef } from 'react';

import { Option } from 'react-bootstrap-typeahead/types/types';

interface VehicleSearchFormProps {
  existingVehiclePlates: string[];
  onVehicleSelect: (plate: string) => void;
  onReset: () => void;
}

export default function VehicleSearchForm({
  existingVehiclePlates,
  onVehicleSelect,
  onReset,
}: VehicleSearchFormProps) {
  const ref = createRef<any>();

  function onPlateSelect(selectedItem: Option[]) {
    if (selectedItem.length === 1 && selectedItem[0]) {
      onVehicleSelect(selectedItem[0] as string);
    }
  }

  function onClear() {
    ref.current.clear();
    onReset();
  }

  return (
    <>
      <Card className="mb-3 col-4">
        <Card.Header>
          <strong>Fahrzeuge durchsuchen</strong>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Typeahead
                ref={ref}
                multiple={false}
                id="existingPlateSearch"
                onChange={onPlateSelect}
                options={existingVehiclePlates}
              />
            </Col>
            <Col lg={3} className="text-end">
              <Button variant="light" onClick={onClear}>
                <i className="bi bi-x-circle-fill"></i>
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
