import React, { useEffect, useState } from 'react';

import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import PhotosTable from './PhotosTable';
import { Col, Form, Row } from 'react-bootstrap';
import { deletePhoto, getJobPhotos, updatePhoto } from '../../services/api';
import { JobPhoto } from '../../../jobs/types/jobs';
import PhotoGallery from '../PhotoGallery/PhotoGallery';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';
import { useAuth } from '../../../../libs/context/AuthContext';
import ListPagination from '../../../../libs/components/data/ListPagination';
import { PagedResponseDto } from '../../../../types/pagination';

const DEFAULT_PAGE_LIMIT = 50;

export default function PhotosList() {
  const { user } = useAuth();
  const [photos, setPhotos] = useState<PagedResponseDto<JobPhoto>>();
  const [filter, setFilter] = useState<string>('');
  const [showModalWithGallery, setShowModalWithGallery] = useState<boolean>(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number | undefined>(undefined);
  const [deleteItemModal, setDeleteItemModal] = useState<boolean>(false);
  const [actionItem, setActionItem] = useState<JobPhoto | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    loadPhotos();
  }, [filter, currentPage]);

  function loadPhotos() {
    if (!user) {
      return;
    }
    getJobPhotos(filter, currentPage, DEFAULT_PAGE_LIMIT).then(data => setPhotos(data));
  }

  function onPhotoClick(photo: JobPhoto) {
    if (!photos) {
      return;
    }
    setShowModalWithGallery(true);
    setSelectedPhotoIndex(photos.items.findIndex(item => item.id === photo.id));
  }

  function onConfirmDelete(item: JobPhoto) {
    setActionItem(item);
    setDeleteItemModal(true);
  }

  async function deleteItem() {
    if (actionItem) {
      await deletePhoto(actionItem.jobId, actionItem.id);
      loadPhotos();
    }
    setActionItem(undefined);
    setDeleteItemModal(false);
  }

  async function onPhotoUpdate(photo: JobPhoto, field: string, value: any) {
    await updatePhoto(photo.jobId, photo.id, field, value);
    loadPhotos();
  }

  function onPageChange(page: number) {
    setCurrentPage(page);
  }

  function onFilter(e: React.ChangeEvent<HTMLInputElement>) {
    setFilter(e.target.value || '');
    setCurrentPage(1);
  }

  if (!user) {
    return null;
  }

  return (
    <>
      <PageToolbar pageTitle="Bilder">
        <Row>
          <Col lg={{ offset: 4, span: 2 }} className="pt-2">
            Filtern
          </Col>
          <Col lg={{ span: 6 }}>
            <Form.Control onChange={onFilter} />
          </Col>
        </Row>
      </PageToolbar>
      {photos && selectedPhotoIndex !== undefined && (
        <PhotoGallery
          photos={photos.items}
          selectedPhotoIndex={selectedPhotoIndex!}
          show={showModalWithGallery}
          onClose={() => {
            setShowModalWithGallery(false);
            setSelectedPhotoIndex(undefined);
          }}
        />
      )}
      {actionItem && (
        <ConfirmModal
          show={deleteItemModal}
          title="Bild löschen"
          onConfirm={deleteItem}
          onCancel={() => setDeleteItemModal(false)}>
          Möchten Sie wirklich das ausgewählte Bild löschen?
        </ConfirmModal>
      )}
      {photos && (
        <>
          <PhotosTable
            photos={photos.items}
            authenticatedUser={user}
            onPhotoClick={onPhotoClick}
            onPhotoDelete={onConfirmDelete}
            onPhotoUpdate={onPhotoUpdate}
          />
          <ListPagination
            currentPage={currentPage}
            pageCount={photos._metadata.pageCount}
            onPageSelect={onPageChange}
          />
        </>
      )}
    </>
  );
}
