import { Button, Card, Col, Form, Row, Stack } from 'react-bootstrap';
import React, { useState } from 'react';

import PhotoForm from '../forms/PhotoForm';
import { JobPhoto, JobPhotoForm, JobVehiclePart } from '../../types/jobs';
import { formatDate } from '../../../../libs/services/date';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import PhotoGallery from '../../../photos/components/PhotoGallery/PhotoGallery';
import { getPhotoPreviewPath } from '../../../photos/services/photos';
import { useAuth } from '../../../../libs/context/AuthContext';
import PhotoVehiclePartsForm from './PhotoVehiclePartsForm';
import { IconDelete } from '../../../../libs/components/data/Icon';

interface JobPhotosProps {
  photos: JobPhoto[];
  vehicleParts: JobVehiclePart[];
  allowedPhotoAdd?: boolean;
  uploadPhotos?: (photos: JobPhotoForm[]) => void;
  allowedTagsEdit?: boolean;
  savePhotosTags?: () => void;
  onPhotoTagEdit?: (photo: JobPhoto, content: string) => void;
  excludeTags?: string[];
  jobLinkPrefix?: string;
  onPhotoUpdate?: (photo: JobPhoto, field: string, value: any) => void;
  onPhotoVehiclePartUpdate?: (photo: JobPhoto, value: any) => void;
  onPhotoDelete?: (photo: JobPhoto) => void;
}

export default function JobPhotos({
  photos,
  vehicleParts,
  allowedPhotoAdd = true,
  uploadPhotos,
  allowedTagsEdit = false,
  savePhotosTags,
  onPhotoTagEdit,
  excludeTags,
  jobLinkPrefix = 'jobs',
  onPhotoUpdate,
  onPhotoDelete,
  onPhotoVehiclePartUpdate,
}: JobPhotosProps) {
  const { user, isUserAuthenticated, isAdminOrOffice } = useAuth();
  const [newPhotos, setNewPhotos] = useState<JobPhotoForm[]>([]);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number | undefined>(undefined);
  const [showModalWithGallery, setShowModalWithGallery] = useState<boolean>(false);

  function onNewPhotoAdd(photo: JobPhotoForm) {
    setNewPhotos([...newPhotos, photo]);
  }

  function onNewPhotoDelete(id: string) {
    setNewPhotos(newPhotos.filter(item => item.id !== id));
  }

  function uploadNewPhotos() {
    if (newPhotos.length === 0 || !uploadPhotos) {
      return;
    }
    uploadPhotos(newPhotos);
  }

  function editPhotoTags(id: string, tags: string) {
    setNewPhotos(
      newPhotos.map(photo => {
        if (photo.id !== id) {
          return photo;
        }
        return {
          ...photo,
          tags: tags,
        };
      }),
    );
  }

  function editPhotoFields(id: string, field: string, value: any) {
    setNewPhotos(
      newPhotos.map(photo => {
        if (photo.id !== id) {
          return photo;
        }
        return {
          ...photo,
          [field]: value,
        };
      }),
    );
  }

  function onClick(photo: JobPhoto) {
    setShowModalWithGallery(true);
    setSelectedPhotoIndex(photos.findIndex(item => item.id === photo.id));
  }

  if (!isUserAuthenticated() || !user) {
    return null;
  }

  let filteredPhotos = photos;
  if (!isAdminOrOffice()) {
    filteredPhotos = filteredPhotos = filteredPhotos.filter(photo => {
      if (photo.onlyAdmin && !user.isAdmin) {
        return false;
      }
      if (photo.onlyOffice && !isAdminOrOffice()) {
        return false;
      }
      return true;
    });
  }

  if (excludeTags && excludeTags.length > 0) {
    filteredPhotos = filteredPhotos.filter(photo => {
      const result = photo.tags.filter(tag => excludeTags.includes(tag));
      return result.length === 0;
    });
  }

  return (
    <>
      <SectionTitle title="Bilder" />
      {selectedPhotoIndex !== undefined && (
        <PhotoGallery
          jobLinkPrefix={jobLinkPrefix}
          photos={photos}
          selectedPhotoIndex={selectedPhotoIndex!}
          show={showModalWithGallery}
          onClose={() => {
            setShowModalWithGallery(false);
            setSelectedPhotoIndex(undefined);
          }}
        />
      )}
      <Row>
        {filteredPhotos.map(item => (
          <Col key={item.fileName} lg={2} className="mb-3">
            <Card className="bg-light">
              <Card.Body className="p-2">
                <div style={{ overflow: 'hidden', height: 125 }}>
                  <img
                    style={{ cursor: 'pointer', maxHeight: 125 }}
                    src={getPhotoPreviewPath(item)}
                    alt={item.fileName}
                    onClick={() => onClick(item)}
                    className="img-fluid"
                  />
                </div>
                <div className="text-muted mt-1" style={{ fontSize: '0.8rem' }}>
                  {formatDate(item.createdAt, 'datetime')}, {item.createdBy.name}
                </div>
                {allowedTagsEdit && onPhotoTagEdit && (
                  <div className="mt-2">
                    <Form.Control
                      value={item.tags.join(',')}
                      onChange={e => onPhotoTagEdit(item, e.target.value)}
                    />
                  </div>
                )}
                {onPhotoUpdate !== undefined && (
                  <>
                    <Stack className="mt-2 text-muted" direction="horizontal">
                      {user.isAdmin && (
                        <Form.Check
                          label="Admin"
                          checked={item.onlyAdmin}
                          onChange={e => onPhotoUpdate(item, 'onlyAdmin', e.target.checked)}
                        />
                      )}
                      <span className="px-2"></span>
                      <Form.Check
                        label="Büro"
                        checked={item.onlyOffice}
                        onChange={e => onPhotoUpdate(item, 'onlyOffice', e.target.checked)}
                      />
                    </Stack>
                    {vehicleParts && (
                      <PhotoVehiclePartsForm
                        vehicleParts={vehicleParts}
                        selectedPartId={item.vehiclePartId}
                        onSelect={(partId: string | null) =>
                          onPhotoUpdate(item, 'vehiclePartId', partId)
                        }
                      />
                    )}
                  </>
                )}
                {onPhotoVehiclePartUpdate && vehicleParts && (
                  <PhotoVehiclePartsForm
                    vehicleParts={vehicleParts}
                    selectedPartId={item.vehiclePartId}
                    onSelect={(partId: string | null) => onPhotoVehiclePartUpdate(item, partId)}
                  />
                )}
                {user.isAdmin && onPhotoDelete !== undefined && (
                  <div className="mt-2">
                    <IconDelete onClick={() => onPhotoDelete(item)} />
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {allowedTagsEdit && savePhotosTags && photos.length > 0 && (
        <div className="mt-3">
          <Button onClick={() => savePhotosTags()}>Änderungen speichern</Button>
        </div>
      )}
      {allowedPhotoAdd && uploadPhotos && (
        <>
          <PhotoForm
            label="Neue Bilder hinzufügen"
            photos={newPhotos}
            vehicleParts={vehicleParts}
            onCapture={onNewPhotoAdd}
            onDelete={onNewPhotoDelete}
            onTagsEdit={editPhotoTags}
            onPhotoEdit={editPhotoFields}
          />
          {newPhotos.length > 0 && <Button onClick={uploadNewPhotos}>Neue Bilder hochladen</Button>}
        </>
      )}
    </>
  );
}
