import { Button, Card, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import FormInput from '../../../../libs/components/forms/FormInput';
import { BrandTypeFormData } from '../../types/brands';
import { getValidationSchema } from '../../config/validation';

interface BrandTypeFormProps {
  submitButtonLabel: string;
  submitButtonColor?: string;
  initialValues: Partial<BrandTypeFormData>;
  onSubmit: (data: BrandTypeFormData) => void;
}

export function BrandTypeForm({
  submitButtonLabel,
  submitButtonColor = 'success',
  initialValues,
  onSubmit,
}: BrandTypeFormProps) {
  const methods = useForm<BrandTypeFormData>({
    defaultValues: initialValues,
    mode: 'all',
    resolver: yupResolver(getValidationSchema()),
  });

  return (
    <FormProvider {...methods}>
      <Card style={{ width: '25%', minWidth: 400 }} className="bg-light">
        <Card.Body>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormInput name="name" label="Name" type="text" required={true} />
            <Button variant={submitButtonColor} type="submit" className="mt-3">
              {submitButtonLabel}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </FormProvider>
  );
}
