import ButtonLink from '../../../libs/components/actions/ButtonLink';
import PageToolbar from '../../../libs/components/actions/PageToolbar';
import JobForm from './forms/JobForm';
import {
  FormActionState,
  getFormActionReducer,
} from '../../../libs/services/state/formActionReducer';
import { Job, TransformedJobFormData } from '../types/jobs';
import { ActionType } from './forms/FormActions';
import { createJob, getExistingVehiclePlates } from '../services/api';
import { useEffect, useReducer, useState } from 'react';
import ApiErrorMessage from '../../../libs/components/data/ApiErrorMessage';
import Message from '../../../libs/components/data/Message';
import { Button } from 'react-bootstrap';
import { goToJobPrintPage } from '../services/printJob';

const initialState: FormActionState = {
  actionInProgress: false,
  actionSuccess: false,
  actionError: false,
  showForm: true,
  actionErrorResponse: undefined,
};

export default function JobAdd() {
  const [createdJob, setCreatedJob] = useState<Job | undefined>();
  const [state, dispatch] = useReducer(getFormActionReducer(initialState), initialState);
  const [existingPlates, setExistingPlates] = useState<string[] | undefined>(undefined);

  useEffect(() => {
    getExistingVehiclePlates().then(data => setExistingPlates(data));
  }, []);

  async function onSave(actionType: ActionType, data: Partial<TransformedJobFormData>) {
    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      const job = await createJob(data);
      setCreatedJob(job);
      if (actionType === 'saveAndPrint') {
        goToJobPrintPage(job.id);
      }
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
      dispatch({ type: 'TOGGLE_FORM' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    } finally {
      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    }
  }

  if (existingPlates === undefined) {
    return null;
  }

  return (
    <>
      <PageToolbar pageTitle="Arbeitskarte anlegen">
        <ButtonLink link="/jobs" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      {!createdJob && (
        <div className="mb-4">
          <JobForm
            existingVehiclePlates={existingPlates}
            onCreateJob={onSave}
            actionInProgress={state.actionInProgress}
          />
        </div>
      )}
      {state.actionError && (
        <ApiErrorMessage
          label="Arbeitskarte konnte nicht hinzufügt werden"
          errorResponse={state.actionErrorResponse}
        />
      )}
      {state.actionSuccess && (
        <>
          <div className="mb-4">
            <Message type="ok">
              <strong>Arbeitskarte wurde angelegt</strong>
            </Message>
          </div>
          <ButtonLink buttonColor="light" icon="zoom-in" link={`/jobs/${createdJob!.id}`}>
            Arbeitskarte anzeigen
          </ButtonLink>
          <span className="px-2"></span>
          <Button onClick={() => goToJobPrintPage(createdJob!.id)}>
            <i className="bi bi-printer-fill"></i> Arbeitskarte ausdrucken
          </Button>
        </>
      )}
    </>
  );
}
