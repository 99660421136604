import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Vehicle } from '../../types/vehicles';
import { addNewVehicleNote, getVehicle } from '../../services/api';
import VehicleInfo from './VehicleInfo';
import VehicleJobsList from './VehicleJobsList';
import VehicleNotes from './Notes/VehicleNotes';

export default function VehicleDetails() {
  const { id } = useParams();
  const [vehicle, setVehicle] = useState<Vehicle>();

  useEffect(() => {
    loadVehicle();
  }, [id]);

  function loadVehicle() {
    if (!id) {
      return;
    }
    getVehicle(id).then(data => {
      setVehicle(data);
    });
  }

  async function saveNewNote(content: string) {
    if (!vehicle) {
      return;
    }
    await addNewVehicleNote(vehicle.id, content);
    loadVehicle();
  }

  if (!vehicle) {
    return null;
  }

  return (
    <>
      <PageToolbar pageTitle={`Fahrzeug ${vehicle.plate}`}>
        <ButtonLink link={`/vehicles/edit/${vehicle.id}`} buttonColor="info" icon="pencil-square">
          Fahrzeugdaten bearbeiten
        </ButtonLink>
        <span className="px-2"></span>
        <ButtonLink link="/vehicles" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      <VehicleInfo vehicle={vehicle} />
      <VehicleNotes vehicle={vehicle} onNewNote={saveNewNote} />
      <VehicleJobsList vehicle={vehicle} />
    </>
  );
}
