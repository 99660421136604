import * as Yup from 'yup';

import {
  VEHICLE_FIN_LENGTH,
  VEHICLE_HSN_LENGTH,
  VEHICLE_PLATE_FORMAT,
  VEHICLE_TSN_LENGTH,
} from '../../vehicles/config/validation';
import { Vehicle } from '../../vehicles/types/vehicles';

export function getValidationSchema(
  minMileage: number,
  existingVehiclePlates: string[],
  selectedExistingVehicle: Vehicle | undefined,
  jobWithoutVehicle: boolean,
) {
  const brand = Yup.string().required('Marke darf nicht leer sein');

  const brandType = Yup.string().required('Modell darf nicht leer sein');

  let notAllowedPlates = existingVehiclePlates;
  if (selectedExistingVehicle) {
    notAllowedPlates = notAllowedPlates.filter(item => item !== selectedExistingVehicle.plate);
  }

  const plate = Yup.string()
    .required('Kennzeichen darf nicht leer sein')
    //.matches(VEHICLE_PLATE_FORMAT, 'Falsches Format')
    .notOneOf(notAllowedPlates, 'Dieses Kennzeichen bereits existiert');

  const mileage = Yup.number()
    .typeError('KM-Stand muss eine Zahl sein')
    .required('KM-Stand darf nicht leer sein')
    .integer('Falsches Format')
    .positive('KM-Stand muss eine positive Zahl sein')
    .min(minMileage, `KM-Stand muss höher als ${minMileage - 1} sein`);

  const registrationDate = Yup.date()
    .typeError('Erstzulassung must ein valides Datum sein')
    .required('Erstzulassung darf nicht leer sein');

  const fin = Yup.string()
    .required('FIN darf nicht leer sein')
    .length(VEHICLE_FIN_LENGTH, `FIN muss aus ${VEHICLE_FIN_LENGTH} Zeichen bestehen`);

  const hsn = Yup.string()
    .required('HSN darf nicht leer sein')
    .length(VEHICLE_HSN_LENGTH, `HSN muss aus ${VEHICLE_HSN_LENGTH} Zeichen bestehen`);

  const tsn = Yup.string()
    .required('TSN darf nicht leer sein')
    .length(VEHICLE_TSN_LENGTH, `TSN muss aus ${VEHICLE_TSN_LENGTH} Zeichen bestehen`);

  const kw = Yup.number()
    .typeError('kW muss eine Zahl sein')
    .required('kW darf nicht leer sein')
    .min(1, 'kW muss mindestens 1 sein');

  const displacement = Yup.number()
    .typeError('Hubraum muss eine Zahl sein')
    .required('Hubraum darf nicht leer sein')
    .integer('Falsches Format')
    .min(100, 'Hubraum muss mindestens 100 ccm sein');

  const mainAssignedUser = Yup.string().required('Aufgabenmanager muss ausgewählt werden');

  const expectedDoneAt = Yup.date()
    .typeError('"Fahrzeug fertig bis" muss ein valides Datum sein')
    .required('"Fahrzeug fertig bis" darf nicht leer sein');

  const estimatedWorkTimeHours = Yup.number()
    .typeError('"Arbeitszeit Stunden" muss eine Zahl sein')
    .required('"Arbeitszeit Stunden" darf nicht leer sein')
    .min(0, '"Arbeitszeit Stunden" darf nicht weniger als 0 sein');

  const estimatedWorkTimeMinutes = Yup.number()
    .typeError('"Arbeitszeit Minuten" muss eine Zahl sein')
    .required('"Arbeitszeit Minuten" darf nicht leer sein');

  const inspectionHU = Yup.date()
    .typeError('HU must ein valides Datum sein')
    .required('HU Datum darf nicht leer sein');

  const inspectionAU = Yup.date()
    .typeError('AU must ein valides Datum sein')
    .required('AU Datum darf nicht leer sein');

  const inspectionHURemind = Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError('HU must ein valides Datum sein');

  const lastInspection = Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError('"Nächste Inspektion" must ein valides Datum sein');

  const nextInspectionRemind = Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError('"Nächste Inspektion Erinnerung" must ein valides Datum sein');

  const keyPosition1 = Yup.number()
    .typeError('Pos. 1 muss eine Zahl sein')
    .required('Pos. 1 darf nicht leer sein')
    .min(1, 'Pos. 1 muss mindestens 0 sein');

  const keyPosition2 = Yup.number()
    .typeError('Pos. 2 muss eine Zahl sein')
    .required('Pos. 2 darf nicht leer sein')
    .min(1, 'Pos. 2 muss mindestens 10 sein');

  const jobFor = Yup.string().required('"Arbeitskarte für" darf nicht leer sein');
  const clientName = Yup.string().required('Firmen-/Kundennummer darf nicht leer sein');
  const itemNumber = Yup.string().required('Teilnummer darf nicht leer sein');

  let requiredFields = {
    mainAssignedUser,
    expectedDoneAt,
    estimatedWorkTimeHours,
    estimatedWorkTimeMinutes,
  };

  let newVehicleRequiredFields = {
    keyPosition1,
    keyPosition2,
    brand,
    brandType,
    plate,
    mileage,
    registrationDate,
    fin,
    hsn,
    tsn,
    kw,
    displacement,
    inspectionHU,
    inspectionAU,
    inspectionHURemind,
    lastInspection,
    nextInspectionRemind,
  };

  let selectedVehicleRequiredFields = {
    keyPosition1,
    keyPosition2,
    mileage,
  };

  let requiredFieldsWithoutVehicle = {
    jobFor,
    clientName,
    itemNumber,
  };

  if (!jobWithoutVehicle) {
    if (selectedExistingVehicle) {
      requiredFields = { ...requiredFields, ...selectedVehicleRequiredFields };
    } else {
      requiredFields = { ...requiredFields, ...newVehicleRequiredFields };
    }
  } else {
    requiredFields = { ...requiredFields, ...requiredFieldsWithoutVehicle };
  }

  return Yup.object().shape({
    ...requiredFields,
  });
}

export function getJobEditValidationSchema(vehicle: Vehicle | null) {
  const expectedDoneAt = Yup.date()
    .typeError('"Fahrzeug fertig bis" muss ein valides Datum sein')
    .required('"Fahrzeug fertig bis" darf nicht leer sein');

  const estimatedWorkTimeHours = Yup.number()
    .typeError('"Arbeitszeit Stunden" muss eine Zahl sein')
    .required('"Arbeitszeit Stunden" darf nicht leer sein')
    .min(0, '"Arbeitszeit Stunden" darf nicht weniger als 0 sein');

  const estimatedWorkTimeMinutes = Yup.number()
    .typeError('"Arbeitszeit Minuten" muss eine Zahl sein')
    .required('"Arbeitszeit Minuten" darf nicht leer sein');

  const keyPosition1 = Yup.number()
    .typeError('Pos. 1 muss eine Zahl sein')
    .required('Pos. 1 darf nicht leer sein')
    .min(1, 'Pos. 1 muss mindestens 0 sein');

  const keyPosition2 = Yup.number()
    .typeError('Pos. 2 muss eine Zahl sein')
    .required('Pos. 2 darf nicht leer sein')
    .min(1, 'Pos. 2 muss mindestens 10 sein');

  const jobFor = Yup.string().required('"Arbeitskarte für" darf nicht leer sein');
  const clientName = Yup.string().required('Firmen-/Kundennummer darf nicht leer sein');
  const itemNumber = Yup.string().required('Teilnummer darf nicht leer sein');

  let requiredFields = {
    expectedDoneAt,
    estimatedWorkTimeHours,
    estimatedWorkTimeMinutes,
  };

  let vehicleRequiredFields = {
    keyPosition1,
    keyPosition2,
  };

  let requiredFieldsWithoutVehicle = {
    jobFor,
    clientName,
    itemNumber,
  };

  if (vehicle !== null) {
    requiredFields = { ...requiredFields, ...vehicleRequiredFields };
  } else {
    requiredFields = { ...requiredFields, ...requiredFieldsWithoutVehicle };
  }

  return Yup.object().shape({
    ...requiredFields,
  });
}
