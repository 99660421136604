import { API } from '../../../libs/services/api';
import { Vehicle } from '../../vehicles/types/vehicles';
import {
  Job,
  JobEditFormData,
  JobPhoto,
  JobState,
  JobTaskFormData,
  JobUsedKey,
  TransformedJobFormData,
} from '../types/jobs';

const BASE = 'jobs';

export async function getJobs(
  filter: string,
  archivedFilter: boolean,
  userFilter: string,
  withoutCarFilter: boolean,
): Promise<Job[]> {
  return (
    await API.get(
      `${BASE}?filter=${filter}&archivedFilter=${
        archivedFilter ? '1' : ''
      }&userFilter=${userFilter}&withoutCarFilter=${withoutCarFilter ? '1' : '0'}`,
    )
  ).data;
}

export async function getRelatedJobs(vehicleId: string): Promise<Job[]> {
  return (await API.get(`${BASE}/ofVehicle/${vehicleId}`)).data;
}

export async function getJob(id: string): Promise<Job> {
  return (await API.get(`${BASE}/${id}`)).data;
}

export async function createJob(data: Partial<TransformedJobFormData>): Promise<Job> {
  return (await API.post(BASE, { ...data, withCar: 'mileage' in data })).data;
}

export async function addNewJobNote(jobId: string, content: string) {
  return (await API.post(`${BASE}/${jobId}/notes`, { content })).data;
}

export async function addNewAdminJobNote(jobId: string, content: string) {
  return (await API.post(`${BASE}/${jobId}/adminNotes`, { content })).data;
}

export async function deleteJob(id: string): Promise<void> {
  await API.delete(`${BASE}/${id}`);
}

export async function reopenJob(id: string): Promise<void> {
  await API.patch(`${BASE}/${id}/reopen`);
}

export async function getLatestJobForVehicle(vehicleId: string): Promise<Job> {
  return (await API.get(`${BASE}/latestJobForVehicle/${vehicleId}`)).data;
}

export async function getAllJobsForVehicle(vehicleId: string): Promise<Job[]> {
  return (await API.get(`${BASE}/allJobsForVehicle/${vehicleId}`)).data;
}

export async function getExistingVehiclePlates(): Promise<string[]> {
  return (await API.get('vehicles/existingPlates')).data;
}

export async function getVehicleByPlate(plate: string): Promise<Vehicle> {
  return (await API.get(`vehicles/byPlate/${plate}`)).data;
}

export async function getUsedKeys(): Promise<JobUsedKey[]> {
  return (await API.get(`${BASE}/usedKeys`)).data;
}

export async function getLatestDoneJobs(limit: number): Promise<Job[]> {
  return (await API.get(`${BASE}/done?limit=${limit}`)).data;
}

export async function getNextExpectedDoneJobs(limit: number): Promise<Job[]> {
  return (await API.get(`${BASE}/expectedDone?limit=${limit}`)).data;
}

export async function getLatestJobs(limit: number): Promise<Job[]> {
  return (await API.get(`${BASE}/latest?limit=${limit}`)).data;
}

export async function updateAssignedUsers(jobId: string, users: string[]) {
  return (await API.patch(`${BASE}/${jobId}/assignedUsers`, { users })).data;
}

export async function startUserWorkTime(jobId: string, userId: string) {
  await API.patch(`${BASE}/${jobId}/startWorkTime/${userId}`);
}

export async function stopUserWorkTime(jobId: string, userId: string) {
  await API.patch(`${BASE}/${jobId}/stopWorkTime/${userId}`);
}

export async function updateMainAssignedUser(jobId: string, userId: string) {
  return (await API.patch(`${BASE}/${jobId}/mainAssignedUser`, { userId })).data;
}

export async function setJobState(jobId: string, jobState: JobState) {
  return (await API.patch(`${BASE}/${jobId}/jobState/${jobState}`)).data;
}

export async function changeJobIsDone(jobId: string) {
  return (await API.patch(`${BASE}/${jobId}/isDone`)).data;
}

export async function saveNewJobTasks(jobId: string, tasks: JobTaskFormData[]) {
  return (await API.patch(`${BASE}/${jobId}/tasks`, { tasks })).data;
}

export async function updatePhotosTags(jobId: string, editedPhotos: JobPhoto[]) {
  return (
    await API.patch(`${BASE}/${jobId}/photoTags`, {
      tags: editedPhotos.map(photo => ({ photoId: photo.id, tags: photo.tags })),
    })
  ).data;
}

export async function archiveJob(id: string) {
  return (await API.patch(`${BASE}/${id}/archive`)).data;
}

export async function activateArchivedJob(id: string) {
  return (await API.patch(`${BASE}/${id}/activate`)).data;
}

export async function moveToWaitingRoom(id: string) {
  return (await API.patch(`${BASE}/${id}/waitingRoom`)).data;
}

export async function deleteJobTask(jobId: string, jobTaskId: string) {
  return (await API.delete(`${BASE}/${jobId}/tasks/${jobTaskId}`)).data;
}

export async function updateJob(
  id: string,
  data: JobEditFormData,
  withCar: boolean,
): Promise<void> {
  await API.patch(`${BASE}/${id}`, { ...data, withCar });
}
