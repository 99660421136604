import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import WorkTimeTable from './WorkTimeTable';
import { deleteWorkTimeItem, getWorkTime } from '../../services/api';
import { WorkTimeInProgress } from '../../types/workTime';
import { getUsers } from '../../../users/services/api';
import { User } from '../../../users/types/users';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';
import { formatDate } from '../../../../libs/services/date';
import EditWorkTimeModal from './EditWorkTimeModal';

export default function WorkTimeList() {
  const [items, setItems] = useState<WorkTimeInProgress[]>([]);
  const [sum, setSum] = useState<string>('');
  const [users, setUsers] = useState<User[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [userFilter, setUserFilter] = useState<string>('');
  const [fromFilter, setFromFilter] = useState<string>('');
  const [toFilter, setToFilter] = useState<string>('');
  const [deleteItemModal, setDeleteItemModal] = useState<boolean>(false);
  const [editItemModal, setEditItemModal] = useState<boolean>(false);
  const [actionItem, setActionItem] = useState<WorkTimeInProgress | undefined>();

  useEffect(() => {
    getUsers('workshop').then(data => setUsers(data));
  }, []);

  useEffect(() => {
    load();
  }, [filter, userFilter, fromFilter, toFilter]);

  function load() {
    getWorkTime(filter, userFilter, fromFilter, toFilter).then(data => {
      setItems(data.workTime);
      setSum(data.sum);
    });
  }

  function onUserFilter(event: React.ChangeEvent<HTMLSelectElement>) {
    setUserFilter(event.target.value);
  }

  function onOpenEdit(item: WorkTimeInProgress) {
    setActionItem(item);
    setEditItemModal(true);
  }

  function onConfirmDelete(item: WorkTimeInProgress) {
    setActionItem(item);
    setDeleteItemModal(true);
  }

  async function deleteItem() {
    if (actionItem) {
      await deleteWorkTimeItem(actionItem.jobId, actionItem.id);
      load();
    }
    setActionItem(undefined);
    setDeleteItemModal(false);
  }

  async function onSaveItem() {
    setActionItem(undefined);
    setEditItemModal(false);
    load();
  }

  return (
    <>
      <PageToolbar pageTitle="Arbeitszeiten" titleColWidth={2} contentColWidth={10}></PageToolbar>
      <Row className="mb-4">
        <Col lg={1} className="pt-2 text-end">
          Filtern
        </Col>
        <Col lg={2}>
          <Form.Control onChange={e => setFilter(e.target.value || '')} />
        </Col>
        <Col className="pt-2 text-end" lg={1}>
          Mitarbeiter
        </Col>
        <Col lg={2}>
          <Form.Select onChange={onUserFilter} value={userFilter}>
            <option value="">--- Alle ---</option>
            {users.map(user => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col lg={1} className="pt-2 text-end">
          Von
        </Col>
        <Col lg={2}>
          <Form.Control
            type="date"
            value={fromFilter}
            onChange={e => setFromFilter(e.target.value)}
          />
        </Col>
        <Col lg={1} className="pt-2 text-end">
          Bis
        </Col>
        <Col lg={2}>
          <Form.Control type="date" value={toFilter} onChange={e => setToFilter(e.target.value)} />
        </Col>
      </Row>
      <div className="text-end mb-2">
        Arbeitszeit insgesamt: <strong>{sum}</strong>
      </div>
      {actionItem && (
        <>
          <ConfirmModal
            show={deleteItemModal}
            title="Arbeistzeit löschen"
            onConfirm={deleteItem}
            onCancel={() => setDeleteItemModal(false)}>
            <>
              <div className="mb-2">Möchten Sie wirklich die ausgewählte Arbeitszeit löschen?</div>
              <div>
                Mitarbeiter: <strong>{actionItem.user.name}</strong>
              </div>
              {actionItem.vehicle !== null && (
                <div>
                  Fahrzeug: <strong>{actionItem.vehicle.plate}</strong>
                </div>
              )}
              <div>
                Zeit: <strong>{formatDate(actionItem.startDate, 'datetime')}</strong> -{' '}
                <strong>{formatDate(actionItem.endDate, 'datetime')}</strong>
              </div>
            </>
          </ConfirmModal>
          <EditWorkTimeModal
            workTime={actionItem}
            show={editItemModal}
            onSaved={onSaveItem}
            onCancel={() => setEditItemModal(false)}
          />
        </>
      )}
      <WorkTimeTable items={items} onDelete={onConfirmDelete} onEdit={onOpenEdit} />
    </>
  );
}
