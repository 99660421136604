import { Navigate } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';
import { getModulePermissions } from '../../services/auth';

interface ProtectedPageProps {
  module?: string;
  children: JSX.Element;
}

export default function ProtectedPage({ children, module }: ProtectedPageProps) {
  const { user, isUserAuthenticated, hasUserPermission, isAdminOrOffice } = useAuth();

  if (!user || !isUserAuthenticated()) {
    return <Navigate to="/login" />;
  }

  if (!module) {
    return children;
  }

  const modulePermissions = getModulePermissions(module);

  if (modulePermissions.length === 0) {
    return <Navigate to="/login" />;
  }

  if (!hasUserPermission(modulePermissions)) {
    if (!isAdminOrOffice()) {
      return <Navigate to="/my-jobs" />;
    }
    return <Navigate to="/login" />;
  }

  return children;
}
