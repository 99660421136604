import React from 'react';

interface UserInfoProps {
  userName: string;
}

export default function UserInfo({ userName }: UserInfoProps) {
  return (
    <span>
      <i className="bi bi-person-circle fs-5 me-2"></i>
      <span className="fs-5 me-5">{userName}</span>
    </span>
  );
}
