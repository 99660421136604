import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { formatDate } from '../../../../libs/services/date';
import {
  IconDelete,
  IconDetails,
  IconEdit,
  IconSeparator,
} from '../../../../libs/components/data/Icon';
import { StorageArticle } from '../../types/storage';
import { useAuth } from '../../../../libs/context/AuthContext';

interface StorageTableProps {
  articles: StorageArticle[];
  onDelete: (file: StorageArticle) => void;
}

export default function StorageTable({ articles, onDelete }: StorageTableProps) {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <Table striped bordered className="shadow-sm">
      <thead>
        <tr>
          <th style={{ width: 140 }}>Anlegegt am/von</th>
          <th>Kategorie</th>
          <th>Hersteller</th>
          <th>Rechnungsnummer</th>
          <th>Menge / Einheit</th>
          <th>Lager</th>
          <th style={{ width: 175 }}>Aktionen</th>
        </tr>
      </thead>
      <tbody>
        {articles.map(item => (
          <tr key={item.id}>
            <td>
              {formatDate(item.createdAt, 'datetime')}
              <br />
              {item.createdBy.name}
            </td>
            <td>
              {item.category}
              {item.subCategoryLevel1 !== null && <span> / {item.subCategoryLevel1}</span>}
              {item.subCategoryLevel2 !== null && <span> / {item.subCategoryLevel2}</span>}
              {item.subCategoryLevel3 !== null && <span> / {item.subCategoryLevel3}</span>}
              {item.subCategoryLevel4 !== null && <span> / {item.subCategoryLevel4}</span>}
            </td>
            <td>{item.manufacturer}</td>
            <td>{item.billNumber}</td>
            <td>
              {item.quantity} {item.unit}
            </td>
            <td>
              <div>{item.storagePlace}</div>
              <div>{item.shelf}</div>
              <div>{item.board}</div>
            </td>
            <td>
              <Link to={`/storage/${item.id}`} className="text-info">
                <IconDetails />
              </Link>
              <IconSeparator />
              <Link to={`/storage/edit/${item.id}`} className="text-info">
                <IconEdit />
              </Link>
              {user.isAdmin && (
                <>
                  <IconSeparator />
                  <IconDelete onClick={() => onDelete(item)} />
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
