import { StorageCategory } from '../types/storageCategories';

export function transformCategoriesToTreeData(cats: StorageCategory[]) {
  return {
    name: '',
    children: cats.map(category => {
      return {
        id: category.id,
        name: category.name,
        children: category.subCategoriesLevel1.map(sub1 => {
          return {
            id: sub1.id,
            name: sub1.name,
            children: sub1.subCategoriesLevel2.map(sub2 => {
              return {
                id: sub2.id,
                name: sub2.name,
                children: sub2.subCategoriesLevel3.map(sub3 => {
                  return {
                    id: sub3.id,
                    name: sub3.name,
                    children: sub3.subCategoriesLevel4.map(sub4 => {
                      return {
                        id: sub4.id,
                        name: sub4.name,
                      };
                    }),
                  };
                }),
              };
            }),
          };
        }),
      };
    }),
  };
}
