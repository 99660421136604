import { AxiosError } from 'axios';

interface ErrorListProps {
  errorResponse: unknown;
}

export default function ErrorList({ errorResponse }: ErrorListProps) {
  const err = errorResponse as AxiosError<any>;
  const messages = err?.response?.data?.message;

  if (messages === undefined) {
    return null;
  }

  if (!Array.isArray(messages)) {
    return <div className="text-danger mt-2">{messages as string}</div>;
  }

  return (
    <ul className="text-danger mt-2">
      {messages.map(item => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );
}
