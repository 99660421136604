interface SectionTitleProps {
  title: string | JSX.Element;
  color?: string;
}

export function SectionTitle({ title, color = 'primary' }: SectionTitleProps) {
  return (
    <p className={`mt-4 mb-3 fs-5 text-${color}`}>
      <strong>{title}</strong>
    </p>
  );
}
