import { JobPhoto } from '../../jobs/types/jobs';
import { StorageArticlePhoto } from '../../storage/types/storage';
import { VehicleCommissionPhoto } from '../../vehicle_commission/types/vehicle_commissions';

export function getPhotoPreviewPath(photo: JobPhoto): string {
  const path = `/files/jobs/${photo.jobId}`;

  if (photo.thumbnailFileName !== null) {
    return `${path}/${photo.thumbnailFileName}`;
  }

  return `${path}/${photo.fileName}`;
}

export function getStoragePhotoPreviewPath(photo: StorageArticlePhoto): string {
  const path = `/files/storage/${photo.storageArticleId}`;

  if (photo.thumbnailFileName !== null) {
    return `${path}/${photo.thumbnailFileName}`;
  }

  return `${path}/${photo.fileName}`;
}

export function getVehicleCommissionPreviewPath(photo: VehicleCommissionPhoto): string {
  const path = `/files/vehicle_commissions/${photo.vehicleCommissionId}`;

  if (photo.thumbnailFileName !== null) {
    return `${path}/${photo.thumbnailFileName}`;
  }

  return `${path}/${photo.fileName}`;
}
