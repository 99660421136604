import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import { Vehicle } from '../../types/vehicles';
import { Job } from '../../../jobs/types/jobs';
import Status from '../../../../libs/components/data/Status';
import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import { getAllJobsForVehicle } from '../../../jobs/services/api';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import { IconDetails } from '../../../../libs/components/data/Icon';

interface VehicleJobsListProps {
  vehicle: Vehicle;
}

export default function VehicleJobsList({ vehicle }: VehicleJobsListProps) {
  const [jobs, setJobs] = useState<Job[]>([]);

  useEffect(() => {
    getAllJobsForVehicle(vehicle.id).then(data => setJobs(data));
  }, [vehicle]);

  if (jobs.length === 0) {
    return null;
  }

  return (
    <>
      <SectionTitle title="Arbeitskarten" />
      <Table striped bordered className="shadow-sm">
        <thead>
          <tr>
            <th style={{ width: 75 }}>ID</th>
            <th style={{ width: 80 }}>Erledigt</th>
            <th style={{ width: 125 }}>Anlegegt am</th>
            <th>KM-Stand</th>
            <th>Manager</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map(item => (
            <tr key={item.id}>
              <td>{item.jobKey}</td>
              <td>
                <Status status={item.isDone} labelTrue="Ja" labelFalse="Nein" />
              </td>
              <td>{getFormattedDateOrEmpty(item.createdAt)}</td>
              <td>{item.mileage}</td>
              <td>{item.mainAssignedUser.name}</td>
              <td>
                <Link to={`/jobs/${item.id}`} className="text-info" target="_blank">
                  <IconDetails />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
