import {
  JobFormData,
  JobPhotoForm,
  JobPhotoFormWithoutId,
  JobTaskFormData,
  TransformedJobFormData,
} from '../types/jobs';
import { JobTask } from '../components/forms/JobForm';
import { Vehicle } from '../../vehicles/types/vehicles';
import { extractDateFromDateTime } from '../../../libs/services/time';

export function transformJobFormData(
  data: JobFormData,
  tasks: JobTask[],
  photos: JobPhotoForm[],
  jobWithoutVehicle: boolean,
  selectedVehicle?: Vehicle,
): Partial<TransformedJobFormData> {
  let transformedData: Partial<TransformedJobFormData> = {
    notes: data['notes'] || '',
    adminNotes: data['adminNotes'] || '',
    mainAssignedUser: data['mainAssignedUser'],
    assignedUsers: extractAssignedUsers(data),
    tasks: extractJobTasks(tasks),
    photos: extractJobPhotos(photos),
    expectedDoneAt: extractDateFromDateTime(data['expectedDoneAt']),
    estimatedWorkTimeHours: Number(data['estimatedWorkTimeHours']),
    estimatedWorkTimeMinutes: Number(data['estimatedWorkTimeMinutes']),
  };

  if (!jobWithoutVehicle) {
    const vehicleData: Partial<TransformedJobFormData> = {
      keyPosition1: Number(data['keyPosition1']),
      keyPosition2: Number(data['keyPosition2']),
      brand: selectedVehicle?.brandId ?? data['brand'],
      brandType: selectedVehicle?.brandTypeId ?? data['brandType'],
      plate: selectedVehicle?.plate ?? data['plate'],
      mileage: Number(data['mileage']),
      registrationDate:
        selectedVehicle?.registrationDate ?? extractDateFromDateTime(data['registrationDate']),
      fin: selectedVehicle?.fin ?? data['fin'],
      hsn: selectedVehicle?.hsn ?? data['hsn'],
      tsn: selectedVehicle?.tsn ?? data['tsn'],
      kw: selectedVehicle?.kw ?? Number(data['kw']),
      displacement: selectedVehicle?.displacement ?? Number(data['displacement']),
      vehicleNotes: data['vehicleNotes'],
      inspectionAU: extractDateFromDateTime(
        data['inspectionAU'] ? new Date(data['inspectionAU']) : new Date(),
      ),
      inspectionHU: extractDateFromDateTime(
        data['inspectionHU'] ? new Date(data['inspectionHU']) : new Date(),
      ),
      inspectionHURemind: data['inspectionHURemind']
        ? extractDateFromDateTime(data['inspectionHURemind'])
        : null,
      lastInspection: data['lastInspection']
        ? extractDateFromDateTime(data['lastInspection'])
        : null,
      nextInspectionRemind: data['nextInspectionRemind']
        ? extractDateFromDateTime(data['nextInspectionRemind'])
        : null,
      vehicleTags: data['vehicleTags'].trim(),
    };
    transformedData = { ...transformedData, ...vehicleData };
  } else {
    const fieldsWithoutVehicle: Partial<TransformedJobFormData> = {
      jobFor: data['jobFor'] || '',
      clientName: data['clientName'] || '',
      itemNumber: data['itemNumber'] || '',
    };
    transformedData = { ...transformedData, ...fieldsWithoutVehicle };
  }

  return transformedData;
}

function extractAssignedUsers(data: JobFormData): string[] {
  let result: string[] = [];

  Object.keys(data).forEach(key => {
    if (key.substring(0, 14) === 'assigned_user_' && data[key] === true) {
      result.push(key.substring(14));
    }
  });

  return result;
}

export function extractJobTasks(data: JobTask[]): JobTaskFormData[] {
  const result: JobTaskFormData[] = [];

  data
    .map(task => {
      return {
        ...task,
        items: task.items.filter(item => item.enabled),
      };
    })
    .filter(task => task.items.length)
    .forEach(task => {
      task.items.forEach(item => {
        result.push({
          jobCategoryId: task.categoryId,
          jobSubCategoryId: item.subCategoryId,
          description: item.description,
        });
      });
    });

  return result;
}

export function extractJobPhotos(photos: JobPhotoForm[]): JobPhotoFormWithoutId[] {
  return photos.map(photo => ({
    content: photo.content,
    tags: photo.tags,
    onlyAdmin: photo.onlyAdmin,
    onlyOffice: photo.onlyOffice,
    vehiclePartId: photo.vehiclePartId,
  }));
}
