import { Job } from '../../../jobs/types/jobs';
import { Col, Row, Table } from 'react-bootstrap';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import React from 'react';

interface VehicleDetailsProps {
  job: Job;
}

export default function VehicleDetails({ job }: VehicleDetailsProps) {
  if (job.vehicle === null) {
    return null;
  }

  return (
    <>
      <SectionTitle title="Fahrzeugdaten" />
      <Row>
        <Col lg={12}>
          <Table striped bordered className="shadow-sm">
            <tbody>
              <tr>
                <td style={{ width: 200 }}>
                  <strong>Kennzeichen</strong>
                </td>
                <td>{job.vehicle.plate}</td>
                <td>
                  <strong>Marke</strong>
                </td>
                <td>
                  {job.vehicle.brandName} {job.vehicle.brandTypeName}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>KM-Stand</strong>
                </td>
                <td>{job.mileage}</td>
                <td>
                  <strong>Erstzulassung</strong>
                </td>
                <td>{job.vehicle.registrationDate}</td>
              </tr>
              <tr>
                <td>
                  <strong>FIN</strong>
                </td>
                <td>{job.vehicle.fin}</td>
                <td>
                  <strong>kW / PS</strong>
                </td>
                <td>
                  {job.vehicle.kw} / {job.vehicle.ps}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>HSN</strong>
                </td>
                <td>{job.vehicle.hsn}</td>
                <td>
                  <strong>TSN</strong>
                </td>
                <td>{job.vehicle.tsn}</td>
              </tr>
              <tr>
                <td>
                  <strong>Hubraum (ccm)</strong>
                </td>
                <td>{job.vehicle.displacement}</td>
                <td colSpan={2}></td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
