import React, { ChangeEvent, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';

import VehicleNotesList from './VehicleNotesList';
import { Vehicle } from '../../../types/vehicles';
import { SectionTitle } from '../../../../../libs/components/data/SectionTitle';

interface VehicleNotesProps {
  vehicle: Vehicle;
  onNewNote: (content: string) => void;
}

export default function VehicleNotes({ vehicle, onNewNote }: VehicleNotesProps) {
  const [newNote, setNewNote] = useState<string>('');

  function onNotesChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setNewNote(e.target.value);
  }

  function saveNote() {
    onNewNote(newNote);
    setNewNote('');
  }

  return (
    <>
      <SectionTitle title="Anmerkungen zum Fahrzeug" />
      {vehicle.notes.length > 0 && (
        <Card className="bg-light mb-4">
          <Card.Body className="p-3">
            <VehicleNotesList notes={vehicle.notes} />
          </Card.Body>
        </Card>
      )}
      <div>
        <Form.Control as="textarea" rows={3} value={newNote} onChange={onNotesChange} />
      </div>
      <div className="mt-3">
        <Button size="sm" disabled={newNote.trim() === ''} onClick={saveNote}>
          Neue Anmerkung hinzufügen
        </Button>
      </div>
    </>
  );
}
