import { Link } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';

import { JobCategory } from '../../types/jobCategories';
import { IconAdd, IconDelete, IconEdit } from '../../../../libs/components/data/Icon';
import { useState } from 'react';
import AddSubCategoryModal from '../AddSubCategoryModal';
import { formatDate } from '../../../../libs/services/date';

interface CategoriesTableProps {
  categories: JobCategory[];
  onDelete: (category: JobCategory) => void;
  reloadCategories: () => void;
}

export default function CategoriesTable({
  categories,
  onDelete,
  reloadCategories,
}: CategoriesTableProps) {
  const [addSubCategoryModal, setAddSubCategoryModal] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<JobCategory | undefined>();

  function onConfirmDelete(item: JobCategory) {
    return function () {
      onDelete(item);
    };
  }

  function onAddSubCategory(category: JobCategory) {
    setSelectedCategory(category);
    setAddSubCategoryModal(true);
  }

  function onSubCategoryCreate() {
    setAddSubCategoryModal(false);
    reloadCategories();
  }

  return (
    <>
      <AddSubCategoryModal
        selectedCategory={selectedCategory}
        show={addSubCategoryModal}
        onSaved={onSubCategoryCreate}
        onCancel={() => setAddSubCategoryModal(false)}
      />
      <Table striped bordered hover className="shadow-sm">
        <thead>
          <tr>
            <th style={{ width: 125 }}>Reihenfolge</th>
            <th style={{ width: 125 }}>Angelegt am</th>
            <th>Name</th>
            <th>Unterkategorien</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {categories.map(item => (
            <tr key={item.id}>
              <td>{item.sortOrder}</td>
              <td>{formatDate(item.createdAt, 'date')}</td>
              <td>{item.name}</td>
              <td>
                {item.subCategories.map(sub => (
                  <div key={sub.id}>
                    <span className="me-2">
                      {sub.sortOrder}. {sub.name}
                    </span>
                    <Link
                      to={`/job-categories/${item.id}/edit-subcategory/${sub.id}`}
                      className="text-info">
                      <IconEdit />
                    </Link>
                  </div>
                ))}
              </td>
              <td>
                <Link to={`/job-categories/edit/${item.id}`} className="text-info">
                  <IconEdit />
                </Link>
                <span className="px-2"></span>
                <IconDelete onClick={() => onConfirmDelete(item)} />
                <span className="px-3"></span>
                <Button size="sm" variant="info" onClick={() => onAddSubCategory(item)}>
                  <IconAdd styles="pe-2" /> Unterkategorie
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
