import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import { useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  Job,
  JobPhoto,
  JobPhotoForm,
  JobState,
  NewJobVehiclePartToAdd,
} from '../../../jobs/types/jobs';
import { addNewJobNote, getJob } from '../../../jobs/services/api';
import JobPhotos from '../../../jobs/components/JobDetails/JobPhotos';
import MyJobTasks from './MyJobTasks';
import VehiclePartsToOrder from './VehiclePartsToOrder';
import VehicleDetails from './VehicleDetails';
import {
  addTaskNote,
  endWorkTime,
  saveVehicleParts,
  setJobDone,
  setJobState,
  setTaskDone,
  startWorkTime,
  updateVehiclePart,
  uploadPhotos,
} from '../../services/api';
import MyJobTime from './MyJobTime';
import JobNotes from '../../../jobs/components/JobDetails/JobNotes/JobNotes';
import { Badge, Col, Row, Table } from 'react-bootstrap';
import RelatedJobsList from '../../../jobs/components/JobDetails/RelatedJobsList';
import { JobVehiclePartEditFormData } from '../../types/vehicleParts';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import { useAuth } from '../../../../libs/context/AuthContext';
import { updatePhoto } from '../../../photos/services/api';
import PhotoGallery from '../../../photos/components/PhotoGallery/PhotoGallery';
import JobFiles from '../../../jobs/components/JobDetails/JobFiles/JobFiles';
import { SelectedFile } from '../../../jobs/components/JobDetails/JobFiles/JobFileAddForm';
import { uploadFiles } from '../../../files/services/api';
import { DailySchedule } from '../../../daily_schedule/types/dailySchedule';
import { getDailyScheduleForDayAndCurrentUser } from '../../../daily_schedule/services/api';

export default function MyJobDetails() {
  const { id } = useParams();
  const { user, isAdminOrOffice } = useAuth();
  const [searchParams] = useSearchParams();
  const [job, setJob] = useState<Job>();
  const [dailySchedule, setDailySchedule] = useState<DailySchedule[]>([]);
  const [timeStarted, setTimeStarted] = useState<boolean>(false);
  const [alreadyStartedJobWarning, setAlreadyStartedJobWarning] = useState<Job | undefined>(
    undefined,
  );
  const [uploading, setUploading] = useState<boolean>();
  const [assignToOfficeModal, setAssignToOfficeModal] = useState<boolean>(false);
  const [partPhotosModal, setPartPhotosModal] = useState<boolean>(false);
  const [partPhotos, setPartPhotos] = useState<JobPhoto[]>([]);

  useEffect(() => {
    loadJob();
    setAlreadyStartedJobWarning(undefined);
  }, [id]);

  function loadJob() {
    if (!id) {
      return;
    }
    getJob(id)
      .then(data => {
        if (!user) {
          return;
        }

        setJob(data);
        const inProgress = !!data.workTime.find(
          time => time.userId === user.id && time.endDate === null,
        );
        const isAssignedToCurrentUser =
          data.mainAssignedUser.id === user.id || data.assignedUsers.some(u => u.id === user.id);

        setTimeStarted((inProgress || data.isDone) && isAssignedToCurrentUser);
      })
      .then(() => {
        getDailyScheduleForDayAndCurrentUser(new Date().toISOString().substring(0, 10)).then(data =>
          setDailySchedule(data),
        );
      });
  }

  async function onTaskDone(taskId: string) {
    await setTaskDone(job!.id, taskId);
    loadJob();
  }

  async function onJobDone(jobId: string) {
    await setJobDone(jobId);
    loadJob();
  }

  async function saveNewNote(content: string) {
    if (!job) {
      return;
    }
    await addNewJobNote(job.id, content);
    loadJob();
  }

  async function onTaskNoteAdd(taskId: string, value: string) {
    await addTaskNote(job!.id, taskId, value);
    loadJob();
  }

  async function uploadJobPhotos(photos: JobPhotoForm[]) {
    setUploading(true);
    await uploadPhotos(job!.id, photos);
    loadJob();
    setUploading(false);
  }

  async function onStartWorkTime(force: boolean) {
    try {
      setAlreadyStartedJobWarning(undefined);
      await startWorkTime(job!.id, force);
      loadJob();
    } catch (err: any) {
      if (err.response?.status === 400) {
        setAlreadyStartedJobWarning(err.response.data['startedJob']);
      }
    }
  }

  async function onEndWorkTime() {
    await endWorkTime(job!.id);
    loadJob();
  }

  async function onVehiclePartsSave(parts: NewJobVehiclePartToAdd[]) {
    await saveVehicleParts(job!.id, parts);
    loadJob();
  }

  async function onVehiclePartUpdate(partId: string, data: JobVehiclePartEditFormData) {
    await updateVehiclePart(job!.id, partId, data, isAdminOrOffice());
    loadJob();
  }

  async function assignJobToOffice() {
    await setJobState(job!.id, JobState.OFFICE);
    setAssignToOfficeModal(false);
    loadJob();
  }

  async function onPhotoUpdate(photo: JobPhoto, field: string, value: any) {
    await updatePhoto(photo.jobId, photo.id, field, value);
    loadJob();
  }

  function onShowPhotosOfPart(partId: string) {
    if (!job) {
      return;
    }
    setPartPhotos(job.photos.filter(photo => photo.vehiclePartId === partId));
    setPartPhotosModal(true);
  }

  function getTitleOfPartGallery() {
    if (partPhotos.length === 0 || !job) {
      return undefined;
    }
    const partId = partPhotos[0].vehiclePartId;
    const part = job.vehicleParts.find(part => part.id === partId);
    if (!part) {
      return undefined;
    }
    return (
      <span>
        {part.name} / {part.partNumber1}
      </span>
    );
  }

  async function onFilesUpload(files: SelectedFile[]) {
    if (!job) {
      return;
    }
    await uploadFiles(job.id, files);
    loadJob();
  }

  if (!job || !user) {
    return null;
  }

  return (
    <>
      <ConfirmModal
        show={assignToOfficeModal}
        title="Arbeitskarte dem Büro zuweisen"
        onConfirm={assignJobToOffice}
        onCancel={() => setAssignToOfficeModal(false)}>
        <>
          Möchten Sie wirklich die Arbeitskarte dem Büro zuweisen?
          <div className="fs-5 mt-2 text-danger">
            <strong>
              Uwaga! Zanim potwierdzisz odswież strone czy nie ma jakiś dodatkowych zadań. JEźeli
              nie ma to on moze być dopiero nacisnięty jak karta pracy została kompletnie
              wypełniona, wszystkie zdjęcia zrobione i wszystkie częsci wypisane! Czy tak jest?!
            </strong>
          </div>
        </>
      </ConfirmModal>
      <PhotoGallery
        title={getTitleOfPartGallery()}
        jobLinkPrefix="my-jobs"
        photos={partPhotos}
        selectedPhotoIndex={0}
        show={partPhotosModal}
        onClose={() => {
          setPartPhotosModal(false);
          setPartPhotos([]);
        }}
      />
      <PageToolbar
        pageTitle={
          <span>
            Meine Aufgabe
            <Badge bg="primary" className="ms-2">
              {job.jobKey || ''}
            </Badge>
            {job.vehicle && (
              <span className="ps-3">
                {job.vehicle.brandName} {job.vehicle.brandTypeName}
              </span>
            )}
          </span>
        }>
        <ButtonLink link="/my-jobs" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      <Row>
        <Col lg={6}>
          <MyJobTime
            job={job}
            dailySchedule={dailySchedule}
            alreadyStartedJobWarning={alreadyStartedJobWarning}
            onWorkTimeStart={onStartWorkTime}
            onWorkTimeEnd={onEndWorkTime}
          />
        </Col>
        <Col lg={6}>
          <SectionTitle title="Arbeitskarte" />
          <Table striped bordered className="shadow-sm">
            <tbody>
              {job.vehicle && (
                <tr>
                  <td>
                    <strong>Pos. Schlüsselnummer</strong>
                  </td>
                  <td>
                    {job.keyPosition1 ?? ''}/{job.keyPosition2 ?? ''}
                  </td>
                </tr>
              )}
              {job.vehicle === null && (
                <>
                  <tr>
                    <td>
                      <strong>Arbeitskarte für</strong>
                    </td>
                    <td>{job.jobFor}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Teilnummer</strong>
                    </td>
                    <td>{job.itemNumber}</td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {job.vehicle && (
        <Row>
          <Col>
            <VehicleDetails job={job} />
          </Col>
          <Col>
            <RelatedJobsList currentJob={job} linkPrefix="my-jobs" />
          </Col>
        </Row>
      )}
      {(timeStarted || searchParams.get('fromRelatedList') === '1') && (
        <>
          {!uploading && (
            <JobPhotos
              jobLinkPrefix="my-jobs"
              photos={job.photos}
              vehicleParts={job.vehicleParts}
              uploadPhotos={uploadJobPhotos}
              allowedPhotoAdd={!job.isDone && job.mainAssignedUser.id === user.id}
              excludeTags={['admin', 'office']}
              onPhotoVehiclePartUpdate={(photo, value) =>
                onPhotoUpdate(photo, 'vehiclePartId', value)
              }
            />
          )}
          <JobFiles files={job.files} onFilesUpload={onFilesUpload} />
          <VehiclePartsToOrder
            job={job}
            onVehiclePartsSave={onVehiclePartsSave}
            onVehiclePartUpdate={onVehiclePartUpdate}
            showPhotosOfPart={onShowPhotosOfPart}
          />
          <JobNotes job={job} onNewNote={saveNewNote} />
          <MyJobTasks
            job={job}
            onJobDone={onJobDone}
            onTaskDone={onTaskDone}
            onTaskNoteAdd={onTaskNoteAdd}
          />
          {job.jobState === JobState.WORKSHOP && job.mainAssignedUser.id === user.id && (
            <div className="mt-4">
              <ButtonLink
                click={() => setAssignToOfficeModal(true)}
                buttonColor="warning"
                icon="person-fill-up">
                Arbeitskarte dem Büro zuweisen
              </ButtonLink>
            </div>
          )}
        </>
      )}
    </>
  );
}
