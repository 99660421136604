import { useEffect, useState } from 'react';

import { LogEntry } from '../../types/logs';
import { getLogs } from '../../services/api';
import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import LogsTable from './LogsTable';
import { PagedResponseDto } from '../../../../types/pagination';
import ListPagination from '../../../../libs/components/data/ListPagination';
import { Sort } from '../../../../types/sort';

const DEFAULT_PAGE_LIMIT = 50;
const DEFAULT_SORT: Sort = { field: 'createdAt', dir: 'desc' };

export default function LogsList() {
  const [logs, setLogs] = useState<PagedResponseDto<LogEntry>>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSort] = useState<Sort>(DEFAULT_SORT);

  useEffect(() => {
    loadLogs();
  }, [currentPage, sort]);

  function loadLogs() {
    getLogs(currentPage, DEFAULT_PAGE_LIMIT, sort).then(data => setLogs(data));
  }

  function onPageChange(page: number) {
    setCurrentPage(page);
  }

  function onSort(sort: Sort) {
    setSort(sort);
    setCurrentPage(1);
  }

  return (
    <>
      <PageToolbar pageTitle="Logs"></PageToolbar>
      {logs !== undefined && (
        <>
          <LogsTable logs={logs.items} sort={sort} onSort={onSort} />
          <ListPagination
            currentPage={logs._metadata.page}
            pageCount={logs._metadata.pageCount}
            onPageSelect={onPageChange}
          />
        </>
      )}
    </>
  );
}
