import { API } from '../../../libs/services/api';
import { LoginResponseData } from '../types/login';

const BASE = 'auth';

export async function login(login: string, password: string): Promise<LoginResponseData> {
  return (await API.post(`${BASE}/login`, { login, password })).data;
}

export async function logout() {
  return (await API.post(`${BASE}/logout`)).data;
}
