import { Job, JobPhotoForm, JobState, NewJobVehiclePartToAdd } from '../../jobs/types/jobs';
import { API } from '../../../libs/services/api';
import { JobVehiclePartEditFormData } from '../types/vehicleParts';

const BASE = 'my-jobs';

export async function getMyJobs(filter: string): Promise<Job[]> {
  return (await API.get(`${BASE}?filter=${filter.trim()}`)).data;
}

export async function addTaskNote(jobId: string, taskId: string, content: string): Promise<void> {
  return (await API.post(`${BASE}/${jobId}/tasks/${taskId}/notes`, { content })).data;
}

export async function setTaskDone(jobId: string, taskId: string): Promise<void> {
  return (await API.patch(`${BASE}/${jobId}/tasks/${taskId}/done`)).data;
}

export async function uploadPhotos(jobId: string, photos: JobPhotoForm[]) {
  return (await API.patch(`${BASE}/${jobId}/photos`, { photos })).data;
}

export async function setJobDone(jobId: string): Promise<void> {
  return (await API.patch(`${BASE}/${jobId}/done`)).data;
}

export async function startWorkTime(jobId: string, forceStart: boolean): Promise<void> {
  return (await API.patch(`${BASE}/${jobId}/startTime?forceStart=${forceStart ? '1' : '0'}`)).data;
}

export async function endWorkTime(jobId: string): Promise<void> {
  return (await API.patch(`${BASE}/${jobId}/endTime`)).data;
}

export async function saveVehicleParts(
  jobId: string,
  parts: NewJobVehiclePartToAdd[],
): Promise<void> {
  return (await API.post(`${BASE}/${jobId}/vehicleParts`, { parts })).data;
}

export async function updateVehiclePart(
  jobId: string,
  partId: string,
  dto: JobVehiclePartEditFormData,
  isAdminOrOffice: boolean,
): Promise<void> {
  let data: Record<string, any> = {
    name: dto.name,
    type: dto.type,
    partNumber1: dto.partNumber1,
    partNumber2: dto.partNumber2,
    price: Number(dto.price.replace(',', '.')),
    quantity: Number(dto.quantity),
  };

  if (isAdminOrOffice) {
    data = {
      ...data,
      partNumber: dto.partNumber,
      status: dto.status,
      manufacturer: dto.manufacturer,
      invoiceNo: dto.invoiceNo,
      notes: dto.notes,
      orderedIn: dto.orderedIn,
      orderedAt: dto.orderedAt,
      orderTime: dto.orderTime,
      priceVKNet: Number(String(dto.priceVKNet).replace(',', '.')),
      priceEKNet: Number(String(dto.priceEKNet).replace(',', '.')),
      deposit: Number(String(dto.deposit).replace(',', '.')),
    };
  }

  return (await API.patch(`${BASE}/${jobId}/vehicleParts/${partId}`, data)).data;
}

export async function setJobState(jobId: string, jobState: JobState) {
  return (await API.patch(`${BASE}/${jobId}/jobState/${jobState}`)).data;
}
