import { API } from '../../../libs/services/api';
import { WorkTimeInProgress } from '../../work_time/types/workTime';

export async function getWorkTimeInProgress(): Promise<WorkTimeInProgress[]> {
  return (await API.get('/work-time/allInProgress')).data;
}

export async function getLatestWorkTime(): Promise<WorkTimeInProgress[]> {
  return (await API.get('/work-time/latest')).data;
}

export async function stopAllWorkTimeInProgress(): Promise<void> {
  return (await API.post('/work-time/stopAll')).data;
}
