import { Table } from 'react-bootstrap';

import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import { Job, JobState } from '../../../jobs/types/jobs';
import { Link } from 'react-router-dom';
import Status from '../../../../libs/components/data/Status';
import { getSumOfWorkTime, getSumOfWorkTimeForJobAndUser, workTimeDiff } from '../../services/time';
import { IconDetails } from '../../../../libs/components/data/Icon';
import TableHeader from '../../../../libs/components/data/table/TableHeader';

interface JobsTableProps {
  jobs: Job[];
  userId: string;
}

const TABLE_COLUMNS = [
  { key: 'id', label: 'ID', width: 65 },
  { key: 'user', label: 'Bearbeiter', width: 100 },
  { key: 'estimatedDone', label: 'Fertig bis', width: 110 },
  { key: 'estimatedTime', label: 'Geschätzte Arbeitszeit', width: 120 },
  { key: 'workTime', label: 'Meine Arbeitszeit', width: 120 },
  { key: 'manager', label: 'Aufgabenman.' },
  { key: 'plate', label: 'Kennz.', width: 120 },
  { key: 'brand', label: 'Marke' },
  { key: 'mileage', label: 'KM-Stand', width: 100 },
  { key: 'keyPosition1', label: 'Pos. Schlnr.', width: 75 },
];

export default function MyJobsTable({ jobs, userId }: JobsTableProps) {
  const today = new Date().toISOString().substring(0, 10);

  return (
    <Table striped bordered className="shadow-sm">
      <TableHeader columns={TABLE_COLUMNS} actionColumn={true} actionsLabel="" />
      <tbody>
        {jobs.map(item => (
          <tr key={item.id}>
            <td>{item.jobKey}</td>
            <td>
              <Status
                status={item.jobState === JobState.WORKSHOP}
                labelTrue="Wekstatt"
                labelFalse="Büro"
                colorTrue="primary"
                colorFalse="warning"
              />
            </td>
            <td>{getFormattedDateOrEmpty(item.expectedDoneAt)}</td>
            <td>
              {item.estimatedWorkTimeHours} St. {item.estimatedWorkTimeMinutes} Min.
            </td>
            <td
              className={`text-${
                workTimeDiff(
                  item.estimatedWorkTimeHours,
                  item.estimatedWorkTimeMinutes,
                  getSumOfWorkTime(
                    item.workTime.filter(
                      t => t.userId === userId && t.startDate.substring(0, 10) === today,
                    ),
                    false,
                  ) as number,
                ) <= 0
                  ? 'danger'
                  : 'success'
              }`}>
              {getSumOfWorkTimeForJobAndUser(item, userId, today)}
            </td>
            <td>{item.mainAssignedUser.name}</td>
            <td>{item.vehicle?.plate || '---'}</td>
            <td>
              {item.vehicle?.brandName || '---'} {item.vehicle?.brandTypeName || ''}
            </td>
            <td>{item.vehicle ? item.mileage : '---'}</td>
            <td>
              {item.keyPosition1} / {item.keyPosition2}
            </td>
            <td>
              <Link to={`/my-jobs/${item.id}`} className="text-info">
                <IconDetails />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
