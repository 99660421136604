import { Col, Row } from 'react-bootstrap';
import FormInput from '../../../../libs/components/forms/FormInput';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';

export default function JobTimesForm() {
  return (
    <Row>
      <SectionTitle title="Arbeitszeit" />
      <Col lg={2}>
        <FormInput name="expectedDoneAt" label="Fahrzeug fertig bis" type="date" required={true} />
      </Col>
      <Col lg={4}>
        <Row>
          <Col lg={6}>
            <FormInput
              name="estimatedWorkTimeHours"
              label="Arbeitszeit Stunden"
              type="number"
              required={true}
            />
          </Col>
          <Col lg={6}>
            <FormInput
              name="estimatedWorkTimeMinutes"
              label="Arbeitszeit Minuten"
              type="select"
              selectItems={[
                { value: '0', label: '0' },
                { value: '15', label: '15' },
                { value: '30', label: '30' },
                { value: '45', label: '45' },
              ]}
              required={true}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
