import React from 'react';

import PageToolbar from '../../../libs/components/actions/PageToolbar';
import DbBackupStatus from './DbBackupStatus';
import DiskStatus from './DiskStatus';

export default function StatusDashboard() {
  return (
    <>
      <PageToolbar pageTitle="System Status" />
      <DbBackupStatus />
      <DiskStatus />
    </>
  );
}
