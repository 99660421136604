import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import { WorkTimeInProgress } from '../../types/workTime';
import {
  IconDelete,
  IconDetails,
  IconEdit,
  IconSeparator,
  IconVehicleDetails,
} from '../../../../libs/components/data/Icon';
import { isStartStopTheSame } from '../../services/time';
import TableHeader from '../../../../libs/components/data/table/TableHeader';

interface WorkTimeTableProps {
  items: WorkTimeInProgress[];
  onDelete: (item: WorkTimeInProgress) => void;
  onEdit: (item: WorkTimeInProgress) => void;
}

const TABLE_COLUMNS = [
  { key: 'startDate', label: 'Begonnen am', width: 150 },
  { key: 'endDate', label: 'Beendet am', width: 150 },
  { key: 'duration', label: 'Dauer', width: 150 },
  { key: 'user', label: 'Mitarbeiter' },
  { key: 'plate', label: 'Kennzeichen' },
  { key: 'brand', label: 'Marke' },
];

export default function WorkTimeTable({ items, onEdit, onDelete }: WorkTimeTableProps) {
  return (
    <Table striped bordered className="shadow-sm">
      <TableHeader columns={TABLE_COLUMNS} actionColumn={true} />
      <tbody>
        {items.map(item => (
          <tr
            key={item.id}
            className={`${!isStartStopTheSame(item.startDate, item.endDate) ? 'bg-danger' : ''}`}>
            <td>{getFormattedDateOrEmpty(item.startDate, 'datetime')}</td>
            <td>{getFormattedDateOrEmpty(item.endDate, 'datetime')}</td>
            <td>{item.duration}</td>
            <td>{item.user.name}</td>
            <td>{item.vehicle?.plate ?? '---'}</td>
            <td>
              {item.vehicle !== null && (
                <>
                  {item.vehicle.brandName} {item.vehicle.brandTypeName}
                </>
              )}
              {item.vehicle === null && <span>---</span>}
            </td>
            <td>
              <Link to={`/jobs/${item.jobId}`} className="text-primary me-3">
                <IconDetails />
              </Link>
              {item.vehicle !== null && (
                <>
                  <Link to={`/vehicles/${item.vehicle.id}`} className="text-info">
                    <IconVehicleDetails />
                  </Link>
                  <IconSeparator />
                </>
              )}
              <IconEdit onClick={() => onEdit(item)} />
              <IconSeparator />
              {item.endDate !== null && <IconDelete onClick={() => onDelete(item)} />}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
