import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useReducer, useState } from 'react';
import {
  FormActionState,
  getFormActionReducer,
} from '../../../../libs/services/state/formActionReducer';
import { PhoneCallForm } from './PhoneCallForm';
import ApiErrorMessage from '../../../../libs/components/data/ApiErrorMessage';
import Message from '../../../../libs/components/data/Message';
import { PhoneCall, PhoneCallFormData } from '../../types/phone_calls';
import { getPhoneCall, updatePhoneCall } from '../../services/api';

const initialState: FormActionState = {
  actionInProgress: false,
  actionSuccess: false,
  actionError: false,
  showForm: true,
  actionErrorResponse: undefined,
};

export default function PhoneCallEdit() {
  const { id } = useParams();
  const [phoneCall, setPhoneCall] = useState<PhoneCall>();
  const [formData, setFormData] = useState<PhoneCallFormData>();
  const [state, dispatch] = useReducer(getFormActionReducer(initialState), initialState);
  const navigate = useNavigate();
  const search = useLocation().search;
  const created = new URLSearchParams(search).get('created');

  useEffect(() => {
    if (!id) {
      return;
    }
    getPhoneCall(id).then(data => {
      setPhoneCall(data);
      setFormData({ ...data });
    });
  }, [id]);

  async function onSubmit(action: string, data: PhoneCallFormData) {
    if (!phoneCall) {
      return;
    }

    await updatePhoneCall(id!, data);

    if (action === 'saveAndClose') {
      navigate('/phone-calls');
    }

    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      await updatePhoneCall(phoneCall.id, data);
      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    }
  }

  return (
    <>
      <PageToolbar pageTitle="Telefongespräch bearbeiten">
        <ButtonLink link="/phone-calls" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      {created && (
        <div className="mb-4">
          <Message type="ok">
            <span>
              <strong>Telefongespräch wurde angelegt</strong>
            </span>
          </Message>
        </div>
      )}
      {state.showForm && formData && (
        <div className="mb-4">
          <PhoneCallForm
            mode="edit"
            submitButtonLabel="Speichern"
            onSubmit={onSubmit}
            initialValues={{
              ...formData,
              assignedUser: formData['assignedUser'] ? formData['assignedUser']['_id'] : null,
              resubmissionUser: formData['resubmissionUser']
                ? formData['resubmissionUser']['_id']
                : null,
              callDate: formData['callDate'].substring(0, 10),
              resubmission: formData['resubmission']?.substring(0, 10),
            }}
          />
        </div>
      )}
      {state.actionError && (
        <ApiErrorMessage
          label="Telefongespräch konnte nicht aktualisiert werden"
          errorResponse={state.actionErrorResponse}
        />
      )}
      {state.actionSuccess && (
        <Message type="ok">
          <span>
            <strong>Telefongespräch wurde aktualisiert</strong>
          </span>
        </Message>
      )}
    </>
  );
}
