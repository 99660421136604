import * as Yup from 'yup';

export const VEHICLE_FIN_LENGTH = 17;
export const VEHICLE_HSN_LENGTH = 4;
export const VEHICLE_TSN_LENGTH = 3;
export const VEHICLE_MILEAGE_MIN = 0;
export const VEHICLE_PLATE_FORMAT = /^[A-ZÖÜÄ]{1,3}-[A-ZÖÜÄ]{1,2} [1-9]{1}[0-9]{1,3}$/;

export function getValidationSchema() {
  const plate = Yup.string().required('Kennzeichen darf nicht leer sein');
  // .matches(VEHICLE_PLATE_FORMAT, 'Falsches Format');

  const mileage = Yup.number()
    .typeError('KM-Stand muss eine Zahl sein')
    .required('KM-Stand darf nicht leer sein')
    .integer('Falsches Format')
    .positive('KM-Stand muss eine positive Zahl sein');

  const registrationDate = Yup.date()
    .typeError('Erstzulassung must ein valides Datum sein')
    .required('Erstzulassung darf nicht leer sein');

  const fin = Yup.string()
    .required('FIN darf nicht leer sein')
    .length(VEHICLE_FIN_LENGTH, `FIN muss aus ${VEHICLE_FIN_LENGTH} Zeichen bestehen`);

  const hsn = Yup.string()
    .required('HSN darf nicht leer sein')
    .length(VEHICLE_HSN_LENGTH, `HSN muss aus ${VEHICLE_HSN_LENGTH} Zeichen bestehen`);

  const tsn = Yup.string()
    .required('TSN darf nicht leer sein')
    .length(VEHICLE_TSN_LENGTH, `TSN muss aus ${VEHICLE_TSN_LENGTH} Zeichen bestehen`);

  const kw = Yup.number()
    .typeError('kW muss eine Zahl sein')
    .required('kW darf nicht leer sein')
    .min(1, 'kW muss mindestens 1 sein');

  const displacement = Yup.number()
    .typeError('Hubraum muss eine Zahl sein')
    .required('Hubraum darf nicht leer sein')
    .integer('Falsches Format')
    .min(100, 'Hubraum muss mindestens 100 ccm sein');

  const inspectionHU = Yup.date()
    .typeError('HU must ein valides Datum sein')
    .required('HU Datum darf nicht leer sein');

  const inspectionAU = Yup.date()
    .typeError('AU must ein valides Datum sein')
    .required('AU Datum darf nicht leer sein');

  const inspectionHURemind = Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError('HU must ein valides Datum sein');

  const lastInspection = Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError('"Nächste Inspektion" must ein valides Datum sein');

  const nextInspectionRemind = Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError('"Nächste Inspektion Erinnerung" must ein valides Datum sein');

  return Yup.object().shape({
    plate,
    mileage,
    registrationDate,
    fin,
    hsn,
    tsn,
    kw,
    displacement,
    inspectionHU,
    inspectionAU,
    inspectionHURemind,
    lastInspection,
    nextInspectionRemind,
  });
}
