import * as Yup from 'yup';

export function getCategoryValidationSchema() {
  const name = Yup.string().required('Name darf nicht leer sein');
  const sortOrder = Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, {
      message: 'Reihenfolge muss eine Zahl sein',
      excludeEmptyString: true,
    });

  return Yup.object().shape({ name, sortOrder });
}

export function getSubCategoryValidationSchema() {
  const name = Yup.string().required('Name darf nicht leer sein');
  const sortOrder = Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, {
      message: 'Reihenfolge muss eine Zahl sein',
      excludeEmptyString: true,
    });

  return Yup.object().shape({ name, sortOrder });
}
