export const phoneCallCategories = [
  'Allgemeine Anfrage',
  'Bestehender Reparaturauftrag',
  'Getriebespülung',
  'Getriebereparatur',
  'Verkauf von Teilen',
  'TÜV',
  'Partikelfilter',
  'Inspektion',
  'Lagersatz',
  'Sonstige',
];

export const phoneCallTypes = ['Eingehender Anruf', 'Ausgehender Anruf'];

export const phoneCallFormats = ['Telefonischer Kontakt', 'WhatsApp', 'Kleinanzeigen', 'Andere'];

export const phoneCallStatuses = ['erledigt', 'in Bearbeitung'];
