import { API } from '../../../libs/services/api';
import { TodaysWorkOverview, WorkOverview, WorkTimeSummary } from '../types/workTime';

const BASE = 'stats';

export async function getJobWorkTimeSummary(jobId: string): Promise<WorkTimeSummary> {
  return (await API.get(`${BASE}/jobWorkTime/${jobId}`)).data;
}

export async function getTodaysWorkOverview(day: string): Promise<TodaysWorkOverview> {
  return (await API.get(`${BASE}/todaysWork?day=${day}`)).data;
}

export async function getTodaysWorkOverviewForUser(userId: string): Promise<WorkOverview> {
  return (await API.get(`${BASE}/todaysWork/${userId}`)).data;
}
