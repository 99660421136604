import { API } from '../../../libs/services/api';
import { PhoneCall, PhoneCallFormData } from '../types/phone_calls';

const BASE = 'phoneCalls';

export async function getPhoneCalls(
  filter: string,
  categoryFilter: string,
  statusFilter: string,
): Promise<PhoneCall[]> {
  return (
    await API.get(`${BASE}?filter=${filter}&category=${categoryFilter}&status=${statusFilter}`)
  ).data;
}

export async function getNextResubmissionPhoneCalls(
  filter: string,
  categoryFilter: string,
  statusFilter: string,
): Promise<PhoneCall[]> {
  return (
    await API.get(
      `${BASE}/next-resubmission?filter=${filter}&category=${categoryFilter}&status=${statusFilter}`,
    )
  ).data;
}

export async function getAllResubmissionPhoneCalls(): Promise<PhoneCall[]> {
  return (await API.get(`${BASE}/resubmission`)).data;
}

export async function getAutocomplete(): Promise<Record<string, unknown>[]> {
  return (await API.get(`${BASE}/autocomplete`)).data;
}

export async function getPhoneCall(id: string): Promise<PhoneCall> {
  return (await API.get(`${BASE}/${id}`)).data;
}

export async function getOtherPhoneCallsForNumber(phoneNumber: string, skipId: string) {
  return (await API.get(`${BASE}/list/${phoneNumber}?skipId=${skipId}`)).data;
}

export async function addPhoneCall(data: PhoneCallFormData): Promise<PhoneCall> {
  return (await API.post(BASE, transformFormToDto(data))).data;
}

export async function updatePhoneCall(id: string, data: PhoneCallFormData): Promise<PhoneCall> {
  return (await API.patch(`${BASE}/${id}`, transformFormToDto(data))).data;
}

export async function addPhoneCallNote(
  id: string,
  note: string,
  noteType: string,
  newNoteResubmission: string | undefined,
  done?: boolean,
) {
  return (
    await API.post(`${BASE}/${id}/notes?done=${done ? '1' : '0'}`, {
      content: note,
      type: noteType,
      resubmission: newNoteResubmission,
    })
  ).data;
}

export async function markPhoneCallAsDone(id: string) {
  await API.patch(`${BASE}/${id}/done`);
}

export async function deletePhoneCall(id: string): Promise<void> {
  await API.delete(`${BASE}/${id}`);
}

function transformFormToDto(data: PhoneCallFormData) {
  return {
    ...data,
    note: data['note'] ?? null,
    phoneNumber: String(data['phoneNumber']),
    callDate: data['callDate'].toLocaleDateString('sv'),
    resubmission: data['resubmission'] ? data['resubmission'].toLocaleDateString('sv') : null,
  };
}
