import { useEffect, useState } from 'react';
import TreeView, { flattenTree } from 'react-accessible-treeview';

import { deleteStorageCategory, getStorageCategories } from '../../services/api';
import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';
import { StorageCategory } from '../../types/storageCategories';
import { transformCategoriesToTreeData } from '../../services/categoryTree';
import { IconAdd, IconDelete, IconEdit } from '../../../../libs/components/data/Icon';
import { Link } from 'react-router-dom';
import AddSubCategoryModal from '../AddSubCategoryModal';

export default function StorageCategoriesList() {
  const [categories, setCategories] = useState<StorageCategory[]>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [actionItemId, setActionItemId] = useState<string | undefined>();
  const [selectedCategory, setSelectedCategory] = useState<
    { id: string; name: string } | undefined
  >();
  const [addSubCategoryModal, setAddSubCategoryModal] = useState<boolean>(false);

  useEffect(() => {
    loadCategories();
  }, []);

  function loadCategories() {
    getStorageCategories().then(cats => {
      setCategories(cats);
    });
  }

  function onConfirmDelete(id: string, level: number) {
    setActionItemId(id);
    setDeleteModal(true);
  }

  async function onDelete() {
    if (actionItemId) {
      await deleteStorageCategory(actionItemId);
      loadCategories();
    }
    setActionItemId(undefined);
    setDeleteModal(false);
  }

  function onSubCategoryCreate() {
    setAddSubCategoryModal(false);
    loadCategories();
  }

  function onAddSubCategory(id: string) {
    setSelectedCategory(findCategory(id)!);
    setAddSubCategoryModal(true);
  }

  function findCategory(id: string) {
    let found: { id: string; name: string } | null = null;

    categories.forEach(cat => {
      if (cat.id === id) {
        found = cat;
      } else {
        cat.subCategoriesLevel1.forEach(level1 => {
          if (level1.id === id) {
            found = level1;
          } else {
            level1.subCategoriesLevel2.forEach(level2 => {
              if (level2.id === id) {
                found = level2;
              } else {
                level2.subCategoriesLevel3.forEach(level3 => {
                  if (level3.id === id) {
                    found = level3;
                  } else {
                    level3.subCategoriesLevel4.forEach(level4 => {
                      if (level4.id === id) {
                        found = level4;
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    return found;
  }

  return (
    <>
      <AddSubCategoryModal
        selectedCategory={selectedCategory}
        show={addSubCategoryModal}
        onSaved={onSubCategoryCreate}
        onCancel={() => setAddSubCategoryModal(false)}
      />
      <ConfirmModal
        show={deleteModal}
        title="Kategorie löschen"
        onConfirm={onDelete}
        onCancel={() => setDeleteModal(false)}>
        <span>Möchten Sie wirklich diese Kategorie löschen?</span>
      </ConfirmModal>
      <PageToolbar pageTitle="Lager Kategorien">
        <ButtonLink link="/storage-categories/add" buttonColor="success" icon="plus-circle-fill">
          Kategorie hinzufügen
        </ButtonLink>
      </PageToolbar>
      <TreeView
        data={flattenTree(transformCategoriesToTreeData(categories))}
        nodeRenderer={({ element, isBranch, isExpanded, getNodeProps, level, handleExpand }) => {
          return (
            <div
              {...getNodeProps({ onClick: handleExpand })}
              style={{ marginLeft: 20 * (level - 1), backgroundColor: '#ebebeb', padding: 5 }}>
              <i className={`bi bi-caret-${isExpanded ? 'down' : 'right'}-fill`}></i>
              <span className="name" style={{ fontWeight: level === 1 ? 'bold' : 'normal' }}>
                {element.name}
              </span>
              <span className="px-2"></span>
              <span style={{ fontSize: '1rem' }}>
                <Link to={`/storage-categories/edit/${element.id}`} className="text-info">
                  <IconEdit />
                </Link>
                {level < 5 && (
                  <>
                    <span className="px-2"></span>
                    <IconAdd onClick={() => onAddSubCategory(element.id as string)} />
                  </>
                )}
                {!isBranch && (
                  <>
                    <span className="px-2"></span>
                    <IconDelete onClick={() => onConfirmDelete(element.id as string, level - 0)} />
                  </>
                )}
              </span>
            </div>
          );
        }}
      />
    </>
  );
}
