import { Button, Form, Modal } from 'react-bootstrap';
import * as React from 'react';
import { useState } from 'react';

import ErrorList from '../../../libs/components/data/ErrorList';
import { JobCategory, JobSubCategory } from '../types/jobCategories';
import { addJobSubCategory } from '../services/api';

interface AddSubCategoryModalProps {
  selectedCategory: JobCategory | undefined;
  show: boolean;
  onSaved: () => void;
  onCancel: () => void;
}

export default function AddSubCategoryModal(props: AddSubCategoryModalProps) {
  const [name, setName] = useState<string>('');
  const [defaultDescription, setDefaultDescription] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<number>(0);
  const [errorResponse, setErrorResponse] = useState<unknown>();

  async function onSubmit() {
    if (name.trim() === '' || !props.selectedCategory) {
      return;
    }

    const existingSubCategory = props.selectedCategory?.subCategories.find(
      (item: JobSubCategory) => item.name === name,
    );

    if (existingSubCategory) {
      setErrorResponse({
        response: { data: { message: 'Diese Unterkategorie bereits existiert' } },
      });
      return;
    }

    try {
      await addJobSubCategory(props.selectedCategory.id, {
        name,
        defaultDescription,
        sortOrder,
      });
      setName('');
      setDefaultDescription('');
      props.onSaved();
    } catch (err) {
      setErrorResponse(err);
    }
  }

  function onCancel() {
    setName('');
    setDefaultDescription('');
    props.onCancel();
  }

  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  function onChangeDefaultDescription(event: React.ChangeEvent<HTMLInputElement>) {
    setDefaultDescription(event.target.value);
  }

  function onChangeSortOrder(event: React.ChangeEvent<HTMLInputElement>) {
    setSortOrder(Number(event.target.value));
  }

  if (!props.selectedCategory) {
    return null;
  }

  return (
    <Modal show={props.show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          Unterkategorie für <strong>{props.selectedCategory.name}</strong> hinzufügen
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Form.Group>
            <Form.Label>
              Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control type="text" onChange={onChangeName} value={name} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Standardbeschreibung</Form.Label>
            <Form.Control
              type="text"
              onChange={onChangeDefaultDescription}
              value={defaultDescription}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Reihenfolge</Form.Label>
            <Form.Control type="number" onChange={onChangeSortOrder} value={sortOrder} />
          </Form.Group>
          {errorResponse && <ErrorList errorResponse={errorResponse} />}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Abbrechen
        </Button>
        <Button variant="primary" onClick={onSubmit} disabled={name.trim() === ''}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
