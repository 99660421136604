import React from 'react';
import { JobVehiclePart, JobVehiclePartOrderStatus } from '../../../jobs/types/jobs';

interface VehiclePartStatusProps {
  part: JobVehiclePart;
}

export default function VehiclePartStatus({ part }: VehiclePartStatusProps) {
  let label = '';
  let color = '';

  switch (part.orderStatus) {
    case JobVehiclePartOrderStatus.TO_ORDER:
      label = 'zu bestellen';
      color = 'warning';
      break;
    case JobVehiclePartOrderStatus.ORDERED:
      label = 'bestellt';
      color = 'info';
      break;
    case JobVehiclePartOrderStatus.DELIVERED:
      label = 'geliefert';
      color = 'success';
      break;
  }

  return <div className={`badge bg-${color}`}>{label}</div>;
}
