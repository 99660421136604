import React, { useEffect, useState } from 'react';

import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import PhoneCallsTable from './PhoneCallsTable';
import { Col, Form, Row } from 'react-bootstrap';
import { PhoneCall } from '../../types/phone_calls';
import { deletePhoneCall, getNextResubmissionPhoneCalls, getPhoneCalls } from '../../services/api';
import { phoneCallCategories } from '../../config/phone_calls';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';

export default function PhoneCallsList() {
  const [phoneCalls, setPhoneCalls] = useState<PhoneCall[]>([]);
  const [nextResubmissionPhoneCalls, setNextResubmissionPhoneCalls] = useState<PhoneCall[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [categoryFilter, setCategoryFilter] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [actionItem, setActionItem] = useState<PhoneCall | undefined>();

  useEffect(() => {
    loadPhoneCalls();
  }, [filter, categoryFilter, statusFilter]);

  function loadPhoneCalls() {
    getPhoneCalls(filter, categoryFilter, statusFilter).then(data => setPhoneCalls(data));
    getNextResubmissionPhoneCalls(filter, categoryFilter, statusFilter).then(data =>
      setNextResubmissionPhoneCalls(data),
    );
  }

  async function onCategoryFilter(event: React.ChangeEvent<HTMLSelectElement>) {
    setCategoryFilter(event.target.value);
  }

  async function onStatusFilter(event: React.ChangeEvent<HTMLSelectElement>) {
    setStatusFilter(event.target.value);
  }

  function onConfirmDelete(item: PhoneCall) {
    setActionItem(item);
    setDeleteModal(true);
  }

  async function onDelete() {
    if (actionItem) {
      await deletePhoneCall(actionItem.id);
      loadPhoneCalls();
    }
    setActionItem(undefined);
    setDeleteModal(false);
  }

  return (
    <>
      <ConfirmModal
        show={deleteModal}
        title="Telefongespräch löschen"
        onConfirm={onDelete}
        onCancel={() => setDeleteModal(false)}>
        <span>Möchten Sie wirklich das Telefongespräch löschen?</span>
      </ConfirmModal>
      <PageToolbar pageTitle="Telefongespräche" titleColWidth={3}>
        <Row>
          <Col lg={{ offset: 0, span: 1 }} className="pt-2">
            Filtern
          </Col>
          <Col lg={{ span: 2 }}>
            <Form.Control onChange={e => setFilter(e.target.value || '')} />
          </Col>
          <Col className="pt-2" lg={1}>
            Kat.
          </Col>
          <Col lg={3}>
            <Form.Select onChange={onCategoryFilter} value={categoryFilter}>
              <option value="">--- Alle ---</option>
              {phoneCallCategories.map(item => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col className="pt-2" lg={1}>
            Status
          </Col>
          <Col lg={2}>
            <Form.Select onChange={onStatusFilter} value={statusFilter}>
              <option value="">--- Alle ---</option>
              <option value="erledigt">erledigt</option>
              <option value="in Bearbeitung">in Bearbeitung</option>
            </Form.Select>
          </Col>
          <Col lg={2}>
            <ButtonLink link="/phone-calls/add" buttonColor="success" icon="plus-circle-fill">
              Neues Gespräch
            </ButtonLink>
          </Col>
        </Row>
      </PageToolbar>
      <SectionTitle title="Anstehende Wiedervorlagen" />
      <div className="mb-3 text-end">
        <ButtonLink link="/phone-calls/resubmission" buttonColor="secondary" size="sm">
          Alle mit Wiedervorlage anzeigen
        </ButtonLink>
      </div>
      <PhoneCallsTable mode="resubmission" phoneCalls={nextResubmissionPhoneCalls} />
      <SectionTitle title="Alle Gespräche" />
      <PhoneCallsTable phoneCalls={phoneCalls} onDelete={onConfirmDelete} />
    </>
  );
}
