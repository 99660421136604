import React, { useEffect, useState } from 'react';

import PageToolbar from '../../../libs/components/actions/PageToolbar';
import { TodaysWorkOverview, WorkOverviewItem } from '../../stats/types/workTime';
import { getTodaysWorkOverview } from '../../stats/services/api';
import { Card, Col, Form, Row } from 'react-bootstrap';
import UserOverview from './UserOverview';
import ConfirmModal from '../../../libs/components/modals/ConfirmModal';
import { useAuth } from '../../../libs/context/AuthContext';
import { deleteWorkTimeItem } from '../../work_time/services/api';
import EditWorkTimeModal from '../../work_time/components/WorkTimeList/EditWorkTimeModal';
import { User } from '../../users/types/users';
import { Vehicle } from '../../vehicles/types/vehicles';
import { getDailyScheduleForDay } from '../../daily_schedule/services/api';
import { DailySchedule } from '../../daily_schedule/types/dailySchedule';

export default function WorkOverview() {
  const { user } = useAuth();
  const [workOverview, setWorkOverView] = useState<TodaysWorkOverview | undefined>(undefined);
  const [dailySchedule, setDailySchedule] = useState<DailySchedule[] | undefined>(undefined);
  const [dayFilter, setDayFilter] = useState<string>(new Date().toISOString().substring(0, 10));
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [actionItem, setActionItem] = useState<WorkOverviewItem | undefined>();
  const [editItemModal, setEditItemModal] = useState<boolean>(false);

  useEffect(() => {
    loadOverview();
  }, []);

  useEffect(() => {
    loadOverview();
  }, [dayFilter]);

  function loadOverview() {
    getTodaysWorkOverview(dayFilter).then(data => {
      setWorkOverView(data);
      getDailyScheduleForDay(dayFilter).then(data => setDailySchedule(data));
    });
  }

  function onOpenEdit(item: WorkOverviewItem) {
    setActionItem(item);
    setEditItemModal(true);
  }

  function onConfirmDelete(item: WorkOverviewItem) {
    setActionItem(item);
    setDeleteModal(true);
  }

  async function onDelete() {
    if (!actionItem || !user?.isAdmin) {
      return;
    }
    await deleteWorkTimeItem(actionItem.jobId, actionItem.id);
    setActionItem(undefined);
    setDeleteModal(false);
    loadOverview();
  }

  async function onSaveItem() {
    setActionItem(undefined);
    setEditItemModal(false);
    loadOverview();
  }

  return (
    <>
      <ConfirmModal
        show={deleteModal}
        title="Arbeitszeit löschen"
        onConfirm={onDelete}
        onCancel={() => setDeleteModal(false)}>
        <div>Möchten Sie wirklich den Eintrag löschen?</div>
      </ConfirmModal>
      {actionItem && actionItem.endDate !== null && (
        <EditWorkTimeModal
          workTime={{
            id: actionItem.id,
            jobId: actionItem.jobId,
            user: {} as User,
            startDate: `${actionItem.startDate}T${actionItem.startTime}`,
            endDate: `${actionItem.endDate}T${actionItem.endTime}`,
            duration: '',
            vehicle: {} as Vehicle,
          }}
          show={editItemModal}
          onSaved={onSaveItem}
          onCancel={() => setEditItemModal(false)}
        />
      )}
      <PageToolbar pageTitle="Arbeitsübersicht">
        <Row>
          <Col lg={7}></Col>
          <Col lg={1} className="pt-2">
            Tag
          </Col>
          <Col lg={4}>
            <Form.Control
              type="date"
              value={dayFilter}
              onChange={e => setDayFilter(e.target.value)}
            />
          </Col>
        </Row>
      </PageToolbar>
      {workOverview !== undefined && dailySchedule !== undefined && (
        <Row>
          {Object.keys(workOverview).map(user => (
            <Col key={user} lg={6}>
              <Card className="border-0">
                <Card.Body>
                  <UserOverview
                    date={dayFilter}
                    dailySchedule={dailySchedule}
                    username={user}
                    userId={workOverview[user]?.items[0]?.userId}
                    workTime={workOverview[user]}
                    onWorkTimeEdit={onOpenEdit}
                    onWorkTimeDelete={onConfirmDelete}
                  />
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}
