import { useState } from 'react';

import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';
import FilesTable from './FilesTable';
import { JobFile } from '../../../jobs/types/jobs';
import { useFiles } from '../../hooks/useFiles';
import { useAuth } from '../../../../libs/context/AuthContext';

export default function FilesList() {
  const { files, deleteFile } = useFiles();
  const { user } = useAuth();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [actionItem, setActionItem] = useState<JobFile | undefined>();
  const [error, setError] = useState<string>('');

  function onConfirmDelete(item: JobFile) {
    setActionItem(item);
    setError('');
    setDeleteModal(true);
  }

  async function onDelete() {
    setError('');
    if (actionItem) {
      try {
        await deleteFile(actionItem.jobId, actionItem.id);
        setActionItem(undefined);
        setDeleteModal(false);
      } catch (err: any) {
        setError(err.response?.data?.message || err.message);
      }
    }
  }

  if (!user) {
    return null;
  }

  return (
    <>
      <ConfirmModal
        show={deleteModal}
        title="Datei löschen"
        onConfirm={onDelete}
        onCancel={() => setDeleteModal(false)}>
        <>
          <div>Möchten Sie wirklich die Datei löschen?</div>
          <div>
            <strong>{actionItem?.fileName}</strong>
          </div>
          {error !== '' && <div className="text-danger mt-2">{error}</div>}
        </>
      </ConfirmModal>
      <PageToolbar pageTitle="Dateien"></PageToolbar>
      <FilesTable files={files} authUser={user} onDelete={onConfirmDelete} />
    </>
  );
}
