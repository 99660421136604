import { Vehicle } from '../../vehicles/types/vehicles';
import { User } from '../../users/types/users';
import { JobCategory, JobSubCategory } from '../../job_categories/types/jobCategories';

export interface Job {
  id: string;
  jobKey: string;
  mileage: number;
  notes: JobNote[];
  adminNotes: JobNote[];
  isDone: boolean;
  vehicle: Vehicle | null;
  createdBy: User;
  createdAt: string;
  doneAt: string;
  expectedDoneAt: string;
  mainAssignedUser: User;
  assignedUsers: User[];
  tasks: JobTask[];
  photos: JobPhoto[];
  files: JobFile[];
  workTime: JobWorkTime[];
  vehicleParts: JobVehiclePart[];
  estimatedWorkTimeHours: number;
  estimatedWorkTimeMinutes: number;
  jobState: JobState;
  keyPosition1: number | null;
  keyPosition2: number | null;
  isArchived: boolean;
  jobFor: string;
  clientName: string;
  itemNumber: string;
}

export enum JobVehiclePartOrderStatus {
  TO_ORDER = 'toOrder',
  ORDERED = 'ordered',
  DELIVERED = 'delivered',
}

export interface JobVehiclePart {
  id: string;
  name: string;
  type: 'Artikel' | 'Eigene Position';
  partNumber1: string;
  partNumber2: string;
  price: number;
  quantity: number;
  orderStatus?: JobVehiclePartOrderStatus;
  notes: string;
  createdAt: string;
  createdBy: User;
  orderStatusLastChangedAt: string | null;
  orderStatusLastChangedBy: User | null;

  // admin:
  partNumber?: string;
  manufacturer?: string;
  invoiceNo?: string;
  orderedIn?: string;
  orderedAt?: string;
  orderTime?: string;
  priceVKNet?: number;
  priceEKNet?: number;
  deposit?: number;
}

export type NewJobVehiclePartToAdd = Omit<
  JobVehiclePart,
  | 'id'
  | 'notes'
  | 'createdAt'
  | 'createdBy'
  | 'orderStatusLastChangedAt'
  | 'orderStatusLastChangedBy'
> &
  NewJobVehiclePartToAddAdminFields;

interface NewJobVehiclePartToAddAdminFields {
  partNumber?: string;
  status?: string;
  manufacturer?: string;
  invoiceNo?: string;
  notes?: string;
  orderedIn?: string;
  orderedAt?: string;
  orderTime?: string;
  priceVKNet?: number;
  priceEKNet?: number;
  deposit?: number;
}

export interface JobTask {
  id: string;
  category: JobCategory;
  subCategory: JobSubCategory;
  description: string;
  isDone: boolean;
  assignedUserNotes: JobNote[];
  lastUpdatedAt: string;
  doneAt: string;
  createdAt: string;
}

export interface JobWorkTime {
  id: string;
  userId: string;
  startDate: string;
  endDate: string | null;
}

export interface JobNote {
  id: string;
  content: string;
  createdAt: string;
  createdBy: User;
}

export interface JobPhoto {
  id: string;
  jobId: string;
  vehiclePartId: string | null;
  fileName: string;
  thumbnailFileName: string | null;
  tags: string[];
  createdAt: string;
  createdBy: User;
  onlyAdmin: boolean;
  onlyOffice: boolean;
}

export interface JobFile {
  id: string;
  jobId: string;
  fileName: string;
  tags: string[];
  createdAt: string;
  createdBy: User;
  onlyAdmin: boolean;
  onlyOffice: boolean;
}

export type JobFormData = Record<string, any>;

export interface TransformedJobFormData {
  keyPosition1: number;
  keyPosition2: number;
  brand: string;
  brandType: string;
  plate: string;
  mileage: number;
  registrationDate: string;
  fin: string;
  hsn: string;
  tsn: string;
  kw: number;
  displacement: number;
  notes: string;
  adminNotes: string;
  mainAssignedUser: string;
  assignedUsers: string[];
  tasks: JobTaskFormData[];
  photos: JobPhotoFormWithoutId[];
  expectedDoneAt: string;
  estimatedWorkTimeHours: number;
  estimatedWorkTimeMinutes: number;
  vehicleNotes: string;
  inspectionHU: string;
  inspectionHURemind: string | null;
  inspectionAU: string;
  lastInspection: string | null;
  nextInspectionRemind: string | null;
  vehicleTags: string;
  jobFor: string;
  clientName: string;
  itemNumber: string;
}

export interface JobTaskFormData {
  jobCategoryId: string;
  jobSubCategoryId: string;
  description: string;
}

export interface AddedJobCategory {
  categoryId: string;
  subCategories: AddedJobCategorySubcategory[];
}

export interface AddedJobCategorySubcategory {
  id: string;
  selected: boolean;
  description: string;
}

export enum JobState {
  WORKSHOP = 'WORKSHOP',
  OFFICE = 'OFFICE',
}

export interface JobPhotoForm {
  id: string;
  content: string;
  tags: string;
  onlyAdmin: boolean;
  onlyOffice: boolean;
  vehiclePartId: string | null;
}

export type JobPhotoFormWithoutId = Omit<JobPhotoForm, 'id'>;

export interface JobEditFormData {
  keyPosition1: number | null;
  keyPosition2: number | null;
  expectedDoneAt: string;
  estimatedWorkTimeHours: number;
  estimatedWorkTimeMinutes: number;
  jobFor: string;
  clientName: string;
  itemNumber: string;
}

export interface JobUsedKey {
  key: string;
  jobId: string;
  jobKey: string;
  plate: string | null;
}
