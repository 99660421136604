import * as Yup from 'yup';

export function getCategoryValidationSchema() {
  const name = Yup.string().required('Name darf nicht leer sein');

  return Yup.object().shape({ name });
}

export function getSubCategoryValidationSchema() {
  const name = Yup.string().required('Name darf nicht leer sein');

  return Yup.object().shape({ name });
}
