import { Button, Card, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import FormInput from '../../../libs/components/forms/FormInput';
import { getSubCategoryValidationSchema } from '../config/validation';
import * as React from 'react';

interface SubCategoryFormProps {
  submitButtonLabel: string;
  submitButtonColor?: string;
  initialValues: Partial<any>;
  onSubmit: (data: any) => void;
}

export function SubCategoryForm({
  submitButtonLabel,
  submitButtonColor = 'success',
  initialValues,
  onSubmit,
}: SubCategoryFormProps) {
  const methods = useForm<any>({
    defaultValues: initialValues,
    mode: 'all',
    resolver: yupResolver(getSubCategoryValidationSchema()),
  });

  return (
    <FormProvider {...methods}>
      <Card style={{ width: '50%', minWidth: 400 }} className="bg-light">
        <Card.Body>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormInput name="name" label="Name" type="text" required={true} />
            <FormInput
              name="_categories"
              label="Weitere Unterkategorien"
              type="textarea"
              rows={20}
              required={false}
            />
            <Button variant={submitButtonColor} type="submit" className="mt-3">
              {submitButtonLabel}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </FormProvider>
  );
}
