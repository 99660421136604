import React, { useEffect, useState } from 'react';

import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import VehiclePartsTable from './VehiclePartsTable';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { JobVehiclePart } from '../../../jobs/types/jobs';
import {
  getJobVehicleParts,
  toggleJobVehiclePartOrderStatus,
  updateJobVehiclePartNotes,
} from '../../services/api';
import VehiclePartInfoEditModal from '../VehiclePartInfoEditModal';
import ConfirmModal from '../../../../libs/components/modals/ConfirmModal';

export default function VehiclePartsList() {
  const [vehicleParts, setVehicleParts] = useState<JobVehiclePart[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [orderStatusFilter, setOrderStatusFilter] = useState<string>('');
  const [editedPart, setEditedPart] = useState<JobVehiclePart | undefined>();
  const [notesModal, setNotesModal] = useState<boolean>(false);
  const [toggleStatusModal, setToggleStatusModal] = useState<boolean>(false);
  const [toggleStatusPartId, setToggleStatusPartId] = useState<string>();

  useEffect(() => {
    loadParts();
  }, [filter, orderStatusFilter]);

  function loadParts() {
    getJobVehicleParts(filter, orderStatusFilter).then(data => setVehicleParts(data));
  }

  async function onToggleOrderStatus(partId: string) {
    setToggleStatusPartId(partId);
    await setToggleStatusModal(true);
  }

  async function onConfirmToggleOrderStatus() {
    if (!toggleStatusPartId) {
      return;
    }
    await toggleJobVehiclePartOrderStatus(toggleStatusPartId);
    setToggleStatusModal(false);
    loadParts();
  }

  async function onNoteEdit(partId: string) {
    setEditedPart(vehicleParts.find(item => item.id === partId));
    setNotesModal(true);
  }

  async function onNoteSave(content: string) {
    if (!editedPart) {
      return;
    }
    await updateJobVehiclePartNotes(editedPart.id, content);
    setNotesModal(false);
    loadParts();
  }

  async function onStatusFilter(event: React.ChangeEvent<HTMLSelectElement>) {
    setOrderStatusFilter(event.target.value);
  }

  return (
    <>
      {editedPart && (
        <VehiclePartInfoEditModal
          content={editedPart.notes || ''}
          show={notesModal}
          onSaved={onNoteSave}
          onCancel={() => {
            setEditedPart(undefined);
            setNotesModal(false);
          }}
        />
      )}
      <ConfirmModal
        show={toggleStatusModal}
        title="Bestellstatus ändern"
        onConfirm={onConfirmToggleOrderStatus}
        onCancel={() => setToggleStatusModal(false)}>
        Möchten Sie wirklich den Bestellstatus ändern?
      </ConfirmModal>
      <PageToolbar pageTitle="Autoteile">
        <Row>
          <Col lg={{ offset: 0, span: 2 }} className="pt-2">
            Filtern
          </Col>
          <Col lg={{ span: 4 }}>
            <Form.Control onChange={e => setFilter(e.target.value || '')} />
          </Col>
          <Col className="pt-2" lg={2}>
            Status
          </Col>
          <Col>
            <Form.Select onChange={onStatusFilter} value={orderStatusFilter}>
              <option value="">--- Alle ---</option>
              <option value="1">Nur bestellt</option>
              <option value="0">Nur nicht bestellt</option>
            </Form.Select>
          </Col>
        </Row>
      </PageToolbar>
      <VehiclePartsTable
        parts={vehicleParts}
        toggleOrderStatus={onToggleOrderStatus}
        onNoteEdit={onNoteEdit}
      />
    </>
  );
}
