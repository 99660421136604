import { Table } from 'react-bootstrap';

import { formatDate } from '../../../../libs/services/date';
import { Job, JobState } from '../../types/jobs';
import { Link } from 'react-router-dom';
import Status from '../../../../libs/components/data/Status';
import { useAuth } from '../../../../libs/context/AuthContext';
import {
  Icon,
  IconArchive,
  IconDelete,
  IconDetails,
  IconEdit,
  IconSeparator,
  IconWaitingRoom,
} from '../../../../libs/components/data/Icon';

interface JobsTableProps {
  jobs: Job[];
  onDelete: (job: Job) => void;
  onArchive: (job: Job) => void;
  onMoveToWaitingFrom: (job: Job) => void;
}

// to be archived job must be done and do not contain a vehicle
function canBeArchived(job: Job): boolean {
  return !job.isArchived && job.isDone && !job.vehicle;
}

export default function JobsTable({
  jobs,
  onArchive,
  onDelete,
  onMoveToWaitingFrom,
}: JobsTableProps) {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  function onConfirmDelete(item: Job) {
    return function () {
      onDelete(item);
    };
  }

  return (
    <Table striped bordered className="shadow-sm">
      <thead>
        <tr>
          <th style={{ width: 75 }}>ID</th>
          <th style={{ width: 90 }}>Erledigt</th>
          <th style={{ width: 100 }}>Bearbeiter</th>
          <th style={{ width: 140 }}>Anlegegt am/von</th>
          <th>Aufgabenman.</th>
          <th style={{ width: 100 }}>Fertig bis</th>
          <th style={{ width: 115 }}>Kennzeichen / Beschreibung</th>
          <th>Marke</th>
          <th>KM-Stand</th>
          <th>Pos. Schlnr.</th>
          <th style={{ width: 175 }}>Aktionen</th>
        </tr>
      </thead>
      <tbody>
        {jobs.map(item => (
          <tr key={item.id}>
            <td>{item.jobKey}</td>
            <td>
              <Status status={item.isDone} labelTrue="Ja" labelFalse="Nein" />
              {item.isArchived && (
                <Status
                  status={item.isArchived}
                  colorTrue="warning"
                  labelTrue="Archiviert"
                  labelFalse=""
                />
              )}
            </td>
            <td>
              <Status
                status={item.jobState === JobState.WORKSHOP}
                labelTrue="Werkstatt"
                labelFalse="Büro"
                colorTrue="primary"
                colorFalse="warning"
              />
            </td>
            <td>
              {formatDate(item.createdAt, 'datetime')}
              <br />
              {item.createdBy.name}
            </td>
            <td>{item.mainAssignedUser.name}</td>
            <td>{formatDate(item.expectedDoneAt, 'date')}</td>
            {item.vehicle && (
              <>
                <td>{item.vehicle?.plate || '---'}</td>
                <td>
                  {item.vehicle?.brandName || '---'} {item.vehicle?.brandTypeName || ''}
                </td>
                <td>{item.vehicle ? item.mileage : '---'}</td>
              </>
            )}
            {!item.vehicle && <td colSpan={3}>{item.jobFor}</td>}
            <td>
              {item.keyPosition1} / {item.keyPosition2}
            </td>
            <td>
              <Link to={`/jobs/${item.id}`} className="text-info">
                <IconDetails />
              </Link>
              <span className="px-2"></span>
              {item.vehicle !== null && (
                <>
                  <Link to={`/vehicles/${item.vehicle.id}`} className="text-info">
                    <Icon icon="car-front-fill" />
                  </Link>
                  <IconSeparator />
                </>
              )}
              <Link to={`/jobs/edit/${item.id}`} className="text-info">
                <IconEdit />
              </Link>
              {user.isAdmin && (
                <>
                  <IconSeparator />
                  <IconDelete onClick={onConfirmDelete(item)} />
                </>
              )}
              <IconSeparator />
              <IconWaitingRoom onClick={() => onMoveToWaitingFrom(item)} />
              {canBeArchived(item) && (
                <>
                  <IconSeparator />
                  <IconArchive onClick={() => onArchive(item)} />
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
