import { Badge, Table } from 'react-bootstrap';

import { getFormattedDateOrEmpty } from '../../../../libs/services/date';
import { LogEntry } from '../../types/logs';
import { getActionColor } from '../../services/logs';
import { Link } from 'react-router-dom';
import { IconDetails } from '../../../../libs/components/data/Icon';
import TableHeader from '../../../../libs/components/data/table/TableHeader';
import { Sort } from '../../../../types/sort';

interface LogsTableProps {
  logs: LogEntry[];
  sort: Sort;
  onSort?: (sort: Sort) => void;
}

const TABLE_COLUMNS = [
  { key: 'createdAt', label: 'Gespeichert am', width: 160, sortable: true },
  { key: 'ip', label: 'IP', sortable: true },
  { key: 'appModule', label: 'Modul', sortable: true },
  { key: 'action', label: 'Aktion', sortable: true },
  { key: 'user', label: 'Benutzer', sortable: true },
  { key: 'content', label: 'Inhalt' },
];

export default function LogsTable({ logs, sort, onSort }: LogsTableProps) {
  return (
    <Table striped bordered className="shadow-sm">
      <TableHeader columns={TABLE_COLUMNS} actionColumn={true} currentSort={sort} onSort={onSort} />
      <tbody>
        {logs.map(item => (
          <tr key={item.id}>
            <td>{getFormattedDateOrEmpty(item.createdAt, 'datetimesec')}</td>
            <td>{item.ip}</td>
            <td>{item.appModule}</td>
            <td>
              <Badge bg={getActionColor(item.action)}>{item.action}</Badge>
            </td>
            <td>{item.authUser?.name}</td>
            <td>{JSON.stringify(item.content).substring(0, 50)}...</td>
            <td>
              <Link to={`/logs/${item.id}`} className="text-info">
                <IconDetails />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
