import { User } from '../../types/users';
import Status from '../../../../libs/components/data/Status';

interface UserTypeLabelProps {
  user: Readonly<User>;
}

export function UserTypeLabel({ user }: UserTypeLabelProps) {
  let label = 'USER';
  let color = 'dark';

  if (user.isAdmin) {
    label = 'ADMIN';
    color = 'primary';
  } else if (user.isOffice) {
    label = 'OFFICE';
    color = 'info';
  }

  return <Status status={true} labelTrue={label} labelFalse="" colorTrue={color} colorFalse="" />;
}
