import React, { useState } from 'react';

import { SectionTitle } from '../../../../../libs/components/data/SectionTitle';
import { JobFile } from '../../../types/jobs';
import JobFilesTable from './JobFilesTable';
import JobFileAddForm, { SelectedFile } from './JobFileAddForm';
import { useAuth } from '../../../../../libs/context/AuthContext';
import ConfirmModal from '../../../../../libs/components/modals/ConfirmModal';

interface JobFilesProps {
  files: JobFile[];
  onFilesUpload: (files: SelectedFile[]) => void;
  onFileUpdate?: (file: JobFile, field: keyof JobFile, value: any) => void;
  onAllTagsUpdate?: (updatedFiles: JobFile[]) => void;
  onFileDelete?: (file: JobFile) => void;
}

export default function JobFiles({
  files,
  onFilesUpload,
  onFileUpdate,
  onAllTagsUpdate,
  onFileDelete,
}: JobFilesProps) {
  const { user, isAdminOrOffice } = useAuth();
  const [deleteFileModal, setDeleteFileModal] = useState<boolean>(false);
  const [actionItem, setActionItem] = useState<JobFile | undefined>();

  function onConfirmFileDelete(file: JobFile) {
    setActionItem(file);
    setDeleteFileModal(true);
  }

  function doFileDelete() {
    if (!actionItem || onFileDelete === undefined) {
      return;
    }
    onFileDelete(actionItem);
    setActionItem(undefined);
    setDeleteFileModal(false);
  }

  if (!user) {
    return null;
  }

  function filterFiles(): JobFile[] {
    if (!user) {
      return [];
    }
    if (user.isAdmin) {
      return files;
    }
    return files.filter(file => {
      if (file.onlyAdmin && !user.isAdmin) {
        return false;
      } else if (file.onlyOffice && !isAdminOrOffice()) {
        return false;
      }
      return true;
    });
  }

  return (
    <>
      <ConfirmModal
        show={deleteFileModal}
        title="Datei löschen"
        onConfirm={doFileDelete}
        onCancel={() => setDeleteFileModal(false)}>
        <>
          <div>Möchten Sie wirklich die Datei löschen?</div>
          <div>
            <strong>{actionItem?.fileName}</strong>
          </div>
        </>
      </ConfirmModal>
      <SectionTitle title="Dateien" />
      <JobFileAddForm authUser={user} onFilesUpload={onFilesUpload} />
      <JobFilesTable
        files={filterFiles()}
        authUser={user}
        onUpdate={isAdminOrOffice() && onFileUpdate !== undefined ? onFileUpdate : undefined}
        onDelete={user.isAdmin && onFileDelete !== undefined ? onConfirmFileDelete : undefined}
        onAllTagsUpdate={isAdminOrOffice() ? onAllTagsUpdate : undefined}
      />
    </>
  );
}
