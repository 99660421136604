import { Button, Card, Form, Stack } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { JobPhotoForm, JobVehiclePart } from '../../types/jobs';
import { v4 } from 'uuid';
import { SectionTitle } from '../../../../libs/components/data/SectionTitle';
import { IconDelete } from '../../../../libs/components/data/Icon';
import PhotoVehiclePartsForm from '../JobDetails/PhotoVehiclePartsForm';
import { useAuth } from '../../../../libs/context/AuthContext';

interface PhotoFormProps {
  label?: string;
  photos: JobPhotoForm[];
  vehicleParts: JobVehiclePart[];
  onCapture: (photo: JobPhotoForm) => void;
  onDelete: (id: string) => void;
  onTagsEdit: (id: string, content: string) => void;
  onPhotoEdit?: (id: string, field: string, value: any) => void;
}

export default function PhotoForm({
  label = 'Bilder',
  photos,
  vehicleParts,
  onCapture,
  onDelete,
  onTagsEdit,
  onPhotoEdit,
}: PhotoFormProps) {
  const { user } = useAuth();
  const ref = useRef<Webcam>(null);
  const [enableCam, setEnableCam] = useState<boolean>(false);

  const capture = () => {
    if (!ref || !ref.current) {
      return;
    }
    const imageSrc = ref.current.getScreenshot();
    if (imageSrc === null) {
      return;
    }
    onCapture({
      id: v4(),
      content: imageSrc,
      tags: '',
      onlyAdmin: false,
      onlyOffice: false,
      vehiclePartId: null,
    });
  };

  if (!user) {
    return null;
  }

  return (
    <div className="mb-4 mt-4">
      {label && <SectionTitle title={label} />}
      <Card>
        <Card.Body>
          <div className="mb-2">
            <Button variant="light" onClick={() => setEnableCam(!enableCam)}>
              Camera {!enableCam ? 'einschalten' : 'ausschalten'}
            </Button>
          </div>
          {enableCam && (
            <>
              <div>
                <Webcam
                  ref={ref}
                  screenshotFormat="image/jpeg"
                  screenshotQuality={1}
                  minScreenshotWidth={2048}
                />
              </div>
              <Button variant="info" onClick={capture}>
                <i className="bi bi-camera-fill"></i>
              </Button>
            </>
          )}
          {photos.length > 0 && (
            <div className="mt-4">
              <Stack direction="horizontal" gap={3}>
                {photos.map(photo => (
                  <Card className="me-3 bg-light" key={`photo-${photo.id}`}>
                    <Card.Body className="p-2">
                      <div className="mb-3">
                        <img src={photo.content} width={175} alt={photo.id} />
                      </div>
                      <div className="mb-2">
                        <Form.Control
                          size="sm"
                          className="d-inline"
                          style={{ width: 175 }}
                          value={photo.tags}
                          onChange={e => onTagsEdit(photo.id, e.target.value)}
                        />
                      </div>
                      {onPhotoEdit !== undefined && (
                        <>
                          <Stack className="mt-2 text-muted" direction="horizontal">
                            {user.isAdmin && (
                              <Form.Check
                                label="Admin"
                                checked={photo.onlyAdmin}
                                onChange={e => onPhotoEdit(photo.id, 'onlyAdmin', e.target.checked)}
                              />
                            )}
                            <span className="px-2"></span>
                            {(user.isOffice || user.isAdmin) && (
                              <Form.Check
                                label="Büro"
                                checked={photo.onlyOffice}
                                onChange={e =>
                                  onPhotoEdit(photo.id, 'onlyOffice', e.target.checked)
                                }
                              />
                            )}
                          </Stack>
                          {vehicleParts && (
                            <PhotoVehiclePartsForm
                              vehicleParts={vehicleParts}
                              selectedPartId={photo.vehiclePartId}
                              onSelect={(partId: string | null) =>
                                onPhotoEdit(photo.id, 'vehiclePartId', partId)
                              }
                            />
                          )}
                        </>
                      )}
                      <div>
                        <IconDelete onClick={() => onDelete(photo.id)} />
                      </div>
                    </Card.Body>
                  </Card>
                ))}
              </Stack>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
