import { Col, Form, Row } from 'react-bootstrap';
import { Fragment, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  StorageCategory,
  StorageSubCategoryLevel1,
  StorageSubCategoryLevel2,
} from '../../../storage_categories/types/storageCategories';

interface CategoryFormProps {
  categories: StorageCategory[];
}

export default function CategoryForm({ categories }: CategoryFormProps) {
  const [selectedSubcategoriesLevel1, setSelectedSubcategoriesLevel1] = useState<
    StorageSubCategoryLevel1[] | undefined
  >();
  const [selectedSubcategoriesLevel2, setSelectedSubcategoriesLevel2] = useState<
    StorageSubCategoryLevel2[] | undefined
  >();

  const {
    watch,
    register,
    formState: { errors, touchedFields },
  } = useFormContext();

  const catWatch = watch('category');
  const subCat1Watch = watch('subCategory1');

  useEffect(() => {
    if (!catWatch) {
      setSelectedSubcategoriesLevel1(undefined);
      setSelectedSubcategoriesLevel2(undefined);
    } else {
      const selectedCat = categories.find(cat => cat.id === catWatch);
      setSelectedSubcategoriesLevel1(selectedCat?.subCategoriesLevel1);
    }
  }, [catWatch]);

  useEffect(() => {
    if (!subCat1Watch) {
      setSelectedSubcategoriesLevel2(undefined);
    } else {
      const selectedCat = categories.find(cat => cat.id === catWatch);
      const selectedSubCat1 = selectedCat?.subCategoriesLevel1.find(sub => sub.id === subCat1Watch);

      setSelectedSubcategoriesLevel2(selectedSubCat1?.subCategoriesLevel2);
    }
  }, [subCat1Watch]);

  let validClassCategory = '';
  if (touchedFields['category']) {
    validClassCategory = errors['category'] ? 'is-invalid' : 'is-valid';
  }
  let validClassSubCategory1 = '';
  if (touchedFields['subCategory1']) {
    validClassSubCategory1 = errors['subCategory1'] ? 'is-invalid' : 'is-valid';
  }

  return (
    <Row className="mb-3">
      <Col lg={3}>
        <Form.Label>
          Kategorie <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select {...register('category', { required: true })} className={validClassCategory}>
          <option value="">--- Auswählen ---</option>
          {categories.map(category => (
            <option value={category.id} key={category.id}>
              {category.name}
            </option>
          ))}
        </Form.Select>
        {errors['category'] !== undefined && (
          <p className="text-danger">{errors['category']?.message as string}</p>
        )}
      </Col>
      {selectedSubcategoriesLevel1 && (
        <Col lg={3}>
          <Form.Group className="mb-5">
            <Form.Label>
              Unterkategorie 1 <span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              {...register('subCategory1', { required: true })}
              className={validClassSubCategory1}>
              <option value="">--- Auswählen ---</option>
              {selectedSubcategoriesLevel1.map(subCategoryLevel1 => (
                <Fragment key={subCategoryLevel1.id}>
                  <option value={subCategoryLevel1.id}>{subCategoryLevel1.name}</option>
                </Fragment>
              ))}
            </Form.Select>
            {errors['subCategory'] !== undefined && (
              <p className="text-danger">{errors['subCategory1']?.message as string}</p>
            )}
          </Form.Group>
        </Col>
      )}
      {selectedSubcategoriesLevel2 && (
        <Col lg={3}>
          <Form.Group className="mb-5">
            <Form.Label>Weitere Unterkategorien</Form.Label>
            <Form.Select {...register('subCategory2')}>
              <option value="">--- Auswählen ---</option>
              {selectedSubcategoriesLevel2.map(subCategoryLevel2 => (
                <Fragment key={subCategoryLevel2.id}>
                  <option value={subCategoryLevel2.id}>{subCategoryLevel2.name}</option>
                  {subCategoryLevel2.subCategoriesLevel3.map(subCategoryLevel3 => (
                    <Fragment key={subCategoryLevel3.id}>
                      <option value={subCategoryLevel3.id}>
                        {`\u00A0\u00A0\u00A0`}
                        {subCategoryLevel3.name}
                      </option>
                      {subCategoryLevel3.subCategoriesLevel4.map(subCategoryLevel4 => (
                        <Fragment key={subCategoryLevel4.id}>
                          <option value={subCategoryLevel4.id}>
                            {`\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0`}
                            {subCategoryLevel4.name}
                          </option>
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      )}
    </Row>
  );
}
