import PageToolbar from '../../../../libs/components/actions/PageToolbar';
import { useReducer, useState } from 'react';
import ButtonLink from '../../../../libs/components/actions/ButtonLink';
import Message from '../../../../libs/components/data/Message';
import ApiErrorMessage from '../../../../libs/components/data/ApiErrorMessage';
import {
  FormActionState,
  getFormActionReducer,
} from '../../../../libs/services/state/formActionReducer';
import { StorageArticle, StorageArticleFormData } from '../../types/storage';
import { addArticle } from '../../services/api';
import { ArticleForm } from './ArticleForm';

const initialState: FormActionState = {
  actionInProgress: false,
  actionSuccess: false,
  actionError: false,
  showForm: true,
  actionErrorResponse: undefined,
};

export default function ArticleAdd() {
  const [createdArticle, setCreatedArticle] = useState<StorageArticle>();
  const [state, dispatch] = useReducer(getFormActionReducer(initialState), initialState);

  async function onSubmit(data: StorageArticleFormData) {
    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      const article = await addArticle(data);
      setCreatedArticle(article);
      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
      dispatch({ type: 'TOGGLE_FORM' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    }
  }

  return (
    <>
      <PageToolbar pageTitle="Artikel hinzufügen">
        <ButtonLink link="/storage" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      {state.showForm && (
        <div className="mb-4">
          <ArticleForm
            submitButtonLabel="Artikel hinzufügen"
            onSubmit={onSubmit}
            initialValues={{}}
          />
        </div>
      )}
      {state.actionError && (
        <ApiErrorMessage
          label="Artikel konnte nicht hinzugefügt werden"
          errorResponse={state.actionErrorResponse}
        />
      )}
      {state.actionSuccess && (
        <Message type="ok">
          <span>
            <strong>Artikel wurde hinzugefügt</strong>
          </span>
          <div className="mt-4">
            <ButtonLink
              buttonColor="light"
              icon="pencil-square"
              link={`/storage/edit/${createdArticle?.id}`}>
              Artikel bearbeiten
            </ButtonLink>
          </div>
        </Message>
      )}
    </>
  );
}
