export function getActionColor(action: string): string {
  switch (action) {
    case 'ERROR':
      return 'danger';
    case 'ADD':
    case 'UPDATE':
    case 'DELETE':
      return 'success';
    case 'LOGIN':
    case 'ACCESS':
      return 'warning';
    case 'CRON':
      return 'primary';
    default:
      return 'secondary';
  }
}
