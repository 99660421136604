import * as Yup from 'yup';

const category = Yup.string().required('Kategorie darf nicht leer sein');
const subCategory1 = Yup.string().required('Unterkategorie darf nicht leer sein');
const manufacturer = Yup.string().required('Hersteller darf nicht leer sein');
const supplier = Yup.string().required('Lieferant darf nicht leer sein');

const orderDate = Yup.date()
  .required('Bestelldatum darf nicht leer sein')
  .typeError('Bestelldatum must ein valides Datum sein');

const billNumber = Yup.string().required('Rechnungsnummer darf nicht leer sein');
const quantity = Yup.number()
  .required('Menge darf nicht leer sein')
  .typeError('Menge muss eine Zahl sein')
  .integer('Falsches Format')
  .positive('Menge muss eine positive Zahl sein');

const unit = Yup.string().required('Einheit darf nicht leer sein');

const purchasingNetPrice = Yup.number()
  .required('EK Preis Netto darf nicht leer sein')
  .typeError('EK Preis Netto muss eine Zahl sein')
  .integer('Falsches Format')
  .positive('EK Preis Netto muss eine positive Zahl sein');

const perUnitPurchasingNetPrice = Yup.number()
  .required('EK Preis Netto pro Einheit darf nicht leer sein')
  .typeError('EK Preis Netto pro Einheit muss eine Zahl sein')
  .integer('Falsches Format')
  .positive('EK Preis Netto pro Einheit muss eine positive Zahl sein');

const sellingNetPrice = Yup.number()
  .required('VK Preis Netto darf nicht leer sein')
  .typeError('VK Preis Netto muss eine Zahl sein')
  .integer('Falsches Format')
  .positive('VK Preis Netto muss eine positive Zahl sein');

const perUnitSellingNetPrice = Yup.number()
  .required('VK Preis Netto pro Einheit darf nicht leer sein')
  .typeError('VK Preis Netto pro Einheit muss eine Zahl sein')
  .integer('Falsches Format')
  .positive('VK Preis Netto pro Einheit muss eine positive Zahl sein');

const storagePlace = Yup.string().required('Lageort darf nicht leer sein');
const shelf = Yup.string().required('Regalnummer darf nicht leer sein');
const board = Yup.string().required('Gestellnummer darf nicht leer sein');

export function getValidationSchema() {
  const schema: Record<string, Yup.AnySchema> = {
    category,
    subCategory1,
    manufacturer,
    supplier,
    orderDate,
    billNumber,
    quantity,
    unit,
    purchasingNetPrice,
    perUnitPurchasingNetPrice,
    sellingNetPrice,
    perUnitSellingNetPrice,
    storagePlace,
    shelf,
    board,
  };

  return Yup.object().shape(schema);
}
