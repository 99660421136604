import { Brand } from '../../brands/types/brands';
import { SelectOption } from '../../../libs/components/forms/FormInput';

export function mapBrandsToForm(brands: Brand[]): SelectOption[] {
  return brands.map(item => ({ value: item.id, label: item.name }));
}

export function mapBrandTypesToForm(brandId: string, brands: Brand[]): SelectOption[] {
  const brand = brands.find(item => item.id === brandId);
  if (!brand) {
    return [];
  }

  return brand.types.map(item => ({ value: item.id, label: item.name }));
}
