import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import styles from './LoginForm.module.scss';
import FormInput from '../../../libs/components/forms/FormInput';
import { UserFormData } from '../../users/types/users';
import { login as loginApi } from '../services/api';
import { getSystemInfo } from '../../system_status/services/api';
import { SystemInfo } from '../../system_status/types/info';
import { useAuth } from '../../../libs/context/AuthContext';
import { IconWEMA } from '../../../libs/components/data/Icon';

const validationSchema = Yup.object().shape({
  login: Yup.string().required('Benutzername darf nicht leer sein'),
  password: Yup.string().required('Passwort darf nicht leer sein'),
});

export default function LoginForm() {
  const auth = useAuth();
  const [error, setError] = useState<number>(0);
  const [systemInfo, setSystemInfo] = useState<SystemInfo>();
  const navigate = useNavigate();
  const methods = useForm<UserFormData>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    getSystemInfo().then(data => setSystemInfo(data));
  }, []);

  async function onSubmit(data: { login: string; password: string }) {
    setError(0);
    try {
      const { token, user } = await loginApi(data.login, data.password);
      auth.login(token, user);
      const mainPath = user.isAdmin || user.isOffice ? '/' : '/my-jobs';
      setTimeout(() => navigate(mainPath), 0);
    } catch (err: any) {
      setError(err.response?.status || -1);
    }
  }

  function getErrorMessage(err: number) {
    switch (err) {
      case 400:
      case 401:
        return 'Falscher Benurzername/Passwort';
      case 403:
        return 'Zugriff verweigert';
      case 422:
        return 'Eine Benutzersitzung bereits existiert. Melden Sie sich zuerst ab';
      default:
        return 'Unbekannter Fehler';
    }
  }

  if (!systemInfo) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Card className="bg-light">
        <Card.Header className="text-primary">
          <IconWEMA color="primary" /> <strong>Werkstatt Manager</strong>
        </Card.Header>
        <FormProvider {...methods}>
          {error !== 0 && <div className="text-danger mt-4">{getErrorMessage(error)}</div>}
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Card.Body>
              <FormInput name="login" label="Benutzername" type="text" required={true} />
              <FormInput name="password" label="Passwort" type="password" required={true} />
              <Button variant="primary" type="submit">
                ANMELDEN
              </Button>
            </Card.Body>
          </Form>
        </FormProvider>
      </Card>
      <div className="text-muted mt-2">
        v.{systemInfo.version} {systemInfo.environment}
      </div>
    </div>
  );
}
