import PageToolbar from '../../../libs/components/actions/PageToolbar';
import { UserForm } from './UserForm';
import { User, UserFormData } from '../types/users';
import { addUser } from '../services/api';
import { useReducer, useState } from 'react';
import ButtonLink from '../../../libs/components/actions/ButtonLink';
import Message from '../../../libs/components/data/Message';
import ApiErrorMessage from '../../../libs/components/data/ApiErrorMessage';
import {
  FormActionState,
  getFormActionReducer,
} from '../../../libs/services/state/formActionReducer';

const initialState: FormActionState = {
  actionInProgress: false,
  actionSuccess: false,
  actionError: false,
  showForm: true,
  actionErrorResponse: undefined,
};

export default function UserAdd() {
  const [createdUser, setCreatedUser] = useState<User>();
  const [state, dispatch] = useReducer(getFormActionReducer(initialState), initialState);

  async function onSubmit(data: UserFormData) {
    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
    try {
      const user = await addUser(data);
      setCreatedUser(user);
      dispatch({ type: 'TOGGLE_ACTION_IN_PROGRESS' });
      dispatch({ type: 'TOGGLE_ACTION_SUCCESS' });
      dispatch({ type: 'TOGGLE_FORM' });
    } catch (err) {
      dispatch({ type: 'TOGGLE_ACTION_ERROR' });
      dispatch({ type: 'SET_ACTION_ERROR_RESPONSE', payload: err });
    }
  }

  return (
    <>
      <PageToolbar pageTitle="Benutzer anlegen">
        <ButtonLink link="/users" buttonColor="secondary" icon="arrow-left-circle-fill">
          Zurück
        </ButtonLink>
      </PageToolbar>
      {state.showForm && (
        <div className="mb-4" style={{ width: '25%', minWidth: 400 }}>
          <UserForm
            submitButtonLabel="Benutzer anlegen"
            onSubmit={onSubmit}
            initialValues={{ enabled: true }}
          />
        </div>
      )}
      {state.actionError && (
        <ApiErrorMessage
          label="Benutzer konnte nicht angelegt werden"
          errorResponse={state.actionErrorResponse}
        />
      )}
      {state.actionSuccess && (
        <Message type="ok">
          <span>
            <strong>Benutzer wurde angelegt</strong>
          </span>
          <div className="mt-4">
            <ButtonLink
              buttonColor="light"
              icon="pencil-square"
              link={`/users/edit/${createdUser?.id}`}>
              Benutzer bearbeiten
            </ButtonLink>
          </div>
        </Message>
      )}
    </>
  );
}
