import { API } from '../../../libs/services/api';
import { Brand, BrandFormData, BrandTypeFormData } from '../types/brands';

const BASE = 'brands';

export async function getBrands(): Promise<Brand[]> {
  return (await API.get(BASE)).data;
}

export async function getBrand(id: string): Promise<Brand> {
  return (await API.get(`${BASE}/${id}`)).data;
}

export async function addBrand(data: BrandFormData): Promise<Brand> {
  const payload = {
    name: data.name,
  };
  return (await API.post(BASE, payload)).data;
}

export async function addBrandType(brandId: string, data: BrandTypeFormData): Promise<Brand> {
  return (await API.post(`${BASE}/${brandId}/types`, data)).data;
}

export async function updateBrand(id: string, data: BrandFormData) {
  return (await API.patch(`${BASE}/${id}`, data)).data;
}

export async function updateBrandType(brandId: string, typeId: string, data: BrandTypeFormData) {
  return (await API.patch(`${BASE}/${brandId}/types/${typeId}`, data)).data;
}
