import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Vehicle } from '../../types/vehicles';
import { formatDate, getFormattedDateOrEmpty } from '../../../../libs/services/date';
import Status from '../../../../libs/components/data/Status';
import TableHeader from '../../../../libs/components/data/table/TableHeader';
import { Icon, IconDetails, IconEdit, IconSeparator } from '../../../../libs/components/data/Icon';

interface VehiclesTableProps {
  vehicles: Vehicle[];
  excludePlatesFromArchive: string[];
  onVehicleArchive: (vehicle: Vehicle) => void;
}

const TABLE_COLUMNS = [
  { key: 'createdAt', label: 'Ang. am', width: 90 },
  { key: 'isArchived', label: 'Arch.', width: 50 },
  { key: 'plate', label: 'Kennz.', width: 100 },
  { key: 'mainAssignedUser', label: 'Aufgabenman.', width: 125 },
  { key: 'brandName', label: 'Marke' },
  { key: 'mileage', label: 'KM-Stand', width: 90 },
  { key: 'registrationDate', label: 'Erstzulassung', width: 115 },
  { key: 'hu/au', label: 'HU/AU' },
  { key: 'expectedDoneAt', label: 'Fertig bis' },
  { key: 'lastInspection', label: 'Letzte Insp.' },
  { key: 'partNumber', label: 'Pos. Schl.' },
];

export default function VehiclesTable({
  vehicles,
  excludePlatesFromArchive,
  onVehicleArchive,
}: VehiclesTableProps) {
  return (
    <Table striped bordered hover className="shadow-sm">
      <TableHeader columns={TABLE_COLUMNS} actionColumn={true} />
      <tbody>
        {vehicles.map(item => (
          <tr key={item.id}>
            <td>{formatDate(item.createdAt, 'date')}</td>
            <td>
              <Status
                status={item.isArchived}
                labelTrue="Ja"
                labelFalse="Nein"
                colorFalse="success"
                colorTrue="warning"
              />
            </td>
            <td>{item.plate}</td>
            <td>{item.mainAssignedUser ? item.mainAssignedUser.name : '---'}</td>
            <td>
              {item.brandName} {item.brandTypeName}
            </td>
            <td>{item.mileage}</td>
            <td>{formatDate(item.registrationDate, 'date')}</td>
            <td>
              {formatDate(item.inspectionHU, 'date')}
              <br />
              {formatDate(item.inspectionAU, 'date')}
            </td>
            <td>{getFormattedDateOrEmpty(item.expectedDoneAt, 'date')}</td>
            <td>{item.lastInspection ? formatDate(item.lastInspection, 'date') : '---'}</td>
            <td>
              {item.keyPosition1 !== null ? `${item.keyPosition1} / ${item.keyPosition2}` : ''}
            </td>
            <td>
              <Link to={`/vehicles/${item.id}`} className="text-info">
                <IconDetails />
              </Link>
              <IconSeparator />
              <Link to={`/vehicles/edit/${item.id}`} className="text-info">
                <IconEdit />
              </Link>
              {item.latestJobId !== null && (
                <>
                  <IconSeparator />
                  <Link to={`/jobs/${item.latestJobId}`} className="text-info">
                    <Icon icon="clipboard-check-fill" />
                  </Link>
                  <IconSeparator />
                </>
              )}
              {!item.isArchived && !excludePlatesFromArchive.includes(item.plate) && (
                <>
                  <IconSeparator />
                  <a href="#" className="text-warning" onClick={() => onVehicleArchive(item)}>
                    <Icon icon="archive-fill" />
                  </a>
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
