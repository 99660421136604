import { Card, Col, Form, Row, Stack } from 'react-bootstrap';
import { JobPhoto } from '../../../jobs/types/jobs';
import { formatDate } from '../../../../libs/services/date';
import React from 'react';
import { getPhotoPreviewPath } from '../../services/photos';
import { User } from '../../../users/types/users';
import { IconDelete } from '../../../../libs/components/data/Icon';

interface PhotosTableProps {
  photos: JobPhoto[];
  authenticatedUser: User;
  onPhotoClick: (photo: JobPhoto) => void;
  onPhotoDelete: (photo: JobPhoto) => void;
  onPhotoUpdate?: (photo: JobPhoto, field: string, value: any) => void;
}

export default function PhotosTable({
  photos,
  authenticatedUser,
  onPhotoClick,
  onPhotoDelete,
  onPhotoUpdate,
}: PhotosTableProps) {
  return (
    <Row>
      {photos.map(item => (
        <Col key={item.fileName} lg={2} className="mb-3">
          <Card className="bg-light">
            <Card.Body className="p-2">
              <div style={{ overflow: 'hidden', height: 125 }}>
                <img
                  style={{ cursor: 'pointer', maxHeight: 125 }}
                  src={getPhotoPreviewPath(item)}
                  alt={item.fileName}
                  onClick={() => onPhotoClick(item)}
                  className="img-fluid"
                />
              </div>
              <Row style={{ fontSize: '0.8rem' }}>
                <Col lg={9}>
                  <div className="text-muted mt-1">
                    {formatDate(item.createdAt, 'datetime')}, {item.createdBy.name}
                  </div>
                  <div className="my-2" style={{ fontSize: '0.8rem' }}>
                    {item.tags.join(', ')}
                  </div>
                  {onPhotoUpdate !== undefined && (
                    <Stack className="text-muted" direction="horizontal">
                      {authenticatedUser.isAdmin && (
                        <Form.Check
                          label="Admin"
                          checked={item.onlyAdmin === true}
                          onChange={e => onPhotoUpdate(item, 'onlyAdmin', e.target.checked)}
                        />
                      )}
                      <span className="px-2"></span>
                      <Form.Check
                        label="Büro"
                        checked={item.onlyOffice === true}
                        onChange={e => onPhotoUpdate(item, 'onlyOffice', e.target.checked)}
                      />
                    </Stack>
                  )}
                </Col>
                <Col lg={3} className="mt-1 text-end">
                  {authenticatedUser.isAdmin && <IconDelete onClick={() => onPhotoDelete(item)} />}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
