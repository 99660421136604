import { Fragment } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import styles from './Menu.module.scss';
import { MODULES } from '../../config/modules';
import { useAuth } from '../../libs/context/AuthContext';

interface MenuProps {
  onModuleSelect?: () => void;
}

export default function Menu({ onModuleSelect }: MenuProps) {
  const navigate = useNavigate();
  const { user, isUserAuthenticated, hasUserPermission } = useAuth();

  function openPage(link: string) {
    return function () {
      if (onModuleSelect) {
        onModuleSelect();
      }
      navigate(link);
    };
  }

  if (!isUserAuthenticated() || !user) {
    return null;
  }

  const modules = MODULES.filter(item => hasUserPermission(item.permissions));

  return (
    <ListGroup className="shadow-sm">
      {modules.map(group => (
        <Fragment key={group.name}>
          <ListGroup.Item className={styles.groupHeader}>
            <strong>{group.label}</strong>
          </ListGroup.Item>
          {group.items
            .filter(item => hasUserPermission(item.permissions))
            .map(item => (
              <ListGroup.Item
                key={item.name}
                action
                className={`${styles.item} bg-light`}
                onClick={openPage(item.link)}>
                <i className={`${styles.icon} bi bi-${item.icon}`}></i> {item.label}
              </ListGroup.Item>
            ))}
        </Fragment>
      ))}
    </ListGroup>
  );
}
