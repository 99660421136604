import { Button, Form, Modal } from 'react-bootstrap';
import * as React from 'react';
import { useState } from 'react';

import ErrorList from '../../../libs/components/data/ErrorList';
import { addStorageSubCategory } from '../services/api';

interface AddSubCategoryModalProps {
  selectedCategory: { id: string; name: string } | undefined;
  show: boolean;
  onSaved: () => void;
  onCancel: () => void;
}

export default function AddSubCategoryModal(props: AddSubCategoryModalProps) {
  const [name, setName] = useState<string>('');
  const [errorResponse, setErrorResponse] = useState<unknown>();

  async function onSubmit() {
    if (name.trim() === '' || !props.selectedCategory) {
      return;
    }

    try {
      await addStorageSubCategory(props.selectedCategory.id, { name });
      setName('');
      props.onSaved();
    } catch (err) {
      setErrorResponse(err);
    }
  }

  function onCancel() {
    setName('');
    props.onCancel();
  }

  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  if (!props.selectedCategory) {
    return null;
  }

  return (
    <Modal show={props.show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          Unterkategorie für <strong>{props.selectedCategory.name}</strong> hinzufügen
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Form.Group>
            <Form.Label>
              Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control type="text" onChange={onChangeName} value={name} />
          </Form.Group>
          {errorResponse && <ErrorList errorResponse={errorResponse} />}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Abbrechen
        </Button>
        <Button variant="primary" onClick={onSubmit} disabled={name.trim() === ''}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
